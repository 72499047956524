import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Watermark } from "@hirohe/react-watermark";
import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import { CustomTheme } from "../../../../../types/customTheme";
import g_line_1 from "../../../../../assets/images/g_line_1.png";
import g_line_2 from "../../../../../assets/images/g_line_2.png";
import g_line_3 from "../../../../../assets/images/g_line_3.png";
import g_line_4 from "../../../../../assets/images/g_line_4.png";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.entity_background,
    },
    color: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

export interface DashboardProps {
  data: any;
  dateDifference: any;
  dataSetLength: any;
  setDataIndex: any;
  isEmpty?: any;
  currentStartDate: any;
  currentEndDate: any;
  order: any;
}

const StackedBarChart: React.FunctionComponent<DashboardProps> = ({
  data,
  dateDifference,
  dataSetLength,
  setDataIndex,
  isEmpty,
  currentStartDate,
  currentEndDate,
  order,
}) => {
  const classes = useStyles();
  const theme: CustomTheme = useTheme();

  /* Change the tooltip label using the x-axis of the chart. */
  const handleTooltipLabelDateFormat = (label: any) => {
    if (label === "12 AM") {
      return "12:00 AM";
    } else {
      // Input = 1 AM Output 1:00 AM
      return moment(label, "HH").format("hh:mm A");
    }
  };

  let img: any = {};
  img["1"] = new Image();
  img["1"].src = g_line_1;
  img["1"].height = 8;
  img["1"].width = 12;

  img["2"] = new Image();
  img["2"].src = g_line_2;
  img["2"].height = 8;
  img["2"].width = 12;

  img["3"] = new Image();
  img["3"].src = g_line_3;
  img["3"].height = 8;
  img["3"].width = 12;

  img["4"] = new Image();
  img["4"].src = g_line_4;
  img["4"].height = 8;
  img["4"].width = 12;

  img["5"] = new Image();
  img["5"].src = g_line_1;
  img["5"].height = 8;
  img["5"].width = 12;

  img["6"] = new Image();
  img["6"].src = g_line_2;
  img["6"].height = 8;
  img["6"].width = 12;

  /* Change the chart UI after select date range */
  const optionsOneDateRange: any = {
    maintainAspectRatio: false,
    elements: {
      line: {
        // line width
        tension: 0.5,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      xAxes: {
        stacked: true,
        type: "time",
        time: {
          displayFormats: {
            day: "YYYY-MM-DD",
          },
          unit: "day",
        },
        ticks: {
          // Change the font size in the weekend dates.
          font: (c: any) => {
            return (
              (moment(c.tick.Date).day() === 6 ||
                moment(c.tick.Date).day() === 0) && { weight: "600" }
            );
          },
          // Change the font color in the weekend dates.
          color: (c: any) => {
            return moment(c.tick.Date).day() === 6 ||
              moment(c.tick.Date).day() === 0
              ? theme.palette.background.g_line_3
              : theme.palette.custom.orange.contrastText;
          },
          callback: function (label: any, index: any, labels: any) {
            labels[index].Date = label;
            return moment(label).format("Do");
          },
        },
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        suggestedMax: 100,
        ticks: {
          beginAtZero: false,
          max: 0,
          min: -0.5,
          color: theme.palette.custom.orange.contrastText,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        multiKeyBackground: "red",
        callbacks: {
          labelPointStyle: (context: any) => {
            // A line will show the current Period.
            if (context.dataset.label === "Current Period") {
              return {
                rotation: 0,
                radius: 5,
              };
            } else {
              // A dotted line will show the current Period.
              return {
                rotation: 0,
                radius: 5,
              };
            }
          },
          title: (context: any) => {
            return "";
          },
          textDirection: "ltr",
          // Change the tooltip description
          label: (context: any) => {
            setDataIndex(context.dataIndex);
            // Change tool tip description from previous periods
            if (context.dataset.label === "Previous Period") {
              // To display the value without a decimal format for an order.
              if (order) {
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  moment(context.label)
                    .subtract(dateDifference, "d")
                    .format("ll") +
                  "   " +
                  context.formattedValue.replace(",", "")
                );
              } else {
                // To display the value with a decimal format for an other tooltips.
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  moment(context.label)
                    .subtract(dateDifference, "d")
                    .format("ll") +
                  "   " +
                  parseFloat(context.formattedValue.replace(",", "")).toFixed(2)
                );
              }
            } else {
              // To display the value without a decimal format for an order.
              if (order) {
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  moment(context.label).format("ll") +
                  "   " +
                  context.formattedValue.replace(",", "")
                );
              } else {
                // To display the value with a decimal format for an other tooltips.
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  moment(context.label).format("ll") +
                  "   " +
                  parseFloat(context.formattedValue.replace(",", "")).toFixed(2)
                );
              }
            }
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          // Show chart details in the footer.
          generateLabels: function (chart: any) {
            const data = chart.data;
            const legends = Array.isArray(data.datasets)
              ? data.datasets.map(function (dataset: any, i: any) {
                  return {
                    text:
                      dataset.label === "Previous Period"
                        ? `${dataset.key.replace("Payments", "")} (Previous)`
                        : `${dataset.key.replace("Payments", "")} (Current)`,
                    fillStyle: dataset.backgroundColor,
                    hidden: !chart.isDatasetVisible(i),
                    fontColor: theme.palette.custom.orange.contrastText,
                    lineDash: dataset.borderDash,
                    lineWidth: dataset.borderWidth,
                    strokeStyle: dataset.backgroundColor,
                    fill: dataset.fill,
                    datasetIndex: i,
                  };
                }, this)
              : [];
            return legends;
          },
          boxWidth: 35,
          boxHeight: 6,
          padding: 32,
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  /* Change the chart UI after select one date */
  const dateOptions: any = {
    maintainAspectRatio: false,
    elements: {
      line: {
        // line width
        tension: 0.5,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      xAxes: {
        stacked: true,
        ticks: {
          color: theme.palette.custom.orange.contrastText,
        },
        grid: {
          display: false,
        },
      },
      yAxes: {
        beginAtZero: true,
        suggestedMax: 100,
        ticks: {
          color: theme.palette.custom.orange.contrastText,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        multiKeyBackground: "red",
        callbacks: {
          labelPointStyle: (context: any) => {
            // A line will show the current Period.
            if (context.dataset.label === "Current Period") {
              return {
                rotation: 0,
                radius: 5,
              };
            } else {
              // A dotted line will show the current Period.
              return {
                rotation: 0,
                radius: 5,
              };
            }
          },
          title: (context: any) => {
            return "";
          },
          textDirection: "ltr",
          // Change the tooltip description
          label: (context: any) => {
            setDataIndex(context.dataIndex);
            // Change tool tip description from previous periods
            if (context.dataset.label === "Previous Period") {
              // To display the value without a decimal format for an order.
              if (order) {
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  handleTooltipLabelDateFormat(context.label) +
                  "   " +
                  context.formattedValue.replace(",", "")
                );
              } else {
                // To display the value with a decimal format for an other tooltips.
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  handleTooltipLabelDateFormat(context.label) +
                  "   " +
                  parseFloat(context.formattedValue.replace(",", "")).toFixed(2)
                );
              }
            } else {
              // To display the value without a decimal format for an order.
              if (order) {
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  handleTooltipLabelDateFormat(context.label) +
                  "   " +
                  context.formattedValue.replace(",", "")
                );
              } else {
                // To display the value with a decimal format for an other tooltips.
                return (
                  "  " +
                  context.dataset.key +
                  "   " +
                  handleTooltipLabelDateFormat(context.label) +
                  "   " +
                  parseFloat(context.formattedValue.replace(",", "")).toFixed(2)
                );
              }
            }
          },
        },
      },
      legend: {
        position: "bottom",
        labels: {
          // Show chart details in the footer.
          generateLabels: function (chart: any) {
            const data = chart.data;
            const legends = Array.isArray(data.datasets)
              ? data.datasets.map(function (dataset: any, i: any) {
                  return {
                    text: dataset.key,
                    fillStyle: dataset.backgroundColor,
                    hidden: !chart.isDatasetVisible(i),
                    fontColor: theme.palette.custom.orange.contrastText,
                    lineDash: dataset.borderDash,
                    lineWidth: dataset.borderWidth,
                    strokeStyle: dataset.backgroundColor,
                    fill: dataset.fill,
                    datasetIndex: i,
                  };
                }, this)
              : [];
            return legends;
          },
          boxWidth: 35,
          boxHeight: 6,
          padding: 32,
          font: {
            weight: "bold",
          },
        },
      },
    },
  };
  return (
    <>
      <div
        className={classes.root}
        style={{ height: "350px" }}
        onMouseOut={() => {
          setDataIndex("");
        }}
      >
        {isEmpty ? (
          <Watermark
            lineHeight="1.2rem"
            opacity={0.2}
            rotate={-45}
            show
            text={"Sample Data"}
            textColor={theme.palette.custom.orange.contrastText}
            textSize={24}
            gutter={50}
            wrapperElement="div"
          >
            {" "}
            <div style={{ height: "350px" }}>
              <Bar
                style={{ margin: "16px 16px 0px 16px", height: "350px" }}
                options={
                  currentStartDate === currentEndDate
                    ? dateOptions
                    : optionsOneDateRange
                }
                data={data}
              />
            </div>
          </Watermark>
        ) : (
          <Bar
            style={{ margin: "16px 16px 0px 16px", height: "350px" }}
            options={
              currentStartDate === currentEndDate
                ? dateOptions
                : optionsOneDateRange
            }
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default StackedBarChart;
