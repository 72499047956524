import React, { useEffect, useState } from "react";
import { Box, Hidden } from "@material-ui/core";
import Filter from "../Filter";
import SkeleTonLoading from "../Loading/SkeleTonLoading";
import NoSalesFound from "../NoSalesFound";
import _ from "lodash";
import SaleSubTitle from "./SaleSubTitle/SaleSubTitle";
import SaleTitle from "./SaleTitle/SaleTitle";
import SalePageTitleAndLocationDetails from "./SalePageTitleAndLocationDetails";
import moment from "moment";
import PageHeaderMobile from "../PageHeader/PageHeaderMoblie";

export interface paymentReportProps {
  handleFilterData: any;
  locationSelectorList: any;
  handleLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  getUserInfo?: any;
  userListFilter?: any;
  setFilterDetails: any;
  availableFilter: any;
  isOpenSkeletonLoading: any;
  nodeList: any;
  filterDetails: any;
  children: React.ReactNode;
  title?: string;
  isChart: any;
  payInOut?: any;
  isUserGet?: any;
  isSale?: any;
  isSaleItem?: any;
  topic?: any;
  getPreviousFilterInfo?: any;
  filterTitle?: any;
  isNeedFilterDetails?: any;
  isShift?: any;
  isOrder?: any;
  setCurrentStartDate?: any;
  setCurrentEndDate?: any;
  setPreviousStartDate?: any;
  setPreviousEndDate?: any;
  previousStartDate?: any;
  previousEndDate?: any;
  isDailySale?: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  openFilterCard: any;
  setOpenFilterCard: any;
  handleOnClickText?: any;
  isReport: boolean;
  isInventory?: any;
  isWeekDateRange?: any
}
/* Set the filter, sale title, sale sub title, and table using filter details */
const SaleReport: React.FunctionComponent<paymentReportProps> = ({
  handleFilterData,
  locationSelectorList,
  handleLocationSelectorTypingList,
  isGetLocationInfo,
  getUserInfo,
  userListFilter,
  setFilterDetails,
  availableFilter,
  isOpenSkeletonLoading,
  nodeList,
  filterDetails,
  children,
  title,
  isChart,
  payInOut,
  isUserGet,
  isSale,
  isSaleItem,
  topic,
  getPreviousFilterInfo,
  filterTitle,
  isNeedFilterDetails,
  isShift,
  isOrder,
  setCurrentStartDate,
  setCurrentEndDate,
  setPreviousStartDate,
  setPreviousEndDate,
  previousStartDate,
  previousEndDate,
  isDailySale = false,
  locationSelectedList,
  setLocationSelectedList,
  openFilterCard,
  setOpenFilterCard,
  handleOnClickText,
  isReport,
  isInventory,
  isWeekDateRange
}) => {
  const [filterLocation, setFilterLocation] = useState("");
  const [filterDate, setFilterDate] = useState("");

  /* Change the filter location details and filter date using filter details list */
  useEffect(() => {
    const filterLocation = filterDetails.split("*")[0].split(",");
    if (filterLocation.length === 1) {
      setFilterLocation(filterLocation[0]);
    } else {
      setFilterLocation(filterLocation.length + " locations");
    }
    const startDateAndDate = filterDetails.split("*")[1];

    if (startDateAndDate) {
      if (startDateAndDate.split(" - ")[1]) {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const endDate = moment(startDateAndDate.split(" - ")[1]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${endDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      } else {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${startDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      }
    }
  }, [filterDetails]);
  return (
    <>
      <Box>
        <Hidden lgUp>
          <PageHeaderMobile />
        </Hidden>
          <Filter
            getFilterData={handleFilterData}
            locationSelectorList={locationSelectorList}
            getLocationSelectorTypingList={handleLocationSelectorTypingList}
            isGetLocationInfo={isGetLocationInfo}
            getUserInfo={getUserInfo}
            userList={userListFilter}
            setFilterDetails={setFilterDetails}
            availableFilter={availableFilter}
            isChart={isChart}
            payInOut={payInOut}
            isUserGet={isUserGet}
            isSale={isSale}
            isSaleItem={isSaleItem}
            getPreviousFilterInfo={getPreviousFilterInfo}
            isShift={isShift}
            isOrder={isOrder}
            setPreviousStartDate={setPreviousStartDate}
            setPreviousEndDate={setPreviousEndDate}
            setCurrentStartDate={setCurrentStartDate}
            setCurrentEndDate={setCurrentEndDate}
            previousStartDate={previousStartDate}
            previousEndDate={previousEndDate}
            locationSelectedList={locationSelectedList}
            setLocationSelectedList={setLocationSelectedList}
            openFilterCard={openFilterCard}
            setOpenFilterCard={setOpenFilterCard}
            isDailySale={isDailySale}
            isWeekDateRange={isWeekDateRange}
          />
        {!isOpenSkeletonLoading && !_.isEmpty(nodeList) && (
          <SalePageTitleAndLocationDetails
            topic={topic}
            filterDate={filterDate}
            handleOnClickText={handleOnClickText}
          />
        )}
        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(title) && <SaleTitle title={title} />}

            {!_.isEmpty(nodeList) ? (
              <>
                {isChart && !isDailySale && (
                  <SaleSubTitle
                    filterLocation={filterLocation}
                    filterDate={filterDate}
                  />
                )}
                {children}
              </>
            ) : (
              <NoSalesFound title={isOrder && "Orders"} />
            )}
          </>
        ) : (
          <div style={{ marginTop: "80px" }}>
            <SkeleTonLoading isReport={isReport} />
          </div>
        )}
      </Box>
    </>
  );
};

export default SaleReport;
