import {
  createStyles,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import ValidationMessage from "../../../../components/validation/ValidationMessage";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Autocomplete } from "@material-ui/lab";
import { CustomTheme } from "../../../../types/customTheme";
import { submitButtonName } from "../../../../utils/enum";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";

export interface BusinessInfoProps {
  register: any;
  errors: any;
  handleSubmitData: any;
  reset: any;
  paymentGatewayDetails: any;
  selectedPaymentGatewayObj: any;
  setSelectedPaymentGatewayObj: any;
  selectedFeeTypeObj: any;
  setSelectedFeeTypeObj: any;
  isDirty: any;
  setSelectedFeeTypeObjInitial: any;
  selectedFeeTypeObjInitial: any;
  setPaymentGatewayDetailsInitial: any;
  paymentGatewayDetailsInitial: any;
}

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
    dividerStyle: {
      margin: "12px 0px",
      backgroundColor: theme.palette.custom.red.main,
      height: "2px",
      borderRadius: "10px",
      width: "100%",
    },
    leftSideStyle: {
      margin: "4px 0px",
      paddingRight: "16px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
      },
    },
    rightSideStyle: {
      margin: "4px 0px",
      paddingLeft: "16px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0px",
      },
    },
    createdUpdatedStyle: {
      display: "flex",
      justifyContent: "end",
      margin: "0px",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "start",
        margin: "2px 0px",
      },
    },
  }),
);

// List of payment gateway providers
const paymentGatewayProviderList = [
  {
    id: "unallocated",
    label: "Unallocated",
  },
  { id: "stripe", label: "Stripe" },
  { id: "express", label: "Express" },
];

// List of fee types
const feeTypeList = [
  {
    id: "percent",
    label: "Percent",
  },
  { id: "fixed", label: "Fixed" },
  { id: "combined", label: "Combined" },
];

/*
  PaymentGatewayInfoNode Component:

  This component represents the form for editing payment gateway information.

  Props:
    - register: Function to register input elements with React Hook Form.
    - errors: Object containing form validation errors.
    - handleSubmitData: Function to handle form submission.
    - reset: Function to reset form fields.
    - paymentGatewayDetails: Object containing payment gateway details.
    - selectedPaymentGatewayObj: Object representing the selected payment gateway.
    - setSelectedPaymentGatewayObj: Function to set the selected payment gateway.
    - selectedFeeTypeObj: Object representing the selected fee type.
    - setSelectedFeeTypeObj: Function to set the selected fee type.
    - isDirty: Boolean indicating if the form has unsaved changes.
    - setSelectedFeeTypeObjInitial: Function to set the initial fee type.
    - selectedFeeTypeObjInitial: Object representing the initial fee type.
    - setPaymentGatewayDetailsInitial: Function to set the initial payment gateway details.
    - paymentGatewayDetailsInitial: Object representing the initial payment gateway details.
*/
const PaymentGatewayInfoNode: React.FunctionComponent<BusinessInfoProps> = ({
  register,
  errors,
  handleSubmitData,
  reset,
  paymentGatewayDetails,
  selectedPaymentGatewayObj,
  setSelectedPaymentGatewayObj,
  selectedFeeTypeObj,
  setSelectedFeeTypeObj,
  isDirty,
  setSelectedFeeTypeObjInitial,
  selectedFeeTypeObjInitial,
  setPaymentGatewayDetailsInitial,
  paymentGatewayDetailsInitial,
}) => {
  const [openPaymentGatewayProvider, setOpenPaymentGatewayProvider] =
    useState(false);
  const [openFeeType, setOpenFeeType] = useState(false);

  // Function to map payment gateway providers based on the provided type
  const mapPaymentGatewayProvider = (provider: any) => {
    switch (provider) {
      case "unallocated":
        return { id: "unallocated", label: "Unallocated" };
      case "stripe":
        return { id: "stripe", label: "Stripe" };
      case "express":
        return { id: "express", label: "Express" };
      default:
        return { id: "unallocated", label: "Unallocated" };
    }
  };

  // Function to map fee types based on the provided type
  const mapFeeType = (type: any) => {
    switch (type) {
      case "percent":
        return { id: "percent", label: "Percent" };
      case "fixed":
        return { id: "fixed", label: "Fixed" };
      case "combined":
        return { id: "combined", label: "Combined" };
      default:
        return { id: "percent", label: "Percent" };
    }
  };

  // Effect to update the form fields when paymentGatewayDetails changes
  useEffect(() => {
    if (!_.isEmpty(paymentGatewayDetails)) {
      const {
        created,
        deliveryFee,
        deliveryFeeFixed,
        dineInFee,
        dineInFeeFixed,
        entityId,
        feeType,
        gatewayLocationId,
        id,
        locationId,
        payLinkFee,
        payLinkFixedFee,
        paymentGatewayMode,
        paymentGatewayProvider,
        pickUpFee,
        pickUpFeeFixed,
        terminalFee,
        terminalFeeFixed,
        updated,
        version,
      } = paymentGatewayDetails;

      // Reset form fields with the provided values
      reset({
        created,
        deliveryFee,
        deliveryFeeFixed,
        dineInFee,
        dineInFeeFixed,
        entityId,
        feeType,
        gatewayLocationId,
        id,
        locationId,
        payLinkFee,
        payLinkFixedFee,
        paymentGatewayMode,
        paymentGatewayProvider,
        pickUpFee,
        pickUpFeeFixed,
        terminalFee,
        terminalFeeFixed,
        updated,
        version,
      });

      // Set selected payment gateway and fee type based on the data
      setSelectedPaymentGatewayObj(
        mapPaymentGatewayProvider(paymentGatewayProvider),
      );
      setPaymentGatewayDetailsInitial(
        mapPaymentGatewayProvider(paymentGatewayProvider),
      );

      setSelectedFeeTypeObj(mapFeeType(feeType));
      setSelectedFeeTypeObjInitial(mapFeeType(feeType));
    }
  }, [paymentGatewayDetails]);

  // Event handler for changing payment gateway
  const handlePaymentGatewayChange = (e: any, groupData: any) => {
    setSelectedPaymentGatewayObj(groupData);
  };

  // Event handler for changing fee type
  const handleFeeTypeChange = (e: any, groupData: any) => {
    setSelectedFeeTypeObj(groupData);
  };

  const classes = useStyles();
  const theme: CustomTheme = useTheme();
  return (
    <div style={{ margin: "12px 20px 20px 20px" }}>
      <Typography
        variant="h6"
        align="left"
        style={{
          fontWeight: "bold",
          color: theme.palette.custom.red.secondary,
        }}
      >
        Attention:
      </Typography>
      <Typography
        variant="body2"
        align="left"
        style={{
          color: "#ba2126",
        }}
      >
        Alternating the following configurations may interrupt the processing of
        online orders. Please proceed with caution. online orders. Please
        proceed with caution.
      </Typography>
      <Divider className={classes.dividerStyle} />
      <Grid container>
        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body1"
            style={{ fontWeight: "bold", color: "white", marginLeft: "4px" }}
          >
            Gateway Provider
          </Typography>
          <Autocomplete
            open={openPaymentGatewayProvider}
            onOpen={() => setOpenPaymentGatewayProvider(true)}
            onClose={() => setOpenPaymentGatewayProvider(false)}
            size={"small"}
            id="locationSelectGlobal"
            color="inherit"
            options={paymentGatewayProviderList}
            value={selectedPaymentGatewayObj}
            getOptionLabel={(option: any) => option.label || ""}
            disableClearable
            onChange={handlePaymentGatewayChange}
            classes={{ root: classes.autoComplete }}
            popupIcon={<ArrowDropDownIcon color={"inherit"} />}
            renderOption={(props: any) => {
              return (
                <Grid
                  container
                  {...props}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    zIndex: 0,
                    height: "20px",
                    marginBottom: "4px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.titleColor}>
                      {props.label}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
            renderInput={(params: any) => (
              <TextField
                color="inherit"
                {...params}
                label=""
                placeholder="Select Fee Type"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body1"
            style={{ fontWeight: "bold", color: "white", marginLeft: "4px" }}
          >
            Free Type
          </Typography>
          <Autocomplete
            open={openFeeType}
            onOpen={() => setOpenFeeType(true)}
            onClose={() => setOpenFeeType(false)}
            size={"small"}
            id="locationSelectGlobal"
            color="inherit"
            options={feeTypeList}
            value={selectedFeeTypeObj}
            getOptionLabel={(option: any) => option.label || ""}
            disableClearable
            onChange={handleFeeTypeChange}
            classes={{ root: classes.autoComplete }}
            popupIcon={<ArrowDropDownIcon color={"inherit"} />}
            renderOption={(props: any) => {
              return (
                <Grid
                  container
                  {...props}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    zIndex: 0,
                    height: "20px",
                    marginBottom: "4px",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.titleColor}>
                      {props.label}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
            renderInput={(params: any) => (
              <TextField
                color="inherit"
                {...params}
                label=""
                placeholder="Select Payment Gateway Provider"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: "12px" }}>
          <Typography
            align="left"
            variant="body1"
            style={{ fontWeight: "bold", color: "white", marginLeft: "4px" }}
          >
            Entity Id
          </Typography>
          <TextfieldCommon
            id="entityId"
            name="entityId"
            type="text"
            inputRef={register({
              required: "Entity Id is required",
            })}
          />
          {errors.entityId ? (
            <ValidationMessage message={errors.entityId.message} />
          ) : (
            ""
          )}
        </Grid>
        <Divider className={classes.dividerStyle} />

        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Pickup Percentage
          </Typography>
          <TextfieldCommon
            id="pickUpFee"
            name="pickUpFee"
            type="number"
            label=""
            inputRef={register({
              required: "Pickup Percentage is required",
              min: {
                value: 0,
                message: "Pickup Percentage is need greater than 0.",
              },
            })}
          />
          {errors.pickUpFee ? (
            <ValidationMessage message={errors.pickUpFee.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Pickup Fixed Fee
          </Typography>
          <TextfieldCommon
            id="pickUpFeeFixed"
            name="pickUpFeeFixed"
            type="number"
            label=""
            inputRef={register({
              required: "Pickup Fixed Fee is required",
              min: {
                value: 0,
                message: "Pickup Fixed Fee is need greater than 0.",
              },
            })}
          />
          {errors.pickUpFeeFixed ? (
            <ValidationMessage message={errors.pickUpFeeFixed.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Delivery Percentage
          </Typography>
          <TextfieldCommon
            id="deliveryFee"
            name="deliveryFee"
            type="number"
            label=""
            inputRef={register({
              required: "Delivery Percentage is required",
              min: {
                value: 0,
                message: "Delivery Percentage is need greater than 0.",
              },
            })}
          />
          {errors.deliveryFee ? (
            <ValidationMessage message={errors.deliveryFee.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Delivery Fixed Fee
          </Typography>
          <TextfieldCommon
            id="deliveryFeeFixed"
            name="deliveryFeeFixed"
            type="number"
            label=""
            inputRef={register({
              required: "Delivery Fixed Fee is required",
              min: {
                value: 0,
                message: "Delivery Fixed Fee is need greater than 0.",
              },
            })}
          />
          {errors.deliveryFeeFixed ? (
            <ValidationMessage message={errors.deliveryFeeFixed.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Dine In Percentage
          </Typography>
          <TextfieldCommon
            id="dineInFee"
            name="dineInFee"
            type="number"
            label=""
            inputRef={register({
              required: "Dine In Percentage is required",
              min: {
                value: 0,
                message: "Dine In Percentage is need greater than 0.",
              },
            })}
          />
          {errors.dineInFee ? (
            <ValidationMessage message={errors.dineInFee.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Dine In Fixed Fee
          </Typography>
          <TextfieldCommon
            id="dineInFeeFixed"
            name="dineInFeeFixed"
            type="number"
            label=""
            inputRef={register({
              required: "Dine In Fixed Fee is required",
              min: {
                value: 0,
                message: "Dine In Fixed Fee is need greater than 0.",
              },
            })}
          />
          {errors.dineInFeeFixed ? (
            <ValidationMessage message={errors.dineInFeeFixed.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Terminal Percentage
          </Typography>
          <TextfieldCommon
            id="terminalFee"
            name="terminalFee"
            type="number"
            label=""
            inputRef={register({
              required: "Terminal Percentage is required",
              min: {
                value: 0,
                message: "Terminal Percentage is need greater than 0.",
              },
            })}
          />
          {errors.terminalFee ? (
            <ValidationMessage message={errors.terminalFee.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Terminal Fixed Fee
          </Typography>
          <TextfieldCommon
            id="terminalFeeFixed"
            name="terminalFeeFixed"
            type="number"
            label=""
            inputRef={register({
              required: "Terminal Fixed Fee is required",
              min: {
                value: 0,
                message: "Terminal Fixed Fee is need greater than 0.",
              },
            })}
          />
          {errors.terminalFeeFixed ? (
            <ValidationMessage message={errors.terminalFeeFixed.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Pay Link Percentage
          </Typography>
          <TextfieldCommon
            id="payLinkFee"
            name="payLinkFee"
            type="number"
            label=""
            inputRef={register({
              required: "Pay Link Percentage is required",
              min: {
                value: 0,
                message: "Pay Link Percentage is need greater than 0.",
              },
            })}
          />
          {errors.payLinkFee ? (
            <ValidationMessage message={errors.payLinkFee.message} />
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12} sm={6} className={classes.rightSideStyle}>
          <Typography
            align="left"
            variant="body2"
            style={{ marginLeft: "4px" }}
          >
            Pay Link Fixed Fee
          </Typography>
          <TextfieldCommon
            id="payLinkFixedFee"
            name="payLinkFixedFee"
            type="number"
            label=""
            inputRef={register({
              required: "PayLinK Fixed Fee is required",
              min: {
                value: 0,
                message: "PayLinK Fixed Fee is need greater than 0.",
              },
            })}
          />
          {errors.payLinkFixedFee ? (
            <ValidationMessage message={errors.payLinkFixedFee.message} />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: "flex" }}>
          <Typography variant="body2">Id :</Typography>
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {paymentGatewayDetails.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.createdUpdatedStyle}>
          <Typography variant="body2">Created :</Typography>
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {convertDateTimeFormat(paymentGatewayDetails.created)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: "flex" }}>
          <Typography variant="body2">Version :</Typography>
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {paymentGatewayDetails.version}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.createdUpdatedStyle}>
          <Typography variant="body2">Updated :</Typography>
          <Typography variant="body2" style={{ marginLeft: "8px" }}>
            {convertDateTimeFormat(paymentGatewayDetails.updated)}
          </Typography>
        </Grid>

        {/* <Grid item xs={6}>
          <MyEditor />
        </Grid> */}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              width: "180px",
              marginTop: "16px",
            }}
          >
            <ButtonCommon
              style={{
                flex: 1,
                fontSize: 11,
                marginRight: 4,
                width: "180px",
              }}
              disabled={
                !isDirty &&
                selectedFeeTypeObjInitial.id === selectedFeeTypeObj.id &&
                paymentGatewayDetailsInitial.id === selectedPaymentGatewayObj.id
              }
              variant="contained"
              onClick={handleSubmitData}
            >
              <Typography variant="body1" style={{ color: "black" }}>
                {submitButtonName.UPDATE_SUBMIT_BUTTON}
              </Typography>
            </ButtonCommon>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentGatewayInfoNode;
