import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Grow,
  Hidden,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { Skeleton } from "@material-ui/lab";
import ShareIcon from "@material-ui/icons/Share";

import { CustomTheme } from "../../../../../types/customTheme";
import {
  fetchStockOrderItemForStockOrder,
  updateStockOrderItemStatus,
  deliveredStockOrder,
} from "../../../../../services/inventory/stockOrder";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../../utils/consts";
import { fetchAllStockItemForSupplierInfo } from "../../../../../services/inventory/supplier";
import CardCommon from "../../../../../components/card/CardCommon";
import DeliveryDetailsModal from "../DeliveryDetailsModal";
import { CapitalizeFirstLetter } from "../../../../../utils/ReplaceIcon";
import { convertDateTimeFormatInventory } from "../../../../../utils/ConvertDateTimeFormat";
import DialogCommonOverflow from "../../../../../components/dialogs/DialogOverflowContainer";
import { buttonColors } from "../../../../../utils/enum";
import ButtonCommon from "../../../../../components/buttons/ButtonCommon";
import DefaultAlert from "../../../../../components/alerts/DefaultAlert";

const getColorByStatus = (statusInitial: any) => {
  switch (statusInitial.toLowerCase()) {
    case "delivered":
      return "#5BB995";
    default:
      return "#AC3B52";
  }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  rowColor: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    marginTop: "16px",
  },
  firstGridField: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: "16px",
  },
  gridField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  lastGridField: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    paddingRight: "16px",
  },
  lastGridFieldDeliveredItems: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  lastGridFieldOrderTopic: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    paddingRight: "130px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "108px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "52px",
    },
  },
  lastGridFieldOrder: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  tableData: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
    },
  },
  createdUpdatedDateStyle: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "start",
      marginTop: "8px",
    },
  },
  createdDateStyle: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginRight: "39px",
    },
  },
  updatedRootStyle: {
    fontWeight: "bold",
    marginRight: "4px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "41px",
    },
  },
  updatedDataStyle: {
    marginLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "14px",
    },
  },
  statusRootStyle: {
    fontWeight: "bold",
    marginRight: "48px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "53px",
    },
  },
  statusStyle: {
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "13px",
    },
  },
  supplierNameRoot: {
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      marginTop: "9px",
    },
  },
  supplierNameStyle: {
    fontWeight: "bold",
    marginLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "9px",
      marginTop: "9px",
    },
  },
  supplierNameDataStyle: {
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "13px",
      marginTop: "9px",
    },
  },
  createdDataStyle: {
    marginLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "11px",
    },
  },
  statusColor: {
    height: "32px",
    width: "22px",
    borderRadius: "10px",
    marginRight: "4px",
    marginLeft: "12px",
  },
}));

export interface paymentReportProps {
  isOpenEditDeliveryDetailsModal: any;
  setIsOpenEditDeliveryDetailsModal: any;
  setSuccess: any;
  setError: any;
  handleGetStockOrdersListSorting: any;
  supplierId: any;
  orderId: any;
  selectedStockOrderDetails: any;
  handleOpenDeliveryDetailsModal: any;
  isOpenDeliveryDetailsModal: any;
  setIsOpenDeliveryDetailsModal: any;
  deliveryDetailsNode: any;
  isDeliver: any;
  setIsLoadingToast: any;
  setToastType: any;
  setToastMessage: any;
  isLoadingToast: any;
}

/**
 * EditDeliveryStockOrderItem Component
 *
 * This component is responsible for rendering a dialog that allows users to
 * edit and manage details of a stock order, including ordered and delivered items.
 *
 * Key Features:
 * - Displays stock order details such as status, creation date, supplier name, and update date.
 * - Lists ordered and delivered items separately with relevant information.
 * - Supports editing of delivered items and confirming delivery.
 * - Utilizes custom dialog and modal components for a user-friendly interface.
 */
const EditDeliveryStockOrderItem: React.FunctionComponent<
  paymentReportProps
> = ({
  isOpenEditDeliveryDetailsModal,
  setIsOpenEditDeliveryDetailsModal,
  setSuccess,
  setError,
  handleGetStockOrdersListSorting,
  supplierId,
  orderId,
  selectedStockOrderDetails,
  handleOpenDeliveryDetailsModal,
  isOpenDeliveryDetailsModal,
  setIsOpenDeliveryDetailsModal,
  deliveryDetailsNode,
  isDeliver,
  setIsLoadingToast,
  setToastType,
  setToastMessage,
  isLoadingToast,
}) => {
  const [stockOrderListStatusOrdered, setStockOrderListStatusOrdered] =
    useState<any>([]);
  const [stockOrderListStatusDelivered, setStockOrderListStatusDelivered] =
    useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stockItemsDetailsObj, setSStockItemsDetailsObj] = useState<any>({});
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [inProp, setInProp] = useState(true);
  const [successs, setSuccesss] = useState("");

  const match: any = useRouteMatch();

  /**
   * This useEffect hook runs when the component is mounted (empty dependency array).
   */
  useEffect(() => {
    // Call the function to fetch stock order items for the current stock order.
    getAllStockOrderItemsForStockOrder();

    // Call the function to fetch all stock items for the specified supplier and location.
    getAllStockItemsForSupplier();
  }, []);

  /**
   * This function fetches stock order items for a given location and order ID,
   * processes the response data to extract unique stock item IDs, and updates
   * relevant states based on the fetched data and IDs.
   */
  const getAllStockOrderItemsForStockOrder = async () => {
    try {
      // Fetch stock order items for the specified location and order ID
      const res = await fetchStockOrderItemForStockOrder(
        match.params.locationId,
        orderId,
      );

      // Check if any stock order items were found in the response
      if (res.data.status !== 404) {
        // Update states with fetched data and unique stock item IDs

        // Filter items with status 'order' and store them in 'statusOrdered' array
        const statusOrdered = res.data.data.filter(
          (data: any) => data.status === "order",
        );

        // Filter items with status other than 'order' and store them in 'statusDelivered' array
        const statusDelivered = res.data.data.filter(
          (data: any) => data.status !== "order",
        );

        // Update the state for ordered items with 'statusOrdered' array
        setStockOrderListStatusOrdered(statusOrdered);

        // Update the state for delivered items with 'statusDelivered' array
        setStockOrderListStatusDelivered(statusDelivered);
      }
    } catch (error) {
      // Handle errors

      // Disable loading indicator
      setIsLoading(false);

      // Disable any relevant functionality
      setIsDisable(false);

      // If an error occurs during the API call, set the 'error' state and provide an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetches all stock items for a specified supplier and location.
   * Updates component states based on the API response.
   */
  const getAllStockItemsForSupplier = async () => {
    try {
      // Fetch all stock items for the specified supplier and location
      const res = await fetchAllStockItemForSupplierInfo(
        match.params.locationId,
        supplierId,
      );

      // Check if no stock items were found for the supplier and location
      if (res.data.status === 404) {
        // If no items were found, set the 'isEmpty' state to indicate empty results
        setSStockItemsDetailsObj({});
      } else {
        // Create an object to store stock item details with IDs as keys and names as values
        const stockItemsDetails: any = {};

        // Map through the received data and populate the stockItemsDetails object
        res.data.data.forEach((item: any) => {
          stockItemsDetails[item.id] = item.name;
        });

        // Set the 'stockItemsDetailsObj' state with the populated object
        setSStockItemsDetailsObj(stockItemsDetails);
      }

      // Update component states
      setIsLoading(false); // Set loading state to false
      setIsDisable(false); // Enable the component (disable state to false)
    } catch (error) {
      // If an error occurs during the API call
      // Set the 'error' state with an error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      // Set loading state to false
      setIsLoading(false);
      // Enable the component (disable state to false)
      setIsDisable(false);
    }
  };

  /**
   * This function handles the update of stock order item status.
   * It sends a request to update the status of the specified stock order item.
   * If successful, it sets a success message, updates the stock orders list, and closes the modal.
   * If an error occurs, it sets an error message.
   * @param {Object} output - The updated status information for the stock order item.
   */
  const handleUpdateStatus = async (output: any) => {
    try {
      // Send a request to update the stock order item status.
      await updateStockOrderItemStatus(
        match.params.locationId,
        orderId,
        output,
      );
      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_UPDATED);
      setToastType("success");

      // Update the stock orders list to reflect the changes.
      handleGetStockOrdersListSorting();

      // Close the edit delivery details modal.
      setIsOpenEditDeliveryDetailsModal(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Handles the action when exiting the page. Depending on the edit mode,
   * either prepares and sends an update request with stock order items' data
   * or closes the modal.
   */
  const handleExitPage = () => {
    if (isEdit) {
      // In edit mode, prepare the output object with updated stock order item data.
      const updatedItems = stockOrderListStatusDelivered.map((item: any) => ({
        stockOrderItemId: item.id,
        delivered_qty: item.deliveredQty,
        status: item.status,
        total_cost: parseFloat(item.totalCost).toFixed(2),
        unit_price: parseFloat(item.unitPrice).toFixed(2),
      }));

      // Create the output object with updated stock order items.
      const output = {
        stockOrderitems: updatedItems,
      };

      setIsLoadingToast(true);
      setToastMessage("Loading...");
      setToastType("info");

      // Send an update request with the prepared output.
      handleUpdateStatus(output);
    } else {
      // If not in edit mode, close the edit delivery details modal.
      setIsOpenEditDeliveryDetailsModal(false);
    }
  };

  /**
   * Handles the change of a checkbox for confirming delivery.
   * Sets the checkbox state, closes the delivery details modal, and triggers
   * an update of the delivery data.
   * @param {Object} event - The event object (e.g., a click event).
   * @param {Object} item - The stock order item associated with the checkbox.
   */
  const handleChangeCheckBox = (event: any, item: any) => {
    // Set the checkbox state to true.
    setIsCheckBox(true);

    // Close the delivery details modal.
    setIsOpenDeliveryDetailsModal(false);

    // Set the 'inProp' state to false (if applicable).
    setInProp(false);

    // Prevent the event from further propagation.
    if (event) {
      event.stopPropagation();
    }

    // Prepare the data for updating delivery using the checkbox.
    const deliveryData = {
      deliveredQty: item?.orderQty || 0,
      orderQty: item?.orderQty || 0,
      totalCost: item?.totalCost || 0,
      unitPrice: item?.unitPrice || 0,
    };

    // Trigger an update of the delivery data with the prepared data.
    handleUpdateDeliveryUsingCheckBox(deliveryData, "delivered", item.id);
  };

  /**
   * Handles placing an order by updating the selected stock order's status to 'delivered'.
   */
  const handlePlaceOrder = async () => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");

    // In edit mode, prepare the output object with updated stock order item data.
    const updatedItems = stockOrderListStatusDelivered.map((item: any) => ({
      stockOrderItemId: item.id,
      deliveredQty: item.deliveredQty,
      status: item.status,
      totalCost: parseFloat(item.totalCost).toFixed(2),
      unitPrice: parseFloat(item.unitPrice).toFixed(2),
    }));

    // Create the output object with updated stock order items.
    const output = {
      stockOrderItems: updatedItems,
    };

    // const currentDate = moment().format("YYYY-MM-DD");
    // // Prepare the form data for updating the stock order status.
    // const formData = {
    //   id: selectedStockOrderDetails.id,
    //   locationId: selectedStockOrderDetails.locationId,
    //   supplierId: selectedStockOrderDetails.supplierId.id,
    //   status: "delivered",
    //   version: selectedStockOrderDetails.version,
    //   deliveredOn: currentDate,
    // };

    try {
      // Update the stock order's status to 'delivered'.
      await deliveredStockOrder(
        match.params.locationId,
        output,
        selectedStockOrderDetails.id,
      );

      // Trigger a sorting update of the stock orders list.
      handleGetStockOrdersListSorting();

      // Close the edit delivery details modal.
      setIsOpenEditDeliveryDetailsModal(false);

      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_UPDATED);
      setToastType("success");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  /**
   * Determines whether the 'Confirm Delivery' button should be disabled based on the availability of ordered items.
   * @returns {boolean} True if the button should be disabled, false otherwise.
   */
  const shouldDisableConfirmDeliveryButton = (): boolean => {
    return !_.isEmpty(stockOrderListStatusOrdered);
  };

  /**
   * Updates the delivery details of a stock order item and manages relevant states.
   * @param {Object} data - The updated delivery data.
   * @param {string} status - The new status of the stock order item.
   */
  const handleUpdateStockOrderItemDelivery = (data: any, status: string) => {
    if (isDeliver) {
      // Handle updates when in 'Deliver' mode
      const clonedDeliveredItems = _.cloneDeep(stockOrderListStatusDelivered);
      const updatedDeliveredItems = clonedDeliveredItems.map((item: any) => {
        if (item.id === deliveryDetailsNode.id) {
          // Update the delivery details for the matching item
          item.deliveredQty = data?.deliveredQty;
          item.totalCost = data?.totalCost;
          item.unitPrice = data?.unitPrice;
          item.status = status;
        }
        return item;
      });

      // Update the state with the modified delivered items
      setStockOrderListStatusDelivered(updatedDeliveredItems);
    } else {
      // Handle updates when not in 'Deliver' mode
      const clonedOrderedItems = _.cloneDeep(stockOrderListStatusOrdered);
      const filteredOrderedItems = clonedOrderedItems.filter(
        (item: any) => item.id !== deliveryDetailsNode.id,
      );

      // Find the matching delivery item

      const filteredDeliveryItem = clonedOrderedItems.filter(
        (item: any) => item.id === deliveryDetailsNode.id,
      )[0];

      // Update the delivery details for the matching item
      filteredDeliveryItem["deliveredQty"] = data?.deliveredQty;
      filteredDeliveryItem["totalCost"] = data?.totalCost;
      filteredDeliveryItem["unitPrice"] = data?.unitPrice;
      filteredDeliveryItem["status"] = status;

      // Combine the updated delivery item with existing delivered items
      const updatedDeliveredItems = [
        filteredDeliveryItem,
        ...stockOrderListStatusDelivered,
      ];

      // Update the states with modified data
      setStockOrderListStatusDelivered(updatedDeliveredItems);
      setStockOrderListStatusOrdered(filteredOrderedItems);
    }

    // Set flags to indicate that changes have been made
    setIsEdit(true);
    setIsCheckBox(false);
  };

  /**
   * Sets the 'inProp' state to 'true' after a delay.
   */
  const setInPropToTrue = () => {
    // Add any necessary logic here before setting 'inProp' to 'true'
    setInProp(true);
  };

  /**
   * Updates the delivery details of a stock order item using checkbox data and manages relevant states.
   * @param {Object} updatedData - The updated delivery data.
   * @param {string} newStatus - The new newStatus of the stock order item.
   * @param {string} itemId - The unique identifier of the stock order item.
   */
  const handleUpdateDeliveryUsingCheckBox = (
    updatedData: any,
    newStatus: string,
    itemId: string,
  ) => {
    // Clone the 'stockOrderListStatusOrdered' state to avoid modifying the original
    const clonedOrderedItems = _.cloneDeep(stockOrderListStatusOrdered);

    // Filter out the stock order item with the matching 'itemId' from 'clonedOrderedItems'
    const updatedOrderedItems = clonedOrderedItems.filter(
      (item: any) => item.id !== itemId,
    );

    // Find the stock order item with the matching 'itemId' from 'clonedOrderedItems'
    const filteredOrderedItem = clonedOrderedItems.find(
      (item: any) => item.id === itemId,
    );

    // Clone the 'stockOrderListStatusDelivered' state to avoid modifying the original
    const clonedDeliveredItems = _.cloneDeep(stockOrderListStatusDelivered);

    // Update the delivery details for the matching item
    filteredOrderedItem.deliveredQty = updatedData?.deliveredQty;
    filteredOrderedItem.totalCost = updatedData?.totalCost;
    filteredOrderedItem.unitPrice = updatedData?.unitPrice;
    filteredOrderedItem.status = newStatus;

    // Combine the updated item with 'clonedDeliveredItems'
    const updatedDeliveredItems = [
      filteredOrderedItem,
      ...clonedDeliveredItems,
    ];

    // Update the 'stockOrderListStatusDelivered' state with the new data
    setStockOrderListStatusDelivered(updatedDeliveredItems);

    // Update the 'stockOrderListStatusOrdered' state without the item that was updated
    setStockOrderListStatusOrdered(updatedOrderedItems);

    // Delay any further actions by 500 milliseconds (replace 'myFunction' with actual functionality)
    setTimeout(setInPropToTrue, 500);

    // Set a flag to indicate that changes have been made
    setIsEdit(true);
  };

  /**
   * Generates a grid of Skeleton elements for loading placeholders.
   *
   * @param {number} count - The number of Skeleton elements to render.
   * @param {boolean} alternating - Whether to alternate styles (odd/even) for Skeleton elements.
   * @returns {JSX.Element} - The grid of Skeleton elements.
   */
  const generateSkeletonGrid = (count: any): JSX.Element => {
    const skeletons = [];

    for (let i = 0; i < count; i++) {
      skeletons.push(
        <Grid item xs={12} key={i}>
          <Skeleton
            variant="rect"
            className={classes.rowColor}
            width="100%"
            height={40}
          />
        </Grid>,
      );
    }

    return <Grid container>{skeletons}</Grid>;
  };

  /**
   * Renders a loading skeleton grid.
   *
   * @returns {JSX.Element} - The loading skeleton grid.
   */
  const handleSkeleton = (): JSX.Element => {
    // Specify the number of Skeleton elements and whether to alternate styles.
    const skeletonCount = 6; // Adjust as needed

    return generateSkeletonGrid(skeletonCount);
  };
  const theme: CustomTheme = useTheme();

  /**
   * Render header for the ordered or delivered items list.
   *
   * @param {boolean} isOrder - Indicates whether it's the order header or delivered header.
   * @returns {JSX.Element} - React component for the header.
   */
  const handleHeader = (isOrder: boolean): JSX.Element => {
    return (
      <CardCommon backgroundColor={"table_header_background"}>
        <Grid
          container
          style={{
            backgroundColor: theme.palette.background.table_header_background,
          }}
        >
          <Grid item xs={3} className={classes.firstGridField}>
            <Typography align="left" className={classes.tableData}>
              Name
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridField}>
            <Typography className={classes.tableData}> Order Qty</Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridField}>
            <Typography className={classes.tableData}>
              {" "}
              Delivered Qty
            </Typography>
          </Grid>
          <Grid item xs={isOrder?2:1} className={classes.gridField}>
            <Typography className={classes.tableData}> Unit Price</Typography>
          </Grid>
          {!isOrder && <Grid item xs={2} className={classes.gridField}>
            <Typography className={classes.tableData}> Status</Typography>
          </Grid>}
          <Grid
            item
            xs={isOrder?3:2}
            className={
              isOrder &&
              (selectedStockOrderDetails.status === "ordered" ||
                selectedStockOrderDetails.status === "viewed")
                ? classes.lastGridFieldOrderTopic
                : classes.lastGridField
            }
          >
            <Typography className={classes.tableData}> Total</Typography>
          </Grid>
        </Grid>
      </CardCommon>
    );
  };

  const handleClick = () => {
    setSuccesss("Copied!");
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PRESTO_COMMON_DOMAIN}/${match.params.locationId}/inventory/order/${orderId}`,
    );
    window.open(
      `${process.env.REACT_APP_PRESTO_COMMON_DOMAIN}/${match.params.locationId}/inventory/order/${orderId}`,
      "_blank",
    );
  };

  const classes = useStyles();

  return (
    // Render a dialog component with order details and actions.
    <>
      <DefaultAlert
        open={!!successs}
        handleClose={() => setSuccesss("")}
        message={successs}
        severity={"success"}
      />
      <DialogCommonOverflow
        open={isOpenEditDeliveryDetailsModal}
        setOpen={setIsOpenEditDeliveryDetailsModal}
        isLoading={isLoading}
      >
        <DialogContent>
          {/* Check if not loading */}
          {!isLoading ? (
            // If not loading, show the content
            <>
              <Hidden xsDown>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ width: "300px" }}>
                    <Typography variant="h6">Ordered Details</Typography>
                  </div>
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      {/* Conditional buttons based on order status */}
                      {selectedStockOrderDetails.status !== "ordered" &&
                      selectedStockOrderDetails.status !== "viewed" ? (
                        // Close button (when not in "ordered" status)
                        <ButtonCommon
                          disabled={isDisable || isLoadingToast}
                          variant="contained"
                          style={{
                            fontSize: 11,
                            width: "120px",
                          }}
                          color={buttonColors.CANCEL_BUTTON_COLOR}
                          onClick={() =>
                            setIsOpenEditDeliveryDetailsModal(false)
                          }
                        >
                          Close
                        </ButtonCommon>
                      ) : (
                        <ButtonCommon
                          disabled={isDisable || isLoadingToast}
                          variant="contained"
                          style={{
                            fontSize: 11,
                            width: "110px",
                            marginRight: "20px",
                          }}
                          color={buttonColors.UPDATE_BUTTON_COLOR}
                          onClick={handleExitPage}
                        >
                          Save & Exit
                        </ButtonCommon>
                      )}

                      {/* Confirm Delivery button (when in "ordered" status) */}
                      {(selectedStockOrderDetails.status === "draft" ||
                        selectedStockOrderDetails.status === "ordered" ||
                        selectedStockOrderDetails.status === "viewed") && (
                        <ButtonCommon
                          disabled={
                            isDisable ||
                            shouldDisableConfirmDeliveryButton() ||
                            (selectedStockOrderDetails.status !== "ordered" &&
                              selectedStockOrderDetails.status !== "viewed") ||
                            isLoadingToast
                          }
                          variant="contained"
                          style={{
                            fontSize: 11,
                            width: "160px",
                          }}
                          color={buttonColors.CREATE_BUTTON_COLOR}
                          onClick={handlePlaceOrder}
                        >
                          Confirm Delivery
                        </ButtonCommon>
                      )}
                    </div>
                  </div>
                </div>
              </Hidden>
              <Hidden smUp>
                {selectedStockOrderDetails.status !== "ordered" &&
                  selectedStockOrderDetails.status !== "viewed" && (
                    // Close button (when not in "ordered" status)
                    <div
                      style={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography variant="h6">Ordered Details</Typography>
                      </div>
                      <ButtonCommon
                        disabled={isDisable || isLoadingToast}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "120px",
                        }}
                        color={buttonColors.CANCEL_BUTTON_COLOR}
                        onClick={() => setIsOpenEditDeliveryDetailsModal(false)}
                      >
                        Close
                      </ButtonCommon>{" "}
                    </div>
                  )}

                {/* Confirm Delivery button (when in "ordered" status) */}
                {(selectedStockOrderDetails.status === "draft" ||
                  selectedStockOrderDetails.status === "ordered" ||
                  selectedStockOrderDetails.status === "viewed") && (
                  <div>
                    <div>
                      <Typography variant="h6">Ordered Details</Typography>
                    </div>
                    <div
                      style={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ButtonCommon
                        disabled={isDisable || isLoadingToast}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "110px",
                          marginRight: "20px",
                        }}
                        color={buttonColors.UPDATE_BUTTON_COLOR}
                        onClick={handleExitPage}
                      >
                        Save & Exit
                      </ButtonCommon>
                      <ButtonCommon
                        disabled={
                          isDisable ||
                          shouldDisableConfirmDeliveryButton() ||
                          (selectedStockOrderDetails.status !== "ordered" &&
                            selectedStockOrderDetails.status !== "viewed") ||
                          isLoadingToast
                        }
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "150px",
                        }}
                        color={buttonColors.CREATE_BUTTON_COLOR}
                        onClick={handlePlaceOrder}
                      >
                        Confirm Delivery
                      </ButtonCommon>{" "}
                    </div>
                  </div>
                )}
              </Hidden>
              {/* Grid container for order details */}
              <Grid container style={{ marginBottom: "12px" }}>
                {/* Status */}
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Typography
                    variant="caption"
                    className={classes.statusRootStyle}
                  >
                    Status
                  </Typography>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    :
                  </Typography>
                  <Typography variant="caption" className={classes.statusStyle}>
                    {/* Display the status */}
                    {CapitalizeFirstLetter(selectedStockOrderDetails.status)}
                  </Typography>
                </Grid>

                {/* Created timestamp */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.createdUpdatedDateStyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.createdDateStyle}
                  >
                    Created
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{
                      fontWeight: "bold",
                      marginLeft: "4px",
                      marginRight: "2px",
                    }}
                  >
                    :
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.createdDataStyle}
                  >
                    {/* Display the created timestamp */}
                    {convertDateTimeFormatInventory(
                      selectedStockOrderDetails.created,
                    )}
                  </Typography>
                </Grid>

                {/* Supplier Name */}
                <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                  <Typography
                    variant="caption"
                    className={classes.supplierNameRoot}
                  >
                    Supplier Name
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.supplierNameStyle}
                  >
                    :
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.supplierNameDataStyle}
                  >
                    {/* Display the supplier's name */}
                    {CapitalizeFirstLetter(
                      selectedStockOrderDetails.supplierId.name,
                    )}
                  </Typography>
                </Grid>

                {/* Updated timestamp */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.createdUpdatedDateStyle}
                >
                  <Typography
                    variant="caption"
                    className={classes.updatedRootStyle}
                  >
                    Updated
                  </Typography>
                  <Typography variant="caption" style={{ fontWeight: "bold" }}>
                    :
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.updatedDataStyle}
                  >
                    {/* Display the updated timestamp */}
                    {convertDateTimeFormatInventory(
                      selectedStockOrderDetails.updated,
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* Section for ordered items */}
              {!_.isEmpty(stockOrderListStatusOrdered) && (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography style={{ marginBottom: "12px" }}>
                      Ordered Items
                    </Typography>
                    <Button onClick={handleClick}>
                      <ShareIcon />
                    </Button>
                  </div>

                  {/* Check if there are ordered items */}
                  {!_.isEmpty(stockOrderListStatusOrdered) ? (
                    <div style={{ marginTop: "4px" }}>
                      {/* Render the header for ordered items */}
                      {handleHeader(true)}

                      <Grid container>
                        {/* Map through ordered items */}
                        {stockOrderListStatusOrdered.map((item: any) => (
                          <Grid item xs={12} style={{ marginTop: "4px" }}>
                            <CardCommon
                              backgroundColor={"entity_highlight_background"}
                            >
                              <Button
                                style={{
                                  width: "100%",
                                  textTransform: "none",
                                  backgroundColor:
                                    theme.palette.background
                                      .entity_highlight_background,
                                }}
                                disabled={
                                  selectedStockOrderDetails.status !==
                                    "draft" &&
                                  selectedStockOrderDetails.status !==
                                    "ordered" &&
                                  selectedStockOrderDetails.status !== "viewed"
                                }
                                onClick={() =>
                                  handleOpenDeliveryDetailsModal(item, false)
                                }
                              >
                                <Grid container>
                                  {/* Display ordered item details */}
                                  <Grid
                                    item
                                    xs={3}
                                    className={classes.firstGridField}
                                  >
                                    <Typography
                                      align="left"
                                      className={classes.tableData}
                                    >
                                      {stockItemsDetailsObj[item.stockItemId]}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    className={classes.gridField}
                                  >
                                    <Typography className={classes.tableData}>
                                      {item.orderQty}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    className={classes.gridField}
                                  >
                                    <Typography className={classes.tableData}>
                                      {item.deliveredQty}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    className={classes.gridField}
                                  >
                                    <Typography className={classes.tableData}>
                                      {item.unitPrice}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={3}
                                    className={
                                      selectedStockOrderDetails.status ===
                                        "ordered" ||
                                      selectedStockOrderDetails.status ===
                                        "viewed"
                                        ? classes.lastGridFieldOrder
                                        : classes.lastGridField
                                    }
                                  >
                                    <Typography
                                      style={{ paddingRight: "8px" }}
                                      className={classes.tableData}
                                    >
                                      {item.totalCost}
                                    </Typography>
                                    {(selectedStockOrderDetails.status ===
                                      "ordered" ||
                                      selectedStockOrderDetails.status ===
                                        "draft" ||
                                      selectedStockOrderDetails.status ===
                                        "viewed") && (
                                      <Button
                                        onClick={(event) =>
                                          handleChangeCheckBox(event, item)
                                        }
                                        style={{
                                          textTransform: "none",
                                        }}
                                        className={classes.tableData}
                                        color="secondary"
                                        disabled={
                                          selectedStockOrderDetails.status !==
                                            "ordered" &&
                                          selectedStockOrderDetails.status !==
                                            "viewed"
                                        }
                                      >
                                        Confirm Delivery
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              </Button>
                            </CardCommon>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ) : (
                    // If no ordered items
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">No Ordered Items</Typography>
                    </div>
                  )}
                </>
              )}

              {/* Section for delivered items */}
              {!_.isEmpty(stockOrderListStatusOrdered) ? (
                <Typography style={{ marginTop: "12px", marginBottom: "12px" }}>
                  Delivered Items
                </Typography>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6" style={{ marginBottom: "12px" }}>
                    Delivered Items
                  </Typography>
                  <Button onClick={handleClick}>
                    <ShareIcon />
                  </Button>
                </div>
              )}

              {/* Check if there are delivered items */}
              {!_.isEmpty(stockOrderListStatusDelivered) ? (
                <div>
                  {/* {isEmpty(stockOrderListStatusOrdered) && <Button onClick={handleClick}><ShareIcon /></Button>} */}
                  {/* Render the header for delivered items */}
                  {handleHeader(false)}

                  <Grid container>
                    <Grid item xs={12}>
                      {/* Map through delivered items */}
                      {stockOrderListStatusDelivered.map(
                        (item: any, index: number) => (
                          <Grid item xs={12} style={{ marginTop: "4px" }}>
                            {/* Apply a Grow animation to the first delivered item */}
                            {isCheckBox && index === 0 ? (
                              <Grow
                                in={inProp}
                                style={{ transformOrigin: "0 0 0" }}
                                {...(inProp ? { timeout: 100 } : {})}
                              >
                                <CardCommon
                                  backgroundColor={
                                    "entity_highlight_background"
                                  }
                                >
                                  <Button
                                    style={{
                                      width: "100%",
                                      textTransform: "none",
                                      height: "40px",
                                      backgroundColor:
                                        theme.palette.background
                                          .entity_highlight_background,
                                    }}
                                    disabled={
                                      selectedStockOrderDetails.status !==
                                        "draft" &&
                                      selectedStockOrderDetails.status !==
                                        "ordered" &&
                                      selectedStockOrderDetails.status !==
                                        "viewed"
                                    }
                                    onClick={() =>
                                      handleOpenDeliveryDetailsModal(item, true)
                                    }
                                  >
                                    <Grid container>
                                      {/* Display delivered item details */}
                                      <Grid
                                        item
                                        xs={3}
                                        className={classes.firstGridField}
                                      >
                                        <Typography
                                          align="left"
                                          className={classes.tableData}
                                        >
                                          {
                                            stockItemsDetailsObj[
                                              item.stockItemId
                                            ]
                                          }
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.gridField}
                                      >
                                        <Typography
                                          className={classes.tableData}
                                        >
                                          {item.orderQty}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        className={classes.gridField}
                                      >
                                        <Typography
                                          className={classes.tableData}
                                        >
                                          {item.deliveredQty}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                      item
                                      xs={1}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.unitPrice}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.status === "delivered"
                                          ? "Delivered"
                                          : "Not Delivered"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={
                                        classes.lastGridFieldDeliveredItems
                                      }
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.totalCost}
                                      </Typography>
                                      <div>
                                        <Box
                                          className={classes.statusColor}
                                          style={{
                                            backgroundColor: getColorByStatus(
                                              item.status,
                                            ),
                                          }}
                                        ></Box>
                                      </div>
                                    </Grid>
                                    </Grid>
                                  </Button>
                                </CardCommon>
                              </Grow>
                            ) : (
                              // For other delivered items (without animation)
                              <CardCommon
                                backgroundColor={"entity_highlight_background"}
                              >
                                <Button
                                  style={{
                                    width: "100%",
                                    textTransform: "none",
                                    height: "40px",
                                    backgroundColor:
                                      theme.palette.background
                                        .entity_highlight_background,
                                  }}
                                  disabled={
                                    selectedStockOrderDetails.status !==
                                      "draft" &&
                                    selectedStockOrderDetails.status !==
                                      "ordered" &&
                                    selectedStockOrderDetails.status !==
                                      "viewed"
                                  }
                                  onClick={() =>
                                    handleOpenDeliveryDetailsModal(item, true)
                                  }
                                >
                                  <Grid container>
                                    {/* Display delivered item details */}
                                    <Grid
                                      item
                                      xs={3}
                                      className={classes.firstGridField}
                                    >
                                      <Typography
                                        align="left"
                                        className={classes.tableData}
                                      >
                                        {stockItemsDetailsObj[item.stockItemId]}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.orderQty}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.deliveredQty}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.unitPrice}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={classes.gridField}
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.status === "delivered"
                                          ? "Delivered"
                                          : "Not Delivered"}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      className={
                                        classes.lastGridFieldDeliveredItems
                                      }
                                    >
                                      <Typography className={classes.tableData}>
                                        {item.totalCost}
                                      </Typography>
                                      <div>
                                        <Box
                                          className={classes.statusColor}
                                          style={{
                                            backgroundColor: getColorByStatus(
                                              item.status,
                                            ),
                                          }}
                                        ></Box>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Button>
                              </CardCommon>
                            )}
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Grid>
                </div>
              ) : (
                // If no delivered items
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">No Delivered Items</Typography>
                </div>
              )}
            </>
          ) : (
            // If loading, show skeleton loading indicators
            <div style={{ marginTop: "40px" }}>{handleSkeleton()}</div>
          )}
        </DialogContent>

        <DialogActions>
          <div
            style={{
              display: "block",
              width: "100%",
              marginRight: "16px",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {/* Conditional buttons based on order status */}
              {selectedStockOrderDetails.status !== "ordered" &&
              selectedStockOrderDetails.status !== "viewed" ? (
                // Close button (when not in "ordered" status)
                <ButtonCommon
                  disabled={isDisable || isLoadingToast}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "120px",
                  }}
                  color={buttonColors.CANCEL_BUTTON_COLOR}
                  onClick={() => setIsOpenEditDeliveryDetailsModal(false)}
                >
                  Close
                </ButtonCommon>
              ) : (
                <ButtonCommon
                  disabled={isDisable || isLoadingToast}
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "110px",
                    marginRight: "20px",
                  }}
                  color={buttonColors.UPDATE_BUTTON_COLOR}
                  onClick={handleExitPage}
                >
                  Save & Exit
                </ButtonCommon>
              )}

              {/* Confirm Delivery button (when in "ordered" status) */}
              {(selectedStockOrderDetails.status === "draft" ||
                selectedStockOrderDetails.status === "ordered" ||
                selectedStockOrderDetails.status === "viewed") && (
                <ButtonCommon
                  disabled={
                    isDisable ||
                    shouldDisableConfirmDeliveryButton() ||
                    (selectedStockOrderDetails.status !== "ordered" &&
                      selectedStockOrderDetails.status !== "viewed") ||
                    isLoadingToast
                  }
                  variant="contained"
                  style={{
                    fontSize: 11,
                    width: "150px",
                  }}
                  color={buttonColors.CREATE_BUTTON_COLOR}
                  onClick={handlePlaceOrder}
                >
                  Confirm Delivery
                </ButtonCommon>
              )}
            </div>
          </div>
        </DialogActions>
      </DialogCommonOverflow>

      {/* Render the DeliveryDetailsModal when isOpenDeliveryDetailsModal is true */}
      {isOpenDeliveryDetailsModal && (
        <DeliveryDetailsModal
          isOpenDeliveryDetailsModal={isOpenDeliveryDetailsModal}
          setIsOpenDeliveryDetailsModal={setIsOpenDeliveryDetailsModal}
          deliveryDetailsNode={deliveryDetailsNode}
          handleUpdateStockOrderItemDelivery={
            handleUpdateStockOrderItemDelivery
          }
          selectedStockOrderDetails={selectedStockOrderDetails}
          isDeliver={isDeliver}
          stockItemsDetailsObj={stockItemsDetailsObj}
        />
      )}
    </>
  );
};

export default EditDeliveryStockOrderItem;
