import { Box, Hidden, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { fetchAllCategoryMenu } from "../../../../services/menuApp/categoryMenuService";
import { CustomTheme } from "../../../../types/customTheme";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { getCategoryListByCatMenu } from "../../../../utils/DiscountUtils";
import DiscountTypes from "./DiscountTypes";
import { fetchAllDiscountInfo } from "../../../../services/eatprestoApp/discountInfoService";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface DiscountsProps {}

/* Get all discount information, category menu details and location information using API call at initial load. */
const Discounts: React.FunctionComponent<DiscountsProps> = () => {
  const [discountNodeList, setDiscountNodeList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const match: any = useRouteMatch();

  /* Get all discount information using API call and response data set to discountNodeList state. */
  const getDiscountInfo = async () => {
    setIsLoadingButton(true);
    try {
      fetchAllDiscountInfo(match.params.locationId).then((res: any) => {
        setIsLoading(false);
        setDiscountNodeList(res.data.data);
        setIsLoading(false);
        setIsLoadingButton(false);
      });
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsLoadingButton(false);
    }
  };

  /* Get category menu details */
  const handleCategoryAndItemList = async () => {
    fetchAllCategoryMenu(match.params.locationId)
      .then((res) => {
        const categoryList = getCategoryListByCatMenu(res.data.data);
        setCategoryList(categoryList);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  useEffect(() => {
    document.title = "Eat Presto - Discount";
    getDiscountInfo();
    handleCategoryAndItemList();
  }, []);

  const theme: CustomTheme = useTheme();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <DiscountTypes
          categoryList={categoryList}
          nodes={discountNodeList}
          isLoading={isLoading}
          getDiscountInfo={getDiscountInfo}
          setIsLoadingButton={setIsLoadingButton}
          isLoadingButton={isLoadingButton}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Discounts, Authorities.DISCOUNT_READ);
