import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    arrowColor: {
      color: theme.palette.custom["yellow"].main,
    },
    cardColorStage: {
      backgroundColor: theme.palette.background.imgBackground,
      height: "40px",
      zIndex: 0,
      cursor: "pointer",
    },
    dropDownButton: {
      zIndex: 50,
      width: "28px",
      height: "28px",
    },
  }),
);

export interface BannersProps {
  allStageIndex: any;
  data: any;
  count: any;
  levelOfStage: any;
  handleStyleHeader: any;
  handleStageOneModifierEditPageOpen: any;
  handleEvenStages: any;
  category: any;
  item: any;
  headerStyleBody: any;
  handleItemDropDownOpen: any;
  handleItemDropDownClose: any;
  cat: any;
}

  /* This component show the even stages. */
const EvenStages: React.FunctionComponent<BannersProps> = ({
  allStageIndex,
  data,
  count,
  levelOfStage,
  handleStyleHeader,
  handleStageOneModifierEditPageOpen,
  handleEvenStages,
  category,
  item,
  headerStyleBody,
  handleItemDropDownOpen,
  handleItemDropDownClose,
  cat,
}) => {
  const classes = useStyles();
  return (
    allStageIndex.includes(item.itemId) && (
      <>
      {/* Show the table header */}
        {count === 1 && (
          <div
            style={
              levelOfStage < 3
                ? {
                    marginTop: "4px",
                    paddingLeft: "20px",
                  }
                : {
                    marginTop: "4px",
                    paddingLeft: "68px",
                  }
            }
          >
            <CardCommon>
              <Grid container className={handleStyleHeader(levelOfStage)}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    paddingLeft: "28px",
                  }}
                >
                  Title
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    paddingLeft: "24px",
                  }}
                >
                  Min & Max
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    paddingLeft: "24px",
                  }}
                >
                  Price Change Point
                </Grid>
              </Grid>
            </CardCommon>
          </div>
        )}

        {/* Show the table body. */}
        {data.stageGroup === item.enableStageGroup && (
          <>
            <div
              style={
                levelOfStage < 3
                  ? {
                      marginTop: "4px",
                      paddingLeft: "20px",
                    }
                  : {
                      marginTop: "4px",
                      paddingLeft: "68px",
                    }
              }
            >
              <CardCommon>
              <Grid container className={classes.cardColorStage}>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleStageOneModifierEditPageOpen(data, category.menuId)
                  }
                >
                  <Box className={headerStyleBody(levelOfStage)}></Box>
                  <Typography>{data.title}</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleStageOneModifierEditPageOpen(data, category.menuId)
                  }
                >
                  <Typography
                    style={{
                      display: "flex",
                      paddingLeft: "24px",
                    }}
                  >
                    Min : {data.mealDealMin}
                  </Typography>
                  <Typography
                    style={{
                      display: "flex",
                      paddingLeft: "24px",
                    }}
                  >
                    Max : {data.mealDealMax}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleStageOneModifierEditPageOpen(data, category.menuId)
                  }
                >
                  <Typography
                    style={{
                      display: "flex",
                      paddingLeft: "24px",
                    }}
                  >
                    {data.mealDealPriceChangePoint}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    placeItems: "center",
                    justifyContent: "start",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleStageOneModifierEditPageOpen(data, category.menuId)
                  }
                >
                  <Typography
                    style={{
                      paddingLeft: "24px",
                    }}
                  >
                    {`${data.subProducts.length} items`}
                  </Typography>
                </Grid>
                {!_.isEmpty(data.subProducts) && (
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      placeItems: "center",
                      justifyContent: "end",
                      paddingRight: "16px",
                    }}
                  >
                    {!allStageIndex.includes(data.itemId) ? (
                      <IconButton
                        style={{
                          zIndex: 50,
                        }}
                        className={classes.dropDownButton}
                        onClick={() => handleItemDropDownOpen(data.itemId)}
                      >
                        <ArrowDropDownIcon className={classes.arrowColor} />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{
                          zIndex: 50,
                        }}
                        className={classes.dropDownButton}
                        onClick={() => handleItemDropDownClose(data.itemId)}
                      >
                        <ArrowDropUpIcon />
                      </IconButton>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardCommon>
            </div>
            
            {handleEvenStages(data, cat, levelOfStage)}
          </>
        )}
      </>
    )
  );
};

export default withAuthority(EvenStages, Authorities.MENU_READ);
