import { Hidden } from "@material-ui/core";
import React, { useEffect } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { CustomTheme } from "../../../../types/customTheme";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface DashboardProps {}

const Dashboard: React.FunctionComponent<DashboardProps> = () => {
  useEffect(() => {
    document.title = "Menu - Dashboard ";
  }, []);


  return (
    <Hidden lgUp>
      <PageHeaderMobile />
    </Hidden>
  );
};

export default withAuthority(Dashboard, Authorities.MENU_DASHBOARD_READ);
