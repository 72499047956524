import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import PaginationUsingSelector from "./PaginationUsingSelector";
import PaginationUsingPageNumber from "./PaginationUsingPageNumber";

export interface Props {
  handleChangePaginationUsingPageNumber: any;
  handleChangePaginationUsingSelector: any;
  totalPages: any;
  currentPage: any;
  pageSize: any
  isShift?: any
}

/* Show the page size selection dropdown and page number selection button. */
const Pagination: React.FunctionComponent<Props> = ({
  handleChangePaginationUsingPageNumber,
  totalPages,
  currentPage,
  handleChangePaginationUsingSelector,
  pageSize,
  isShift
}) => {


  return (
    <Grid container style={{ marginTop: "20px" }}>
      <Grid item sm={6} xs={4}>
        <PaginationUsingSelector
          handleChangePaginationUsingSelector={
            handleChangePaginationUsingSelector
          }
          pageSize={pageSize}
          isShift={isShift}
        />
      </Grid>
      <Grid item sm={6} xs={8}>
        <PaginationUsingPageNumber
          handleChangePaginationUsingPageNumber={
            handleChangePaginationUsingPageNumber
          }
          totalPages={totalPages}
          currentPage={currentPage}
        />
      </Grid>
    </Grid>
  );
};

export default Pagination;
