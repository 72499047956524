import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { CustomColor, CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      width: "10px",
      height: "10px,",
      backgroundColor: ({ color }: { color: CustomColor }) =>
        theme.palette.custom[color].main,
      "&:hover": {
        backgroundColor: ({ color }: { color: CustomColor }) =>
          theme.palette.custom[color].dark,
      },
      color: ({ color }: { color: CustomColor }) =>
        theme.palette.custom[color].contrastText,
    },
  }),
);

export interface ButtonCommonProps {
  onClick?: any;
  color?: CustomColor;
  type?: any;
  [propName: string]: any;
  isLoadingPage?: any
}

/* This component shows the button with loading. */
const ButtonCommon: React.FunctionComponent<ButtonCommonProps> = ({
  onClick,
  color = "yellow",
  type,
  children,
  isLoadingPage,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({ color });

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      className={classes.root}
      disableElevation
      onClick={handleClick}
      type={type}
      size={"large"}
      {...props}
    >
      {(isLoading || isLoadingPage) ? (
        <CircularProgress color="inherit" style={{ marginLeft: 4 }} size={20} />
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonCommon;
