import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

export interface OrdersInfoChannelProps {
  orderChannelImage: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    imgStyle: {
      marginRight: "20px",
      width: "35px",
      height: "35px",
      [theme.breakpoints.down("sm")]: {
        width: "35px",
        height: "35px",
        marginRight: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "30px",
        height: "30px",
      },
    },
  }),
);

/* Show the order channel image. */
const OrdersInfoChannel: React.FunctionComponent<OrdersInfoChannelProps> = ({
  orderChannelImage,
}) => {
  const classes = useStyles();
  return (
    <>
      <img
        src={orderChannelImage}
        className={classes.imgStyle}
        alt="order channel image"
      />
    </>
  );
};

export default OrdersInfoChannel;
