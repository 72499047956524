import {
  Button,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { CustomTheme } from "../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      height: "10px,",
      backgroundColor: theme.palette.custom.blue.main,
      "&:hover": {
        backgroundColor: theme.palette.custom.blue.dark,
      },
      color: theme.palette.custom.blue.contrastText,
    },
  }),
);

export interface ButtonCommonProps {
  onClick?: any;
  type?: any;
  [propName: string]: any;
}

/* This component shows the menu selection button. */
const MenuButtonCommon: React.FunctionComponent<ButtonCommonProps> = ({
  onClick,
  type,
  children,
  ...props
}) => {
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event);
  };

  return (
    <Button
      className={classes.root}
      disableElevation
      onClick={handleClick}
      fullWidth
      type={type}
      size={"large"}
      {...props}
    >
      {children}
    </Button>
  );
};

export default MenuButtonCommon;
