import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useContext } from "react";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { GlobalContext } from "../../root/App";
import MenuAppRoutes, { getSidebarRoutes } from "./routes/MenuAppRoutes";
import { initTheme } from "./theme/primary";

export interface PrestoExpressMenuAppProps {}

const PrestoExpressMenuApp: React.FunctionComponent<PrestoExpressMenuAppProps> =
  () => {
    const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
    const { globalState } = useContext(GlobalContext);
    const theme = initTheme({ isDark: globalState.isDarkMode });
    const handleChangeSidebar = () => {
      const sidebar = getSidebarRoutes();
      setSidebarRoutes(sidebar);
    };

    React.useEffect(() => {
      handleChangeSidebar();
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PrimaryLayout
          sidebarRoutes={sidebarRoutes}
          handleChangeSidebar={handleChangeSidebar}
        >
          <MenuAppRoutes />
        </PrimaryLayout>
      </ThemeProvider>
    );
  };

export default PrestoExpressMenuApp;
