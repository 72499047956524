import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { CustomTheme } from "../../../../../types/customTheme";

Chart.register(ArcElement);

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    prevStyle: {
      marginLeft: "-36px",
      marginTop: "128px",
      position: "absolute",
      height: "75px",
      [theme.breakpoints.up("md")]: {
        marginTop: "166px",
        marginLeft: "-80px",
        height: "100px",
        paddingBottom: "16px",
      },
    },
    currentStyle: {
      marginBottom: "40px",
      marginLeft: "44px",
      height: "150px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "40px",
        height: "200px",
      },
    },
  }),
);

export interface DashboardProps {
  currentData: any;
  previousData: any;
}

/* This component displays the mode's current doughnut chart and the mode's previous doughnut chart. */
const ModeChart: React.FunctionComponent<DashboardProps> = ({
  currentData,
  previousData,
}) => {
  const options: any = {
    maintainAspectRatio: false,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        xAlign: 'left',
        yAlign: 'bottom'
      }
    },
  };

  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
      }}
    >
      <div>
        <div className={classes.prevStyle}>
          {/* Previous doughnut chart */}
          <Doughnut options={options} data={previousData}></Doughnut>
        </div>
        <div className={classes.currentStyle}>
          {/* Current doughnut chart */}
          <Doughnut
            options={options}
            data={currentData}
          ></Doughnut>
        </div>
      </div>
    </div>
  );
};

export default ModeChart;
