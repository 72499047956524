import React from "react";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";

import WithLoading from "../../../../utils/WithLoading";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import CardCommon from "../../../../components/card/CardCommon";
import RolesDetailsNode from "./RolesDetailsNode";

export interface RolesDetailsHeaderProps {
  nodeData: any;
  isLoading: any;
  handleResetPassword: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
    },
    headerContainerStyle: {
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyle: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "10px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      paddingRight: "8px",
    },
  }),
);

/**
 * RolesDetailsHeader component renders the header and a list of user roles.
 * It includes a card with sorting options for Name, Email, Roles, Created, and Updated columns.
 */
const RolesDetailsHeader: React.FunctionComponent<RolesDetailsHeaderProps> = ({
  nodeData,
  isLoading,
  handleResetPassword,
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.headerContainerStyle}
        style={{ margin: "24px 0px 0px 0px" }}
      >
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "40px" }}>
            <Grid item md={3} className={classes.headerFirstItemStyle}>
              <SortingMethod
                headerName={"Name"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item md={3} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Email"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item md={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Roles"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item md={2} className={classes.headerItemStyle}>
              <SortingMethod
                headerName={"Created"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
            <Grid item md={2} className={classes.headerItemStyleLastColumn}>
              <SortingMethod
                headerName={"Updated"}
                disableButton={true}
                color={"orange"}
              />
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container className={classes.root}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <RolesDetailsNode
                nodeData={nodeData}
                handleResetPassword={handleResetPassword}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No Users available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(RolesDetailsHeader);
