import {
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Autocomplete } from "@material-ui/lab";
import { Icon } from "@iconify/react";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      backgroundColor: theme.palette.background.entity_highlight_background,
      color: theme.palette.custom.orange.contrastText,
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
    titleColor: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

export interface BannersProps {
  categoryMenuList: any;
  selectedCatMenuTitle: any;
  handleChangeCatMenu: any;
  setIsOpenCatModal: any;
  setIsOpenCatMenuModal: any;
  handleOpenMenuOption: any;
}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const CategoryMenuDropDown: React.FunctionComponent<BannersProps> = ({
  categoryMenuList,
  selectedCatMenuTitle,
  handleChangeCatMenu,
  setIsOpenCatModal,
  setIsOpenCatMenuModal,
  handleOpenMenuOption,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="locationSelectGlobal"
      color="inherit"
      value={categoryMenuList.find(
        (v: any) => v.title === selectedCatMenuTitle,
      )}
      options={categoryMenuList}
      getOptionLabel={(option: any) => option.title}
      disableClearable
      style={{ zIndex: 0 }}
      renderOption={(props) => {
        return (
          <Grid
            container
            {...props}
            style={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: 0,
              height: "20px",
              marginBottom: "4px",
            }}
          >
            <Grid
              item
              xs={11}
              onClick={() => {
                handleChangeCatMenu(props);
              }}
            >
              <Typography variant="body2" className={classes.titleColor}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={1} style={{ paddingRight: "8px" }}>
              <Icon
                icon="bx:edit"
                style={{ cursor: "pointer", zIndex: 50 }}
                width="24px"
                height="24px"
                onClick={() => {
                  setIsOpenCatModal(true);
                  setIsOpenCatMenuModal(false);
                  handleOpenMenuOption(props, "categoryMenu");
                }}
              />
            </Grid>
          </Grid>
        );
      }}
      classes={{ root: classes.autoComplete }}
      renderInput={(params: any) => (
        <>
          <TextField
            color="inherit"
            {...params}
            label=""
            style={{
              borderRadius: 4,
              width: "280px",
            }}
            variant="outlined"
            id={categoryMenuList.id}
            name={categoryMenuList.title}
          />
        </>
      )}
    />
  );
};

export default withAuthority(CategoryMenuDropDown, Authorities.MENU_READ);
