/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import { CustomTheme } from "../../../../types/customTheme";
import "jspdf-autotable";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { handleDownloadPdf } from "./ConvertPdfExcel/convertDataToPdf";
import { handleDownloadCsv } from "./ConvertPdfExcel/convertDataToExcel";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { convertDecimalPoint } from "../../../../utils/ReplaceIcon";
import SaleDetailsModal from "../../../../components/common/SaleDetailsModal";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface VoidSummeryInfoNodeProps {
  voidSummeryNodeList: any;
  locationSelectorList: any;
  filterDetails: any;
  vatPercentList: any;
  filterData: any;
  basedOnShift: any;
  locationSelectedList: any;
  handleOnClickText: any;
}

const useStyles = (nodesLength: number, nodesHeadersLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        fontSize: "13px",
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${nodesLength + 1})`]: {
          color: theme.palette.custom.green.contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "20%",
          width: "20%",
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "8%",
            width: "8%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "8%",
            width: "8%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "8%",
            width: "8%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "8%",
            width: "8%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "12%",
            width: "12%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "12%",
            width: "12%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength - 1})`]: {
            minWidth: "20%",
            width: "20%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${nodesHeadersLength})`]: {
            minWidth: "20%",
            width: "20%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "12px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "32%",
            width: "32%",
          },
        },
      },
    }),
  );

/* Creation of 'void summery' table, and conversion of 'void summery' table to PDF and Excel. */
const VoidSummeryInfoNode: React.FunctionComponent<
  VoidSummeryInfoNodeProps
> = ({
  voidSummeryNodeList,
  locationSelectorList,
  filterDetails,
  vatPercentList,
  filterData,
  basedOnShift,
  locationSelectedList,
  handleOnClickText,
}) => {
  const [sortingObject, setSortingObject] = useState<any>({});
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [nodesLength, setNodeLength] = useState(0);
  const [nodesHeadersLength, setNodesHeadersLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSaleDetailsModal, setOpenSaleDetailsModal] = useState(false);

  const maxWidth = useMediaQuery("(max-width: 1280px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2600px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Creating the data for the void summery table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    const voidSummeryNode: any = [];
    const cloneVoidSummeryNodeList: any = _.cloneDeep(voidSummeryNodeList);
    let voidSummeryNodeObject: any = {};

    /* If the key is larger than 100, divide it by 1000 and convert it to 100 smaller */
    Object.keys(voidSummeryNodeList).map((locationId) => {
      Object.keys(voidSummeryNodeList[locationId]).map((vat: any) => {
        // If the VAT percentage value is more than 100, it is divided by 1000.
        if (vat > 100) {
          const dividedVatPercent = vat / 1000;
          cloneVoidSummeryNodeList[locationId][
            convertDecimalPoint(dividedVatPercent.toString())
          ] = cloneVoidSummeryNodeList[locationId][vat];

          // remove VAT percentage above 100.
          delete cloneVoidSummeryNodeList[locationId][vat];
        }
      });
    });

    if (!_.isEmpty(cloneVoidSummeryNodeList)) {
      /* If the keys in the vatPercentList match the keys in the voidSummeryNodeList, 
        enter the value associated with those keys and create a new object. */
      Object.keys(cloneVoidSummeryNodeList).map((key) => {
        // Get the location name using locationId
        const location = getFilterListFromArrayObject(
          locationSelectorList,
          key,
        );

        // Enter the location name for the location column of the table.
        voidSummeryNodeObject.Location = location[0].label;

        vatPercentList.map((percent: any, index: any) => {
          const vatPercent =
            cloneVoidSummeryNodeList[key][convertDecimalPoint(percent)];
          /* Values ​​are added to the VAT summery table only if there is a VAT percentage 
               and the VAT percentage value is not "total". */
          if (!_.isEmpty(vatPercent) && percent !== "total") {
            voidSummeryNodeObject[`Gross${percent}`] = convertDecimalPoint(
              vatPercent.gross,
            );
            voidSummeryNodeObject[`Vat${percent}`] = convertDecimalPoint(
              vatPercent.vat,
            );
          } else if (percent !== "total") {
            // If there is no VAT percentage value, 0 will be entered as its value.
            voidSummeryNodeObject[`Gross${percent}`] = "0.00";
            voidSummeryNodeObject[`Vat${percent}`] = "0.00";
          }

          // Define void summery total
          if (index + 1 === vatPercentList.length) {
            if (
              !_.isEmpty(cloneVoidSummeryNodeList[key].total) &&
              percent === "total"
            ) {
              // Define gross total
              voidSummeryNodeObject.GrossTotal = convertDecimalPoint(
                cloneVoidSummeryNodeList[key].total.gross,
              );
              // Define vat total
              voidSummeryNodeObject.VatTotal = convertDecimalPoint(
                cloneVoidSummeryNodeList[key].total.vat,
              );
            } else if (percent === "total") {
              // If there is no value for the total, 0 is substituted for the total.
              voidSummeryNodeObject.GrossTotal = "0.00";
              voidSummeryNodeObject.VatTotal = "0.00";
            }

            // Entering the gross total, and vat total into the table.
            voidSummeryNode.push(voidSummeryNodeObject);
            voidSummeryNodeObject = {};
          }
        });
      });
      const voidSummeryTotalObject: any = {};

      /* To get the total of the columns of the table separately, those columns are inserted into an object. */
      Object.keys(voidSummeryNode[0]).map((data: any) => {
        voidSummeryTotalObject[data] = 0;
      });

      /* Calculate the total of the values ​​in the column. */
      voidSummeryNode.map((data: any) => {
        Object.keys(data).map((columnName: any) => {
          // If the column name is location, the value of that column will be added as 'Total'.
          if (columnName === "Location") {
            voidSummeryTotalObject[columnName] = "Total";
          } else {
            // If there is no value for the column name, 0 is added as its value.
            if (_.isEmpty(data[columnName])) {
              data[columnName] = 0;
            }

            // Calculate the total of each column.
            voidSummeryTotalObject[columnName] =
              parseFloat(voidSummeryTotalObject[columnName]) +
              parseFloat(data[columnName]);

            // Convert decimal point
            voidSummeryTotalObject[columnName] = convertDecimalPoint(
              voidSummeryTotalObject[columnName],
            );
          }
        });
      });
      setFooter(voidSummeryTotalObject);

      const headers: any = [];
      const pdfHeaders: any = [];
      const ExcelHeaders: any = [];
      const sortingObject: any = [];
      /* Create headers for pdf, excel, table */
      Object.keys(voidSummeryTotalObject).map((data: any) => {
        if (data === "Location") {
          headers.push("Location");
          pdfHeaders.push({ title: "Location", field: "Location" });
          ExcelHeaders.push({
            header: "Location",
            key: "Location",
            width: 28,
            height: 68,
          });
          sortingObject.Location = "Location";
        } else if (data.slice(0, 5) === "Gross") {
          /* Change the gross headers */

          // Removing the 'Gross' letters from the word and getting the rest.
          const splitGross = data.split("Gross").toString();

          // Replace comma to space
          const removedCommaGross = splitGross.replace(/,/gi, "");

          // () is appended to the header only if the key is not a 'total'.
          if (removedCommaGross !== "Total") {
            headers.push(`Gross (${removedCommaGross})`);
            pdfHeaders.push({
              title: `Gross (${removedCommaGross})`,
              field: data,
            });
            ExcelHeaders.push({
              header: `Gross (${removedCommaGross})`,
              key: data,
              width: 28,
              height: 68,
            });
            sortingObject[`Gross (${removedCommaGross})`] = data;
          } else {
            // () is not appended to the header only if the key is a 'total'.
            headers.push(`Gross ${removedCommaGross}`);
            pdfHeaders.push({
              title: `Gross ${removedCommaGross}`,
              field: data,
            });
            ExcelHeaders.push({
              header: `Gross ${removedCommaGross}`,
              key: data,
              width: 28,
              height: 68,
            });
            sortingObject[`Gross ${removedCommaGross}`] = data;
          }
        } else {
          /* Change the vat headers. */

          // Removing the 'vat' letters from the word and getting the rest.
          const splitVat = data.split("Vat").toString();

          // Replace comma to space
          const removeCommaVat = splitVat.replace(/,/gi, "");
          headers.push(`Vat ${removeCommaVat}`);
          pdfHeaders.push({
            title: `Vat ${removeCommaVat}`,
            field: data,
          });
          ExcelHeaders.push({
            header: `Vat ${removeCommaVat}`,
            key: data,
            width: 28,
            height: 68,
          });
          sortingObject[`Vat ${removeCommaVat}`] = data;
        }
      });

      setHeadersList(headers);
      const cloneVoidSummeryNode = _.cloneDeep(voidSummeryNode);
      // Add the footer
      cloneVoidSummeryNode.push(voidSummeryTotalObject);
      setNodesPdfExcel(cloneVoidSummeryNode);
      setNodeLength(voidSummeryNode.length);
      setNodesHeadersLength(headers.length);
      setNodes({ nodes: voidSummeryNode });

      setPdfHeaders(pdfHeaders);

      /* Making the function of how to sort according to the columns in the table. */
      const newShortingObject: any = [];
      Object.entries(sortingObject).forEach(([key, value]: any) => {
        newShortingObject[key] = (array: any) =>
          array.sort((a: any, b: any) => a[value].localeCompare(b[value]));
      });
      setSortingObject(newShortingObject);
      setExcelHeaders(ExcelHeaders);
    }
  }, [voidSummeryNodeList]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: sortingObject,
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const classes: any = useStyles(nodesLength, nodesHeadersLength)();
  const theme: CustomTheme = useTheme();

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            <div
              style={
                maxWidthLocationChips
                  ? {
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "8px",
                    }
                  : { marginLeft: "16px" }
              }
            >
              <MenuButtonCommon
                id="basic-button"
                variant="contained"
                style={{
                  fontSize: 11,
                  height: "38px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                color="blue"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <GetAppIcon />
                <Typography
                  variant="body1"
                  style={{ paddingLeft: "16px", color: "white" }}
                >
                  Export
                </Typography>
              </MenuButtonCommon>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  style: {
                    borderRadius: "10px",
                    border: `1px solid ${theme.palette.background.entity_border}`,
                  },
                }}
                style={{ top: "56px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDownloadPdf(
                      "p",
                      pdfHeaders,
                      nodesPdfExcel,
                      filterDetails,
                    );
                    handleClose();
                  }}
                >
                  Export as PDF
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDownloadCsv(
                      excelHeaders,
                      nodesPdfExcel,
                      filterDetails,
                    );
                    handleClose();
                  }}
                >
                  Export as CSV
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* </Grid> */}
      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={index === 0}
                      className={classes.baseCellRow}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={
                            index === 0
                              ? {
                                  display: "flex",
                                  justifyContent: "start",
                                  fontWeight: "bold",
                                  paddingLeft: "16px",
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontWeight: "bold",
                                  paddingLeft: "4px",
                                  fontSize: "12px",
                                  color:
                                    theme.palette.custom.orange.contrastText,
                                }
                          }
                        >
                          {data}
                        </Typography>
                      </div>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, i) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={classes.headerRow}
                    >
                      {Object.keys(item).map((data: any, index: any) => (
                        <Cell
                          pinLeft={index === 0}
                          className={classes.baseCellRow}
                          onClick={() => setOpenSaleDetailsModal(true)}
                        >
                          <Typography
                            variant="caption"
                            style={
                              index === 0
                                ? {
                                    display: "flex",
                                    justifyContent: "start",
                                    paddingLeft: "16px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }
                                : index + 1 === tableList.length
                                ? { fontWeight: "bold", fontSize: "12px" }
                                : index === 10
                                ? {
                                    display: "flex",
                                    justifyContent: "end",
                                    fontSize: "12px",
                                    paddingRight: "16px",
                                  }
                                : {
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                  }
                            }
                          >
                            {data === "Location"
                              ? item[data]
                              : convertDecimalPoint(item[data])}
                          </Typography>
                        </Cell>
                      ))}
                    </Row>
                    {i + 1 === tableList.length && (
                      <Row
                        className={classes.headerRowFinal}
                        key={item.gross20}
                        item={item}
                      >
                        {Object.values(footer).map((data: any, index: any) => (
                          <Cell
                            pinLeft={index === 0}
                            className={classes.baseCellRow}
                          >
                            <Typography
                              variant="caption"
                              style={
                                index === 0
                                  ? {
                                      display: "flex",
                                      justifyContent: "start",
                                      paddingLeft: "16px",
                                      textAlign: "left",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      color: "white",
                                    }
                                  : index === 10
                                  ? {
                                      display: "flex",
                                      justifyContent: "end",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      paddingRight: "16px",
                                      color: "white",
                                    }
                                  : {
                                      display: "flex",
                                      justifyContent: "center",
                                      fontWeight: "bold",
                                      fontSize: "12px",
                                      color: "white",
                                    }
                              }
                            >
                              {data}
                            </Typography>
                          </Cell>
                        ))}
                      </Row>
                    )}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
      {openSaleDetailsModal && (
        <SaleDetailsModal
          openSaleDetailsModal={openSaleDetailsModal}
          setOpenSaleDetailsModal={setOpenSaleDetailsModal}
          filterData={filterData}
          selectedPaymentMethod=""
          basedOnShift={basedOnShift}
        />
      )}
    </>
  );
};

export default VoidSummeryInfoNode;
