import authorities from "./authorities";

export const rolesAuthoritiesMapping: any = {
  ROLE_SUPER_ADMIN: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.SALE_PAYMENT_TYPE_UPDATE_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.STOCK_CONFIGURATION_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
    authorities.PAYMENT_CONFIGURATION_CREATE,
    authorities.USERS_PERMISSION_CREATE,
  ],
  ROLE_ADMIN: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.SALE_PAYMENT_TYPE_UPDATE_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.STOCK_CONFIGURATION_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
    authorities.PAYMENT_CONFIGURATION_CREATE,
    authorities.USERS_PERMISSION_CREATE,
  ],
  ROLE_PREX_DEV: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.SALE_PAYMENT_TYPE_UPDATE_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.STOCK_CONFIGURATION_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
  ],
  ROLE_PREX_SUPPORT: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.SALE_PAYMENT_TYPE_UPDATE_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.STOCK_CONFIGURATION_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
  ],
  ROLE_OWNER: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.SALE_PAYMENT_TYPE_UPDATE_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
  ],
  ROLE_MANAGER: [
    authorities.BASIC_INFO_UPDATE,
    authorities.DELIVERY_INFO_CREATE,
    authorities.DISCOUNT_CREATE,
    authorities.OPENING_TIME_CREATE,
    authorities.ORDERS_CREATE,
    authorities.DASHBOARD_CREATE,
    authorities.LOCATION_DASHBOARD_CREATE,
    authorities.BANNERS_CREATE,
    authorities.SALES_VOID_TRANSACTION_INFO_CREATE,
    authorities.MENU_CREATE,
    authorities.MENU_DASHBOARD_CREATE,
    authorities.TASKS_CREATE,
    authorities.INVENTORY_CREATE,
    authorities.SALE_CREATE,
    authorities.INVENTORY_ADMIN_CREATE,
    authorities.INVENTORY_MOMENT_CREATE,
  ],
  ROLE_ACCOUNTANT: [
    authorities.BASIC_INFO_READ,
    authorities.SALE_READ,
    authorities.INVENTORY_MOMENT_READ,
  ],
  ROLE_STAFF: [
    authorities.BASIC_INFO_READ,
    authorities.OPENING_TIME_READ,
    authorities.DELIVERY_INFO_READ,
    authorities.DISCOUNT_READ,
    authorities.ORDERS_READ,
    authorities.DASHBOARD_READ,
    authorities.LOCATION_DASHBOARD_READ,
    authorities.BANNERS_READ,
    authorities.SALES_VOID_TRANSACTION_INFO_READ,
    authorities.MENU_READ,
    authorities.MENU_DASHBOARD_READ,
    authorities.TASKS_READ,
    authorities.INVENTORY_READ,
    authorities.INVENTORY_MOMENT_READ,
  ],
};

export default rolesAuthoritiesMapping;
