import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    rootList: {
      cursor: "pointer",
      height: "160px",
      [theme.breakpoints.down("sm")]: {
        height: "120px",
      },
    },
    imgBoxStyle: {
      margin: "7px",
      marginLeft: "10px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      height: "120px",
      [theme.breakpoints.down("sm")]: {
        height: "92px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    titleStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",

      [theme.breakpoints.down("sm")]: {
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    imgBoxNotAvailableStyle: {
      marginRight: "7px",
      marginTop: "10px",
      marginLeft: "10px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      marginBottom: "10px",
      height: "114px",
      width: "114px",
      [theme.breakpoints.down("sm")]: {
        height: "80px",
        width: "80px",
      },
      backgroundColor: theme.palette.background.imgBackground,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    img: {
      objectFit: "cover",
      width: "114px",
      height: "114px",
      [theme.breakpoints.down("sm")]: {
        width: "88px",
        height: "84px",
      },
    },
    boxStyle: {
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
    },
    priceStyle: {
      marginBottom: "6px",
      paddingRight: "10px",
    },
  }),
);

export interface BannersProps {
  getCategoryMenuPrice: any;
  setIsOpenCatModal: any;
  setIsOpenCatMenuModal: any;
  handleOpenMenuOption: any;
  item: any;
}

/* Get selected menu information, category menu details and location information using API call at initial load. */
const CategoryMenuList: React.FunctionComponent<BannersProps> = ({
  getCategoryMenuPrice,
  setIsOpenCatModal,
  setIsOpenCatMenuModal,
  handleOpenMenuOption,
  item,
}) => {
  /* Change the pixel size of image */
  const handleImageUrl = (url: any) => {
    // If 'imgUrl' has 'cloudinary', that 'imgUrl' edit.
    if (url.includes("cloudinary")) {
      const urlSplit = url.split("upload");
      const inputString = urlSplit[0] + "upload/c_fill,g_center,h_240,w_320";
      const mergeUrl = inputString + urlSplit[1];
      return mergeUrl;
    } else {
      // If 'imgUrl' has not 'cloudinary', that 'imgUrl' does not edit.
      return url;
    }
  };
  const theme: CustomTheme = useTheme();
  const classes = useStyles();
  return (
    <Grid
      container
      onClick={() => {
        setIsOpenCatModal(false);
        setIsOpenCatMenuModal(false);
        handleOpenMenuOption(item, "catMenu");
      }}
    >
      <Grid
        item
        xs={9}
        sm={10}
        md={10}
        lg={9}
        xl={10}
        style={{ display: "flex" }}
      >
        {item.data[0].imageUrl ? (
          <Box className={classes.imgBoxStyle}>
            <img
              className={classes.img}
              src={handleImageUrl(item.data[0].imageUrl)}
              alt="not available images"
              style={{
                borderRadius: "10px",
                border: `1px solid ${theme.palette.background.entity_border}`,
              }}
            />
          </Box>
        ) : (
          <Box className={classes.imgBoxNotAvailableStyle}>
            <ImageOutlinedIcon style={{ fontSize: "52px" }} />
          </Box>
        )}
        <Grid
          item
          xs
          container
          direction="column"
          style={{ paddingLeft: "8px", paddingTop: "12px" }}
        >
          <Grid item xs>
            <Typography
              style={{ fontWeight: "bold" }}
              align="left"
              className={classes.titleStyle}
            >
              {item.title}
            </Typography>
            <Typography
              className={classes.boxStyle}
              style={{ textTransform: "lowercase" }}
              variant="body2"
              align="left"
            >
              {item.desc}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        sm={2}
        md={2}
        lg={3}
        xl={2}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
        }}
      >
        <Typography
          variant="caption"
          align="right"
          className={classes.priceStyle}
        >
          {getCategoryMenuPrice(item)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withAuthority(CategoryMenuList, Authorities.MENU_READ);
