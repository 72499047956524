import React, { useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { initTheme } from "./theme/primary";
import PrimaryLayout from "../../layouts/PrimaryLayout";
import { useRouteMatch } from "react-router-dom";
import { GlobalContext } from "../../root/App";
import { CssBaseline } from "@material-ui/core";
import SaleAppRoutes, { getSidebarRoutes } from "./routes/SaleAppRoutes";
import { getCookie } from "../../utils/cookies";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";

export interface PrestoExpressLocationAppProps {}

const PrestoExpressLocationApp: React.FunctionComponent<
  PrestoExpressLocationAppProps
> = () => {
  const [sidebarRoutes, setSidebarRoutes] = React.useState<any>([]);
  const { globalState } = useContext(GlobalContext);
  const theme = initTheme({ isDark: globalState.isDarkMode });
  const match: any = useRouteMatch();

  const getLocationInfo = async () => {
    try {
      const idToken: any = getCookie("idToken");
      const res = await fetchAllLocations(idToken);
      const location = getFilterListFromArrayObject(
        res.data.data,
        match.params.locationId,
      );
      handleChangeRestaurant(location[0]);
      handleChangeSidebar();
    } catch (error) {}
  };
  
  const handleChangeSidebar = () => {
    const sidebar = getSidebarRoutes();
    setSidebarRoutes(sidebar);
  };

  React.useEffect(() => {
    getLocationInfo();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PrimaryLayout
        sidebarRoutes={sidebarRoutes}
        handleChangeSidebar={handleChangeSidebar}
      >
        <SaleAppRoutes />
      </PrimaryLayout>
    </ThemeProvider>
  );
};

export default PrestoExpressLocationApp;
