import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { fetchAllTaskInfo } from "../../../../services/taskApp/taskInfoService";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import TaskMap from "./TaskMap";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface TaskProps {}

/* Get all task information using API call at initial load. */
const Task: React.FunctionComponent<TaskProps> = () => {
  const [taskNodeList, setTaskNodeList] = useState<any>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const match: any = useRouteMatch();

  /* Get all task information using API call and response data set to taskNodeList state. */
  const getTaskInfo = async () => {
    try {
      fetchAllTaskInfo(match.params.locationId).then((res: any) => {
        setIsLoading(false);
        setTaskNodeList(res.data.data);
        setIsLoading(false);
      });
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Task - Task Information ";
    setIsLoading(true);
    getTaskInfo();
  }, [getTaskInfo]);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box>
        <TaskMap
          nodes={taskNodeList}
          isLoading={isLoading}
          getTaskInfo={getTaskInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(Task, Authorities.TASKS_READ);
