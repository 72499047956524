import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { Box, Grid } from "@material-ui/core";
import _ from "lodash";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import {
  fetchPaymentGatewayInfo,
  updatePaymentGatewayInfo,
} from "../../../../services/eatprestoApp/paymentGatewayService";
import PaymentGatewayInfoNode from "./PaymentGatewayInfoNode";
import { useForm } from "react-hook-form";
import { AxiosError } from "axios";
import CardCommon from "../../../../components/card/CardCommon";

interface FormData {
  created: string;
  deliveryFee: number;
  deliveryFeeFixed: number;
  dineInFee: number;
  dineInFeeFixed: number;
  entityId: string;
  feeType: string;
  gatewayLocationId: string;
  id: number;
  locationId: string;
  payLinkFee: number;
  payLinkFixedFee: number;
  paymentGatewayMode: any;
  paymentGatewayProvider: string;
  pickUpFee: number;
  pickUpFeeFixed: number;
  terminalFee: number;
  terminalFeeFixed: number;
  updated: string;
  version: number;
}

/**
 * PaymentGateway Component:
 * This React component handles the configuration of payment gateways for a specific location.
 * It includes fetching and updating payment gateway information, handling conflicts, and rendering the user interface.
 * Utilizes react-hook-form for form handling.
 *
 * Components:
 * - PaymentGatewayInfoNode: Renders the form and controls related to payment gateway configuration.
 * - InventoryDetailsDefault: Displays default inventory details UI with loading indicators.
 * - DefaultAlert: Displays success and error alerts.
 * - CardCommon: Common card component for consistent styling.
 */
const PaymentGateway: React.FunctionComponent = () => {
  const [selectedPaymentGatewayObj, setSelectedPaymentGatewayObj] =
    useState<any>({});
  const [selectedFeeTypeObj, setSelectedFeeTypeObj] = useState<any>({});
  const [selectedFeeTypeObjInitial, setSelectedFeeTypeObjInitial] =
    useState<any>({});
  const [error, setError] = useState("");
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [paymentGatewayDetails, setPaymentGatewayDetails] = useState<any>({});
  const [paymentGatewayDetailsInitial, setPaymentGatewayDetailsInitial] =
    useState<any>({});

  const match: any = useRouteMatch();

  /* Use a react form hooks */
  const {
    register,
    handleSubmit,
    errors,
    reset,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      created: "",
      deliveryFee: 0,
      deliveryFeeFixed: 0,
      dineInFee: 0,
      dineInFeeFixed: 0,
      entityId: "",
      feeType: "",
      gatewayLocationId: "",
      id: 0,
      locationId: "",
      payLinkFee: 0,
      payLinkFixedFee: 0,
      paymentGatewayMode: "",
      paymentGatewayProvider: "",
      pickUpFee: 0,
      pickUpFeeFixed: 0,
      terminalFee: 0,
      terminalFeeFixed: 0,
      updated: "",
      version: 0,
    },
  });

  /**
   * useEffect hook to set the document title and fetch payment gateway information on component mount.
   */
  useEffect(() => {
    // Set the document title
    document.title = "Location - Payment Configuration";

    // Fetch payment gateway details when the component mounts
    getPaymentGatewayDetails();
  }, []);

  /**
   * Function to fetch payment gateway information and initialize loading state.
   */
  const getPaymentGatewayDetails = async () => {
    try {
      // Fetch payment gateway information using the 'fetchPaymentGatewayInfo' API
      const res = await fetchPaymentGatewayInfo(match.params.locationId);
      setPaymentGatewayDetails(res.data.data);
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // Set error state if there's an issue with the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to handle a conflict (HTTP 409) during payment gateway update.
   * @param {any} formData - Form data to be used for updating.
   */
  const getPaymentGatewayDetails409 = async (formData: any) => {
    try {
      // Fetch payment gateway information using the 'fetchPaymentGatewayInfo' API
      const res = await fetchPaymentGatewayInfo(match.params.locationId);
      // Update the version in the form data
      formData.version = res.data.data.version;
      // Retry updating with the new form data
      handleCreateStockConfiguration(formData);
    } catch (error) {
      // Set error state if there's an issue with the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle the creation or update of payment gateway information.
   * @param {any} formData - Form data to be used for the update.
   */
  const handleCreateStockConfiguration = async (formData: any) => {
    try {
      // Call the API to update payment gateway information
      const res = await updatePaymentGatewayInfo(
        match.params.locationId,
        formData,
      );
      setPaymentGatewayDetails(res.data.data);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      // Handle HTTP 409 conflict by retrying the update
      const err: any = error as AxiosError;
      if (err.response.status === 409) {
        getPaymentGatewayDetails409(formData);
      } else {
        // Set error state for other unexpected errors
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      }
    }
  };

  /**
   * Function to handle form submission.
   * @param {FormData} data - Form data submitted by the user.
   */
  const handleSubmitData = handleSubmit((data) => {
    // Clone form data and set additional properties
    const formData = _.cloneDeep(data);
    formData.created = paymentGatewayDetails.created;
    formData.locationId = paymentGatewayDetails.locationId;
    formData.paymentGatewayMode = paymentGatewayDetails.paymentGatewayMode;
    formData.gatewayLocationId = paymentGatewayDetails.gatewayLocationId;
    formData.updated = paymentGatewayDetails.updated;
    formData.version = paymentGatewayDetails.version;
    formData.id = paymentGatewayDetails.id;
    formData.locationId = paymentGatewayDetails.locationId;
    formData.paymentGatewayProvider = selectedPaymentGatewayObj.id;
    formData.feeType = selectedFeeTypeObj.id;
    // Call the function to handle form submission
    handleCreateStockConfiguration(formData);
  });

  // Render the component JSX
  return (
    <>
      {/* Main container */}
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={[]}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic=""
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
          isLocation={true}
        >
          <Grid container>
            <Grid item xs={12} sm={9} md={6} lg={6}>
              <CardCommon backgroundColor={"entity_background"}>
                <PaymentGatewayInfoNode
                  register={register}
                  errors={errors}
                  handleSubmitData={handleSubmitData}
                  reset={reset}
                  paymentGatewayDetails={paymentGatewayDetails}
                  selectedPaymentGatewayObj={selectedPaymentGatewayObj}
                  setSelectedPaymentGatewayObj={setSelectedPaymentGatewayObj}
                  selectedFeeTypeObj={selectedFeeTypeObj}
                  setSelectedFeeTypeObj={setSelectedFeeTypeObj}
                  isDirty={isDirty}
                  setSelectedFeeTypeObjInitial={setSelectedFeeTypeObjInitial}
                  selectedFeeTypeObjInitial={selectedFeeTypeObjInitial}
                  setPaymentGatewayDetailsInitial={
                    setPaymentGatewayDetailsInitial
                  }
                  paymentGatewayDetailsInitial={paymentGatewayDetailsInitial}
                />
              </CardCommon>
            </Grid>
          </Grid>
        </InventoryDetailsDefault>
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(
  PaymentGateway,
  Authorities.PAYMENT_CONFIGURATION_READ,
);
