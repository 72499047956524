import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import Dashboard from "../pages/Dashboard";
import Menu from "../pages/Menu";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MenuInfo from "../pages/MenuInfo";
import Location from "../../PrestoExpressLocationApp/pages/Location";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "DASHBOARD",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "MENU",
      title: "Menus",
      path: "/menu",
      icon: <MenuBookIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
  ];
  return sidebarRoute;
};

export interface MenuAppRoutesProps {}

const MenuAppRoutes: React.FunctionComponent<MenuAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-menus/menu" component={Menu} exact />
      <Route
        path="/:locationId/presto-menus/:publishedMenuId/presto-menus-info"
        component={MenuInfo}
        exact
      />
      <Route path="/:locationId/presto-menus" component={Dashboard} exact />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default MenuAppRoutes;
