import React, { useCallback, useEffect, useState } from "react";
import {
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import {
  createDeliveryInfo,
  deleteDeliveryInfo,
  updateDeliveryInfo,
} from "../../../../services/eatprestoApp/deliveryInfoService";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import DeliveryArea from "./DeliveryArea";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import CardCommon from "../../../../components/card/CardCommon";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import { CustomTheme } from "../../../../types/customTheme";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_DELETING_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { HttpStatus, submitButtonName } from "../../../../utils/enum";
import { AxiosError } from "axios";
import { Autocomplete } from "@material-ui/lab";
import SwitchButtonResponsive from "../../../../components/switches/SwitchButtonResponsive";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    base: {
      padding: "16px 16px 0 16px",
      borderRadius: 4,
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 8,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      borderRadius: "8px 8px 0 0",
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 8,
    },
    actionsWrapper: {
      padding: "16px 16px",
      display: "flex",
      justifyContent: "center",
    },
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      backgroundColor: theme.palette.background.entity_highlight_background,
      borderRadius: "10px",
      border: "none",
      [`& fieldset`]: {
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    autoComplete: {
      marginTop: "4px",
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
        borderRadius: 10,
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: "none",
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

export interface DeliveryInfoNodeProps {
  nodeData: any;
  type: string;
  getDeliveryInfo: any;
  isAuthorized: boolean;
  handleCopyNode: any;
  handleRemoveNode: (type: string, uuid: string) => void;
  isLoadingButton: any;
}

/**
 * DeliveryInfoNode Component:
 * This React component represents a form for managing delivery information
 * for a specific location. It includes features for creating, updating, and
 * deleting delivery options. The form allows users to input details such
 * as area, delivery type, minimum order price, charge, active status, and more.
 * The component integrates with Material-UI for styling and UI elements.
 */
const DeliveryInfoNode: React.FunctionComponent<DeliveryInfoNodeProps> = ({
  nodeData,
  getDeliveryInfo,
  type,
  handleRemoveNode,
  handleCopyNode,
  isAuthorized,
  isLoadingButton,
}) => {
  const [deliveryType, setDeliveryType] = useState("PICKUP");
  const [paymentTypes, setPaymentTypes] = useState<any>([]);
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [delayMins, setDelayMins] = useState(0);
  const [availability, setAvailability] = useState([]);
  const [area, setArea] = useState<any>({
    title: "",
    data: { distance: 0 },
    type: "",
  });
  const [charge, setCharge] = useState(0);
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [success, setSuccess] = useState("");
  const [availabilityErrorMessage, setAvailabilityErrorMessage] =
    useState(false);
  const [isError, setIsError] = useState(false);
  const [value, setValue] = React.useState("distance");
  const [valueInitial, setValueInitial] = React.useState("distance");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [originalNodeData, setOriginalNodeData] = useState({
    type: "",
    availability: [],
    area: { type: "distance" },
    charge: 0,
    delayMins: 0,
    isActive: false,
    minOrderPrice: 0,
    paymentTypes: [],
  });
  const classes = useStyles();
  const match: any = useRouteMatch();

  /**
   * Event handler for changing the delivery area type (Miles or Post Code).
   * Updates the state with the selected value and resets the selected options.
   */
  const handleChangeMilesPostcode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Update the state with the selected value
    setValue((event.target as HTMLInputElement).value);
    // Reset the selected options when the area type changes
    setSelectedOptions([]);
  };

  /**
   * Retrieves the updated data based on the current component state.
   *
   * @returns {Object} - The updated data object.
   */
  const getNodeDataByState = () => {
    const updatedData: any = _.cloneDeep(originalNodeData);
    updatedData.type = deliveryType;
    updatedData.availability = availability;

    updatedData.charge = charge;
    updatedData.delayMins = delayMins;
    updatedData.isActive = isActive;
    updatedData.minOrderPrice = minOrderPrice;
    updatedData.paymentTypes = paymentTypes;

    // Update area data based on the selected area type
    if (value === "distance") {
      // If the area data has a 'distance' property, use it; otherwise, default to an empty string
      const distanceValue = area.data?.distance || "";
      updatedData.area.data = { distance: distanceValue };
    } else if (value === "postcode") {
      // For 'postcode' type, construct the area data from selected options
      const output = selectedOptions.reduce((acc: any, currentValue: any) => {
        acc[currentValue] = currentValue;
        return acc;
      }, {});

      if (area.data?.distance) {
        // If 'distance' property exists, add it to the area data
        const newObjToAdd = { distance: area.data.distance };

        updatedData.area.data = { ...output, ...newObjToAdd };
      } else {
        // If 'distance' property doesn't exist, use the constructed area data
        updatedData.area.data = output;
      }
    }

    // Update area title and type
    updatedData.area.title = area.title;
    updatedData.area.type = value;
    return updatedData;
  };

  /**
   * Updates the component state based on the provided nodeData.
   *
   * @param {Object} nodeData - The data object containing information about the node.
   */
  const setNodeDataToState = useCallback((nodeData) => {
    const {
      id,
      availability,
      area,
      charge,
      delayMins,
      isActive,
      minOrderPrice,
      paymentTypes,
      type,
    } = nodeData;
    setNodeID(id);
    setIsActive(isActive);
    setDeliveryType(type);
    setMinOrderPrice(minOrderPrice);
    setDelayMins(delayMins);
    setAvailability(availability);
    setArea(area);
    setCharge(charge);
    setPaymentTypes(paymentTypes);

    // Set additional state values based on the area type
    setValue(area.type);
    setValueInitial(area.type);

    // If the area type is 'postcode', extract and set selected options
    if (area.type === "postcode") {
      const filteredKeys = Object.keys(area.data).filter(
        (key) => key !== "distance",
      );
      setSelectedOptions(filteredKeys);
    }
  }, []);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  /* Update the paymentTypeList after selecting the payment options using switches (cash, card). */
  const handleSelectPaymentTypes = (e: any) => {
    const { value, name, checked } = e.target;
    // When a payment type option is selected, that payment type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...paymentTypes, value]);
      setPaymentTypes([...selectedList]);
    } else {
      // When a payment type option is not selected, that payment type option is removed into a list.
      const selectedList: any = paymentTypes.filter(
        (paymentType: String) => name !== paymentType,
      );
      setPaymentTypes(selectedList);
    }
  };

  /* Update the deliveryType state after selecting the delivery options using selector
  (dine-in, pickup, and delivery). */
  const handleSelectDeliveryType = (e: any) => {
    const { value } = e.target;
    setDeliveryType(value);
  };

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  /* Send an API call to update delivery information after click save changes button. */
  const handleSave = async () => {
    setAvailabilityErrorMessage(false);
    setError("");
    const updatedData = getNodeDataByState();
    try {
      const res = await updateDeliveryInfo(
        match.params.locationId,
        nodeID,
        updatedData,
      );
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;

      if (err.response.status === 409) {
        getDeliveryInfo();
        setError(ERROR_MESSAGE_VERSION_ERROR);
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };

  /* Send an API call to crete delivery information after click create button. */
  const handleCreateDeliveryOption = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    const data = getNodeDataByState();
    try {
      await createDeliveryInfo(match.params.locationId, data);
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_CREATED);
      setIsCreated(true);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_CREATING_ERROR);
      }
    }
  };

  /* Send an API call to delete delivery information  after click delete button. */
  const handleDeleteDeliveryOption = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    try {
      await deleteDeliveryInfo(match.params.locationId, nodeID);
      getDeliveryInfo();
      setOpenDeleteConfirm(false);
      setSuccess(SUCCESSFULLY_DELETED);
    } catch (error) {
      setOpenDeleteConfirm(false);
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_DELETING_ERROR);
      }
    }
  };

  /* Change the delivery title */
  const handleTitleChange = (e: any) => {
    const { value } = e.target;
    const areaUpdated = { ...area };
    areaUpdated.title = value;
    setArea(areaUpdated);
  };

  /* Reset states after clicking the cancel button. */
  const resetToOriginalData = () => {
    setNodeDataToState(originalNodeData);
  };

  const updatedData = getNodeDataByState();
  // Checking if a change has occurred in the form.
  const isSame =
    _.isEqual(originalNodeData, updatedData) && valueInitial === value;
  const { newId } = nodeData;
  const deleteFunc = isCreated
    ? handleDeleteDeliveryOption
    : newId
    ? () => handleRemoveNode(type, newId)
    : () => setOpenDeleteConfirm(true);

  /* Check for a create or update and 
  call the handleCreateDeliveryOption function if it is a create and 
  handleSave function if it is a update.
  Only update or save data if minOrderPrice, charge, and delayMins are 0 or greater than 0. */
  const handleSubmit = () => {
    if (
      _.isEmpty(minOrderPrice.toString()) ||
      parseFloat(minOrderPrice.toString()) < 0 ||
      _.isEmpty(charge.toString()) ||
      parseFloat(charge.toString()) < 0 ||
      _.isEmpty(delayMins.toString()) ||
      parseFloat(delayMins.toString()) < 0 ||
      (value === "postcode" && _.isEmpty(selectedOptions))
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      if (!area.title) return;
      if (nodeData.status === "create" && !isCreated) {
        return handleCreateDeliveryOption();
      }
      return handleSave();
    }
  };

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setAvailabilityErrorMessage(false);
    try {
      /* availabilitySelectionList = [{openTime: '11:49', closeTime: '11:49', id: 2, 
                                         nodeList: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                                    {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]}]
           combinedMowArrays = {tue: [{minuteOfWeek: 3589, durationMins: 1440, day: 'tue', openTime: '11:49', closeTime: '11:49'},
                                thu:  {minuteOfWeek: 6469, durationMins: 1440, day: 'thu', openTime: '11:49', closeTime: '11:49'}]} */
      const combinedMowArrays: any = combineMinuteOfWeekArrays(
        availabilitySelectionList,
      );
      let normalizedArray = _.cloneDeep(combinedMowArrays);

      // Remove select same date range in same same date.
      Object.keys(combinedMowArrays).forEach((day) => {
        normalizedArray[day] = normalizeTimeSelections(normalizedArray[day]);
      });

      // Convert array to minute of week array
      const mowArray: any = Object.values(normalizedArray).flat();
      const data = _.cloneDeep(originalNodeData);

      // Update data
      data.availability = mowArray;

      // If the discount has been created, add new 'availability'.
      await updateDeliveryInfo(match.params.locationId, nodeID, data);
      getDeliveryInfo();
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setError(ERROR_MESSAGE_VERSION_ERROR);
        getDeliveryInfo();
      } else {
        setError(ERROR_MESSAGE_UPDATING_ERROR);
      }
    }
  };

  /**
   * Handles the change in selected options for postcodes and updates the component state.
   *
   * @param {any} value - The new selected options for postcodes.
   */
  const handleChangePostCode = (value: any) => {
    // Set the selected options based on the provided value
    setSelectedOptions(value);
  };

  /**
   * Handles the input change event and filters the input value to allow only alphanumeric characters.
   *
   * @param {any} event - The input change event.
   */
  const handleInput = (event: any) => {
    // Get the input value from the event
    const inputValue = event.target.value;

    // Filter out non-alphanumeric characters using a regular expression
    const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");

    // Update the input value with the filtered value
    event.target.value = filteredValue;
  };

  return (
    <Grid item sm={12} md={6} xs={12} lg={4} style={{ display: "flex" }}>
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteDeliveryOption}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <div style={{ width: "100%" }}>
        <CardCommon backgroundColor={"entity_background"}>
          {error && (
            <Typography
              component="div"
              className={classes.error}
              variant="body1"
            >
              {error}
            </Typography>
          )}
          <form className={classes.base}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" align="left">
                {area?.title}
              </Typography>
              {deliveryType === "delivery" && (
                <FormControlLabel
                  style={{ flex: 1 }}
                  value="card"
                  control={<SwitchCommon />}
                  checked={isActive}
                  onChange={handleSwitchActivate}
                  label="Active"
                  labelPlacement="start"
                  disabled={!isAuthorized}
                />
              )}
            </div>
            <Divider variant="fullWidth" style={{ margin: "4px 0 8px 0" }} />
            <div className={classes.header}>
              <div>
                <TextfieldCommon
                  style={{ flex: 2 }}
                  label="Area title"
                  name="ariaTitle"
                  id="ariaTitle"
                  type={"text"}
                  value={area?.title}
                  onChange={handleTitleChange}
                  disabled={!isAuthorized}
                  inputProps={{ maxLength: 100 }}
                  error={!area.title}
                />
                {!area.title && (
                  <ValidationMessage message={"Title can not be empty"} />
                )}
              </div>
              {deliveryType === "delivery" ? (
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    style={{ marginTop: "21px" }}
                    onChange={handleChangeMilesPostcode}
                    defaultValue={value}
                  >
                    <FormControlLabel
                      value="distance"
                      control={<Radio size="small" />}
                      label="Miles"
                      checked={value === "distance"}
                      labelPlacement="start"
                      style={{ maxHeight: "0px" }}
                    />
                    <FormControlLabel
                      value="postcode"
                      control={<Radio size="small" />}
                      label="Post Code"
                      checked={value === "postcode"}
                      labelPlacement="start"
                      style={{ marginTop: "8px" }}
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControlLabel
                  style={{ flex: 1 }}
                  value="card"
                  control={<SwitchCommon />}
                  checked={isActive}
                  onChange={handleSwitchActivate}
                  label="Active"
                  labelPlacement="start"
                  disabled={!isAuthorized}
                />
              )}
            </div>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextfieldCommon
                  label="Minimum order price"
                  id="minOrderPrice"
                  name="minOrderPrice"
                  type="number"
                  value={minOrderPrice}
                  onChange={(e: any) => {
                    setMinOrderPrice(e.target.value);
                  }}
                  disabled={!isAuthorized}
                />
                {((isError && _.isEmpty(minOrderPrice.toString())) ||
                  (isError && parseFloat(minOrderPrice.toString()) < 0)) && (
                  <ValidationMessage message="Please enter valid Minimum Order Price." />
                )}
              </Grid>
              <Grid item md={6} xs={6}>
                <TextfieldCommon
                  label="Charge"
                  id="charge"
                  name="charge"
                  inputProps={{ max: 9999 }}
                  type="number"
                  value={charge}
                  onChange={(e: any) => {
                    setCharge(e.target.value);
                  }}
                  disabled={!isAuthorized}
                />
                {((isError && _.isEmpty(charge.toString())) ||
                  (isError && parseFloat(charge.toString()) < 0)) && (
                  <ValidationMessage message="Please enter valid Charge." />
                )}
              </Grid>
              <Grid item md={6} xs={6} style={{ marginBottom: "12px" }}>
                <TextField
                  fullWidth
                  id="deliveryType"
                  select
                  label="Delivery type"
                  className={classes.textField}
                  value={deliveryType}
                  size="small"
                  onChange={handleSelectDeliveryType}
                  // helperText="Please select your currency"
                  variant="outlined"
                  disabled
                >
                  <MenuItem value="takeout">Pick up</MenuItem>
                  <MenuItem value="delivery">Delivery</MenuItem>
                  <MenuItem value="dinein">Dine in</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                style={{
                  padding: 8,
                  // border: "1px solid gray",
                  borderRadius: 8,
                  marginBottom: "12px",
                }}
              >
                {/* <Typography>Orders&nbsp;are&nbsp;ready&nbsp;in&nbsp;</Typography> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label="Order ready time"
                    variant="outlined"
                    fullWidth
                    type="number"
                    size="small"
                    value={delayMins}
                    className={classes.textField}
                    onChange={(e: any) => {
                      if (e.target.value.length > 4) return;
                      setDelayMins(e.target.value);
                    }}
                    disabled={!isAuthorized}
                  />
                </div>
                {((isError && _.isEmpty(delayMins.toString())) ||
                  (isError && parseFloat(delayMins.toString()) < 0)) && (
                  <ValidationMessage message="Please enter valid Order Ready Time." />
                )}
              </Grid>
            </Grid>
            <AvailabilitySelection
              nodeData={originalNodeData}
              disabled={!isAuthorized}
              handleSubmit={handleSubmitAvailability}
              setAvailabilityErrorMessage={setAvailabilityErrorMessage}
            />
            {availabilityErrorMessage && (
              <ValidationMessage
                message={
                  "Please create a delivery information before updating."
                }
              />
            )}
            <Grid
              container
              spacing={1}
              style={{
                placeItems: "center",
                marginTop: "12px",
              }}
            >
              <Grid item>
                <FormGroup
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  row
                >
                  <FormControlLabel
                    value="cash"
                    disabled={!isAuthorized}
                    control={<SwitchButtonResponsive />}
                    label={<Typography variant="caption">Cash</Typography>}
                    name="cash"
                    checked={paymentTypes.includes("cash")}
                    labelPlacement="start"
                    onChange={handleSelectPaymentTypes}
                    style={{
                      marginLeft: "5px",
                    }}
                  />
                  <FormControlLabel
                    value="card"
                    disabled={!isAuthorized}
                    name="card"
                    control={<SwitchButtonResponsive />}
                    label={<Typography variant="caption">Card</Typography>}
                    checked={paymentTypes.includes("card")}
                    labelPlacement="start"
                    onChange={handleSelectPaymentTypes}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flex: 1,
                }}
              >
                {deliveryType === "delivery" && (
                  <DeliveryArea area={area} setArea={setArea} value={value} />
                )}
              </Grid>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <ButtonCommon
                  variant="contained"
                  color="blue"
                  onClick={() => handleCopyNode(originalNodeData)}
                >
                  <FileCopyIcon />
                </ButtonCommon>
              </Grid>
              <Grid item xs={12}>
                {deliveryType === "delivery" &&
                  (value === "postcode" ? (
                    <div style={{ marginTop: "12px" }}>
                      <Autocomplete
                        options={[]}
                        multiple
                        size={"small"}
                        color="inherit"
                        freeSolo
                        style={{ width: "100%" }}
                        disableClearable
                        fullWidth
                        classes={{ root: classes.autoComplete }}
                        value={selectedOptions}
                        onChange={(event, newValue) => {
                          handleChangePostCode(newValue);
                        }}
                        renderTags={(value, props) =>
                          value.map((option, index) => (
                            <Chip label={option} {...props({ index })} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.textField}
                            multiline
                            rows={3}
                            label="Post Code"
                            onInput={handleInput}
                            {...params}
                          />
                        )}
                      />
                      {Object.keys(selectedOptions).length === 0 && (
                        <ValidationMessage
                          message={"Post Code can not be empty"}
                        />
                      )}
                    </div>
                  ) : (
                    <div style={{ height: "88px" }} />
                  ))}
              </Grid>
            </Grid>
          </form>
          {isAuthorized && (
            <div className={classes.actionsWrapper}>
              <ButtonCommon
                isLoadingPage={isLoadingButton}
                disabled={isSame}
                style={{
                  flex: 1,
                  fontSize: 11,
                  marginRight: 4,
                }}
                variant="contained"
                color={nodeData.status === "create" ? "green" : "yellow"}
                onClick={handleSubmit}
              >
                {nodeData.status === "create" && !isCreated
                  ? submitButtonName.CREATE_SUBMIT_BUTTON
                  : submitButtonName.UPDATE_SUBMIT_BUTTON}
              </ButtonCommon>
              <ButtonCommon
                disabled={isSame}
                variant="contained"
                style={{ fontSize: 11, flex: 1 }}
                color="orange"
                startIcon={<RotateLeftIcon />}
                onClick={resetToOriginalData}
              >
                Cancel
              </ButtonCommon>
              {isAuthorized && (
                <ButtonCommon
                  variant="contained"
                  style={{
                    fontSize: 11,
                    flex: 1,
                    marginLeft: 4,
                  }}
                  color="red"
                  startIcon={<DeleteIcon />}
                  onClick={deleteFunc}
                >
                  Delete
                </ButtonCommon>
              )}
            </div>
          )}
        </CardCommon>
      </div>
    </Grid>
  );
};

export default withAuthority(DeliveryInfoNode, Authorities.DELIVERY_INFO_READ);
