import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import {
  createMasterLocations,
  fetchAllMasterLocations,
  updateMasterLocations,
} from "../../../../services/inventory/masterLocations";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import StockConfigurationNode from "./StockConfigurationNode";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import AlertDialog from "../../../../components/alerts/AlertDialog";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * StockConfiguration Component:
 * This React component manages the configuration of stock departments for a given inventory location.
 * It includes functionality to fetch and display master locations, create or update stock configurations,
 * and handle activation requirements. The component is integrated with authentication checks to ensure
 * the user has the necessary authority.
 *
 * Component Structure:
 * - State variables manage component state, including error messages, activation status, and configuration details.
 * - Functions handle API calls, stock configuration updates, and location-specific actions.
 * - useEffect fetches location information and sets the document title on component mount.
 * - JSX structure includes the main container, inventory details component, and alert dialogs.
 *
 * Note: The component is wrapped with the withAuthority HOC to enforce user authority checks.
 */
const StockConfiguration: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isStockEnable, setIsStockEnable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    successfullyChangeStockConfiguration,
    setSuccessfullyChangeStockConfiguration,
  ] = useState(false);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [isEnableMasterLocation, setIsEnableMasterLocation] = useState(false);
  const [selectedDepartmentObj, setSelectedDepartmentObj] = useState<any>({});
  const [selectedDepartmentObjInitial, setSelectedDepartmentObjInitial] =
    useState<any>({});
  const [stockConfiguration, setStockConfiguration] = useState<any>([]);
  const [isActiveInitial, setIsActiveInitial] = useState(false);

  const match: any = useRouteMatch();

  /**
   * Function: handleGetMasterLocations
   *
   * Description:
   * This asynchronous function is responsible for fetching master locations for a given inventory location
   * using the 'fetchAllMasterLocations' API. It updates the component state based on the API response,
   * handling cases where the response contains valid data or when there's an error. The function manages
   * the loading state and updates the component state with master location details.
   */
  const handleGetMasterLocations = async (locationDetailsNode: any) => {
    try {
      // Attempt to fetch master locations using the 'fetchAllMasterLocations' API
      const { data } = await fetchAllMasterLocations(match.params.locationId);

      // Check if the response data and 'active' property exist
      if (data && data.data) {
        // Destructure relevant data from the API response
        const { masterLocationId, active } = data.data;

        // Obtain location details from the filter locations
        const locationDetails = getFilterListFromArrayObject(
          locationDetailsNode,
          masterLocationId,
        );

        // Update component state based on API response
        setStockConfiguration(data.data);
        setSelectedDepartmentObj({
          id: masterLocationId,
          label:
            masterLocationId === match.params.locationId
              ? "Master Location Not Required"
              : locationDetails[0].businessDisplayName,
        });
        setSelectedDepartmentObjInitial({
          id: masterLocationId,
          label:
            masterLocationId === match.params.locationId
              ? "Master Location Not Required"
              : locationDetails[0].businessDisplayName,
        });
        setIsEnableMasterLocation(masterLocationId !== match.params.locationId);

        // Set the active status in component state
        setIsActive(active);
        setIsActiveInitial(active);
      } else {
        // Handle case where there is no valid data in the API response
        setIsActive(false);
        setIsActiveInitial(false);
        setStockConfiguration([]);
        setSelectedDepartmentObj({
          id: match.params.locationId,
          label: "Master Location Not Required",
        });
        setSelectedDepartmentObjInitial({
          id: match.params.locationId,
          label: "Master Location Not Required",
        });
      }

      // Update loading state after API call completion
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // Handle errors during the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function: handleCreateStockConfiguration
   *
   * Description:
   * This asynchronous function is responsible for creating a new stock configuration for a given location.
   * It prepares the form data, including location and master location details, and calls the 'createMasterLocations'
   * API to create a new stock department. The function handles success and error scenarios, updating the component
   * state accordingly.
   */
  const handleCreateStockConfiguration = async (locationId: any) => {
    // Prepare form data with required information
    const formData = {
      locationId: match.params.locationId, // Assuming match is defined elsewhere
      masterLocationId: locationId,
      active: isActive, // Assuming isActive is defined elsewhere
      version: 0,
    };
    try {
      // Make an asynchronous call to create master locations with the prepared form data
      const res = await createMasterLocations(
        match.params.locationId,
        formData,
      );

      // Update state and variables upon successful creation
      setSuccessfullyChangeStockConfiguration(true);
      setStockConfiguration(res.data.data);
      setIsActiveInitial(res.data.data.active);
      setSelectedDepartmentObjInitial({ id: res.data.data.masterLocationId });
    } catch (err) {
      // Handle errors in case of failure
      setSuccessfullyChangeStockConfiguration(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleUpdateStockConfiguration
   *
   * Description:
   * This asynchronous function is responsible for updating an existing stock configuration for a given location.
   * It prepares the necessary form data, including the ID, location, master location details, and version of the stock configuration,
   * and calls the 'updateMasterLocations' API to update the existing stock department. The function handles both success and error scenarios,
   * updating the component state accordingly.
   */
  const handleUpdateStockConfiguration = async (locationId: any) => {
    // Prepare form data with required information for updating
    const formData = {
      id: stockConfiguration.id, // Assuming stockConfiguration is defined elsewhere
      locationId: match.params.locationId, // Assuming match is defined elsewhere
      masterLocationId: locationId,
      active: isActive, // Assuming isActive is defined elsewhere
      version: stockConfiguration.version, // Assuming stockConfiguration is defined elsewhere
    };

    try {
      // Make an asynchronous call to update master locations with the prepared form data
      const res = await updateMasterLocations(
        match.params.locationId,
        formData,
      );

      // Update state and variables upon successful update
      setSuccessfullyChangeStockConfiguration(true);
      setIsActiveInitial(res.data.data.active);
      setSelectedDepartmentObjInitial({ id: res.data.data.masterLocationId });
      setStockConfiguration(res.data.data);
    } catch (err) {
      // Handle errors in case of failure
      setSuccessfullyChangeStockConfiguration(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function: handleGetMasterLocationSpecificLocation
   *
   * Description:
   * This asynchronous function is responsible for handling the stock configuration based on the provided location ID.
   * It first checks whether the given location ID matches the current location ID in the route.
   * If true, it checks whether the stock configuration is empty, and if so, it creates a new stock configuration using 'handleCreateStockConfiguration';
   * otherwise, it updates the existing stock configuration using 'handleUpdateStockConfiguration'.
   * If the location IDs don't match, it fetches master locations using the 'fetchAllMasterLocations' API.
   * It then checks if the response data and 'active' property exist. If the master location is active and can be used for stock, it updates the stock configuration;
   * otherwise, it sets appropriate error messages.
   */
  const handleGetMasterLocationSpecificLocation = async (locationId: any) => {
    try {
      // Check if the provided location ID matches the current location ID in the route
      if (locationId === match.params.locationId) {
        // If stockConfiguration is empty, create a new stock configuration; otherwise, update the existing one
        _.isEmpty(stockConfiguration)
          ? handleCreateStockConfiguration(locationId)
          : handleUpdateStockConfiguration(locationId);
      } else {
        // Fetch master locations using the 'fetchAllMasterLocations' API
        const { data } = await fetchAllMasterLocations(locationId);

        // Check if the response data and 'active' property exist
        if (data && data.data && data.data.active) {
          // Check if the selected master location can be used for stock
          if (
            data.data.masterLocationId === locationId &&
            data.data.locationId === locationId
          ) {
            setIsStockEnable(true);
            // If stockConfiguration is empty, create a new stock configuration; otherwise, update the existing one
            _.isEmpty(stockConfiguration)
              ? handleCreateStockConfiguration(locationId)
              : handleUpdateStockConfiguration(locationId);
          } else {
            // Set error message if the selected master location cannot be used for stock
            setIsStockEnable(false);
            setErrorMessage("The selected master location cannot be used.");
          }
        } else {
          // Set error message if the selected master location does not have stock enabled
          setIsStockEnable(false);
          setErrorMessage(
            "The selected master location does not have stock enabled.",
          );
        }
      }
    } catch (error) {
      // Handle errors during the API call
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Department"
    document.title = "Inventory - Stock Configuration";

    // Call the function to fetch master locations when the component mounts
    getAllFilterLocation();
  }, []);

  /**
   * Function to fetch location information and initialize stock department loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async () => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations("");

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        locationList.push({
          id: match.params.locationId,
          label: "Master Location Not Required",
        });
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      handleGetMasterLocations(res.data.data);
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  return (
    <>
      {/* Main container */}
      <Box>
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          topic="Stock Configuration"
          isNeedAddButton={false}
          nodeList={[1]}
          isActiveLocationId={true}
        >
          <StockConfigurationNode
            locationSelectorList={locationSelectorList}
            setSelectedDepartmentObj={setSelectedDepartmentObj}
            selectedDepartmentObj={selectedDepartmentObj}
            setIsActive={setIsActive}
            isActive={isActive}
            stockConfiguration={stockConfiguration}
            handleGetMasterLocationSpecificLocation={
              handleGetMasterLocationSpecificLocation
            }
            selectedDepartmentObjInitial={selectedDepartmentObjInitial}
            isActiveInitial={isActiveInitial}
            setIsEnableMasterLocation={setIsEnableMasterLocation}
            isEnableMasterLocation={isEnableMasterLocation}
          />
        </InventoryDetailsDefault>
        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>

      {!isStockEnable && (
        <AlertDialog
          open={!isStockEnable}
          title="Activation Required"
          desc={errorMessage}
          severity="error"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setIsStockEnable(true);
          }}
          color="secondary"
        />
      )}

      {successfullyChangeStockConfiguration && (
        <AlertDialog
          open={successfullyChangeStockConfiguration}
          title="Success"
          desc="Successfully change stock configuration."
          severity="success"
          confirmLabel="Ok"
          disableBackdropClick
          confirmAction={() => {
            setSuccessfullyChangeStockConfiguration(false);
          }}
          color="secondary"
        />
      )}
    </>
  );
};

export default withAuthority(
  StockConfiguration,
  Authorities.STOCK_CONFIGURATION_READ,
);
