export const deliveryChannel = [
  { label: "Eatpresto", id: "eatpresto" },
  { label: "Deliverect", id: "deliverect" },
  { label: "Justeat", id: "justeat" },
  { label: "Ubereats", id: "ubereats" },
  { label: "Deliveroo", id: "deliveroo" },
  { label: "Other", id: "other" },
];

export const orderTypeSelectorList = [
  { label: "Delivery", id: "delivery" },
  { label: "Takeout", id: "takeout" },
  { label: "Dine In", id: "dinein" },
];

export const paymentType = [
  { label: "Card", id: "card" },
  { label: "Cash", id: "cash" },
];

export const orderStatusSelectorList = [
  { label: "Checkout", id: "checkout" },
  { label: "Pending", id: "pending" },
  { label: "New", id: "new" },
  { label: "Pos", id: "pos" },
  { label: "Pre", id: "pre" },
  { label: "Accepted", id: "accepted" },
  { label: "Rejected", id: "rejected" },
  { label: "Ready", id: "ready" },
  { label: "Out for Delivery", id: "out_for_delivery" },
  { label: "Delivered", id: "delivered" },
  { label: "Cancel", id: "cancel" },
  { label: "Cancelled ", id: "cancelled " },
];
export const allSubTypeList = [
  { label: "Not applicable", id: "not_applicable" },
  { label: "Client", id: "client" },
  { label: "Customer", id: "customer" },
  { label: "Presto", id: "presto" },
  { label: "Ship day", id: "shipday" },
];

export const emailPhoneReminder_jobSubTypeList = [
  { label: "Client", id: "client" },
  { label: "Customer", id: "customer" },
  { label: "Presto", id: "presto" },
];

export const accept_check_jobSubTypeList = [
  { label: "Not applicable", id: "not_applicable" },
];

export const deliveryTrackingSubTypeList = [
  { label: "Ship day", id: "shipday" },
];

export const taskTriggerList = [{ label: "New order", id: "new_order" }];

export const typeList = [
  { label: "Email", id: "email" },
  { label: "Phone", id: "phone" },
  { label: "Accept check job", id: "accept_check_job" },
  { label: "Reminder job", id: "reminder_job" },
  { label: "Delivery tracking", id: "delivery_tracking" },
];

export const stockLevelList = [
  { label: "Critical Stock Level", id: "criticalStockLevel" },
  { label: "Warning Stock Level", id: "warningStockLevel" },
  { label: "Adequate Stock Level", id: "adequateStockLevel" },
];
