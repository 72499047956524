/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/require-default-props */
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Card } from "@material-ui/core";
import { CustomColor, CustomTheme } from "../../types/customTheme";

/* Change the card color using props. */
const handleBackgroundColor = (
  color: CustomColor,
  backgroundColor: any,
  theme: CustomTheme,
) => {
  // If there is a value for the color props, that color is used for the background color of the card.
  if (color) {
    return theme.palette.custom[color].main;
  }
  if (backgroundColor === "entity_background") {
    // If value of the backgroundColor is 'entity_background', that color is used for the background color of the card.
    return theme.palette.background.entity_background;
  }
  if (backgroundColor === "entity_highlight_background") {
    // If value of the backgroundColor is 'entity_highlight_background', that color is used for the background color of the card.
    return theme.palette.background.entity_highlight_background;
  }
  if (backgroundColor === "table_header_background") {
    // If value of the backgroundColor is 'entity_highlight_background', that color is used for the background color of the card.
    return theme.palette.background.table_header_background;
  }
  // If value of the backgroundColor prop is empty, card color is paper color.
  return theme.palette.background.paper;
};

/* Change the card hover color using props. */
const handleBackgroundColorHover = (
  color: CustomColor,
  backgroundColor: any,
  theme: CustomTheme,
) => {
  // If there is a value for the color props, that color is used for the background color of the card hover.
  if (color) {
    return theme.palette.custom[color].dark;
  }
  if (backgroundColor === "entity_background") {
    // If value of the backgroundColor is 'entity_background', that color is used for the background color of the card hover.
    return theme.palette.background.entity_background;
  }
  if (backgroundColor === "entity_highlight_background") {
    // If value of the backgroundColor is 'entity_highlight_background', that color is used for the background color of the card hover.
    return theme.palette.background.entity_highlight_background;
  }
  if (backgroundColor === "table_header_background") {
    // If value of the backgroundColor is 'table_header_background', that color is used for the background color of the card.
    return theme.palette.background.table_header_background;
  }
  // If value of the backgroundColor prop is empty, card hover color is paper color.
  return theme.palette.background.paper;
};

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
      overflowY: "auto",
      boxShadow: "none",
      backgroundColor: ({
        color,
        backgroundColor,
      }: {
        color: CustomColor;
        backgroundColor: CustomColor;
      }) => handleBackgroundColor(color, backgroundColor, theme),
      "&:hover": {
        backgroundColor: ({
          color,
          backgroundColor,
        }: {
          color: CustomColor;
          backgroundColor: CustomColor;
        }) => handleBackgroundColorHover(color, backgroundColor, theme),
      },
    },
  }),
);

export interface CardCommonProps {
  children: any;
  color?: any;
  backgroundColor?: any;
  style?: any;
}

/* This component show the card component. */
const CardCommon: React.FunctionComponent<CardCommonProps> = ({
  children,
  color,
  backgroundColor,
  style,
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <Card className={classes.root} style={style}>
      {children}
    </Card>
  );
};

export default CardCommon;
