import React from "react";
import WithLoading from "../../../../utils/WithLoading";
import ChartReportInfoNode from "./ChartReportInfoNode";

export interface DashboardProps {
  isLoading: any;
  currentDashboardNodeList: any;
  previousDashboardNodeList: any;
  paymentNodeList: any;
  previousPaymentNodeList: any;
  previousStartDate: any;
  previousEndDate: any;
  currentStartDate: any;
  currentEndDate: any;
  ordersNodeList: any;
  previousOrdersNodeList: any;
  averageNodeList: any;
  previousAverageNodeList: any;
  platformNodeList: any;
  previousPlatformNodeList: any;
  modeNodeList: any;
  previousModeNodeList: any;
  channelNodeList: any;
  previousChannelNodeList: any;
  isEmptyList?: any;
  currentSalesTotal: any;
  previousSalesTotal: any;
}

/* Until the required data for the sale chart, order chart, average chart, payment chart, 
   platform chart, model chart, and channel chart is received from the API call, 
   this will show the loading of the skeleton. */
const ChartLoading: React.FunctionComponent<DashboardProps> = ({
  isLoading,
  currentDashboardNodeList,
  previousDashboardNodeList,
  previousStartDate,
  paymentNodeList,
  previousPaymentNodeList,
  previousEndDate,
  currentStartDate,
  currentEndDate,
  ordersNodeList,
  previousOrdersNodeList,
  averageNodeList,
  previousAverageNodeList,
  platformNodeList,
  previousPlatformNodeList,
  modeNodeList,
  previousModeNodeList,
  channelNodeList,
  previousChannelNodeList,
  isEmptyList,
  currentSalesTotal,
  previousSalesTotal,
}) => {
  return (
    <>
      <ChartReportInfoNode
        currentDashboardNodeList={currentDashboardNodeList}
        previousDashboardNodeList={previousDashboardNodeList}
        paymentNodeList={paymentNodeList}
        previousPaymentNodeList={previousPaymentNodeList}
        previousStartDate={previousStartDate}
        previousEndDate={previousEndDate}
        currentStartDate={currentStartDate}
        currentEndDate={currentEndDate}
        ordersNodeList={ordersNodeList}
        previousOrdersNodeList={previousOrdersNodeList}
        averageNodeList={averageNodeList}
        previousAverageNodeList={previousAverageNodeList}
        platformNodeList={platformNodeList}
        previousPlatformNodeList={previousPlatformNodeList}
        modeNodeList={modeNodeList}
        previousModeNodeList={previousModeNodeList}
        channelNodeList={channelNodeList}
        previousChannelNodeList={previousChannelNodeList}
        isEmpty={isEmptyList}
        currentSalesTotal={currentSalesTotal}
        previousSalesTotal={previousSalesTotal}
      />
    </>
  );
};

export default WithLoading(ChartLoading, "chart");
