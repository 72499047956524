import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";
import ShiftHeader from "./ShiftHeader";
import withAuthority from "../../../../components/Auth/withAuthority";
import {
  fetchAllShiftReportInfo,
  fetchAllShiftInfo,
  getAllDeviceIdInfo,
} from "../../../../services/salesApp/shiftService";
import { shiftListFilterObject } from "../../../../utils/consts/list";
import { getCookie } from "../../../../utils/cookies";
import { useLocation, useRouteMatch } from "react-router";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import Authorities from "../../../../auth/authorities";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import { fetchAllUserInfo } from "../../../../services/salesApp/salesService";
import { convertDateTimeFormat } from "../../../../utils/ConvertDateTimeFormat";

export interface voidTransactionProps {}
/* Get all shift information, user information, and location details using API call at initial load and after use filter. */
const Shift: React.FunctionComponent<voidTransactionProps> = () => {
  const [shiftNodeList, setShiftNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("startDate");
  const [sortingMethod, setSortingMethod] = useState("DESC");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState("20");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [userList, setUserList] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [shiftReportNode, setShiftReportNode] = useState<any>({});
  const [isShiftSummaryReportLoading, setIsShiftSummaryReportLoading] =
    useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [numberOfLocations, setNumberOfLocations] = useState(0);
  const [deviceIdObj, setDeviceIdObj] = useState<any>({});
  const [deviceIdErr, setDeviceIdErr] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [idList, setIdList] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [isSelectAllShift, setIsSelectAllShift] = useState(false);

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const handleTerminal = (deviceId: any) => {
    const idObj = deviceIdObj[deviceId];
    if (idObj) {
      switch (idObj.sub_type) {
        case "-99":
          return "POS";
        case "pay-kiosk":
          return "Pay Kiosk";
        case "order-kiosk":
          return "Order Kiosk";
        case "wix":
          return "Online POS";
        default:
          return idObj.sub_type;
      }
    } else {
      return deviceId;
    }
  };

  const handleTid = (deviceId: any) => {
    const idObj = deviceIdObj[deviceId];
    if (idObj) {
      return `T-${idObj.prefix}`;
    } else {
      return deviceId;
    }
  };

  /* Get date difference between start and end date. Return date format is days, hours, minutes, and seconds  */
  const timeDiffCalc = (dateFuture: any, dateNow: any) => {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    console.log("calculated days", days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    console.log("calculated hours", hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    console.log("minutes", minutes);

    // calculate seconds
    const seconds = Math.floor(diffInMilliSeconds);
    diffInMilliSeconds -= seconds * 60;
    console.log("seconds", seconds);

    let difference = "";
    if (days > 0) {
      difference += `${days} days`;
    }

    if (hours !== 0) {
      if (days !== 0) {
        difference += ", ";
      }
      difference += `${hours} hours`;
    }
    if (minutes !== 0) {
      if (hours !== 0) {
        difference += ", ";
      }
      difference += `${minutes} min`;
    }
    if (seconds !== 0) {
      if (minutes !== 0) {
        difference += ", ";
      }
      difference += `${seconds} sec`;
    }

    return difference;
  };

  const handleSelectedIdList = (shiftNode: any) => {
    const allShiftNodeList: any = [];
    const selectedTableData: any = [];
    shiftNode.map((shift: any) => {
      const { locationId, id, deviceId, startDate, endDate } = shift;
      const duration = endDate
        ? timeDiffCalc(new Date(endDate), new Date(startDate))
        : "";
      allShiftNodeList.push(shift.id);
      const locationName = getFilterListFromArrayObject(
        locationSelectorList,
        locationId,
      );
      selectedTableData.push({
        id: id,
        Location: locationName[0].label,
        Terminal: handleTerminal(deviceId),
        TID: handleTid(deviceId),
        "Start Date": convertDateTimeFormat(startDate),
        "End Date": endDate === null ? "-" : convertDateTimeFormat(endDate),
        Duration: endDate ? duration : "Live Shift",
      });
    });
    setSelectedList(selectedTableData);
    return allShiftNodeList;
  };

  useEffect(() => {
    const locationId: any = params.get("locationId");
    if (!_.isEmpty(locationId)) {
      const numUniqueLocations = locationId.split(",");
      setNumberOfLocations(numUniqueLocations.length);
    }
  }, [params.get("locationId")]);

  /* Get the shift report Information */
  const handleGetShiftReportInfo = async (idListFormData: any) => {
    const cloneFilterData = _.cloneDeep(filterData);
    // Remove the start date and end date in the filter information.
    const locationIdList = cloneFilterData.substring(44);
    setIsShiftSummaryReportLoading(true);
    try {
      const res = await fetchAllShiftReportInfo(
        idToken,
        match.params.locationId,
        locationIdList,
        idListFormData,
      );
      setIsShiftSummaryReportLoading(false);
      setShiftReportNode(res.data.data);
    } catch (err) {
      setIsShiftSummaryReportLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* If there is a shortening method, it is shortened by that shortening method, otherwise, it is shortened by the locationId. */
  const handleShiftReportSorting = (sortingMethod: any, headerName: any) => {
    let headerNameClone = "";
    let sortingMethodClone = "";
    if (_.isEmpty(headerName)) {
      headerNameClone = "locationId";
      sortingMethodClone = "ASC";
    } else {
      headerNameClone = headerName;
      sortingMethodClone = sortingMethod;
    }
    return { headerNameClone, sortingMethodClone };
  };

  /* Get all shift information using API call and response data set to shiftNodeList state. */
  const getShiftFilterInfoForAllSelected = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filter: any,
  ) => {
    let newPagesize = 0;
    if (pageSize === 10000) {
      setPageSize("all");
      newPagesize = 10000;
    } else {
      setPageSize(pageSize);
      newPagesize = pageSize;
    }

    setCurrentPage(currentPage);
    const { sortingMethodClone, headerNameClone } = handleShiftReportSorting(
      sortingMethod,
      headerName,
    );
    try {
      const res = await fetchAllShiftInfo(
        idToken,
        match.params.locationId,
        newPagesize,
        currentPage,
        sortingMethodClone,
        headerNameClone,
        filter,
      );
      setShiftNodeList(res.data.data);
      if (newPagesize === 10000) {
        const shiftIdList = handleSelectedIdList(res.data.data);

        setIdList(shiftIdList);
      } else {
        setIdList([]);
      }
      setTotalPages(res.data.totalPages);
      setIsLoadingPage(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
    }
  };

  /* Get all shift information using API call and response data set to shiftNodeList state. */
  const getShiftFilterInfo = async (
    pageSize: any,
    currentPage: any,
    sortingMethod: any,
    headerName: any,
    filter: any,
  ) => {
    let newPagesize = 0;
    if (pageSize === 10000) {
      setPageSize("all");
      newPagesize = 10000;
    } else {
      setPageSize(pageSize);
      newPagesize = pageSize;
    }

    setCurrentPage(currentPage);
    const { sortingMethodClone, headerNameClone } = handleShiftReportSorting(
      sortingMethod,
      headerName,
    );
    try {
      const res = await fetchAllShiftInfo(
        idToken,
        match.params.locationId,
        newPagesize,
        currentPage,
        sortingMethodClone,
        headerNameClone,
        filter,
      );
      setShiftNodeList(res.data.data);
      setTotalPages(res.data.totalPages);
      setIsLoadingPage(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingPage(false);
    }
  };

  /* Get all user information using API call and response data set to userList state. */
  const getUserInfo = async (locationId: any) => {
    try {
      const res = await fetchAllUserInfo(idToken, locationId);

      let arr: any = [];
      if (!_.isEmpty(res.data.data)) {
        Object.values(res.data.data).map((data: any) => {
          arr.push({ id: data.id, label: data.name });
        });
      }
      if (!_.isEmpty(arr)) {
        setUserList((prevState: any) => [...prevState, ...arr]);
      }
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Get all location information using API call and response data set to locationSelectorList state. */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setIsLoadingPage(false);
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  const getAllDeviseId = async () => {
    getAllDeviceIdInfo(match.params.locationId)
      .then((res: any) => {
        setDeviceIdObj(res.data.data);
        setDeviceIdErr(false);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setDeviceIdErr(true);
      });
  };

  useEffect(() => {
    document.title = "Sale - Shift Summary";
    setIsLoadingPage(true);
    getAllFilterLocation("");
    getAllDeviseId();
  }, []);

  // Handle Shift information using selector
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    if (pageSize === "all") {
      getShiftFilterInfo(10000, 1, sortingMethod, headerName, filterData);
    } else {
      setIdList([]);
      setSelectedList([]);
      setIsSelectAllShift(false);
      getShiftFilterInfo(
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
        filterData,
      );
    }
    //
  };

  // Handle Shift information using page number
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    getShiftFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  // Sorting the Shift information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setIsSort(true);
    setDisableButton(true);
    setHeaderName(headerName);
    setSortingMethod(sortingMethod);
    getShiftFilterInfo(
      pageSize,
      currentPage,
      sortingMethod,
      headerName,
      filterData,
    );
  };

  // Remove sorting method
  const handleRemoveOrderListSorting = () => {
    setIsSort(false);
    setDisableButton(false);
    setHeaderName("");
    setSortingMethod("");
    getShiftFilterInfo(pageSize, currentPage, "", "", filterData);
  };

  // Get filter data
  const handleFilterData = (filterData: any) => {
    setIdList([]);
    setSelectedList([]);
    setIsSelectAllShift(false);
    setIsLoadingPage(true);
    setFilterData(filterData);
    getShiftFilterInfo(pageSize, 1, sortingMethod, headerName, filterData);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      getShiftFilterInfoForAllSelected(
        10000,
        1,
        sortingMethod,
        headerName,
        filterData,
      );
    } else {
      setIdList([]);
      setSelectedList([]);
    }
    setIsSelectAllShift(event.target.checked);
  };

  return (
    <>
      <Box>
        <SaleReport
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          getUserInfo={getUserInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={shiftListFilterObject}
          isOpenSkeletonLoading={isLoadingPage}
          nodeList={shiftNodeList}
          filterDetails={filterDetails}
          title={""}
          isChart={false}
          payInOut={false}
          isUserGet={true}
          isShift={true}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          topic="Shift Report"
          isReport={true}
        >
          <ShiftHeader
            handleChangeOrderListSorting={handleChangeOrderListSorting}
            handleRemoveOrderListSorting={handleRemoveOrderListSorting}
            nodeData={shiftNodeList}
            disableButton={disableButton}
            locationSelectorList={locationSelectorList}
            userList={userList}
            handleGetShiftReportInfo={handleGetShiftReportInfo}
            shiftReportNode={shiftReportNode}
            filterDetails={filterDetails}
            isShiftSummaryReportLoading={isShiftSummaryReportLoading}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            numberOfLocations={numberOfLocations}
            deviceIdObj={deviceIdObj}
            deviceIdErr={deviceIdErr}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
            pageSize={pageSize}
            idList={idList}
            setIdList={setIdList}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            isSelectAllShift={isSelectAllShift}
            handleChangeCheckBox={handleChangeCheckBox}
            setIsSelectAllShift={setIsSelectAllShift}
          />
          {!_.isEmpty(shiftNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
              isShift={true}
            />
          )}
        </SaleReport>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(Shift, Authorities.SALE_READ);
