import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import InventoryStockOrder from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import {
  createSupplier,
  supplierMap,
  supplierPatch,
} from "../../../../services/inventory/stockItems";
import SupplierHeader from "./SupplierHeader";
import {
  fetchAllSuppliersInfo,
  fetchSingleSupplierInfo,
} from "../../../../services/inventory/supplier";
import SupplierAddModal from "./SupplierAddModal";
import Toast from "../../../../components/alerts/Toast";
import { HttpStatus } from "../../../../utils/enum";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of supplier information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of supplier.
 * Additionally, it provides the ability to create and edit supplier through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const Supplier: React.FunctionComponent = () => {
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [supplierDetailsNode, setSupplierDetailsNode] = useState([]);
  const [supplierDetailsNodeInitial, setSupplierDetailsNodeInitial] = useState(
    [],
  );
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [success, setSuccess] = useState("");
  const [isOpenSupplierModal, setIsOpenSupplierModal] = useState(false);
  const [isOpenSupplierEditModal, setIsOpenSupplierEditModal] = useState(false);
  const [selectedSupplierNode, setSelectedSupplierNode] = useState<any>({});
  const [isSort, setIsSort] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);

  const match: any = useRouteMatch();

  /**
   * Function to fetch supplier information using API call and update component states.
   * @param {number} pageSize - Number of items to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the items.
   * @param {string} headerName - Name of the header to sort by.
   * @param {any} filterList - List of filters for querying items.
   */
  const getAllSupplierInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    try {
      // Attempt to fetch supplier information using the 'fetchAllSuppliersInfo' API
      const res = await fetchAllSuppliersInfo(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      if (res.data.status === 404) {
        setSupplierDetailsNode([]);
        setSupplierDetailsNodeInitial([]);
      } else {
        setSupplierDetailsNode(res.data.data);
        setSupplierDetailsNodeInitial(res.data.data);
      }

      // Set total number of pages
      setTotalPages(res.data.totalPages);

      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setSupplierDetailsNode([]);
      setSupplierDetailsNodeInitial([]);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        getAllFilterLocation("");
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Item"
    document.title = "Inventory - Suppliers";

    // Fetch location information with an empty searchName
    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch location information and initialize supplier loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch supplier information with appropriate parameters
      getAllSupplierInfo(pageSize, 1, "", "");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of items to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Check if a headerName is provided for sorting
    if (headerName) {
      // If headerName is provided, fetch supplier information with sorting and filtering
      getAllSupplierInfo(pageSize, 1, sortingMethod, headerName);
    } else {
      // If headerName is not provided, fetch supplier information without sorting
      getAllSupplierInfo(pageSize, 1, sortingMethod, "");
    }
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch supplier information with the updated pagination parameters
    getAllSupplierInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of supplier list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeSupplierListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch supplier information with the updated sorting parameters
    getAllSupplierInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the supplier list.
   */
  const handleRemoveSupplierListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch supplier information without sorting
    getAllSupplierInfo(pageSize, currentPage, "", "");
  };

  /**
   * Function to handle opening the create supplier modal.
   */
  const handleOpenCreateSupplierModal = () => {
    // Open the create supplier modal
    setIsOpenSupplierModal(true);
    setSearchName("");
    setIsSearch(false);
    // Reset selected supplier node, version, and edit modal flag
    setSelectedSupplierNode({});
    setIsOpenSupplierEditModal(false);
  };

  /**
   * Function to handle opening the edit supplier modal.
   * @param {any} nodeData - The data of the selected supplier.
   */
  const handleOpenEditSupplierModal = (nodeData: any) => {
    // Open the edit supplier modal
    setIsOpenSupplierModal(true);

    // Set the selected supplier node for editing
    setSelectedSupplierNode(nodeData);

    // Set the edit modal flag to true
    setIsOpenSupplierEditModal(true);
  };

  /**
   * Function to handle mapping a supplier to a location.
   * @param {string} supplierId - The ID of the supplier to be mapped.
   */
  const handleSupplierMap = async (supplierId: string) => {
    // Prepare the formData object to send in the request
    const formData = {
      locationId: match.params.locationId,
      supplierId: supplierId,
    };

    try {
      // Make an API call to map the supplier to the location using 'supplierMap' function
      await supplierMap(match.params.locationId, formData);

      // After successful mapping, update the supplier list and success state
      getAllSupplierInfo(pageSize, currentPage, "", "");
      setSuccess(SUCCESSFULLY_CREATED);

      // Close the supplier modal
      setIsOpenSupplierModal(false);
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle creating a new supplier.
   * @param {any} formData - Data for creating the new supplier.
   */
  const handleCreateNewSupplier = async (formData: any) => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");
    try {
      // Make an API call to create a new supplier using 'createSupplier' function
      const res = await createSupplier(match.params.locationId, formData);

      // After successful creation, map the newly created supplier to the location
      handleSupplierMap(res.data.data.id);
      setIsLoadingToast(false);
      setToastMessage(SUCCESSFULLY_CREATED);
      setToastType("success");
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };
  const getSingleSupplierGivenId = async (formData: any) => {
    const res = await fetchSingleSupplierInfo(
      match.params.locationId,
      selectedSupplierNode.id,
    );

    formData.version = res.data.data?.version;
    handleUpdateSupplier(formData);
  };

  /**
   * Function to handle updating a supplier.
   * @param {any} formData - Data for updating the supplier.
   */
  const handleUpdateSupplier = async (formData: any) => {
    setIsLoadingToast(true);
    setToastMessage("Loading...");
    setToastType("info");
    try {
      // Make an API call to update the supplier using 'supplierPatch' function
      const res = await supplierPatch(match.params.locationId, formData);

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleSupplierGivenId(formData);
      } else {
        // After successful update, fetch all supplier information to refresh the list
        getAllSupplierInfo(pageSize, currentPage, "", "");

        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_UPDATED);
        setToastType("success");

        // Close the supplier modal
        setIsOpenSupplierModal(false);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  const handleChangeSearch = (value: any) => {
    setSearchName(value);
    if (value) {
      setIsSearch(true);
      if (!_.isEmpty(supplierDetailsNodeInitial)) {
        const filterSupplierList = supplierDetailsNodeInitial.filter(
          (supplier: any) =>
            supplier.name
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase()),
        );
        setSupplierDetailsNode(filterSupplierList);
      }
    } else {
      setIsSearch(false);
      setSupplierDetailsNode(supplierDetailsNodeInitial);
    }
  };

  const handleClearSearch = () => {
    setSupplierDetailsNode(supplierDetailsNodeInitial);
    setIsSearch(false);
    setSearchName("");
  };

  return (
    <>
      {/* Main container */}
      <Box>
        <Toast
          message={toastMessage}
          type={toastType}
          loading={isLoadingToast}
        />

        {/* InventoryDetails component */}
        <InventoryStockOrder
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={supplierDetailsNode}
          topic="Suppliers"
          handleOpenCreateModal={handleOpenCreateSupplierModal}
          buttonName="Add Supplier"
          isOrder={true}
          handleChangeSearch={handleChangeSearch}
          searchName={searchName}
          isSearch={isSearch}
          handleClearSearch={handleClearSearch}
          isNeedAddButton={true}
          isActiveLocationId={isActiveLocationId}
        >
          {/* Supplier table component */}
          <SupplierHeader
            handleChangeSupplierListSorting={handleChangeSupplierListSorting}
            handleRemoveSupplierListSorting={handleRemoveSupplierListSorting}
            nodeData={supplierDetailsNode}
            disableButton={disableButton}
            isLoading={isOpenSkeletonLoading}
            handleOpenEditSupplierModal={handleOpenEditSupplierModal}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
            handleChangeSearch={handleChangeSearch}
            handleClearSearch={handleClearSearch}
            isSearch={isSearch}
            searchName={searchName}
          />
          {/* Render Pagination if supplierDetailsNode is not empty */}
          {!_.isEmpty(supplierDetailsNode) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
        </InventoryStockOrder>

        {isOpenSupplierModal && (
          <SupplierAddModal
            isOpenSupplierModal={isOpenSupplierModal}
            setIsOpenSupplierModal={setIsOpenSupplierModal}
            isOpenSupplierEditModal={isOpenSupplierEditModal}
            handleCreateNewSupplier={handleCreateNewSupplier}
            selectedSupplierNode={selectedSupplierNode}
            handleUpdateSupplier={handleUpdateSupplier}
            isLoadingToast={isLoadingToast}
          />
        )}

        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(Supplier, Authorities.INVENTORY_ADMIN_READ);
