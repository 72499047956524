import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import "jspdf-autotable";
import { Icon } from "@iconify/react";

import { CustomTheme } from "../../../../types/customTheme";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { paymentTypesList } from "../../../../utils/consts/list";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import {
  getFilterListFromArrayObject,
  sortNumbers,
  sortStrings,
} from "../../../../utils/commonArrayMap";
import {
  CapitalizeFirstLetter,
  convertDecimalPoint,
} from "../../../../utils/ReplaceIcon";
import SaleDetailsModal from "../../../../components/common/SaleDetailsModal";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";
import view_dark from "../../../../assets/images/view_dark.png";
import view_light from "../../../../assets/images/view_light.png";
import { getLocalStore } from "../../../../utils/store/localStore";

export interface VoidSummeryInfoNodeProps {
  paymentReportNodeList: any;
  locationSelectorList: any;
  filterDetails: any;
  filterData: any;
  basedOnShift: any;
  locationSelectedList: any;
  handleOnClickText: any;
}

const useStyles = (nodesLength: number, bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        fontSize: "12px",
      },
      endTextStyle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
        paddingRight: "20px",
      },
      endTextStyleIndexFour: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "12px",
      },
      headerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "16px",
      },
      footerEndTextStyle: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      footerEndTextStyles: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
        paddingRight: "20px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${nodesLength + 2})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${bodyLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
        [`&:nth-of-type(n+${bodyLength + 2}):nth-of-type(-n+${
          nodesLength + 1
        })`]: {
          color: theme.palette.custom["purple"].contrastText,
          backgroundColor: theme.palette.custom.purple.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.purple.light,
          },
        },
      },
      totalRow: {
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "25%",
          width: "25%",
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
            minWidth: "25%",
            width: "25%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${4})`]: {
            minWidth: "25%",
            width: "25%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
            minWidth: "28%",
            width: "28%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${4})`]: {
            minWidth: "28%",
            width: "28%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "32%",
            width: "32%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${3})`]: {
            minWidth: "40%",
            width: "40%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(${4})`]: {
            minWidth: "40%",
            width: "40%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "16px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "48%",
            width: "48%",
          },
        },
      },
    }),
  );

const PaymentReportInfoNode: React.FunctionComponent<
  VoidSummeryInfoNodeProps
> = ({
  paymentReportNodeList,
  locationSelectorList,
  filterDetails,
  filterData,
  basedOnShift,
  locationSelectedList,
  handleOnClickText,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [nodesLength, setNodeLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [locationsLength, setLocationsLength] = useState(0);
  const [openSaleDetailsModal, setOpenSaleDetailsModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [cloneFilterData, setCloneFilterData] = useState("");

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    let clonePaymentReportNode: any = _.cloneDeep(paymentReportNodeList);
    let paymentReportNode: any = [];
    let footer: any = [];
    let paymentMethodList: any = [];
    let methodCountObject: any = {};
    let length = 0;

    if (!_.isEmpty(clonePaymentReportNode)) {
      /* Counting the number of locations where payments have been made. */
      Object.entries(clonePaymentReportNode).forEach(([key, value]: any) => {
        // Counts only if payment is made.
        if (!_.isEmpty(value)) {
          length = length + 1;
        }
      });

      Object.keys(clonePaymentReportNode).map((key) => {
        // Get the location name using locationId
        const location = getFilterListFromArrayObject(
          locationSelectorList,
          key,
        );
        let totalAmount = 0.0;
        if (!_.isEmpty(clonePaymentReportNode[key])) {
          Object.entries(clonePaymentReportNode[key]).forEach(
            ([key1, value]: any) => {
              if (!_.isEmpty(paymentTypesList[key1])) {
                // Calculate the total payments
                totalAmount = totalAmount + value.amount;
                const paymentMethod = paymentTypesList[key1].paymentMethod;

                /* Enter values ​​for the Location, Method, Amount, and Percentage columns in the Payment table. */
                paymentReportNode.push({
                  Location: location[0].label,
                  Method: paymentMethod,
                  methodKey: key1,
                  Amount: parseFloat(value.amount).toFixed(2),
                  Percentage: `${parseFloat(value.percentage).toFixed(2)}%`,
                  locationId: key,
                });
                // Only if there is more than one location, 'payment method' will be included in a list.
                if (
                  !paymentMethodList.includes(`${paymentMethod}*${key1}`) &&
                  length > 1
                ) {
                  paymentMethodList.push(`${paymentMethod}*${key1}`);
                  methodCountObject[paymentMethod] = 0;
                }
                // Calculate the amount of each location.
                methodCountObject[paymentMethod] =
                  methodCountObject[paymentMethod] + value.amount;
              } else if (key1.split("-")[0] === "wix") {
                // Calculate the total payments
                totalAmount = totalAmount + value.amount;
                const paymentMethod = CapitalizeFirstLetter(key1.split("-")[1]);

                /* Enter values ​​for the Location, Method, Amount, and Percentage columns in the Payment table. */
                paymentReportNode.push({
                  Location: location[0].label,
                  Method: paymentMethod.replace("-", " "),
                  methodKey: key1,
                  Amount: parseFloat(value.amount).toFixed(2),
                  Percentage: `${parseFloat(value.percentage).toFixed(2)}%`,
                  locationId: key,
                });
                // Only if there is more than one location, 'payment method' will be included in a list.
                if (
                  !paymentMethodList.includes(`${paymentMethod}*${key1}`) &&
                  length > 1
                ) {
                  paymentMethodList.push(`${paymentMethod}*${key1}`);
                  methodCountObject[paymentMethod] = 0;
                }
                // Calculate the amount of each location.
                methodCountObject[paymentMethod] =
                  methodCountObject[paymentMethod] + value.amount;
              } else {
                // Calculate the total payments
                totalAmount = totalAmount + value.amount;

                /* Enter values ​​for the Location, Method, Amount, and Percentage columns in the Payment table. */
                paymentReportNode.push({
                  Location: location[0].label,
                  Method: CapitalizeFirstLetter(key1).replace("-", " "),
                  methodKey: key1,
                  Amount: parseFloat(value.amount).toFixed(2),
                  Percentage: `${parseFloat(value.percentage).toFixed(2)}%`,
                  locationId: key,
                });
                // Only if there is more than one location, 'payment method' will be included in a list.
                if (
                  !paymentMethodList.includes(`${key1}*${key1}`) &&
                  length > 1
                ) {
                  paymentMethodList.push(`${key1}*${key1}`);
                  methodCountObject[key1] = 0;
                }
                // Calculate the amount of each location.
                methodCountObject[key1] =
                  methodCountObject[key1] + value.amount;
              }
            },
          );
        }

        /* Only if there is more than one location, the total amount of each location is obtained. */
        if (
          !_.isEmpty(Object.values(clonePaymentReportNode[key])) &&
          length > 1
        ) {
          paymentReportNode.push({
            Location: location[0].label,
            Method: "Total Payments",
            Amount: parseFloat(totalAmount.toString()).toFixed(2),
            Percentage: "",
            locationId: key,
          });
        }

        setLocationsLength(length);
      });
    }

    if (!_.isEmpty(paymentReportNode)) {
      let grandTotalAmount = 0;
      // Calculate all locations total
      paymentReportNode.map((data: any) => {
        if (data.Method !== "Total Payments") {
          grandTotalAmount = grandTotalAmount + parseFloat(data.Amount);
        }
      });

      // Calculating the percentage of total payments at each location.
      paymentReportNode.map((data: any) => {
        // The percentage should be calculated for the total payment row only.
        if (data.Method === "Total Payments") {
          data.Percentage = `${parseFloat(
            ((parseFloat(data.Amount) / grandTotalAmount) * 100).toString(),
          ).toFixed(2)}%`;
        }
      });

      if (length > 1) {
        // Removing the 'Total Payments' row at the end.
        const footerDetails = paymentReportNode.pop();

        // Inserting the removed 'Total Payments' row into the 'footer'.
        footer.push(footerDetails);
      }

      if (!_.isEmpty(paymentMethodList)) {
        // Enter the total of payment methods for the table.
        paymentMethodList.map((data: any) => {
          // Calculate percentage of the total amount
          const percentage =
            (methodCountObject[data.split("*")[0]] / grandTotalAmount) * 100;
          footer.push({
            Location: `${data.split("*")[0]} Total`,
            Method: data.split("*")[1],
            Amount: parseFloat(methodCountObject[data.split("*")[0]]).toFixed(
              2,
            ),
            Percentage: `${convertDecimalPoint(percentage.toString())}%`,
          });
        });
      }

      // Adding the total of all the locations obtained above to the 'footer'.
      footer.push({
        Location: "Grand Totals",
        Method: "",
        Amount: parseFloat(grandTotalAmount.toString()).toFixed(2),
        Percentage: "",
      });

      // Set pdf headers
      setPdfHeaders([
        { title: "Location", field: "Location" },
        { title: "Payment Method", field: "Method" },
        { title: "Payment Received", field: "Amount" },
        { title: "Percentage of Total", field: "Percentage" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Location", key: "Location", width: 52, height: 68 },
        { header: "Payment Method", key: "Method", width: 32, height: 68 },
        { header: "Payment Received", key: "Amount", width: 32, height: 68 },
        {
          header: "Percentage of Total",
          key: "Percentage",
          width: 32,
          height: 68,
        },
      ]);

      // Set table headers
      setHeadersList([
        "Location",
        "Payment Method",
        "methodKey",
        "Payment Received",
        "Percentage Of Total",
      ]);

      if (length > 1) {
        // Sum of the payment report length and payment method length
        setNodeLength(paymentMethodList.length + paymentReportNode.length);
      } else {
        // Add the payment report data length
        setNodeLength(paymentReportNode.length - 1);
      }

      // Payment report length
      setBodyLength(paymentReportNode.length);

      // set the table data in the state
      setNodes({ nodes: paymentReportNode });

      // set the table footer in the state
      setFooter(footer);

      // Merge the body and footer of PDF/ Excel.
      const merged = [...paymentReportNode, ...footer];

      // set the pdf excel data in the state
      setNodesPdfExcel(merged);
    }
  }, [paymentReportNodeList]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Location: (array) => sortStrings(array, "Location"),
        "Payment Method": (array) => sortStrings(array, "Method"),
        "Payment Received": (array) => sortNumbers(array, "Amount"),
        "Percentage Of Total": (array) => sortStrings(array, "Percentage"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  const replaceLocationId = (inputString: any, newLocationId: any) => {
    // Regular expression to match and capture locationId values
    var regex = /locationId!\(([^)]+)\)/g;

    // Replace each match with the newLocationId
    var result = inputString.replace(
      regex,
      "locationId!(" + newLocationId + ")",
    );

    return result;
  };

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Open the saleList modal */
  const handleOpenSaleListModal = (
    method: any,
    item: any,
    index?: any,
    isViewButtonClick?: any,
  ) => {
    // Setting the state of `openSaleDetailsModal` to `true`
    if ((index === 1 || isViewButtonClick) && method !== undefined) {
      setOpenSaleDetailsModal(true);
      setSelectedPaymentMethod(method);
      var result = replaceLocationId(filterData, item.locationId);
      setCloneFilterData(result);
    }
  };

  const handleOpenSaleListModalUsingFooter = (
    data: any,
    index: number,
    isViewButtonClick: boolean,
  ) => {
    if (
      data.Method !== "Total Payments" &&
      ((isViewButtonClick && data.Method !== "") ||
        (index === 1 && data.Method !== ""))
    ) {
      setOpenSaleDetailsModal(true);
      setSelectedPaymentMethod(data.Method);
      setCloneFilterData(filterData);
    }
  };

  const isDarkMode = getLocalStore("isDarkMode");
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const theme: CustomTheme = useTheme();
  const classes: any = useStyles(nodesLength, bodyLength)();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map(
                    (data: any, index: any) =>
                      data !== "methodKey" &&
                      data !== "locationId" &&
                      (locationsLength > 1 ? (
                        <HeaderCell
                          pinLeft={index === 0 ? true : false}
                          className={classes.baseCellRow}
                          style={{ paddingRight: "8px" }}
                        >
                          <Typography
                            variant="caption"
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : index === 4
                                ? classes.headerEndTextStyle
                                : classes.textStyleHeader
                            }
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            {data}
                          </Typography>
                        </HeaderCell>
                      ) : (
                        <HeaderCellSort
                          sortKey={data}
                          pinLeft={index === 0 ? true : false}
                          className={classes.baseCellRow}
                          style={{ paddingRight: "4px" }}
                        >
                          <Typography
                            variant="caption"
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : index === 4
                                ? classes.headerEndTextStyle
                                : classes.textStyleHeader
                            }
                            style={{
                              color: theme.palette.custom.orange.contrastText,
                            }}
                          >
                            {data}
                          </Typography>
                        </HeaderCellSort>
                      )),
                  )}
                </HeaderRow>
              </Header>

              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={
                        item.Method === "Total Payments"
                          ? classes.totalRow
                          : classes.headerRow
                      }
                    >
                      {Object.keys(item).map(
                        (data: any, index: any) =>
                          data !== "methodKey" &&
                          data !== "locationId" && (
                            <>
                              <Cell
                                pinLeft={index === 0 ? true : false}
                                className={classes.baseCellRow}
                                onClick={() =>
                                  handleOpenSaleListModal(
                                    item["methodKey"],
                                    item,
                                    index,
                                  )
                                }
                              >
                                <Typography
                                  variant="caption"
                                  align="left"
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : (item.Method === "Total Payments" &&
                                          index === 3) ||
                                        index === 4
                                      ? index === 4
                                        ? classes.endTextStyleIndexFour
                                        : classes.endTextStyle
                                      : classes.textStyle
                                  }
                                  style={
                                    !item["methodKey"]
                                      ? { fontWeight: "bold", color: "white" }
                                      : { fontWeight: "normal" }
                                  }
                                >
                                  {item[data]}
                                  {index === 4 &&
                                    (isDarkMode === "true" || isDarkMode ? (
                                      <Button
                                        onClick={() =>
                                          handleOpenSaleListModal(
                                            item["methodKey"],
                                            item,
                                            index,
                                            true,
                                          )
                                        }
                                      >
                                        <img
                                          src={view_dark}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          handleOpenSaleListModal(
                                            item["methodKey"],
                                            item,
                                            index,
                                            true,
                                          )
                                        }
                                      >
                                        <img
                                          src={view_light}
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                          }}
                                        />{" "}
                                      </Button>
                                    ))}
                                </Typography>
                              </Cell>
                            </>
                          ),
                      )}
                    </Row>
                    {bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any, footerIndex: any) => (
                        <>
                          <Row
                            className={
                              footerIndex + 1 === footer.length
                                ? classes.headerRowFinal
                                : classes.finalTotalRow
                            }
                            key={item.gross20}
                            item={item}
                          >
                            {Object.keys(item).map(
                              (data: any, index: any) =>
                                data !== "methodKey" &&
                                data !== "locationId" && (
                                  <>
                                    <Cell
                                      pinLeft={index === 0 ? true : false}
                                      className={classes.baseCellRow}
                                      onClick={() =>
                                        handleOpenSaleListModalUsingFooter(
                                          data1,
                                          index,
                                          false,
                                        )
                                      }
                                    >
                                      <Typography
                                        variant="caption"
                                        style={{
                                          fontWeight: "bold",
                                          color: "white",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        className={
                                          index === 0
                                            ? classes.startTextStyle
                                            : index === 4
                                            ? footerIndex === 0
                                              ? classes.footerEndTextStyles
                                              : classes.footerEndTextStyle
                                            : classes.textStyle
                                        }
                                      >
                                        {data1[data]}
                                        {footerIndex !== 0 &&
                                          data1.Method !== "" &&
                                          ((!item["methodKey"] &&
                                            index === 3) ||
                                            index === 4) &&
                                          (isDarkMode === "true" ||
                                          isDarkMode ? (
                                            <Button
                                              onClick={() =>
                                                handleOpenSaleListModalUsingFooter(
                                                  data1,
                                                  index,
                                                  true,
                                                )
                                              }
                                            >
                                              <img
                                                src={view_dark}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                }}
                                              />
                                            </Button>
                                          ) : (
                                            <Button
                                              onClick={() =>
                                                handleOpenSaleListModalUsingFooter(
                                                  data1,
                                                  index,
                                                  true,
                                                )
                                              }
                                            >
                                              <img
                                                src={view_light}
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                }}
                                              />{" "}
                                            </Button>
                                          ))}
                                      </Typography>
                                    </Cell>
                                  </>
                                ),
                            )}
                          </Row>
                        </>
                      ))}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
      {openSaleDetailsModal && (
        <SaleDetailsModal
          openSaleDetailsModal={openSaleDetailsModal}
          setOpenSaleDetailsModal={setOpenSaleDetailsModal}
          filterData={cloneFilterData}
          selectedPaymentMethod={selectedPaymentMethod}
          basedOnShift={basedOnShift}
        />
      )}
    </>
  );
};

export default PaymentReportInfoNode;
