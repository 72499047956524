import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";

import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { Box } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import InventoryDetailsDefault from "../../../../components/common/InventoryDetails/InventoryDetailsDefault";
import StockDepartmentHeader from "./StockDepartmentHeader";
import AddStockDepartmentModal from "./StockDepartmentAddModal";
import {
  fetchAllStockDepartmentsInfoSorting,
  fetchSingleStockDepartmentInfo,
  updateStockDepartments,
} from "../../../../services/inventory/stockItems";
import Toast from "../../../../components/alerts/Toast";
import { HttpStatus } from "../../../../utils/enum";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of stock department information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of stock department.
 * Additionally, it provides the ability to create and edit stock department through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const StockDepartment: React.FunctionComponent = () => {
  const [stockDepartmentNodeList, setStockDepartmentNodeList] = useState<any>(
    [],
  );
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("");
  const [sortingMethod, setSortingMethod] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isSort, setIsSort] = useState(false);
  const [version, setVersion] = useState("");
  const [success, setSuccess] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [isOpenStockDepartmentModal, setIsOpenStockDepartmentModal] =
    useState(false);
  const [isOpenStockDepartmentEditModal, setIsOpenStockDepartmentEditModal] =
    useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [departmentNameInitial, setDepartmentNameInitial] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [selectedGroupObj, setSelectedGroupObj] = useState<any>({});
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock departments information using API call and update component states.
   * @param {number} pageSize - Number of department to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the department.
   * @param {string} headerName - Name of the header to sort by.
   */
  const getStockDepartmentInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);

    try {
      // Attempt to fetch stock department information using the 'fetchAllStockDepartmentsInfoSorting' API
      const res = await fetchAllStockDepartmentsInfoSorting(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      // Update component states with the received data from the API response
      // Set stock department data
      setStockDepartmentNodeList(res.data.data);
      // Set total number of pages
      setTotalPages(res.data.totalPages);
      // Disable skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to fetch master locations and handle active location check.
   */
  const handleGetMasterLocations = async () => {
    try {
      // Attempt to fetch master locations using the 'fetchAllMasterLocations' API
      const res = await fetchAllMasterLocations(match.params.locationId);

      // Check if the response data and 'active' property exist
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        // If the location is active, fetch all filter locations and set isActiveLocationId to true
        getAllFilterLocation();
        setIsActiveLocationId(true);
      } else {
        // If the location is not active, set isActiveLocationId to false and update loading states
        setIsActiveLocationId(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Department"
    document.title = "Inventory - Stock Departments";

    // Call the function to fetch master locations when the component mounts
    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch location information and initialize stock department loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async () => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations("");

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock departments information with appropriate parameters
      getStockDepartmentInfo(pageSize, 1, "", "");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of department to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Check if a headerName is provided for sorting
    if (headerName) {
      // If headerName is provided, fetch stock department information with sorting and filtering
      getStockDepartmentInfo(pageSize, 1, sortingMethod, headerName);
    } else {
      // If headerName is not provided, fetch stock department information without sorting
      getStockDepartmentInfo(pageSize, 1, sortingMethod, "");
    }
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch stock department information with the updated pagination parameters
    getStockDepartmentInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of stock department list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockDepartmentListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock department information with the updated sorting parameters
    getStockDepartmentInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the stock department list.
   */
  const handleRemoveStockDepartmentListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock department information without sorting
    getStockDepartmentInfo(pageSize, currentPage, "", "");
  };

  /**
   * Function to get stock department details.
   */
  const handleGetStockDepartmentList = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock department information without sorting
    getStockDepartmentInfo(pageSize, 1, "", "");
  };

  /**
   * Function to handle opening the create stock department modal.
   */
  const handleOpenCreateStockDepartmentModal = () => {
    // Open the 'isOpenCreateStockOrderModal'
    setIsOpenStockDepartmentModal(true);
    setDepartmentName("");
    setDepartmentNameInitial("");
    setVersion("");
    setIsOpenStockDepartmentEditModal(false);
  };

  /**
   * Function to handle opening the edit stock department modal.
   * @param {string} departmentId - ID of the department.
   * @param {string} stockGroupId - ID of the stock group.
   * @param {string} name - Name of the department.
   * @param {string} version - Version of the department.
   * @param {React.MouseEvent<HTMLLIElement>} event - The click event triggered.
   */
  const handleOpenEditStockDepartmentModal = (
    departmentId: string,
    stockGroupId: string,
    name: string,
    version: string,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // If an event is provided, stop its propagation (prevent further bubbling)
    if (event) {
      event.stopPropagation();
    }
    // Set the stock group ID in the state
    setGroupId(stockGroupId);

    // Set the department ID in the state
    setDepartmentId(departmentId);

    // Set the version in the state
    setVersion(version);

    // Open the create stock department modal
    setIsOpenStockDepartmentModal(true);

    // Set the department name in the state
    setDepartmentName(name);

    // Set the department name in the state
    setDepartmentNameInitial(name);

    // Open the edit stock department modal (edit mode)
    setIsOpenStockDepartmentEditModal(true);
  };

  const getSingleDepartmentGivenId = async () => {
    const res = await fetchSingleStockDepartmentInfo(
      match.params.locationId,
      groupId,
    );

    setVersion(res.data.data?.version);
    handleUpdateDepartment(res.data.data?.version);
  };

  /**
   * Handler for updating a department.
   * This function submits the updated department details to the API,
   * handles success and error cases, and updates relevant state.
   */
  const handleUpdateDepartment = async (updatedVersion?: any) => {
    // Prepare form data for updating the department
    const formData = {
      id: departmentId,
      name: departmentName,
      locationId: match.params.locationId,
      version: updatedVersion || version,
      stockGroupId: selectedGroupObj.id,
    };

    try {
      // Call the API to update the stock department
      const res = await updateStockDepartments(
        match.params.locationId,
        formData,
      );

      if (res.data.status === HttpStatus.CONFLICT_409) {
        getSingleDepartmentGivenId();
      } else {
        // Update the stock department list
        handleGetStockDepartmentList();

        // Reset the selected group object, set success message, and close the modal
        setSelectedGroupObj({});
        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_UPDATED);
        setToastType("success");
        setIsOpenStockDepartmentModal(false);
      }
    } catch (err) {
      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoadingToast(false);
      setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setToastType("error");
    }
  };

  return (
    <>
      {/* Main container */}
      <Box>
        <Toast
          message={toastMessage}
          type={toastType}
          loading={isLoadingToast}
        />

        {/* InventoryDetails component */}
        <InventoryDetailsDefault
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={stockDepartmentNodeList}
          topic="Stock Departments"
          handleOpenCreateModal={handleOpenCreateStockDepartmentModal}
          buttonName="Add Stock Department"
          isNeedAddButton={true}
          isActiveLocationId={isActiveLocationId}
        >
          {/* StockDepartment table component */}
          <StockDepartmentHeader
            handleChangeStockDepartmentListSorting={
              handleChangeStockDepartmentListSorting
            }
            handleRemoveStockDepartmentListSorting={
              handleRemoveStockDepartmentListSorting
            }
            nodeData={stockDepartmentNodeList}
            disableButton={disableButton}
            locationSelectorList={locationSelectorList}
            handleOpenEditStockDepartmentModal={
              handleOpenEditStockDepartmentModal
            }
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
          />
          {/* Render Pagination if stockDepartmentNodeList is not empty */}
          <Pagination
            handleChangePaginationUsingSelector={
              handleChangePaginationUsingSelector
            }
            handleChangePaginationUsingPageNumber={
              handleChangePaginationUsingPageNumber
            }
            totalPages={totalPages}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        </InventoryDetailsDefault>

        <AddStockDepartmentModal
          isOpenStockDepartmentModal={isOpenStockDepartmentModal}
          setIsOpenStockDepartmentModal={setIsOpenStockDepartmentModal}
          handleGetStockDepartmentList={handleGetStockDepartmentList}
          setError={setError}
          isOpenStockDepartmentEditModal={isOpenStockDepartmentEditModal}
          setDepartmentName={setDepartmentName}
          departmentName={departmentName}
          groupId={groupId}
          departmentNameInitial={departmentNameInitial}
          handleUpdateDepartment={handleUpdateDepartment}
          setToastMessage={setToastMessage}
          setToastType={setToastType}
          isLoadingToast={isLoadingToast}
          setIsLoadingToast={setIsLoadingToast}
          selectedGroupObj={selectedGroupObj}
          setSelectedGroupObj={setSelectedGroupObj}
        />

        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockDepartment, Authorities.INVENTORY_ADMIN_READ);
