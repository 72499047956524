import { FormControlLabel, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import AvailabilitySelection from "../../../../components/time/AvailabilitySelection";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import {
  combineMinuteOfWeekArrays,
  normalizeTimeSelections,
} from "../../../../utils/time-conversions";
import {
  ERROR_MESSAGE_NOT_AVAILABLE_BANNER_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  NODE_STATUS_CREATE,
  REMOVE_ALL_HTML_TAG,
  REMOVE_PARAGRAPH_HTML_TAG,
  REMOVE_PARAGRAPH_AND_BREAK_HTML_TAG,
  REMOVE_BREAK_AND_FONT_SIZE_HTML_TAG,
  ADD_HTML_SPACE,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { Theme } from "../../../../types/customTheme";
import {
  buttonColors,
  DeliveryTpe,
  HttpStatus,
  submitButtonName,
} from "../../../../utils/enum";
import ColorSelection from "./ColorSelection";
import ThemeService from "../../services/ThemeService";
import HtmlEditor from "../../../../components/common/HtmlEditor";
import { convertHtmlEditorStateToHtmlTag } from "../../../../utils/htmlEditor";
import CardCommon from "../../../../components/card/CardCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deliveryType: {
      width: "100%",
      padding: "12px 12px 0 12px",
      [theme.breakpoints.up("sm")]: {
        padding: "16px 16px 0 16px",
      },
      borderRadius: 4,
    },
    textColorStyle: {
      flexDirection: "column",
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      borderRadius: "8px 8px 0 0",
    },
    actionsWrapper: {
      padding: "16px 16px",
      display: "flex",
      justifyContent: "center",
    },
  }),
);

export interface BannerInfoNodeProps {
  nodeData: any;
  getThemeInfo: any;
  isAuthorized: boolean;
  handleRemoveNode: any;
  themeInitialNodes: any;
  index: any;
  setThemeInitialNodes: any;
}

/* Create, delete, and update banner information and users can view all banner information. */
const BannersInfoNode: React.FunctionComponent<BannerInfoNodeProps> = ({
  nodeData,
  getThemeInfo,
  handleRemoveNode,
  isAuthorized,
  themeInitialNodes,
  index,
  setThemeInitialNodes,
}) => {
  const [deliveryTypesList, setDeliveryTypesList] = useState<any>([]);
  const [isDeliveryTypeListEmpty, setIsDeliveryTypeListEmpty] = useState(false);
  const [textColor, setTextColor] = useState("");
  const [isTextColorEmpty, setIsTextColorEmpty] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState("");
  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [subTitle, setSubTitle] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubTitleEmpty, setIsSubTitleEmpty] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [isBackgroundColorEmpty, setIsBackgroundColorEmpty] = useState(false);
  const [openHtmlEditor, setOpenHtmlEditor] = useState(false);
  const [removableHtmlTagOfTitle, setRemovableHtmlTagOfTitle] = useState("");
  const [removableHtmlTagOfSubTitle, setRemovableHtmlTagOfSubTitle] =
    useState("");
  const [availabilityErrorMessage, setAvailabilityErrorMessage] =
    useState(false);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [htmlEditorState, setHtmlEditorState] = useState("");
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [originalNodeData, setOriginalNodeData] = useState({
    availability: [],
    backgroundColor: "",
    deliveryTypes: [],
    isActive: false,
    subtitle: "",
    textColor: "",
    title: "",
  });

  const match: any = useRouteMatch();

  /* Get updated data after changes. */
  const getNodeDataByState = () => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.availability = availabilityList;
    updatedData.isActive = isActive;
    updatedData.title = title;
    updatedData.subtitle = subTitle;
    updatedData.textColor = textColor;
    updatedData.backgroundColor = backgroundColor;
    updatedData.deliveryTypes = deliveryTypesList;
    return updatedData;
  };

  /* Update states after creating delete or update banner information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      isActive,
      title,
      subtitle,
      deliveryTypes,
      backgroundColor,
      availability,
      textColor,
    } = nodeValues;
    setTitle(title);
    setSubTitle(subtitle);
    setBackgroundColor(backgroundColor);
    setTextColor(textColor);
    setDeliveryTypesList(deliveryTypes);
    setAvailabilityList(availability);
    setIsActive(isActive);
    setRemovableHtmlTagOfTitle(title);
    setRemovableHtmlTagOfSubTitle(subtitle);
  }, []);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  // Checking for any changes between updated data and initial data.
  const updatedData = getNodeDataByState();
  const isSame = _.isEqual(originalNodeData, updatedData);

  /* Update the deliveryTypeList after selecting the delivery options using switches (dine-in, takeout, and delivery). */
  const handleSelectDeliveryTypes = (e: any) => {
    setIsDeliveryTypeListEmpty(false);
    const { value, name, checked } = e.target;
    // When a delivery type option is selected, that delivery type option is entered into a list.
    if (checked) {
      const selectedList: any = new Set([...deliveryTypesList, value]);
      setDeliveryTypesList([...selectedList]);
    } else {
      // When a delivery type option is not selected, that delivery type option is removed into a list.
      const selectedList: any = deliveryTypesList.filter(
        (amountType: String) => name !== amountType,
      );
      setDeliveryTypesList(selectedList);
    }
  };

  /* Remove banner information using index */
  const handleDeleteThemeInitialNodeInfo = () => {
    var findDeletedBannerIndex = themeInitialNodes.banners.findIndex(
      (value: any, key: any) => {
        return key === index;
      },
    );
    // When the first banner information should be removed, this is executed.
    if (index === 0) {
      themeInitialNodes.banners.shift();
      return themeInitialNodes;
    } else {
      // When the other banner information should be removed, this is executed.
      themeInitialNodes.banners.splice(findDeletedBannerIndex, index);
      return themeInitialNodes;
    }
  };

  /* When an error occurs during an API call, identify what the error is. */
  const handleApiCallErrorMessage = (error: any) => {
    // If the status of the error message is 409
    if (error.response.status === HttpStatus.CONFLICT_409) {
      setError(ERROR_MESSAGE_VERSION_ERROR);
      getThemeInfo();
    } else {
      // If the status of the error message is not 409
      setError(ERROR_MESSAGE_UPDATING_ERROR);
    }
  };

  /* Send an API call to update banner information after click save changes button. */
  const handleUpdateBannerInfo = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    const updatedData = getNodeDataByState();
    const themeNode = handleUpdateThemeInitialNodeInfo(updatedData);
    try {
      const res = await ThemeService.updateThemeConfig(
        match.params.locationId,
        themeNode,
      );
      setSuccess(SUCCESSFULLY_UPDATED);
      setNodeDataToState(res.data.data.banners[index]);
      setOriginalNodeData(res.data.data.banners[index]);
      setThemeInitialNodes(res.data.data);
    } catch (error) {
      handleApiCallErrorMessage(error);
    }
  };

  /* Send an API call to crete banner information after click create button. */
  const handleCreateBannerInfo = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    const createData = getNodeDataByState();
    themeInitialNodes.banners.push(createData);
    try {
      const res = await ThemeService.updateThemeConfig(
        match.params.locationId,
        themeInitialNodes,
      );
      setSuccess(SUCCESSFULLY_CREATED);
      setNodeDataToState(res.data.data.banners[index]);
      setOriginalNodeData(res.data.data.banners[index]);
      setThemeInitialNodes(res.data.data);
      setIsCreated(true);
    } catch (error) {
      handleApiCallErrorMessage(error);
    }
  };

  /* Send an API call to delete banner information  after click delete button. */
  const handleDeleteBannerInfo = async () => {
    setError("");
    setAvailabilityErrorMessage(false);
    const themeNode = handleDeleteThemeInitialNodeInfo();
    try {
      await ThemeService.updateThemeConfig(
        match.params.locationId,
        themeNode,
      );
      setSuccess(SUCCESSFULLY_DELETED);
      getThemeInfo();
      setOpenDeleteConfirm(false);
    } catch (error) {
      setOpenDeleteConfirm(false);
      handleApiCallErrorMessage(error);
    }
  };

  /* Reset states after clicking the cancel button. */
  const resetToOriginalData = () => {
    setIsDeliveryTypeListEmpty(false);
    setIsTitleEmpty(false);
    setIsBackgroundColorEmpty(false);
    setIsSubTitleEmpty(false);
    setIsTextColorEmpty(false);
    setRemovableHtmlTagOfTitle("");
    setRemovableHtmlTagOfSubTitle("");
    setNodeDataToState(originalNodeData);
  };

  /* Remove banner information using status. 
  If the status is "create", it will be removed not using an API call
  and if the status is not "create", it will be removed using an API call.  */
  const deleteFunc = () => {
    if (isCreated) {
      handleDeleteBannerInfo();
    } else if (nodeData.status === NODE_STATUS_CREATE) {
      handleRemoveNode();
    } else {
      setOpenDeleteConfirm(true);
    }
  };

  /* Check validation after click submit or crete button */
  const handleCheckValidation = () => {
    let isValidated = false;
    if (_.isEmpty(deliveryTypesList)) {
      setIsDeliveryTypeListEmpty(true);
    } else if (_.isEmpty(title)) {
      setIsTitleEmpty(true);
    } else if (_.isEmpty(subTitle)) {
      setIsSubTitleEmpty(true);
    } else if (_.isEmpty(backgroundColor)) {
      setIsBackgroundColorEmpty(true);
    } else if (_.isEmpty(textColor)) {
      setIsTextColorEmpty(true);
    } else {
      isValidated = true;
    }
    return isValidated;
  };

  /* Reset validation states after clicking the submit or create button. */
  const handleRemoveValidationState = () => {
    setIsDeliveryTypeListEmpty(false);
    setIsTitleEmpty(false);
    setIsBackgroundColorEmpty(false);
    setIsSubTitleEmpty(false);
    setIsTextColorEmpty(false);
  };

  /* Check for a create or update and 
  call the handleCreateBannerInfo function if it is a create and 
  handleUpdateBannerInfo function if it is a update. */
  const handleSubmit = () => {
    setError("");
    const isValidated = handleCheckValidation();
    if (_.isEmpty(deliveryTypesList)) {
      setIsDeliveryTypeListEmpty(true);
    } else if (nodeData.status === NODE_STATUS_CREATE && isValidated  && !isCreated) {
      handleRemoveValidationState();
      return handleCreateBannerInfo();
    } else if (isValidated) {
      handleRemoveValidationState();
      return handleUpdateBannerInfo();
    }
  };

  /* Removed the changed banner info list and added the updated banner info list to the theme info list. */
  const handleUpdateThemeInitialNodeInfo = (updatedData: any) => {
    // Remove changed banner info list.
    const removeUpdatedData = themeInitialNodes.banners.filter(
      (value: any, key: any) => {
        return key !== index;
      },
    );
    // Added updated banner info list to the location of the removed banner info list using the index.
    removeUpdatedData.splice(index, 0, updatedData);
    themeInitialNodes.banners = removeUpdatedData;
    return themeInitialNodes;
  };

  /* Return availability list. */
  const handleChangeAvailability = (availabilitySelectionList: any) => {
    const combinedMowArrays: any = combineMinuteOfWeekArrays(
      availabilitySelectionList,
    );
    const minuteOfWeekArray = _.cloneDeep(combinedMowArrays);
    Object.keys(combinedMowArrays).forEach((day) => {
      minuteOfWeekArray[day] = normalizeTimeSelections(minuteOfWeekArray[day]);
    });
    const mowArray: any = Object.values(minuteOfWeekArray).flat();
    const data = _.cloneDeep(originalNodeData);
    data.availability = mowArray;
    return data;
  };

  /* Send an API call to after click availability apply button. 
  An API call is sent only if the status is not "create". */
  const handleSubmitAvailability = async (
    availabilitySelectionList: Array<any>,
  ) => {
    setError("");
    setAvailabilityErrorMessage(false);

     // If the discount has not been created, an error message will be displayed when adding a new 'availability'.
    if (nodeData.status === NODE_STATUS_CREATE && !isCreated) {
      setError(ERROR_MESSAGE_NOT_AVAILABLE_BANNER_ERROR);
    } else {
       // If the discount has been created, add new 'availability'.
      const availabilityList = handleChangeAvailability(
        availabilitySelectionList,
      );
      const themeNode = handleUpdateThemeInitialNodeInfo(availabilityList);
      try {
        const res = await ThemeService.updateThemeConfig(
          match.params.locationId,
          themeNode,
        );
        setSuccess(SUCCESSFULLY_UPDATED);
        setNodeDataToState(res.data.data.banners[index]);
        setOriginalNodeData(res.data.data.banners[index]);
        setThemeInitialNodes(res.data.data);
      } catch (error) {
        handleApiCallErrorMessage(error);
      }
    }
  };

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  //remove paragraph and break tags and after add space. This is use to only show UI.
  const handleChangeParagraphAndBreakTag = (editorState: any) => {
    return convertHtmlEditorStateToHtmlTag(editorState).replace(
      REMOVE_PARAGRAPH_AND_BREAK_HTML_TAG,
      ADD_HTML_SPACE,
    );
  };

  //remove paragraph tags and after add break tag. This is use to send API call
  const handleChangeParagraphTag = (editorState: any) => {
    return convertHtmlEditorStateToHtmlTag(editorState).replace(
      REMOVE_PARAGRAPH_HTML_TAG,
      "<br>",
    );
  };

  /* When editing an HTML editor, it sets the state to be sent to the backend and made visible to the user. */
  const handleSubmitEditorState = (editorState: any) => {
    setOpenHtmlEditor(false);
    if (isTitleEdit) {
      let removableParagraphAndBreakTagOfTitle =
        handleChangeParagraphAndBreakTag(editorState);
      let removableParagraphTagOfTitle = handleChangeParagraphTag(editorState);
      // Sets the state to be visible to the user.
      setRemovableHtmlTagOfTitle(removableParagraphAndBreakTagOfTitle);
      // Sets the state to be sent to the backend.
      setTitle(removableParagraphTagOfTitle);
      setIsTitleEmpty(false);
      setIsSubTitleEmpty(false);
    } else {
      let removableParagraphAndBreakTagOfSubTitle =
        handleChangeParagraphAndBreakTag(editorState);
      let removableParagraphTagOfSubTitle =
        handleChangeParagraphTag(editorState);
      // Sets the state to be visible to the user.
      setRemovableHtmlTagOfSubTitle(removableParagraphAndBreakTagOfSubTitle);
      // Sets the state to be sent to the backend.
      setSubTitle(removableParagraphTagOfSubTitle);
      setIsTitleEmpty(false);
      setIsSubTitleEmpty(false);
    }
  };

  const handleCloseHtmlEditor = () => {
    setOpenHtmlEditor(false);
  };

  /* Remove break and fontsize HTML tags. */
  const removeBreakAndFontSizeHtmlTag = () => {
    return removableHtmlTagOfSubTitle.replace(
      REMOVE_BREAK_AND_FONT_SIZE_HTML_TAG,
      "",
    );
  };

  /* Remove all HTML tags and replace the space. */
  const removeAllHtmlTag = () => {
    return removableHtmlTagOfTitle.replace(REMOVE_ALL_HTML_TAG, "");
  };

  const classes = useStyles();

  return (
    <>
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteBannerInfo}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <CardCommon backgroundColor={"entity_background"}>
        {error && (
          <Typography component="div" className={classes.error} variant="body1">
            {error}
          </Typography>
        )}

        <form className={classes.deliveryType}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="dinein"
                  disabled={!isAuthorized}
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Dine In
                    </Typography>
                  }
                  name="dinein"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.DINEIN)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="delivery"
                  disabled={!isAuthorized}
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Delivery
                    </Typography>
                  }
                  name="delivery"
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.DELIVERY)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  value="takeout"
                  disabled={!isAuthorized}
                  name="takeout"
                  control={<SwitchCommon />}
                  label={
                    <Typography variant="caption" color="textSecondary">
                      Takeout
                    </Typography>
                  }
                  checked={
                    !_.isEmpty(deliveryTypesList)
                      ? deliveryTypesList.includes(DeliveryTpe.TAKEOUT)
                      : false
                  }
                  labelPlacement="bottom"
                  onChange={handleSelectDeliveryTypes}
                />
              </div>
              {_.isEqual(isDeliveryTypeListEmpty, true) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select a least one delivery option.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <FormControlLabel
                style={{ marginRight: "0px" }}
                control={<SwitchCommon />}
                checked={isActive}
                onChange={handleSwitchActivate}
                label="Active"
                labelPlacement="bottom"
                disabled={!isAuthorized}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <Typography
                  style={{ fontWeight: "bold" }}
                  variant="h5"
                  align="left"
                >
                  Title
                </Typography>
                <EditIcon
                  onClick={() => {
                    setOpenHtmlEditor(true);
                    setHtmlEditorState(title);
                    setIsTitleEdit(true);
                  }}
                />
              </div>
              {!_.isEmpty(removableHtmlTagOfTitle) && (
                <Typography
                  style={{
                    marginLeft: "12px",
                    marginTop: "12px",
                    marginBottom: "12px",
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: removeAllHtmlTag(),
                  }}
                  align="left"
                />
              )}
              {_.isEqual(isTitleEmpty, true) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter title.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingBottom: "0px",
                paddingTop: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                <Typography
                  variant="h5"
                  align="left"
                  style={
                    _.isEmpty(title)
                      ? { marginTop: "24px", fontWeight: "bold" }
                      : { marginTop: "0px", fontWeight: "bold" }
                  }
                >
                  Sub Title
                </Typography>
                <EditIcon
                  style={
                    _.isEmpty(title)
                      ? { marginTop: "24px" }
                      : { marginTop: "0px" }
                  }
                  onClick={() => {
                    setOpenHtmlEditor(true);
                    setHtmlEditorState(subTitle);
                    setIsTitleEdit(false);
                  }}
                />
              </div>
              {!_.isEmpty(removableHtmlTagOfSubTitle) && (
                <Typography
                  style={{
                    marginLeft: "12px",
                    marginTop: "12px",
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: removeBreakAndFontSizeHtmlTag(),
                  }}
                  align="left"
                />
              )}
              {_.isEqual(isSubTitleEmpty, true) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="left"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please enter sub title.
                  </Typography>
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              style={
                _.isEmpty(subTitle)
                  ? { marginTop: "24px", marginBottom: "8px" }
                  : { marginTop: "0px", marginBottom: "8px" }
              }
            >
              <div>
                <ColorSelection
                  title={"Background Color"}
                  colorCode={backgroundColor}
                  setColor={(color: any) => {
                    setBackgroundColor(color.hex);
                    setIsBackgroundColorEmpty(false);
                  }}
                  setInitialData={() => setBackgroundColor("")}
                />
              </div>
              {_.isEqual(isBackgroundColorEmpty, true) && (
                <div
                  style={{
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="center"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select background color.
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid
              item
              className={classes.textColorStyle}
              xs={6}
              style={
                _.isEmpty(subTitle)
                  ? { marginTop: "24px" }
                  : { marginTop: "0px" }
              }
            >
              <ColorSelection
                title={"Text Color"}
                colorCode={textColor}
                setColor={(color: any) => {
                  setTextColor(color.hex);
                  setIsTextColorEmpty(false);
                }}
                setInitialData={() => setTextColor("")}
              />
              {_.isEqual(isTextColorEmpty, true) && (
                <div
                  style={{
                    marginBottom: "3px",
                  }}
                >
                  <Typography
                    align="center"
                    variant="caption"
                    style={{ color: "red" }}
                  >
                    Please select text color.
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>

          <AvailabilitySelection
            nodeData={originalNodeData}
            disabled={!isAuthorized}
            handleSubmit={handleSubmitAvailability}
            setAvailabilityErrorMessage={setAvailabilityErrorMessage}
          />
          {availabilityErrorMessage && (
            <ValidationMessage
              message={"Please create a banner before updating."}
            />
          )}
        </form>

        {isAuthorized && (
          <div className={classes.actionsWrapper}>
            <ButtonCommon
              disabled={isSame}
              style={{
                flex: 1,
                fontSize: 11,
                marginRight: 4,
              }}
              variant="contained"
              color={
                nodeData.status === NODE_STATUS_CREATE && !isCreated
                  ? buttonColors.CREATE_BUTTON_COLOR
                  : buttonColors.UPDATE_BUTTON_COLOR
              }
              onClick={handleSubmit}
            >
              {nodeData.status === NODE_STATUS_CREATE
                ? submitButtonName.CREATE_SUBMIT_BUTTON
                : submitButtonName.UPDATE_SUBMIT_BUTTON}
            </ButtonCommon>
            <ButtonCommon
              disabled={isSame}
              variant="contained"
              style={{ fontSize: 11, flex: 1 }}
              color={buttonColors.CANCEL_BUTTON_COLOR}
              startIcon={<RotateLeftIcon />}
              onClick={resetToOriginalData}
            >
              Cancel
            </ButtonCommon>
            {isAuthorized && (
              <ButtonCommon
                variant="contained"
                style={{
                  fontSize: 11,
                  flex: 1,
                  marginLeft: 4,
                }}
                color={buttonColors.DELETE_BUTTON_COLOR}
                startIcon={<DeleteIcon />}
                onClick={deleteFunc}
              >
                Delete
              </ButtonCommon>
            )}
          </div>
        )}
      </CardCommon>

      <HtmlEditor
        handleSubmitEditorState={handleSubmitEditorState}
        htmlEditorState={htmlEditorState}
        open={openHtmlEditor}
        handleClose={handleCloseHtmlEditor}
      />
    </>
  );
};

export default withAuthority(BannersInfoNode, Authorities.BANNERS_READ);
