import IconButton from "@material-ui/core/IconButton";
import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppsIcon from "@material-ui/icons/Apps";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import StorefrontIcon from "@material-ui/icons/Storefront";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import eatprestoLogo from "../../assets/images/eatPrestoLogo.png";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import * as React from "react";
import commonThemeDark from "../../root/theme/dark";
import eatPrestoLogo from "../../assets/images/eatPrestoLogo.png";
import { useLocation } from "react-router";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import {
  APP_PRESTO_EATPRESTO,
  APP_PRESTO_INVENTORY,
  APP_PRESTO_LOCATION,
  APP_PRESTO_MENU,
  APP_PRESTO_SALES,
  APP_PRESTO_TASK,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  URL_EATPRESTO,
  URL_PRESTO_LOCATION,
  URL_PRESTO_MENUS,
  URL_PRESTO_SALES,
  URL_PRESTO_TASK,
} from "../../utils/consts";
import { CustomTheme } from "../../types/customTheme";
import { Grid } from "@material-ui/core";
import DefaultAlert from "../alerts/DefaultAlert";
import { getCurrentAppCode } from "../../utils/getCurrentAppCode";
import _ from "lodash";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { handleAppVisible } from "../../utils/appVisibility";
import { getIsAuthorized } from "../../utils/authorities";
import Authorities from "../../auth/authorities";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    flexColCenter: {
      width: "98px",
      height: "98px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    iconButtonColor: {
      backgroundColor: theme.palette.background.entity_background,
      border: `1px solid ${theme.palette.background.entity_border_entity_background}`,
    },
    popoverContent: {
      display: "flow-root",
      width: "300px",
      [theme.breakpoints.up("sm")]: {
        width: "430px",
      },
      padding: 15,
    },
    iconStyle: {
      "&:hover": {
        background: "none",
      },
    },
  }),
);

interface PrestoAppObject {
  title: string;
  appName: string;
}

const APPS = [
  {
    title: "MANAGE LOCATIONS",
    appName: APP_PRESTO_LOCATION,
    icon: <StorefrontIcon fontSize="large" />,
    backgroundColor: commonThemeDark.palette.custom.yellow.main,
  },
  {
    title: "ONLINE ORDERING",
    appName: APP_PRESTO_EATPRESTO,
    icon: <img src={eatprestoLogo} width="58px" height="40px" />,
    backgroundColor: commonThemeDark.palette.custom.red.light,
  },
  {
    title: "SALES AND REPORTING",
    appName: APP_PRESTO_SALES,
    icon: <SignalCellularAltIcon fontSize="large" />,
    backgroundColor: "#25ACE3",
  },
  // {
  //   title: "PRESTO TASKS",
  //   appName: APP_PRESTO_TASK,
  //   icon: <PlaylistAddCheckIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.green.light,
  // },
  // {
  //   title: "Menus",
  //   appName: APP_PRESTO_MENU,
  //   icon: <MenuBookIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.purple.main,
  // },
  {
    title: "Inventory",
    appName: APP_PRESTO_INVENTORY,
    icon: <BusinessCenterIcon style={{ fontSize: "60px" }} />,
    backgroundColor: commonThemeDark.palette.custom.green.light,
  },
];

export interface PrestoAppsProps {
  authUser: any;
}

const PrestoApps: React.FunctionComponent<PrestoAppsProps> = ({ authUser }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [appList, setAppList] = React.useState<Array<PrestoAppObject>>([]);
  const [error, setError] = React.useState("");
  const location = useLocation();
  const classes = useStyles();

  const fetchPrestoApps = React.useCallback(async () => {
    try {
      // const res = await fetchAllLocations();
      setAppList(APPS);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, []);

  /* Get the presto app details */
  React.useEffect(() => {
    fetchPrestoApps();
  }, [fetchPrestoApps]);

  /* Open the app selector pop up */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close the app selector pop up */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* After the user selects a app, the website redirects to the corresponding app based on the app name. */
  const handleAppChange = (app: string) => {
    const [__, locationId, ...rest] = location.pathname.split("/");
    // If the app name is 'PRESTO_EATPRESTO', Redirect to the eatpresto application.
    if (app === APP_PRESTO_EATPRESTO) {
      window.location.href = `${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}/${locationId}/${URL_EATPRESTO}`;
    } else if (app === APP_PRESTO_LOCATION) {
      // If the app name is 'PRESTO_LOCATION', Redirect to the location application.
      if (getIsAuthorized(Authorities.OPENING_TIME_READ)) {
        window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}/${locationId}/${URL_PRESTO_LOCATION}`;
      } else {
        window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}/${locationId}/${URL_PRESTO_LOCATION}/basic-info`;
      }
    } else if (app === APP_PRESTO_SALES) {
      // If the app name is 'PRESTO_SALES', Redirect to the sale application.
      window.location.href = `${process.env.REACT_APP_PRESTO_SALES_DOMAIN}/${locationId}/dashboard`;
    }
    // else if (app === APP_PRESTO_TASK) {
    //   // If the app name is 'PRESTO_TASK', Redirect to the task application.
    //   window.location.href = `${process.env.REACT_APP_PRESTO_TASK_DOMAIN}/${locationId}/${URL_PRESTO_TASK}`;
    // } else if (app === APP_PRESTO_MENU) {
    //   // If the app name is 'PRESTO_MENU', Redirect to the menu application.
    //   window.location.href = `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}/${locationId}/${URL_PRESTO_MENUS}`;
    // }
    else if (app === APP_PRESTO_INVENTORY) {
      // If the app name is 'PRESTO_INVENTORY', Redirect to the inventory application.
      if (getIsAuthorized(Authorities.INVENTORY_READ)) {
        window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}/${locationId}/stockTake`;
      } else {
        window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}/${locationId}/stockMovements`;
      }
    }
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const search = useLocation().search;
  const appName = new URLSearchParams(search).get("app");
  const matchesLg = useMediaQuery("(max-width: 960px)");
  const theme: CustomTheme = useTheme();
  return (
    <>
      {matchesLg ? (
        <IconButton
          style={{
            color: theme.palette.custom.orange.contrastText,
            width: "220px",
            display: "flex",
            justifyContent: "Start",
            paddingLeft: "28px",
          }}
          className={classes.iconStyle}
          aria-describedby={id}
          aria-label="open presto applications"
          onClick={handleClick}
          disabled={!_.isEmpty(appName)}
        >
          <AppsIcon />
          <span style={{ margin: 4 }}></span>
          <Typography>Apps</Typography>
        </IconButton>
      ) : (
        <IconButton
          style={{
            color: theme.palette.custom.orange.contrastText,
          }}
          aria-describedby={id}
          aria-label="open presto applications"
          onClick={handleClick}
          disabled={!_.isEmpty(appName)}
        >
          <AppsIcon />
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
      >
        <div className={classes.popoverContent}>
          <Grid container>
            {appList.map(
              (app: any) =>
                handleAppVisible(app.appName, authUser.uId) && (
                  <Grid item xs={6} sm={4} style={{ padding: "10px" }}>
                    <IconButton
                      onClick={() => handleAppChange(app.appName)}
                      style={{ borderRadius: 8 }}
                      className={classes.iconButtonColor}
                    >
                      <div className={classes.flexColCenter}>
                        <Typography style={{ color: app.backgroundColor }}>
                          {app.icon}
                        </Typography>
                        <Typography variant="body2"> {app.title}</Typography>
                      </div>
                    </IconButton>
                  </Grid>
                ),
            )}
          </Grid>
        </div>
      </Popover>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default PrestoApps;
