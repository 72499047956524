import { APP_PRESTO_MENU, APP_PRESTO_TASK } from "./consts";

/* Check the user Id and show the application only for the user Id to which the application should be shown. */
export const handleAppVisible = (appName: String, uId: String) => {
  // Checked only for task and menu applications.
  if (
    window.location.href.includes("http://localhost:") ||
    window.location.href.includes("https://demo.")
  ) {
    return true;
  } else {
    if (appName === APP_PRESTO_TASK || appName === APP_PRESTO_MENU) {
      // If the User ID is XGeJh3qSjLYx7nwgSGRGPgiwq4E2 or XGeJh3qSjLYx7nwgSGRGPgiwq4E2,
      // Show the task, and menu applications.
      if (
        uId === "XGeJh3qSjLYx7nwgSGRGPgiwq4E2" ||
        uId === "Fv8lhBPqNgYHrZgc3kVE4pxUVuN2"
      ) {
        return true;
      } else {
        // If the User ID is XGeJh3qSjLYx7nwgSGRGPgiwq4E2 or XGeJh3qSjLYx7nwgSGRGPgiwq4E2,
        // Do not show the task, and menu applications.
        return false;
      }
    } else {
      return true;
    }
  }
};
