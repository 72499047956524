import React from "react";
import SalesHeader from "./Sales/SalesHeader";
import LocationDetailsCard from "../LocationDetailsCard";
import { Grid, useMediaQuery } from "@material-ui/core";

export interface InputBoxDefaultProps {
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  nodeData: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  userList: any;
  numberOfLocations: any;
  getUserInfo: any;
  isModal?: any;
  getSaleFilterInfo: any;
  isPaymentTypeLoading: any;
  setIsPaymentTypeLoading: any;
  setIsDiscountLoading: any;
  isDiscountLoading: any;
  locationSelectedList: any;
  handleOnClickText: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

/* Show the Sale and Cancel sale details */
const SaleList: React.FunctionComponent<InputBoxDefaultProps> = ({
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  nodeData,
  disableButton,
  isLoading,
  locationSelectorList,
  userList,
  numberOfLocations,
  getUserInfo,
  isModal,
  getSaleFilterInfo,
  isPaymentTypeLoading,
  setIsPaymentTypeLoading,
  setIsDiscountLoading,
  isDiscountLoading,
  locationSelectedList,
  handleOnClickText,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const maxWidth = useMediaQuery("(max-width: 960px)");
  return (
    <>
      <Grid
        container
        style={
          maxWidth
            ? {
                marginTop: "8px",
              }
            : {
                marginTop: "8px",
              }
        }
      > 
        <Grid item xs={12}>
          <div
            style={maxWidthLocationChips?{
              display: "block",
            }:{
              display: "flex",
            }}
          >
            {" "}
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
          </div>
        </Grid>
      </Grid>
      <SalesHeader
        handleChangeOrderListSorting={handleChangeOrderListSorting}
        handleRemoveOrderListSorting={handleRemoveOrderListSorting}
        nodeData={nodeData}
        disableButton={disableButton}
        isLoading={isLoading}
        locationSelectorList={locationSelectorList}
        userList={userList}
        numberOfLocations={numberOfLocations}
        getUserInfo={getUserInfo}
        isModal={isModal}
        getSaleFilterInfo={getSaleFilterInfo}
        isPaymentTypeLoading={isPaymentTypeLoading}
        setIsPaymentTypeLoading={setIsPaymentTypeLoading}
        setIsDiscountLoading={setIsDiscountLoading}
        isDiscountLoading={isDiscountLoading}
        locationSelectedList={locationSelectedList}
        isSort={isSort}
        headerName={headerName}
        sortingMethod={sortingMethod}
      />
    </>
  );
};

export default SaleList;
