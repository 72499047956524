import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import {  Skeleton } from "@material-ui/lab";

import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_CREATED,
} from "../../utils/consts";
import AddMapProduct from "./AddMapProduct";
import TextfieldCommon from "../textField/TextfieldCommon";
import { CustomTheme } from "../../types/customTheme";
import {
  createMapProductsInfo,
} from "../../services/inventory/stockItems";
import { areArraysEqual } from "../../utils/commonArrayMap";
import {  buttonColors } from "../../utils/enum";
import ButtonCommon from "../buttons/ButtonCommon";
import IncrementDecrementTextboxNewsDefault from "../common/IncrementDecrementTextbox/IncrementDecrementTextboxNewsDefault";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    "& .MuiTabs-root": {
      maxWidth: "calc(100vw - 320px)",
    },
  },
  rowOdd: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    border: `1px solid ${theme.palette.background.entity_border}`,
    paddingRight: "26px",
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {},
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  footerStyle: {
    margin: "16px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  footerButtonStyle: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  root1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginRight: "0px",
    },
  },
  root2: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginRight: "28px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "12px",
      justifyContent: "space-between",
    },
  },
}));

export interface AddMapProductModalProps {
  isOpenCreateStockItemsModal: any;
  setIsOpenCreateStockItemsModal: any;
  setError: any;
  setIsCreated: any;
  menuGroups: any;
  menuDepartments: any;
  menuProducts: any;
  isOpenMapProductsEditModal: any;
  id: any;
  stockMappingDetailsNode: any;
  mapProductSkeletonLoading: any;
  setStockMappingDetailsNode: any;
  setIsLoadingToast: any;
  setToastType: any;
  setToastMessage: any;
  isLoadingToast: any;
  displaySuffix: any;
  setMapProductSkeletonLoading: any;
}

interface FormData {
  showRemainderSep: any;
  autoUnitConvert: boolean;
  id: string;
  barcode: string;
  criticalThreshold: any;
  displaySuffix: string;
  inputMultiplier: any;
  inputSuffix: string;
  name: string;
  sku: string;
  warningThreshold: any;
  active: Boolean;
  primaryLocationId: string;
  version: number;
  stockDepartmentId: any;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

/**
 * This is a React functional component called 'AddMapProductModal' that is part of a larger
 * application. It provides a user interface for adding or updating stock items, departments, and groups.
 * The component includes functionality for handling user inputs, making API requests, and rendering
 * different modals based on conditions.
 *
 * The code is organized into various sections, including state management, event handlers, API calls,
 * and JSX rendering. It makes use of React hooks and components to create a dynamic and interactive
 * user interface.
 *
 * Please note that this code represents a portion of a larger application and may require additional
 * context and dependencies to function properly in a real-world application.
 */
const AddMapProductModal: React.FunctionComponent<AddMapProductModalProps> = ({
  isOpenCreateStockItemsModal,
  setIsOpenCreateStockItemsModal,
  setError,
  setIsCreated,
  menuGroups,
  menuDepartments,
  menuProducts,
  isOpenMapProductsEditModal,
  id,
  stockMappingDetailsNode,
  mapProductSkeletonLoading,
  setStockMappingDetailsNode,
  setIsLoadingToast,
  setToastType,
  setToastMessage,
  isLoadingToast,
  displaySuffix,
  setMapProductSkeletonLoading,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [filterDepartment, setFilterDepartment] = useState<any>({});
  const [menuNode, setMenuNode] = useState<any>({});
  const [menuNodeInitial, setMenuNodeInitial] = useState<any>({});
  const [initialOutputMultiplier, setInitialOutputMultiplier] = useState("1");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
  const [outputMultiplierObj, setOutputMultiplierObj] = useState<any>({});
  const [stockItemId, setStockItemId] = useState("");
  const [checkedProductIdList, setCheckedProductIdList] = useState<any>([]);
  const [checkedProductIdListInitial, setCheckedProductIdListInitial] =
    useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const opens = Boolean(anchorEl);
  const match: any = useRouteMatch();

  // Map to store elements by title
  const elementsByTitle: any = {};

    /**
   * This useEffect handles the behavior when the isOpenMapProductsEditModal state changes.
   * It is responsible for initializing certain states and opening a modal when isOpenMapProductsEditModal becomes true.
   *
   * @param {boolean} isOpenMapProductsEditModal - Indicates whether the map products edit modal is open.
   * @param {boolean} setIsCreated - A function to set the "isCreated" state.
   * @param {boolean} setIsOpenMapProducts - A function to set the "isOpenMapProducts" state.
   * @param {string} id - The ID to set as the stock item ID.
   */
    useEffect(() => {
      // Check if the map products edit modal is open
      if (isOpenMapProductsEditModal) {
  
        // Set the stock item ID to the provided "id"
        setStockItemId(id);
      }
    }, [isOpenMapProductsEditModal]);

  /**
   * Return a set of Skeleton elements to represent loading content
   */
  const handleMapProductSkeleton = () => {
    return (
      <Grid container spacing={2} style={{ marginRight: "20px", marginTop: "26px" }}>
        {/* Create a set of 8 Skeleton items with alternating styles */}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map(
          (item, index) => (
            <Grid item xs={6} key={index} style={{ paddingRight: "26px" }}>
              <Skeleton
                variant="rect"
                width={"100%"}
                className={classes.rowOdd} // Apply the specified class for styling
                height={40}
              />
            </Grid>
          ),
        )}
      </Grid>
    );
  };

  /**
   * This useEffect is responsible for mapping product data from the stockMappingDetailsNode
   * and updating the relevant state variables with the collected information.
   *
   * It initializes an empty object to store the mapped product data and an array to store product IDs.
   * Then, it iterates through the stockMappingDetailsNode data, extracts relevant data for each product,
   * and creates an entry in the mappedProducts object for each product ID.
   * Finally, it updates the state with the collected product IDs and mapped data.
   *
   * @param {Array} stockMappingDetailsNode - The array of stock mapping details containing product information.
   * @param {Function} setCheckedProductIdList - The state setter function for the product IDs.
   * @param {Function} setOutputMultiplierObj - The state setter function for the mapped product data.
   */
  useEffect(() => {
    // Initialize an empty object to store the mapped product data
    const mappedProducts: any = {};

    // Initialize an array to store the product IDs
    const productIds: any = [];

    // Check if stockMappingDetailsNode contains data
    if (stockMappingDetailsNode.length > 0) {
      // Iterate through the stockMappingDetailsNode data
      stockMappingDetailsNode.forEach((item: any) => {
        // Extract relevant data from the item
        const { productId, outputMultiplier } = item;
        if (parseFloat(outputMultiplier.toString()) !== 0) {
          // Add the productId to the productIds array
          productIds.push(productId);

          // Create an entry in the mappedProducts object for the productId
          mappedProducts[productId] = {
            product_id: productId,
            output_multiplier: outputMultiplier,
          };
        }
      });
    }

    // Update the state with the collected product IDs and mapped data
    setCheckedProductIdList(productIds);
    setCheckedProductIdListInitial(productIds);
    setOutputMultiplierObj(mappedProducts);
  }, [stockMappingDetailsNode]);
  /**
   * This useEffect handles the selection of the first department when the "Create Stock Items" modal is opened.
   *
   * It checks if 'menuDepartments' is available, not empty, and if the 'isOpenCreateStockItemsModal' flag is set to true.
   * If these conditions are met, it selects the first department from 'menuDepartments' and updates the state with its ID.
   *
   * @param {Object} menuDepartments - The object containing menu departments.
   * @param {boolean} isOpenCreateStockItemsModal - A flag indicating whether the "Create Stock Items" modal is open.
   * @param {Function} setSelectedDepartmentId - The state setter function for the selected department ID.
   */
  useEffect(() => {
    if (
      menuDepartments &&
      Object.values(menuDepartments).length > 0 &&
      isOpenCreateStockItemsModal
    ) {
      // Get the first department from the menuDepartments object
      const firstGroup: any = Object.values(menuDepartments)[0];

      // Set the selected department ID in the state
      setSelectedDepartmentId(firstGroup.id);
    }
  }, [menuDepartments, isOpenCreateStockItemsModal]);

  /**
   * This useEffect handles the conversion and organization of menu data into a structured format
   * when the "Create Stock Items" modal is open and menu data is available.
   *
   * It checks if 'isOpenCreateStockItemsModal' is true and if 'menuProducts', 'menuDepartments', and 'menuGroups'
   * have data. If these conditions are met, it organizes the data into a structured format and updates the state
   * with the converted menu data.
   *
   * @param {Object} menuProducts - The object containing menu products.
   * @param {Object} menuDepartments - The object containing menu departments.
   * @param {Object} menuGroups - The object containing menu groups.
   * @param {boolean} isOpenCreateStockItemsModal - A flag indicating whether the "Create Stock Items" modal is open.
   * @param {Function} setMenuNode - The state setter function for the converted menu data.
   * @param {Function} setMenuNodeInitial - The state setter function for the initial converted menu data.
   */
  useEffect(() => {
    if (
      isOpenCreateStockItemsModal &&
      Object.values(menuProducts).length > 0 &&
      Object.values(menuDepartments).length > 0 &&
      Object.values(menuGroups).length > 0
    ) {
      const convertedMenuData: any = {};

      // Iterate through menu departments
      for (const departmentId in menuDepartments) {
        const department = menuDepartments[departmentId];
        const groupId = department.group_id;

        // Create an entry in the convertedMenuData object for the group if it doesn't exist
        if (!convertedMenuData[groupId]) {
          convertedMenuData[groupId] = {
            ...menuGroups[groupId],
            department: {},
          };
        }

        // Add department data to the convertedMenuData object
        convertedMenuData[groupId].department[departmentId] = {
          ...department,
          products: {},
        };
      }

      // Iterate through menu products
      for (const productId in menuProducts) {
        const product = menuProducts[productId];
        const departmentId = product.department_id;
        const groupId = menuDepartments[departmentId].group_id;

        // Add product data to the convertedMenuData object
        convertedMenuData[groupId].department[departmentId].products[
          productId
        ] = product;
      }
      // Update the state with the converted menu data
      setMenuNode(convertedMenuData);
      setMenuNodeInitial(convertedMenuData);
    }
  }, [menuProducts, menuDepartments, menuGroups, isOpenCreateStockItemsModal]);

  // Function to register elements with their titles in the map
  const registerElementWithTitle = (title: any, elementRef: any) => {
    elementsByTitle[title] = elementRef;
  };

  /**
   * Handle button click event. This function is used to respond to
   * button clicks by updating selected departments or filtering by groups.
   * Additionally, it scrolls to the target element associated with the clicked button.
   *
   * @param {string} title - The title of the clicked button, which may contain information about groups or departments.
   * @param {Object} menuDepartments - The menu departments data used for filtering departments.
   * @param {Object} elementsByTitle - A mapping of elements by their titles.
   * @param {function} setFilterDepartment - A function to set the filtered department data.
   * @param {function} setSelectedDepartmentId - A function to set the selected department ID.
   */
  const handleButtonClick = (title: any) => () => {
    if (title.includes("group")) {
      // Split the title to get the group ID
      const splitGroupId = title.split(" ");

      if (menuDepartments) {
        // Filter menu departments based on the group ID
        const filterMenuDepartment = Object.values(menuDepartments).filter(
          (department: any) =>
            department.group_id.toString() === splitGroupId[1],
        );

        // Set the filtered department data
        setFilterDepartment(filterMenuDepartment);
      }
    } else {
      // Split the title to get the department ID
      const splitDepartmentId = title.split(" ");
      // Set the selected department ID
      setSelectedDepartmentId(splitDepartmentId[1]);
    }
    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
        block: "start", // You can change this to 'end' if you want to scroll to the bottom
      });
    }
  };

  /**
   * Handle mobile button click event. This function is used to respond to button clicks on mobile devices,
   * updating selected departments or filtering by groups, and scrolling to the target element.
   *
   * @param {string} title - The title of the clicked button, which may contain information about groups or departments.
   * @param {Object} menuDepartments - The menu departments data used for filtering departments.
   * @param {Object} elementsByTitle - A mapping of elements by their titles.
   * @param {function} setFilterDepartment - A function to set the filtered department data.
   * @param {function} setIsOpenDrawer - A function to control the visibility of a mobile drawer.
   */
  const handleButtonClickMobile = (title: any) => {
    if (title.includes("group")) {
      // Split the title to get the group ID
      const splitGroupId = title.split(" ");

      if (menuDepartments) {
        // Filter menu departments based on the group ID
        const filterMenuDepartment = Object.values(menuDepartments).filter(
          (department: any) =>
            department.group_id.toString() === splitGroupId[1],
        );
        // Set the filtered department data
        setFilterDepartment(filterMenuDepartment);
      }
    }
    const splitGroupId = title.split(" ");
    setSelectedDepartmentId(splitGroupId[1]);

    // Scroll to the target element when the button is clicked
    const targetElement = elementsByTitle[title];
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth", // You can use 'auto' for instant scrolling
        top: 0, // Scroll to the top of the element
      });
    }
  };

  /**
   * Reset various state variables to their initial values.
   */
  const handleReset = () => {
    setIsCreated(false);
    setIsOpenCreateStockItemsModal(false);
    setOutputMultiplierObj({});
    setSelectedDepartmentId("");
    setCheckedProductIdList([]);
    setOutputMultiplierObj({});
    setStockMappingDetailsNode([]);
    setCheckedProductIdListInitial([]);
  };

  /**
   * Handle clearing the search value and resetting the menu nodes to their initial state.
   */
  const handleClearSearch = () => {
    // Clear the search value
    setSearchValue("");

    // Reset the menu nodes to their initial state
    setMenuNode(menuNodeInitial);
  };

  /**
   * Handle changes in the search input value by filtering the menu nodes.
   *
   * This function clones the initial menu nodes, filters them based on the search input,
   * and updates the displayed menu nodes accordingly.
   *
   * @param {Event} e - The event object containing the search input value.
   */
  const handleChangeSearch = (e: any) => {
    setMapProductSkeletonLoading(true);
    const ids = [];
    if (e.target.value) {
      // Clone the initial menu nodes to work with
      const cloneMenuNode = _.cloneDeep(menuNodeInitial);

      // Initialize an empty object to store filtered menu nodes
      const filteredMenuNodes: any = {};

      // Iterate through the groups in the cloned menu nodes
      for (const id in cloneMenuNode) {
        const group = cloneMenuNode[id];

        // Initialize an empty object to store filtered departments within the group
        const filteredDepartments: any = {};

        // Iterate through the departments in the group
        for (const id in group.department) {
          const department = group.department[id];

          // Initialize an empty object to store filtered products within the department
          const filteredProducts: any = {};

          // Iterate through the products in the department
          for (const id in department.products) {
            const product = department.products[id];

            // Check if the product name contains the search value (case-insensitive)
            if (
              product.name.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              ids.push(department.id);
              filteredProducts[id] = product;
            }
          }

          // If there are filtered products, add the department with filtered products to the filtered departments
          if (Object.keys(filteredProducts).length > 0) {
            filteredDepartments[id] = {
              ...department,
              products: filteredProducts,
            };
          }
        }

        // If there are filtered departments, add the group with filtered departments to the filtered menu nodes
        if (Object.keys(filteredDepartments).length > 0) {
          filteredMenuNodes[id] = { ...group, department: filteredDepartments };
        }
      }

      // Update the menu nodes with the filtered menu nodes

      setMenuNode(filteredMenuNodes);
    } else {
      setMenuNode(menuNodeInitial);
    }

    // Update the search value
    setSearchValue(e.target.value);
    setTimeout(() => {
      setMapProductSkeletonLoading(false);
    }, 0);
  };

  /**
   * Handle changes in the active category group based on user selection.
   *
   * @param {any} val - The selected value representing a category.
   * @param {any} catMenuIdx - The index of the category menu.
   */
  const handleChange = (val: any, catMenuIdx: any) => {
    // Set the active category group based on user selection
    setActiveGroupId(catMenuIdx);
  };
  const [isError, setIsError] = useState<any>([]);

  /**
   * Handle the submission of output multiplier data.
   * This function clones the output multiplier object, prepares the data, and sends it for creation.
   */
  const handleSubmitOutputMultiplier = async () => {
    // setIsLoadingToast(true);
    // setToastMessage("Loading...");
    // setToastType("info");

    // Clone the output multiplier object to avoid mutating the original data
    const cloneOutputMultiplierObj = _.cloneDeep(outputMultiplierObj);

    // Prepare the output data for submission
    const outputData: any = {
      // Filter and map the cloned output multiplier data to the required format
      products: Object.values(cloneOutputMultiplierObj).map((item: any) => ({
        product_id: item.product_id,
        output_multiplier: item.output_multiplier,
      })),
    };

    // Prepare the output data for submission
    const outputDatas: any = [];
    Object.values(cloneOutputMultiplierObj)
      .filter(
        (item: any) =>
          Number.isNaN(parseFloat(item.output_multiplier)) ||
          parseFloat(item.output_multiplier) <= 0,
      )
      .map((item: any) => outputDatas.push(item.product_id));
    if (outputDatas.length > 0) {
      setError("Please correct all the errors.");
      setIsError(outputDatas);
    } else {
      setIsError([]);

      // Add the stock_item_id to the output data
      outputData["stock_item_id"] = stockItemId;

      try {
        // Send the output data for creation
        await createMapProductsInfo(match.params.locationId, outputData);

        // Reset form and display success message
        handleReset();
        setIsLoadingToast(false);
        setToastMessage(SUCCESSFULLY_CREATED);
        setToastType("success");
      } catch (error) {
        // If an error occurs during the API call, set the 'error' state with an unexpected error message
        // setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setIsLoadingToast(false);
        setToastMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
        setToastType("error");
      }
    }
  };

  /**
   * Handles incrementing the initial output multiplier value, rounding up if it's a floating-point number.
   * The updated value is then set as the new initial output multiplier.
   */
  const handleIncrementInitial = () => {
    // Clone the initial output multiplier to avoid mutating the original value
    const cloneInitialOutputMultiplier = _.cloneDeep(initialOutputMultiplier);
    let updatedMultiplier: any = "";

    // Check if the initial output multiplier is a floating-point number
    if (!_.isEmpty(cloneInitialOutputMultiplier.split(".")[1])) {
      updatedMultiplier = Math.ceil(parseFloat(cloneInitialOutputMultiplier));
    } else {
      // Increment the integer part if it's not a floating-point number
      updatedMultiplier = (Number(cloneInitialOutputMultiplier) + 1).toString();
    }

    // Set the updated multiplier as the new initial output multiplier
    setInitialOutputMultiplier(updatedMultiplier);
  };

  /**
   * Handles decrementing the initial output multiplier value, rounding down if it's a floating-point number.
   * The updated value is then set as the new initial output multiplier.
   */
  const handleDecrementInitial = () => {
    // Clone the initial output multiplier to avoid mutating the original value
    const cloneInitialOutputMultiplier = _.cloneDeep(initialOutputMultiplier);
    let updatedMultiplier: any = "";

    // Check if the initial output multiplier is a floating-point number
    if (!_.isEmpty(cloneInitialOutputMultiplier.split(".")[1])) {
      // Ensure the resulting value is not negative

      // Convert the cloneInitialOutputMultiplier to a floating-point number
      // For example, "3.5" becomes 3.5
      const floatValue = parseFloat(cloneInitialOutputMultiplier);

      // Round down the floating-point number to the nearest integer
      // For example, Math.floor(3.5) becomes 3
      updatedMultiplier = Math.floor(floatValue) - 1;
    } else {
      // Ensure the resulting value is not negative

      //  Convert the cloneInitialOutputMultiplier to a number
      // For example, "2" becomes 2
      const intValue = Number(cloneInitialOutputMultiplier);

      // Subtract 1 from the number
      // For example, 2 - 1 becomes 1
      updatedMultiplier = (intValue - 1).toString();
    }
    setInitialOutputMultiplier(updatedMultiplier);
  };

  /**
   * Compare two arrays for equality.
   *
   * @param {Array} array1 - The first array to compare.
   * @param {Array} array2 - The second array to compare.
   * @returns {boolean} - `true` if the arrays are equal, `false` otherwise.
   */
  const result = areArraysEqual(
    checkedProductIdList,
    checkedProductIdListInitial,
  );

  /**
   * Render the appropriate modal component based on the application state.
   * This function dynamically selects and renders the correct modal component
   * depending on the current application state.
   *
   * @returns {JSX.Element} The rendered modal component.
   */
  const handleModal = (): JSX.Element => {
    // Check if the map product skeleton is not loading
    if (!mapProductSkeletonLoading) {
      // Render the AddMapProduct component with the required props
      return (
        <AddMapProduct
          menuGroups={menuGroups}
          menuDepartments={menuDepartments}
          menuNode={menuNode}
          registerElementWithTitle={registerElementWithTitle}
          setFilterDepartment={setFilterDepartment}
          initialOutputMultiplier={initialOutputMultiplier}
          outputMultiplierObj={outputMultiplierObj}
          setOutputMultiplierObj={setOutputMultiplierObj}
          isOpenCreateStockItemsModal={isOpenCreateStockItemsModal}
          checkedProductIdList={checkedProductIdList}
          setCheckedProductIdList={setCheckedProductIdList}
          displaySuffix={displaySuffix}
          setIsEdit={setIsEdit}
          initialOrderQty={initialOutputMultiplier}
          activeGroupId={activeGroupId}
          searchValue={searchValue}
          isError={isError}
          setIsError={setIsError}
        />
      );
    } else {
      // If the map product skeleton is loading, render a loading skeleton
      return handleMapProductSkeleton();
    }
  };

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const containerRef = useRef(null);

  const max = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const dialogRef = useRef<HTMLDivElement>(null);
  const theme: CustomTheme = useTheme();
  return (
    <>
      <DialogCommonDefault
        open={isOpenCreateStockItemsModal}
        setOpen={setIsOpenCreateStockItemsModal}
        maxWidth={"xl"}
        isNeedFixedHeight={true}
        backgroundColor={theme.palette.background.entity_background}
      >
        <DialogContent ref={containerRef}>
          <div style={{ display: "block" }}>
            <div
              style={{
                position: "fixed",
                top: "32px",
                paddingTop: "20px",
                zIndex: 1000,
                right: "50px",
                left: "57px",
                backgroundColor: theme.palette.background.entity_background,
              }}
            >
              <div style={{ marginRight: "28px" }}>
                <div>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    LINK YOUR STOCK TO PRODUCTS
                  </Typography>
                </div>
                <div style={{ marginTop: "12px", marginBottom: "8px" }}>
                  <Typography style={{ fontSize: "16px" }}>
                    Choose which products use this stock item, and tell us how
                    much to take out of stock when one of those products is
                    sold.
                  </Typography>
                </div>
              </div>
              <div className={classes.root1}>
                <Tabs
                  classes={{
                    root: classes.root,
                  }}
                  value={activeGroupId}
                  onChange={handleChange}
                  variant="scrollable"
                  selectionFollowsFocus
                  scrollButtons="auto"
                  disabled={isLoadingToast}
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="scrollable category menu items list"
                >
                  {!_.isEmpty(menuGroups) &&
                    Object.values(menuGroups).map((group: any, idx: number) => (
                      <Tab
                        key={String(idx)}
                        onClick={handleButtonClick(`group ${group.id}`)}
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            {group.name}
                          </Typography>
                        }
                        {...a11yProps(group)}
                      />
                    ))}
                </Tabs>
                <div className={classes.root2}>
                  <ButtonCommon
                    onClick={() => {
                      handleReset();
                    }}
                    disabled={isLoadingToast}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "120px",
                      marginRight: "12px",
                    }}
                    color={buttonColors.CANCEL_BUTTON_COLOR}
                  >
                    Cancel
                  </ButtonCommon>
                  <ButtonCommon
                    disabled={
                      checkedProductIdList.length === 0 ||
                      !isEdit ||
                      isLoadingToast
                    }
                    onClick={handleSubmitOutputMultiplier}
                    variant="contained"
                    style={{
                      fontSize: 11,
                      width: "120px",
                    }}
                    color={buttonColors.UPDATE_BUTTON_COLOR}
                  >
                    Save
                  </ButtonCommon>
                </div>
              </div>

              <Hidden xsDown>
                <div
                  style={{
                    margin: "12px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "28px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "12px",
                    }}
                  >
                    <IconButton
                      id="basic-button"
                      style={{
                        fontSize: 11,
                        marginRight: "12px",
                        marginTop: "6px",
                        backgroundColor:
                          theme.palette.background.entity_highlight_background,
                      }}
                      onClick={handleClick}
                    >
                      {opens ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={opens}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      PaperProps={{
                        elevation: 0,
                        style: {
                          borderRadius: "10px",
                          border: `1px solid ${theme.palette.background.entity_border}`,
                          marginTop: "60px",
                          marginLeft: "40px",
                          top: "320px",
                          left: "70x",
                          bottom: "34px",
                        },
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      {!_.isEmpty(filterDepartment) &&
                        filterDepartment.map((department: any) => (
                          <MenuItem
                            onClick={() => {
                              handleButtonClickMobile(
                                `department ${department.id}`,
                              );
                              handleClose();
                            }}
                            style={{
                              textTransform: "none",
                              display: "flex",
                              justifyContent: "start",
                            }}
                          >
                            <Typography
                              style={
                                selectedDepartmentId === department.id
                                  ? { fontWeight: "bold" }
                                  : { fontWeight: "normal" }
                              }
                            >
                              {department.name}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Menu>
                    <TextfieldCommon
                      id="search"
                      name="search"
                      label="Search"
                      type="text"
                      style={{ width: max ? "200px" : "240px" }}
                      value={searchValue}
                      disabled={isLoadingToast}
                      onChange={handleChangeSearch}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchValue ? (
                              <CloseIcon onClick={handleClearSearch} />
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    <IncrementDecrementTextboxNewsDefault
                      handleDecrement={handleDecrementInitial}
                      handleIncrement={handleIncrementInitial}
                      initialOrderQty={initialOutputMultiplier}
                      setInitialOrderQty={setInitialOutputMultiplier}
                      displaySuffix={displaySuffix}
                    />
                  </div>
                </div>
              </Hidden>

              <Hidden smUp>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "12px",
                    marginRight: "28px",
                  }}
                >
                  <IconButton
                    id="basic-button"
                    style={{
                      fontSize: 11,
                      marginRight: "12px",
                      marginTop: "6px",
                      backgroundColor:
                        theme.palette.background.entity_highlight_background,
                    }}
                    onClick={handleClick}
                  >
                    {opens ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={opens}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      style: {
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.background.entity_border}`,
                        marginTop: "60px",
                        marginLeft: "40px",
                        top: "320px",
                        left: "70x",
                        bottom: "34px",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    {!_.isEmpty(filterDepartment) &&
                      filterDepartment.map((department: any) => (
                        <MenuItem
                          onClick={() => {
                            handleButtonClickMobile(
                              `department ${department.id}`,
                            );
                            handleClose();
                          }}
                          style={{
                            textTransform: "none",
                            display: "flex",
                            justifyContent: "start",
                          }}
                        >
                          <Typography
                            style={
                              selectedDepartmentId === department.id
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" }
                            }
                          >
                            {department.name}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Menu>
                  <div>
                    <TextfieldCommon
                      id="search"
                      name="search"
                      label="Search"
                      type="text"
                      style={{ width: max ? "200px" : "240px" }}
                      value={searchValue}
                      disabled={isLoadingToast}
                      onChange={handleChangeSearch}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {searchValue ? (
                              <CloseIcon onClick={handleClearSearch} />
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginRight: "28px",
                    marginTop: "12px",
                    marginBottom: "12px",
                  }}
                >
                  <IncrementDecrementTextboxNewsDefault
                    handleDecrement={handleDecrementInitial}
                    handleIncrement={handleIncrementInitial}
                    initialOrderQty={initialOutputMultiplier}
                    setInitialOrderQty={setInitialOutputMultiplier}
                    displaySuffix={displaySuffix}
                  />
                </div>
              </Hidden>
            </div>
          </div>
          <DialogTitle style={{ marginBottom: "8px", padding: "0px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="h6"
                style={{ marginLeft: "4px" }}
              ></Typography>
              <div style={{ display: "block", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Hidden xsDown>
                      <ButtonCommon
                        onClick={() => {
                          handleReset();
                        }}
                        disabled={isLoadingToast}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "120px",
                          marginRight: "12px",
                        }}
                        color={buttonColors.CANCEL_BUTTON_COLOR}
                      >
                        Cancel
                      </ButtonCommon>
                      <ButtonCommon
                        disabled={
                          checkedProductIdList.length === 0 ||
                          result ||
                          isLoadingToast
                        }
                        onClick={handleSubmitOutputMultiplier}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "120px",
                        }}
                        color={
                          checkedProductIdList.length === 0
                            ? buttonColors.CREATE_BUTTON_COLOR
                            : buttonColors.UPDATE_BUTTON_COLOR
                        }
                      >
                        {`${
                          checkedProductIdList.length === 0
                            ? "Create"
                            : "Update"
                        }`}
                      </ButtonCommon>
                    </Hidden>
                    <Hidden smUp>
                      <ButtonCommon
                        onClick={() => {
                          handleReset();
                        }}
                        disabled={isLoadingToast}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "120px",
                          marginRight: "12px",
                        }}
                        color={buttonColors.CANCEL_BUTTON_COLOR}
                      >
                        Cancel
                      </ButtonCommon>
                      <ButtonCommon
                        disabled={
                          checkedProductIdList.length === 0 ||
                          result ||
                          isLoadingToast
                        }
                        onClick={handleSubmitOutputMultiplier}
                        variant="contained"
                        style={{
                          fontSize: 11,
                          width: "120px",
                        }}
                        color={
                          checkedProductIdList.length === 0
                            ? buttonColors.CREATE_BUTTON_COLOR
                            : buttonColors.UPDATE_BUTTON_COLOR
                        }
                      >
                        {`${
                          checkedProductIdList.length === 0
                            ? "Create"
                            : "Update"
                        }`}
                      </ButtonCommon>
                    </Hidden>
                  </div>
                </div>
              </div>
            </div>
          </DialogTitle>
          <div ref={dialogRef}>{handleModal()}</div>
        </DialogContent>
      </DialogCommonDefault>
    </>
  );
};

export default AddMapProductModal;
