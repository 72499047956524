import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { getCategoryMenuPrice } from "../../../../utils/getCategoryMenuPrice";
import _ from "lodash";
import MenuOption from "./MenuOption";
import { Icon } from "@iconify/react";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { useForm } from "react-hook-form";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import CloseIcon from "@material-ui/icons/Close";
import { updateCategoryMenu } from "../../../../services/menuApp/categoryMenuService";
import { getCookie } from "../../../../utils/cookies";
import ReplayIcon from "@material-ui/icons/Replay";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import {
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { AxiosError } from "axios";
import { useRouteMatch } from "react-router-dom";
import { HttpStatus } from "../../../../utils/enum";
import CategoryMenuList from "./CategoryMenuList";
import CategoryMenuDropDown from "./CategoryMenuDropDown";
import CatMenuPopUp from "./CatMenuPopUp";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: "4px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "8px",
      },
    },
    categoryTitle: {
      backgroundColor: theme.palette.background.default,
      position: "sticky",
      top: 56,
      paddingTop: "100px",
      zIndex: 0,
      fontSize: "20px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "32px",
        top: 62,
        paddingTop: "52px",
      },
    },
    boxStyleOffline: {
      position: "fixed",
      display: "flex",
      justifyContent: "space-between",
      right: 10,
      zIndex: 5,
    },
    replayIcon: {
      fontSize: "24px",
      color: theme.palette.custom["yellow"].main,
    },
    replayIconDisable: {
      fontSize: "24px",
    },
    fontColor: {
      color: theme.palette.custom.orange.contrastText,
    },
  }),
);

export interface MenuInfoPageProps {
  menuOptionNodeList: any;
  getMenuOptionInfo: any;
  menuNodeList: any;
  getCategoryMenuInfo: any;
}

const MenuInfoPage: React.SFC<MenuInfoPageProps> = ({
  menuOptionNodeList,
  getMenuOptionInfo,
  menuNodeList,
  getCategoryMenuInfo,
}) => {
  const [categoryMenuList, setCategoryMenuList] = useState<any>();
  const [openMenuOption, setOpenMenuOption] = useState(false);
  const [isOpenCatMenuModal, setIsOpenCatMenuModal] = useState(false);
  const [isOpenStage1RevertModal, setIsOpenStage1RevertModal] = useState(false);
  const [isOpenStage2RevertModal, setIsOpenStage2RevertModal] = useState(false);
  const [isOpenBasicInfoRevertModal, setIsOpenBasicInfoRevertModal] =
    useState(false);
  const [isOpenCatMenuRevertModal, setIsOpenCatMenuRevertModal] =
    useState(false);
  const [menuOption, setMenuOption] = useState<any>({});
  const [selectedCatMenu, setSelectedCatMenu] = useState<any>([]);
  const [catMenuId, setCatMenuId] = useState("");
  const [selectedCatMenuTitle, setSelectedCatMenuTitle] = useState("");
  const [itemList, setItemList] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenStage1ModifierEditPopup, setIsOpenStage1ModifierEditPopup] =
    useState(false);
  const [isOpenStage2EditPopup, setIsOpenStage2EditPopup] = useState(false);
  const [isOpenCatModal, setIsOpenCatModal] = useState(false);
  const [stage1, setStage1] = useState<any>([]);
  const [stage2, setStage2] = useState<any>([]);
  const [stage2MenuId, setStage2MenuId] = useState("");
  const [stage1Revert, setStage1Revert] = useState("");
  const [stage2Revert, setStage2Revert] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingPageCatMenu, setIsLoadingPageCatMenu] = useState(false);
  const [isLoadingRevertModalButton, setIsLoadingRevertModalButton] =
    useState(false);
  const [isOpenSuccessSnackbar, setIsOpenSuccessSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [catMenuEditId, setCatMenuEditId] = useState("");
  const [isOpenErrorSnackbar, setIsOpenErrorSnackbar] = useState(false);
  const [index, setIndex] = useState("");
  const [name, setName] = useState("");
  const [formData, setFormData] = useState<any>({});
  const [changeState, setChangeState] = useState(false);

  const match: any = useRouteMatch();

  /* Use a react form hooks */
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    formState: { isDirty },
  } = useForm();

  const idToken = getCookie("idToken");
  /* Setting up the catMenu list. */
  useEffect(() => {
    if (!_.isEmpty(menuNodeList)) {
      const catMenu = Object.values(menuNodeList.menu).map((catMenu: any) => [
        { id: catMenu.catMenuId, title: catMenu.title },
      ]);

      setMenuOption(formData);
      setChangeState(!changeState);
      setCategoryMenuList(catMenu.flat());
    }
  }, [menuNodeList]);

  useEffect(() => {
    /* When no cat menu is selected, get the details of the first cat menu in the list of all cat menus. */
    if (_.isEmpty(selectedCatMenuTitle) && !_.isEmpty(categoryMenuList)) {
      // Set the first dat menu id
      setCatMenuId(categoryMenuList[0].id);
      // Set the first dat menu title
      setSelectedCatMenuTitle(categoryMenuList[0].title);
    }

    /* When a cat menu is selected, get the details of that cat menu. */
    if (!_.isEmpty(categoryMenuList) && !_.isEmpty(menuNodeList)) {
      const categoryMenu = Object.values(menuNodeList.menu).filter(
        (e: any) => e.title === selectedCatMenuTitle,
      );

      if (!_.isEmpty(categoryMenu)) {
        // Get the cat menu item details of selected cat menu
        const menu = categoryMenu.map((catMenu: any) =>
          Object.values(catMenu.data).map((category: any) => category),
        );
        setItemList(menu.flat());
      }
    }
  }, [selectedCatMenuTitle, categoryMenuList]);

  /* After selecting the cat menu, enter cat menu id and cat menu name into state. */
  const handleChangeCatMenu = (event: any) => {
    setSelectedCatMenuTitle(event.title);
    setCatMenuId(event.id);
  };

  const handleOpenMenuOption = (category: any, select: any) => {
    /* If the 'categoryMenu' edit button is clicked,, */
    if (select === "categoryMenu") {
      setCatMenuEditId(category.id);
      // Get the 'catMenu' details using the selected 'catMenuId'.
      const catMenu = Object.values(menuNodeList.menu).filter(
        (e: any) => e.catMenuId === category.id,
      );

      setSelectedCatMenu(catMenu);
      setMenuOption(catMenu[0]);
    } else {
      /* If the 'category' edit button is clicked,, */
      setMenuOption(category);
      setSelectedCatMenu(category);
    }

    setOpenMenuOption(true);
  };

  /* After closing the modal, set all states to false. */
  const handleCloseAllModal = () => {
    setIsOpenStage1ModifierEditPopup(false);
    setIsLoadingRevertModalButton(false);
    setIsOpenBasicInfoRevertModal(false);
    setIsOpenStage1RevertModal(false);
    setIsOpenStage2RevertModal(false);
    setIsOpenCatMenuRevertModal(false);
    setIsOpenStage2EditPopup(false);
    setIsLoadingPage(false);
    setIsLoadingPageCatMenu(false);
    setIsOpenSuccessSnackbar(true);
  };

  /* Send API call after changing category menu details. */
  const updateMenuInfo = async (menuNodeList: any) => {
    try {
      const res = await updateCategoryMenu(
        match.params.publishedMenuId,
        idToken,
        menuNodeList,
      );
      setMessage(SUCCESSFULLY_UPDATED);
      getCategoryMenuInfo();
      handleCloseAllModal();
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === HttpStatus.CONFLICT_409) {
        setMessage(ERROR_MESSAGE_VERSION_ERROR);
      } else {
        setMessage(ERROR_MESSAGE_UPDATING_ERROR);
      }
      handleCloseAllModal();
      setIsOpenErrorSnackbar(true);
    }
  };

  /* If the user has made any changes in the catMenu title, update the menu list to send that change to the backend. */
  const handleSubmitCatMenu = handleSubmit((data: any) => {
    setIsLoadingPageCatMenu(true);
    let selectedCatMenuClone: any;
    // If a cat menu is edited,
    if (isOpenCatMenuModal) {
      selectedCatMenuClone = _.cloneDeep(selectedCatMenu);
    } else {
      // If a category menu is edited,
      selectedCatMenuClone = _.cloneDeep(selectedCatMenu[0]);
    }

    const menuNodeListInitialClone = _.cloneDeep(menuNodeList);

    // If the 'originTitle' value is null, insert the value of that title into the value of originTitle..
    if (
      selectedCatMenuClone.originTitle === null &&
      !_.isEmpty(selectedCatMenuClone.title)
    ) {
      selectedCatMenuClone.originTitle = selectedCatMenuClone.title;
    } else if (_.isEmpty(selectedCatMenuClone.originTitle)) {
      /* If the 'originTitle' value is empty, 
         insert the null into the value of originTitle. */
      selectedCatMenuClone.originTitle = null;
    }
    selectedCatMenuClone.title = data.title;
    // If a cat menu is edited,
    if (isOpenCatMenuModal) {
      // Processing to send user input data to the backend.
      Object.values(menuNodeListInitialClone.menu).map((e: any) => {
        if (e.catMenuId === catMenuId) {
          Object.values(e.data).map((category: any) => {
            if (category.catId === selectedCatMenuClone.catId) {
              category.title = selectedCatMenuClone.title;
              category.originTitle = selectedCatMenuClone.originTitle;
              setFormData(category);
            }
          });
        }
        return e;
      });
    } else {
      // If a category menu is edited, Processing to send user input data to the backend.
      setSelectedCatMenuTitle(data.title);
      Object.values(menuNodeListInitialClone.menu).map((e: any) => {
        if (e.catMenuId === catMenuEditId) {
          e.title = selectedCatMenuClone.title;
          e.originTitle = selectedCatMenuClone.originTitle;
          setFormData(e);
        }
        return e;
      });
    }
    updateMenuInfo(menuNodeListInitialClone);
  });

  /* Add the catMenu 'title' to 'originTitle' and catMenu 'originTitle' to null  */
  const handleRevertCatMenu = () => {
    setIsLoadingPage(true);
    let selectedCatMenuClone: any;

    // If a cat menu is edited,
    if (isOpenCatMenuModal) {
      selectedCatMenuClone = _.cloneDeep(selectedCatMenu);
    } else {
      // If a category menu is edited,
      selectedCatMenuClone = _.cloneDeep(selectedCatMenu[0]);
    }

    // If the 'originTitle' value is null.
    if (selectedCatMenuClone.originTitle !== null) {
      // Insert the value of originTitle into the value of title
      selectedCatMenuClone.title = selectedCatMenuClone.originTitle;
      // Insert the null into the value of originTitle
      selectedCatMenuClone.originTitle = null;
    }
    // If a cat menu is edited,
    if (isOpenCatMenuModal) {
      Object.values(menuNodeList.menu).map((cat: any) => {
        if (cat.catMenuId === catMenuId) {
          Object.values(cat.data).map((category: any) => {
            if (category.catId === selectedCatMenuClone.catId) {
              category.title = selectedCatMenuClone.title;
              category.originTitle = selectedCatMenuClone.originTitle;
              setFormData(category);
            }
          });
        }
        return cat;
      });
    } else {
      // If a category menu is edited,
      setSelectedCatMenuTitle(selectedCatMenuClone.title);
      Object.values(menuNodeList.menu).map((catMenu: any) => {
        if (catMenu.catMenuId === catMenuEditId) {
          catMenu.title = selectedCatMenuClone.title;
          catMenu.originTitle = selectedCatMenuClone.originTitle;
          setFormData(catMenu);
        }
        return catMenu;
      });
    }
    updateMenuInfo(menuNodeList);
  };

  /* If the user has made any changes in the menu title, menu description, menu size, or menu price, 
    update the menu list to send that change to the backend. */
  const handleSubmitMenuBasicInfo = handleSubmit((data: any) => {
    setIsLoadingPage(true);
    let editMenuData = Object.keys(data);

    const selectedCategoryClone = _.cloneDeep(menuOption);
    const menuNode = _.cloneDeep(menuNodeList);

    /* If the 'originTitle' value is null, 'title' value is edited, and 'title' value is not empty,
       insert the 'title' value into the value of originTitle.  */
    if (
      data.title !== selectedCategoryClone.title &&
      selectedCategoryClone.originTitle === null &&
      !_.isEmpty(selectedCategoryClone.title)
    ) {
      selectedCategoryClone.originTitle = selectedCategoryClone.title;
    } else if (_.isEmpty(selectedCategoryClone.originTitle)) {
      /* If the 'originTitle' value is empty, 
         insert the null into the value of originTitle. */
      selectedCategoryClone.originTitle = null;
    }
    // Insert the 'title' value into the value of title
    selectedCategoryClone.title = data.title;

    /* If the 'originDesc' value is null, 'desc' value is edited, and 'desc' value is not empty,
       insert the 'desc' value into the value of originDesc. */
    if (
      data.desc !== selectedCategoryClone.desc &&
      selectedCategoryClone.originDesc === null &&
      !_.isEmpty(selectedCategoryClone.desc)
    ) {
      selectedCategoryClone.originDesc = selectedCategoryClone.desc;
    } else if (_.isEmpty(selectedCategoryClone.originDesc)) {
      /* If the 'originDesc' value is empty, 
         insert the null into the value of originDesc. */
      selectedCategoryClone.originDesc = null;
    }
    // Insert the 'desc' value into the value of desc
    selectedCategoryClone.desc = data.desc;

    /* If the 'itemType' is 'direct'. */
    selectedCategoryClone.data.map((multi: any, index: any) => {
      /* Change the size */
      if (editMenuData.includes(`size${index}`)) {
        /* If the 'originSize' value is null, 'size' value is changed, and value of 'size' is not empty,
           insert the value of 'size' into the value of originSize  */
        if (
          data[`size${index}`] !== selectedCategoryClone.data[index].size &&
          selectedCategoryClone.data[index].originSize === null &&
          !_.isEmpty(selectedCategoryClone.data[index].size)
        ) {
          selectedCategoryClone.data[index].originSize =
            selectedCategoryClone.data[index].size;
        } else if (_.isEmpty(selectedCategoryClone.data[index].originSize)) {
          /* If the value of 'originSize is empty, 
               insert the null into the value of originSize. */
          selectedCategoryClone.data[index].originSize = null;
        }
        // insert the value of 'size' into the value of menu size.
        selectedCategoryClone.data[index].size = data[`size${index}`];
      }

      /* Change the price */
      if (editMenuData.includes(`price${index}`)) {
        /* If the 'originPrice' value is null, price' is changed, and value of 'price' is not empty,
             insert the value of 'price' into the value of originPrice */
        if (
          data[`price${index}`] !== selectedCategoryClone.data[index].price &&
          selectedCategoryClone.data[index].originPrice === null &&
          !_.isEmpty(selectedCategoryClone.data[index].price)
        ) {
          selectedCategoryClone.data[index].originPrice =
            selectedCategoryClone.data[index].price;
        } else if (_.isEmpty(selectedCategoryClone.data[index].originPrice)) {
          /* If the value of 'originPrice is empty, 
               insert the null into the value of originPrice. */
          selectedCategoryClone.data[index].originPrice = null;
        }
        // insert the value of 'price' into the value of menu price.
        selectedCategoryClone.data[index].price = data[`price${index}`];
      }
    });

    /* Update the menuNode using updated data. */
    Object.values(menuNode.menu).map((catMenu: any) => {
      if (catMenu.title === selectedCatMenuTitle) {
        Object.values(catMenu.data).map((cat: any) => {
          Object.values(cat.data).map((menu: any) => {
            if (menu.menuId === selectedCategoryClone.menuId) {
              menu.title = selectedCategoryClone.title;
              menu.originTitle = selectedCategoryClone.originTitle;
              menu.originDesc = selectedCategoryClone.originDesc;
              menu.desc = selectedCategoryClone.desc;
              selectedCategoryClone.data.map((multi: any, index: any) => {
                menu.data[index].originPrice = multi.originPrice;
                menu.data[index].price = multi.price;
                menu.data[index].originSize = multi.originSize;
                menu.data[index].size = multi.size;
              });
              setFormData(menu);
            }
          });
        });
        return catMenu;
      }
      return catMenu;
    });

    updateMenuInfo(menuNode);
  });

  /* Open the stage one modifier edit form */
  const handleStageOneModifierEditPageOpen = (stage1: any) => {
    setStage1(stage1);
    /* Add the form data */
    reset({
      stage1Title: stage1.title,
      min: parseFloat(stage1.mealDealMin).toFixed(2),
      max: parseFloat(stage1.mealDealMax).toFixed(2),
      mealDealPriceChangePoint: parseFloat(
        stage1.mealDealPriceChangePoint,
      ).toFixed(2),
    });
    // Open the stage one modifier modal.
    setIsOpenStage1ModifierEditPopup(true);
  };

  /* Open the stage two modifier edit form */
  const handleStageTwoModifierEditPageOpen = (stage2: any, data: any) => {
    setStage2MenuId(data);
    setStage2(stage2);
    // Add the form data.
    reset({
      stage2Title: stage2.title,
      surcharge: parseFloat(stage2.surcharge).toFixed(2),
      extraCost: parseFloat(stage2.extraCost).toFixed(2),
    });
    // Open the stage two modifier modal.
    setIsOpenStage2EditPopup(true);
  };

  /* If the user has made any changes in the stage1 modifier title, min, max, or meal deal price change point, 
    update the menu list to send that change to the backend. */
  const handleSubmitStageOneModifiers = handleSubmit((data) => {
    setIsLoadingPage(true);
    const selectedCategoryClone = _.cloneDeep(stage1);
    const menuNodeListInitialClone = _.cloneDeep(menuNodeList);

    /* If the 'originTitle' value is null, 'stage1Title' value is edited, and 'stage1Title' value is not empty,
       insert the 'title' value into the value of originTitle.  */
    if (
      data.stage1Title !== selectedCategoryClone.title &&
      selectedCategoryClone.originTitle === null &&
      !_.isEmpty(selectedCategoryClone.title)
    ) {
      selectedCategoryClone.originTitle = selectedCategoryClone.title;
    } else if (_.isEmpty(selectedCategoryClone.originTitle)) {
      /* If the 'originTitle' value is empty, 
         insert the null into the value of originTitle. */
      selectedCategoryClone.originTitle = null;
    }
    // Insert the 'stage1Title' value into the value of title
    selectedCategoryClone.title = data.stage1Title;

    /* If the 'originMealDealMin' value is null, 'min' value is edited, and 'min' value is not empty,
       insert the 'mealDealMin' value into the value of originMealDealMin.  */
    if (
      data.min != selectedCategoryClone.mealDealMin &&
      selectedCategoryClone.originMealDealMin === null &&
      selectedCategoryClone.mealDealMin
    ) {
      selectedCategoryClone.originMealDealMin =
        selectedCategoryClone.mealDealMin;
    } else if (selectedCategoryClone.originMealDealMin) {
      /* If the 'originMealDealMin' value is empty, 
         insert the null into the value of originMealDealMin. */
      selectedCategoryClone.originMealDealMin = null;
    }
    // Insert the 'min' value into the value of mealDealMin
    selectedCategoryClone.mealDealMin = parseFloat(
      parseFloat(data.min).toFixed(2),
    );

    /* If the 'originMealDealMax' value is null, 'max' value is edited, and 'max' value is not empty,
       insert the 'mealDealMax' value into the value of originMealDealMax.  */
    if (
      data.max != selectedCategoryClone.mealDealMax &&
      selectedCategoryClone.originMealDealMax === null &&
      selectedCategoryClone.mealDealMax
    ) {
      selectedCategoryClone.originMealDealMax =
        selectedCategoryClone.mealDealMax;
    } else if (selectedCategoryClone.originMealDealMax) {
      /* If the 'originMealDealMax' value is empty, 
         insert the null into the value of originMealDealMax. */
      selectedCategoryClone.originMealDealMax = null;
    }
    // Insert the 'max' value into the value of mealDealMax
    selectedCategoryClone.mealDealMax = parseFloat(
      parseFloat(data.max).toFixed(2),
    );

    /* If the 'originMealDealPriceChangePoint' value is null, 'mealDealPriceChangePoint' value is edited, and 
       'mealDealPriceChangePoint' value is not empty,
       insert the 'mealDealPriceChangePoint' value into the value of originMealDealPriceChangePoint.  */
    if (
      data.mealDealPriceChangePoint !=
        selectedCategoryClone.mealDealPriceChangePoint &&
      selectedCategoryClone.originMealDealPriceChangePoint === null &&
      selectedCategoryClone.mealDealPriceChangePoint
    ) {
      selectedCategoryClone.originMealDealPriceChangePoint =
        selectedCategoryClone.mealDealPriceChangePoint;
    } else if (selectedCategoryClone.originMealDealPriceChangePoint) {
      /* If the 'originMealDealPriceChangePoint' value is empty, 
         insert the null into the value of originMealDealPriceChangePoint. */
      selectedCategoryClone.originMealDealPriceChangePoint = null;
    }
    // Insert the 'mealDealPriceChangePoint' value into the value of mealDealPriceChangePoint
    selectedCategoryClone.mealDealPriceChangePoint = parseFloat(
      parseFloat(data.mealDealPriceChangePoint).toFixed(2),
    );

    /* Update the menuNode using updated data. */
    Object.values(menuNodeListInitialClone.menu).map((catMenu: any) => {
      if (catMenu.title === selectedCatMenuTitle) {
        Object.values(catMenu.data).map((category: any) => {
          Object.values(category.data).map((stageOne: any) => {
            if (stageOne.itemType === "byo") {
              stageOne.data[0].subProducts.map((stage: any) => {
                if (stage.itemId === selectedCategoryClone.itemId) {
                  stage.title = selectedCategoryClone.title;
                  stage.originTitle = selectedCategoryClone.originTitle;
                  stage.mealDealMin = selectedCategoryClone.mealDealMin;
                  stage.originMealDealMin =
                    selectedCategoryClone.originMealDealMin;
                  stage.mealDealMax = selectedCategoryClone.mealDealMax;
                  stage.originMealDealMax =
                    selectedCategoryClone.originMealDealMax;
                  stage.mealDealPriceChangePoint =
                    selectedCategoryClone.mealDealPriceChangePoint;
                  stage.originMealDealPriceChangePoint =
                    selectedCategoryClone.originMealDealPriceChangePoint;
                  setFormData(stageOne);
                }
              });
            }
          });
        });
        return catMenu;
      }
      return catMenu;
    });
    updateMenuInfo(menuNodeListInitialClone);
  });

  /* If the user has made any changes in the stage2 modifier title, surcharge, or extraCost, 
    update the menu list to send that change to the backend. */
  const handleSubmitStageTwoModifiers = handleSubmit((data) => {
    setIsLoadingPage(true);
    const selectedCategoryClone = _.cloneDeep(stage2);
    const menuNodeListInitialClone = _.cloneDeep(menuNodeList);

    /* If the 'originTitle' value is null, 'stage2Title' value is edited, and 
       'stage2Title' value is not empty,
       insert the 'stage2Title' value into the value of originTitle.  */
    if (
      data.stage2Title !== selectedCategoryClone.title &&
      selectedCategoryClone.originTitle === null &&
      !_.isEmpty(selectedCategoryClone.title)
    ) {
      selectedCategoryClone.originTitle = selectedCategoryClone.title;
    } else if (_.isEmpty(selectedCategoryClone.originTitle)) {
      /* If the 'originTitle' value is empty, 
         insert the null into the value of originTitle. */
      selectedCategoryClone.originTitle = null;
    }
    // Insert the 'stage2Title' value into the value of title
    selectedCategoryClone.title = data.stage2Title;

    /* If the 'originSurcharge' value is null, 'surcharge' value is edited, and 
       'surcharge' value is not empty,
       insert the 'surcharge' value into the value of originSurcharge.  */
    if (
      data.surcharge !== selectedCategoryClone.surcharge &&
      selectedCategoryClone.originSurcharge === null &&
      !_.isEmpty(selectedCategoryClone.surcharge)
    ) {
      selectedCategoryClone.originSurcharge = selectedCategoryClone.surcharge;
    } else if (_.isEmpty(selectedCategoryClone.originSurcharge)) {
      /* If the 'originSurcharge' value is empty, 
         insert the null into the value of originSurcharge. */
      selectedCategoryClone.originSurcharge = null;
    }
    // Insert the 'surcharge' value into the value of surcharge
    selectedCategoryClone.surcharge = data.surcharge;

    /* If the 'originExtraCost' value is null, 'extraCost' value is edited, and 
       'extraCost' value is not empty,
       insert the 'extraCost' value into the value of originExtraCost.  */
    if (
      data.extraCost !== selectedCategoryClone.extraCost &&
      selectedCategoryClone.originExtraCost === null &&
      !_.isEmpty(selectedCategoryClone.extraCost)
    ) {
      selectedCategoryClone.originExtraCost = selectedCategoryClone.extraCost;
    } else if (_.isEmpty(selectedCategoryClone.originExtraCost)) {
      /* If the 'originExtraCost' value is empty, 
         insert the null into the value of originExtraCost. */
      selectedCategoryClone.originExtraCost = null;
    }
    // Insert the 'surcharge' value into the value of surcharge
    selectedCategoryClone.extraCost = data.extraCost;

    /* Update the menuNode using updated data. */
    Object.values(menuNodeListInitialClone.menu).map((e: any) => {
      if (e.title === selectedCatMenuTitle) {
        Object.values(e.data).map((category: any) => {
          Object.values(category.data).map((stageOne: any) => {
            if (stageOne.itemType === "byo") {
              stageOne.data[0].subProducts.map((stage: any) => {
                stage.subProducts.map((item: any) => {
                  if (item.itemId === selectedCategoryClone.itemId) {
                    item.title = selectedCategoryClone.title;
                    item.originTitle = selectedCategoryClone.originTitle;
                    item.surcharge = selectedCategoryClone.surcharge;
                    item.originSurcharge =
                      selectedCategoryClone.originSurcharge;
                    item.extraCost = selectedCategoryClone.extraCost;
                    item.originExtraCost =
                      selectedCategoryClone.originExtraCost;
                    if (stageOne.menuId === stage2MenuId) {
                      setFormData(stageOne);
                    }
                  }
                });
              });
            }
          });
        });
        return e;
      }
      return e;
    });

    updateMenuInfo(menuNodeListInitialClone);
  });

  /* Open the stage 1 revert confirmation modal. */
  const handleOpenStage1ModifierRevertModal = (textfieldName: any) => {
    setIsOpenStage1RevertModal(true);
    // The name of the text field to revert.
    setStage1Revert(textfieldName);
  };

  /* Open the stage 2 revert confirmation modal. */
  const handleOpenStage2ModifierRevertModal = (textfieldName: any) => {
    setIsOpenStage2RevertModal(true);
    // The name of the text field to revert.
    setStage2Revert(textfieldName);
  };

  /* Open the basic information revert confirmation modal. */
  const handleOpenBasicInfoRevertModal = (name: any, index: any) => {
    setIndex(index);
    setName(name);
    setIsOpenBasicInfoRevertModal(true);
  };

  /* When the user reverts the title, min, max, or mealDealPriceChangePoint values ​​of the stage1 modifier, 
     Update the data send to the backend.  */
  const handleRevertStage1ModifierData = () => {
    setIsLoadingRevertModalButton(true);
    const selectedCategoryClone = _.cloneDeep(stage1);
    const menuNodeListInitialClone = _.cloneDeep(menuNodeList);

    /* If the name of the stage1 revert text field name is 'title', and if the value of originTitle is not null,
       insert the 'originTitle' value into the value of 'title' and
       insert the null into the value of 'originTitle' */
    if (
      stage1Revert === "title" &&
      selectedCategoryClone.originTitle !== null
    ) {
      selectedCategoryClone.title = selectedCategoryClone.originTitle;
      selectedCategoryClone.originTitle = null;
    } else if (
      stage1Revert === "min" &&
      selectedCategoryClone.originMealDealMin !== null
    ) {
      /* If the name of the stage1 revert text field name is 'min', and if the value of originMealDealMin is not null,
       insert the 'originMealDealMin' value into the value of 'mealDealMin' and
       insert the null into the value of 'originMealDealMin' */
      selectedCategoryClone.mealDealMin =
        selectedCategoryClone.originMealDealMin;
      selectedCategoryClone.originMealDealMin = null;
    } else if (
      stage1Revert === "max" &&
      selectedCategoryClone.originMealDealMax !== null
    ) {
      /* If the name of the stage1 revert text field name is 'max', and if the value of originMealDealMax is not null,
       insert the 'originMealDealMax' value into the value of 'mealDealMax' and
       insert the null into the value of 'originMealDealMax' */
      selectedCategoryClone.mealDealMax =
        selectedCategoryClone.originMealDealMax;
      selectedCategoryClone.originMealDealMax = null;
    } else if (
      stage1Revert === "mealDealPriceChangePoint" &&
      selectedCategoryClone.originMealDealPriceChangePoint !== null
    ) {
      /* If the name of the stage1 revert text field name is 'mealDealPriceChangePoint', and if the value of originMealDealPriceChangePoint is not null,
       insert the 'originMealDealPriceChangePoint' value into the value of 'mealDealPriceChangePoint' and
       insert the null into the value of 'originMealDealPriceChangePoint' */
      selectedCategoryClone.mealDealPriceChangePoint =
        selectedCategoryClone.originMealDealPriceChangePoint;
      selectedCategoryClone.originMealDealPriceChangePoint = null;
    }

    /* Update the menuNode using updated data. */
    Object.values(menuNodeListInitialClone.menu).map((catMenu: any) => {
      if (catMenu.title === selectedCatMenuTitle) {
        Object.values(catMenu.data).map((category: any) => {
          Object.values(category.data).map((stageOne: any) => {
            if (stageOne.itemType === "byo") {
              stageOne.data[0].subProducts.map((stage: any) => {
                if (stage.itemId === selectedCategoryClone.itemId) {
                  stage.title = selectedCategoryClone.title;
                  stage.originTitle = selectedCategoryClone.originTitle;
                  stage.mealDealMin = selectedCategoryClone.mealDealMin;
                  stage.originMealDealMin =
                    selectedCategoryClone.originMealDealMin;
                  stage.mealDealMax = selectedCategoryClone.mealDealMax;
                  stage.originMealDealMax =
                    selectedCategoryClone.originMealDealMax;
                  stage.mealDealPriceChangePoint =
                    selectedCategoryClone.mealDealPriceChangePoint;
                  stage.originMealDealPriceChangePoint =
                    selectedCategoryClone.originMealDealPriceChangePoint;
                  setFormData(stageOne);
                }
              });
            }
          });
        });
        return catMenu;
      }
      return catMenu;
    });
    updateMenuInfo(menuNodeListInitialClone);
  };

  /* When the user reverts the title, surCharge, or extraCost values ​​of the stage2 modifier, 
     Update the data send to the backend.  */
  const handleRevertStage2ModifierData = () => {
    setIsLoadingRevertModalButton(true);
    const selectedCategoryClone = _.cloneDeep(stage2);

    /* If the name of the stage2 revert text field name is 'stage2Title', and if the value of originTitle is not null,
       insert the 'originTitle' value into the value of 'title' and
       insert the null into the value of 'originTitle' */
    if (
      stage2Revert === "stage2Title" &&
      selectedCategoryClone.originTitle !== null
    ) {
      selectedCategoryClone.title = selectedCategoryClone.originTitle;
      selectedCategoryClone.originTitle = null;
    } else if (
      stage2Revert === "surCharge" &&
      selectedCategoryClone.originSurcharge !== null
    ) {
      /* If the name of the stage2 revert text field name is 'surCharge', and if the value of originSurcharge is not null,
       insert the 'originSurcharge' value into the value of 'surcharge' and
       insert the null into the value of 'originSurcharge' */
      selectedCategoryClone.surcharge = selectedCategoryClone.originSurcharge;
      selectedCategoryClone.originSurcharge = null;
    } else if (
      stage2Revert === "extraCost" &&
      selectedCategoryClone.originExtraCost !== null
    ) {
      /* If the name of the stage2 revert text field name is 'extraCost', and if the value of originExtraCost is not null,
       insert the 'originExtraCost' value into the value of 'extraCost' and
       insert the null into the value of 'originExtraCost' */
      selectedCategoryClone.extraCost = selectedCategoryClone.originExtraCost;
      selectedCategoryClone.originExtraCost = null;
    }

    /* Update the menuNode using updated data. */
    Object.values(menuNodeList.menu).map((catMenu: any) => {
      if (catMenu.title === selectedCatMenuTitle) {
        Object.values(catMenu.data).map((category: any) => {
          Object.values(category.data).map((stageOne: any) => {
            if (stageOne.itemType === "byo") {
              stageOne.data[0].subProducts.map((stage: any) => {
                stage.subProducts.map((item: any) => {
                  if (item.itemId === selectedCategoryClone.itemId) {
                    item.title = selectedCategoryClone.title;
                    item.originTitle = selectedCategoryClone.originTitle;
                    item.surcharge = selectedCategoryClone.surcharge;
                    item.originSurcharge =
                      selectedCategoryClone.originSurcharge;
                    item.extraCost = selectedCategoryClone.extraCost;
                    item.originExtraCost =
                      selectedCategoryClone.originExtraCost;
                    if (stageOne.menuId === stage2MenuId) {
                      setFormData(stageOne);
                    }
                  }
                });
              });
            }
          });
        });
        return catMenu;
      }
      return catMenu;
    });

    updateMenuInfo(menuNodeList);
  };

  /* When the user reverts the title, desc, size, or price values ​​of the basic information, 
     Update the data send to the backend.  */
  const handleSubmitBasicInfoRevertModal = () => {
    setIsLoadingRevertModalButton(true);
    const selectedCategoryClone = _.cloneDeep(menuOption);
    const menuNode = _.cloneDeep(menuNodeList);

    /* If the name of the title revert text field name is 'title', and if the value of originTitle is not null,
       insert the 'originTitle' value into the value of 'title' and
       insert the null into the value of 'originTitle' */
    if (name === "title" && selectedCategoryClone.originTitle !== null) {
      selectedCategoryClone.title = selectedCategoryClone.originTitle;
      selectedCategoryClone.originTitle = null;
    } else if (name === "desc" && selectedCategoryClone.originDesc !== null) {
      /* If the name of the desc revert text field name is 'desc', and if the value of originDesc is not null,
       insert the 'originDesc' value into the value of 'desc' and
       insert the null into the value of 'originDesc' */
      selectedCategoryClone.desc = selectedCategoryClone.originDesc;
      selectedCategoryClone.originDesc = null;
    } else if (name === "size" && selectedCategoryClone.originSize !== null) {
      /* If the name of the size revert text field name is 'size', and if the value of originSize is not null,
       insert the 'originSize' value into the value of 'size' and
       insert the null into the value of 'originSize' */
      selectedCategoryClone.data[index].size =
        selectedCategoryClone.data[index].originSize;
      selectedCategoryClone.data[index].originSize = null;
    } else if (
      name === "price" &&
      selectedCategoryClone.data[index].originPrice !== null
    ) {
      /* If the name of the price revert text field name is 'price', and if the value of originPrice is not null,
       insert the 'originPrice' value into the value of 'price' and
       insert the null into the value of 'originPrice' */
      selectedCategoryClone.data[index].price =
        selectedCategoryClone.data[index].originPrice;
      selectedCategoryClone.data[index].originPrice = null;
    }

    /* Update the menuNode using updated data. */
    Object.values(menuNode.menu).map((e: any) => {
      if (e.title === selectedCatMenuTitle) {
        Object.values(e.data).map((category: any) => {
          Object.values(category.data).map((menu1: any) => {
            if (menu1.menuId === selectedCategoryClone.menuId) {
              menu1.title = selectedCategoryClone.title;
              menu1.originTitle = selectedCategoryClone.originTitle;
              menu1.desc = selectedCategoryClone.desc;
              menu1.originDesc = selectedCategoryClone.originDesc;
              menu1.data[index].price = selectedCategoryClone.data[index].price;
              menu1.data[index].originPrice =
                selectedCategoryClone.data[index].originPrice;
              menu1.data[index].size = selectedCategoryClone.data[index].size;
              menu1.data[index].originSize =
                selectedCategoryClone.data[index].originSize;
              setFormData(menu1);
            }
          });
        });
        return e;
      }
      return e;
    });

    updateMenuInfo(menuNode);
  };

  /* Close the success snackbar. */
  const handleCloseSnackbar = () => {
    setIsOpenSuccessSnackbar(false);
  };

  /* Close the error snackbar. */
  const handleCloseErrorSnackbar = () => {
    setIsOpenErrorSnackbar(false);
  };

  const classes = useStyles();

  /* Change the revert icon color. */
  const replayIconStyle = (data: any) => {
    if (data === null) {
      return classes.replayIconDisable;
    } else {
      return classes.replayIcon;
    }
  };

  const theme: CustomTheme = useTheme();

  return (
    <>
      {!_.isEmpty(itemList) && (
        <Grid container spacing={2} className={classes.root}>
          {!_.isEmpty(categoryMenuList) && (
            <Grid item xs={12} className={classes.boxStyleOffline}>
              <CategoryMenuDropDown
                categoryMenuList={categoryMenuList}
                selectedCatMenuTitle={selectedCatMenuTitle}
                handleChangeCatMenu={handleChangeCatMenu}
                setIsOpenCatModal={setIsOpenCatModal}
                setIsOpenCatMenuModal={setIsOpenCatMenuModal}
                handleOpenMenuOption={handleOpenMenuOption}
              />

              <CatMenuPopUp
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
                itemList={itemList}
                isOpenCatMenuModal={isOpenCatMenuModal}
              />
            </Grid>
          )}
        </Grid>
      )}

      {/* Show the menu details  */}
      {!_.isEmpty(itemList) ? (
        itemList.map((category: any) => {
          return (
            <div>
              <Typography
                align="left"
                className={classes.categoryTitle}
                id={category.title}
              >
                {category.title}{" "}
                <Icon
                  icon="bx:edit"
                  style={{ marginLeft: "8px", cursor: "pointer" }}
                  width="24px"
                  height="24px"
                  onClick={() => {
                    setIsOpenCatMenuModal(true);
                    setIsOpenCatModal(false);
                    handleOpenMenuOption(category, "catMenu");
                  }}
                />
              </Typography>
              <Grid container spacing={2}>
                {Object.values(category.data).map((item: any) => {
                  return (
                    <Grid item md={6} xs={12} lg={4}>
                      <CardCommon backgroundColor={"entity_background"}>
                        <CategoryMenuList
                          getCategoryMenuPrice={getCategoryMenuPrice}
                          setIsOpenCatModal={setIsOpenCatModal}
                          setIsOpenCatMenuModal={setIsOpenCatMenuModal}
                          handleOpenMenuOption={handleOpenMenuOption}
                          item={item}
                        />
                      </CardCommon>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <CircularProgress size={50} />
        </div>
      )}
      {openMenuOption && (
        <MenuOption
          openMenuOption={openMenuOption}
          setOpenMenuOption={setOpenMenuOption}
          category={menuOption}
          menuOptionNodeList={menuOptionNodeList}
          getMenuOptionInfo={getMenuOptionInfo}
          handleStageOneModifierEditPageOpen={
            handleStageOneModifierEditPageOpen
          }
          handleOpenBasicInfoRevertModal={handleOpenBasicInfoRevertModal}
          isLoadingPage={isLoadingPage}
          isLoadingPageCatMenu={isLoadingPageCatMenu}
          setMenuOption={setMenuOption}
          isOpenCatModal={isOpenCatModal}
          setIsOpenCatModal={setIsOpenCatModal}
          setIsOpenCatMenuRevertModal={setIsOpenCatMenuRevertModal}
          handleSubmitCatMenu={handleSubmitCatMenu}
          reset={reset}
          register={register}
          errors={errors}
          isDirty={isDirty}
          isOpenCatMenuModal={isOpenCatMenuModal}
          changeState={changeState}
          handleStageTwoModifierEditPageOpen={
            handleStageTwoModifierEditPageOpen
          }
          handleSubmitMenuBasicInfo={handleSubmitMenuBasicInfo}
        />
      )}

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="sm"
        open={isOpenCatMenuRevertModal}
        onClose={() => setIsOpenCatMenuRevertModal(false)}
        disableBackdropClick
      >
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} style={{ paddingLeft: "8px" }}>
            <Typography variant="h5">Are you sure?</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "8px",
              marginTop: "8px",
              marginBottom: "36px",
            }}
          >
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "4px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"red"}
                onClick={() => setIsOpenCatMenuRevertModal(false)}
              >
                {"Cancel"}
              </ButtonCommon>
            </div>
            <div style={{ width: "160px", marginLeft: "16px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                isLoadingPage={isLoadingPage}
                onClick={() => handleRevertCatMenu()}
              >
                {"Confirm"}
              </ButtonCommon>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="md"
        open={isOpenStage1ModifierEditPopup}
        onClose={() => setIsOpenStage1ModifierEditPopup(false)}
        disableBackdropClick
      >
        <form>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={10}
              style={{ marginBottom: "16px", paddingLeft: "8px" }}
            >
              <Typography variant="h6" className={classes.fontColor}>
                Edit Stage sub products
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpenStage1ModifierEditPopup(false)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="stage1Title"
                name="stage1Title"
                label="Title"
                disabled={false}
                type="text"
                inputRef={register({
                  required: "Title is required",
                })}
                helperText={
                  errors.stage1Title ? (
                    <ValidationMessage message={errors.stage1Title.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage1.originTitle === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage1.originTitle)}
                    onClick={() => {
                      handleOpenStage1ModifierRevertModal("title");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="min"
                name="min"
                label="Min"
                disabled={false}
                type="number"
                inputRef={register({
                  required: "Min value is required.",
                  min: {
                    value: 0,
                    message: "Minimum value is 0.",
                  },
                })}
                helperText={
                  errors.min ? (
                    <ValidationMessage message={errors.min.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage1.originMealDealMin === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage1.originMealDealMin)}
                    onClick={() => {
                      handleOpenStage1ModifierRevertModal("min");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="max"
                name="max"
                label="Max"
                disabled={false}
                type="number"
                inputRef={register({
                  required: "Max value is required.",
                  validate: (val) => {
                    if (parseFloat(watch("min")) > parseFloat(val)) {
                      return "Max value is invalid.";
                    }
                  },
                  min: {
                    value: 1,
                    message: "Minimum value is 1.",
                  },
                })}
                helperText={
                  errors.max ? (
                    <ValidationMessage message={errors.max.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage1.originMealDealMax === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage1.originMealDealMax)}
                    onClick={() => {
                      handleOpenStage1ModifierRevertModal("max");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="mealDealPriceChangePoint"
                name="mealDealPriceChangePoint"
                label="Meal Deal Price Change Point"
                disabled={false}
                type="number"
                inputRef={register({
                  required: "Meal deal price change point is required.",
                })}
                helperText={
                  errors.mealDealPriceChangePoint ? (
                    <ValidationMessage
                      message={errors.mealDealPriceChangePoint.message}
                    />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage1.originMealDealPriceChangePoint === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(
                      stage1.originMealDealPriceChangePoint,
                    )}
                    onClick={() => {
                      handleOpenStage1ModifierRevertModal(
                        "mealDealPriceChangePoint",
                      );
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "16px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                disabled={!isDirty}
                isLoadingPage={isLoadingPage}
                onClick={handleSubmitStageOneModifiers}
              >
                {"Rename"}
              </ButtonCommon>
            </div>
          </Grid>
        </form>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="md"
        open={isOpenStage2EditPopup}
        onClose={() => setIsOpenStage2EditPopup(false)}
        disableBackdropClick
      >
        <form>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={10}
              style={{ marginBottom: "16px", paddingLeft: "8px" }}
            >
              <Typography variant="h6" className={classes.fontColor}>
                Edit Stage sub products
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpenStage2EditPopup(false)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="stage2Title"
                name="stage2Title"
                label="Title"
                disabled={false}
                type="text"
                inputRef={register({
                  required: "Title is required",
                })}
                helperText={
                  errors.stage2Title ? (
                    <ValidationMessage message={errors.stage2Title.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage2.originTitle === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage2.originTitle)}
                    onClick={() => {
                      handleOpenStage2ModifierRevertModal("stage2Title");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="surcharge"
                name="surcharge"
                label="Surcharge"
                disabled={false}
                type="number"
                inputRef={register({
                  required: "surcharge is Required.",
                })}
                helperText={
                  errors.surCharge ? (
                    <ValidationMessage message={errors.surCharge.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage2.originSurcharge === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage2.originSurcharge)}
                    onClick={() => {
                      handleOpenStage2ModifierRevertModal("surCharge");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", placeItems: "center" }}
            >
              <TextfieldCommon
                id="extraCost"
                name="extraCost"
                label="ExtraCost"
                disabled={false}
                type="number"
                inputRef={register({
                  required: "Extra Cost is required.",
                })}
                helperText={
                  errors.extraCost ? (
                    <ValidationMessage message={errors.extraCost.message} />
                  ) : (
                    ""
                  )
                }
              />
              <Tooltip title="Click to revert back to original value">
                <Button
                  disabled={stage2.originExtraCost === null}
                  style={{ display: "flex ", justifyContent: "end" }}
                >
                  <ReplayIcon
                    className={replayIconStyle(stage2.originExtraCost)}
                    onClick={() => {
                      handleOpenStage2ModifierRevertModal("extraCost");
                    }}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "16px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                disabled={!isDirty}
                isLoadingPage={isLoadingPage}
                onClick={handleSubmitStageTwoModifiers}
              >
                {"Rename"}
              </ButtonCommon>
            </div>
          </Grid>
        </form>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="sm"
        open={isOpenStage1RevertModal}
        onClose={() => setIsOpenStage1RevertModal(false)}
        disableBackdropClick
      >
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} style={{ paddingLeft: "8px" }}>
            <Typography variant="h5">Are you sure?</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "8px",
              marginTop: "8px",
              marginBottom: "36px",
            }}
          >
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "4px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"red"}
                onClick={() => setIsOpenStage1RevertModal(false)}
              >
                {"cancel"}
              </ButtonCommon>
            </div>
            <div style={{ width: "160px", marginLeft: "16px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                isLoadingPage={isLoadingRevertModalButton}
                onClick={() => handleRevertStage1ModifierData()}
              >
                {"Confirm"}
              </ButtonCommon>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="sm"
        open={isOpenStage2RevertModal}
        onClose={() => setIsOpenStage2RevertModal(false)}
        disableBackdropClick
      >
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} style={{ paddingLeft: "8px" }}>
            <Typography variant="h5">Are you sure?</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "8px",
              marginTop: "8px",
              marginBottom: "36px",
            }}
          >
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "4px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"red"}
                onClick={() => setIsOpenStage2RevertModal(false)}
              >
                {"cancel"}
              </ButtonCommon>
            </div>
            <div style={{ width: "160px", marginLeft: "16px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                isLoadingPage={isLoadingRevertModalButton}
                onClick={() => handleRevertStage2ModifierData()}
              >
                {"confirm"}
              </ButtonCommon>
            </div>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
            border: `1px solid ${theme.palette.background.entity_border}`,
          },
        }}
        fullWidth
        maxWidth="sm"
        open={isOpenBasicInfoRevertModal}
        onClose={() => setIsOpenBasicInfoRevertModal(false)}
        disableBackdropClick
      >
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} style={{ paddingLeft: "8px" }}>
            <Typography variant="h5">Are you sure?</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "8px",
              marginTop: "8px",
              marginBottom: "36px",
            }}
          >
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "4px",
              paddingRight: "18px",
            }}
          >
            <div style={{ width: "160px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"red"}
                onClick={() => setIsOpenBasicInfoRevertModal(false)}
              >
                {"cancel"}
              </ButtonCommon>
            </div>
            <div style={{ width: "160px", marginLeft: "16px" }}>
              <ButtonCommon
                style={{
                  flex: 1,
                  fontSize: 11,
                  width: "160px",
                }}
                variant="contained"
                color={"green"}
                isLoadingPage={isLoadingRevertModalButton}
                onClick={() => handleSubmitBasicInfoRevertModal()}
              >
                {"Confirm"}
              </ButtonCommon>
            </div>
          </Grid>
        </Grid>
      </Dialog>
      <DefaultAlert
        open={isOpenSuccessSnackbar}
        handleClose={handleCloseSnackbar}
        message={message}
        severity="success"
      />
      <DefaultAlert
        open={isOpenErrorSnackbar}
        handleClose={handleCloseErrorSnackbar}
        message={message}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MenuInfoPage, Authorities.MENU_READ);
