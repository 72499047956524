import { Watermark } from "@hirohe/react-watermark";
import {
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CardCommon from "../../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../../types/customTheme";
import {
  initialChannelColorList,
  initialModeColorList,
  initialPlatformsColorList,
} from "../../../../../utils/consts/list";
import WithLoading from "../../../../../utils/WithLoading";
import ModeChart from "./ModeChart";
import ModeTable from "./ModeTable";

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    tabPanel: {
      backgroundColor: theme.palette.background.entity_background,
    },
    tabStyle: {
      minWidth: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    mode: {
      minWidth: "100px",
      [theme.breakpoints.up("md")]: {
        minWidth: "320px",
      },
    },
    tableStyle: {
      marginRight: "20px",
      minWidth: "200px",
      zIndex: 0,
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: "36px",
        marginLeft: "20px",
      },
    },
    newChartStyle: {
      display: "flex",
      placeItems: "center",
      justifyContent: "start",
      zIndex: 6,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
  }),
);

// When no data is in current sales mode list, this data is displayed as this dummy data.
const dummySalesModeNodeList = {
  collection: {
    mode: "collection",
    order_count: 2,
    discount_amount: 0,
    total_amount: 31.7,
  },
  dine_in: {
    mode: "dine_in",
    order_count: 1,
    discount_amount: 0,
    total_amount: 3,
  },
  future_table: {
    mode: "future_table",
    order_count: 1,
    discount_amount: 0,
    total_amount: 2,
  },
  walk_in: {
    mode: "walk_in",
    order_count: 11,
    discount_amount: 0,
    total_amount: 45,
  },
};

// When no data is in previous sales mode list, this data is displayed as this dummy data.
const dummyPreviousSalesModeNodeList = {
  collection: {
    mode: "collection",
    order_count: 80,
    discount_amount: 20,
    total_amount: 3,
  },
  dine_in: {
    mode: "dine_in",
    order_count: 10,
    discount_amount: 0,
    total_amount: 34,
  },
  future_table: {
    mode: "future_table",
    order_count: 12,
    discount_amount: 0,
    total_amount: 22,
  },
  walk_in: {
    mode: "walk_in",
    order_count: 10,
    discount_amount: 0,
    total_amount: 20,
  },
};

// When no data is in current sales platform list, this data is displayed as this dummy data.
const dummySalesPlatformNodeList = {
  "walk in": {
    mode: "walk in",
    order_count: 2,
    discount_amount: 0,
    total_amount: 31.7,
  },
  online: {
    mode: "online",
    order_count: 1,
    discount_amount: 0,
    total_amount: 3,
  },
  phone: {
    mode: "phone",
    order_count: 1,
    discount_amount: 0,
    total_amount: 2,
  },
};

// When no data is in previous sales platform list, this data is displayed as this dummy data.
const dummyPreviousSalesPlatformNodeList = {
  "walk in": {
    mode: "walk in",
    order_count: 80,
    discount_amount: 20,
    total_amount: 3,
  },
  online: {
    mode: "dine_in",
    order_count: 10,
    discount_amount: 0,
    total_amount: 34,
  },
  phone: {
    mode: "future_table",
    order_count: 12,
    discount_amount: 0,
    total_amount: 22,
  },
};

// When no data is in current sales channel list, this data is displayed as this dummy data.
const dummySalesChannelNodeList = {
  eatpresto: {
    mode: "eatpresto",
    order_count: 2,
    discount_amount: 0,
    total_amount: 31.7,
  },
  justeat: {
    mode: "justeat",
    order_count: 1,
    discount_amount: 0,
    total_amount: 3,
  },
  deliveroo: {
    mode: "deliveroo",
    order_count: 1,
    discount_amount: 0,
    total_amount: 2,
  },
  ubereats: {
    mode: "ubereats",
    order_count: 12,
    discount_amount: 0,
    total_amount: 8,
  },
};

// When no data is in previous sales channel list, this data is displayed as this dummy data.
const dummyPreviousSalesChannelNodeList = {
  eatpresto: {
    mode: "eatpresto",
    order_count: 80,
    discount_amount: 20,
    total_amount: 3,
  },
  justeat: {
    mode: "justeat",
    order_count: 10,
    discount_amount: 0,
    total_amount: 34,
  },
  deliveroo: {
    mode: "deliveroo",
    order_count: 12,
    discount_amount: 0,
    total_amount: 22,
  },
  ubereats: {
    mode: "ubereats",
    order_count: 12,
    discount_amount: 0,
    total_amount: 22,
  },
};

export interface DashboardProps {
  salesModeNodeList: any;
  previousSalesModeNodeList: any;
  salesPlatformsNodeList: any;
  previousPlatformNodeList: any;
  salesChannelsNodeList: any;
  previousSalChannelsNodeList: any;
  isLoading: any;
}

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`sale-dashboard-chart-type-tab-${index}`}
      aria-labelledby={`sale-dashboard-chart-type-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

/* This component handles all data processing for doughnut charts and tables of mode, platform, and channels.
   It has the tab panel representation UI and also displays dummy data if there is no data from the API call.
   The skeleton is also loaded until data is received from the API call. */
const Mode: React.FunctionComponent<DashboardProps> = ({
  salesModeNodeList,
  previousSalesModeNodeList,
  salesPlatformsNodeList,
  previousPlatformNodeList,
  salesChannelsNodeList,
  previousSalChannelsNodeList,
  isLoading,
}) => {
  const [value, setValue] = React.useState<number>(0);
  const [modeList, setModeList] = useState<any>([]);
  const [platformList, setPlatformList] = useState<any>([]);
  const [channelList, setChannelList] = useState<any>([]);
  const [isShowDummyMode, seIsShowDummyMode] = useState(false);
  const [availablePlatform, setAvailablePlatform] = useState(false);
  const [availableChannel, setAvailableChannel] = useState(false);
  const [currentModeData, setCurrentModeData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });
  const [previousModeData, setPreviousModeData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });
  const [currentPlatformData, setCurrentPlatformData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });
  const [previousPlatformData, setPreviousPlatformData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });
  const [currentChannelData, setCurrenChannelData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });
  const [previousChannelData, setPreviousChannelData] = useState<any>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverOffset: 1,
      },
    ],
  });

  const handleUpdateModeData = (
    modeList: any,
    currentList: any,
    previousList: any,
  ) => {
    let previousModeList: any = [];
    let currentModeList: any = [];
    let available = false;
    modeList.map((data: any) => {
      /* If there are values ​​for the keys, insert them into a previous mode list of matching values ​​for those keys.
         ex:- previousModeList = [{mode: {id: 'walk_in', color: '#0d89bb'}, prevDiscount: 0, prevOrder: 1, prevSales: 317.8},
                                  {mode: {id: 'collection', color: '#9948bc'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
                                  {mode: {id: 'delivery', color: '#ea326a'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
                                  {mode: {id: 'dine_in', color: '#d6a20f'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
                                  {mode: {id: 'future_table', color: '#49bf97'}, prevDiscount: 0, prevOrder: 0, prevSales: 0}] */
      if (!_.isEmpty(previousList[data.id])) {
        available = true;
        previousModeList.push({
          mode: data,
          prevOrder: previousList[data.id].order_count,
          prevDiscount: previousList[data.id].discount_amount,
          prevSales: previousList[data.id].total_amount,
        });
      } else {
        /*If there are no values ​​for the keys, Insert them as zero in the previous mode list. */
        previousModeList.push({
          mode: data,
          prevOrder: 0,
          prevDiscount: 0,
          prevSales: 0,
        });
      }
      /* If there are values ​​for the keys, insert them into a current mode list of matching values ​​for those keys. */
      if (!_.isEmpty(currentList[data.id])) {
        available = true;
        currentModeList.push({
          mode: data,
          currentOrder: currentList[data.id].order_count,
          currentDiscount: currentList[data.id].discount_amount,
          currentSales: currentList[data.id].total_amount,
        });
      } else {
        /*If there are no values ​​for the keys, Insert them as zero in the current mode list. */
        currentModeList.push({
          mode: data,
          currentOrder: 0,
          currentDiscount: 0,
          currentSales: 0,
        });
      }
    });

    /* Merge previous mode list and current mode list.
       ex:- [{currentDiscount: 0, currentOrder: 2, currentSales: 64, mode: {id: 'walk in', color: '#0d89bb'}, prevDiscount: 0, prevOrder: 1, prevSales: 317.8},
             {currentDiscount: 0, currentOrder: 0, currentSales: 0, mode: {id: 'collection', color: '#9948bc'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
             {currentDiscount: 0, currentOrder: 0, currentSales: 0, mode: {id: 'delivery', color: '#ea326a'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
             {currentDiscount: 0, currentOrder: 0, currentSales: 0, mode: {id: 'dine_in', color: '#d6a20f'}, prevDiscount: 0, prevOrder: 0, prevSales: 0},
             {currentDiscount: 0, currentOrder: 0, currentSales: 0, mode: {id: 'future_table', color: '#49bf97'}, prevDiscount: 0, prevOrder: 0, prevSales: 0}] */
    const mergePrevAndCurrentList = previousModeList.map((prevObj: any) => ({
      ...prevObj,
      ...currentModeList.find(
        (currentObj: any) => currentObj.mode === prevObj.mode,
      ),
    }));

    let chartListCurrent: any = {};
    let chartListPrevious: any = {};

    /* Creating the data required for the chart. */
    mergePrevAndCurrentList.map((data: any) => {
      Object.entries(data).forEach(([key, value]) => {
        const firstThreeCharacter = key.slice(0, 3);
        /* If the key is not a mode, the data required for the current chart is entered. 
           ex:- {deliveroo: 2, eatpresto: 31.7, justeat: 3, ubereats: 8} */
        if (key !== "mode" && firstThreeCharacter !== "pre") {
          chartListCurrent[data.mode.id] = data.currentSales;
        } else if (key !== "mode" && firstThreeCharacter === "pre") {
          /* If the key is not a mode, the data required for the current chart is entered. 
             ex:- {deliveroo: 22, eatpresto: 3, justeat: 34, ubereats: 22} */
          chartListPrevious[data.mode.id] = data.prevSales;
        }
      });
    });

    let modeTotal: any = {};
    /* The sum of current discounts, current orders, current sales, previous discounts, 
       previous orders, and previous sales are calculated separately. */
    Object.keys(mergePrevAndCurrentList[0]).map((mode: any) => {
      mergePrevAndCurrentList.map((data: any) => {
        if (modeTotal[mode] === undefined) {
          modeTotal[mode] = 0;
        }
        modeTotal[mode] = modeTotal[mode] + data[mode];
      });
    });

    // Enter the ID as 'Total' to identify the total row.
    modeTotal["mode"] = { id: "Total" };
    mergePrevAndCurrentList.push(modeTotal);

    let labels: any = [];
    let colorList: any = [];

    // Adding labels to a doughnut chart.
    modeList.map((data: any) => {
      labels.push(data.id.replace("_", " ").toUpperCase());
    });

    // Adding colors to a doughnut chart.
    modeList.map((data: any) => {
      colorList.push(data.color);
    });

    const currentData = {
      labels: labels,
      datasets: [
        {
          data: Object.values(chartListCurrent),
          backgroundColor: colorList,
          hoverOffset: 1,
        },
      ],
    };

    const previousData = {
      labels: labels,
      datasets: [
        {
          data: Object.values(chartListPrevious),
          backgroundColor: colorList,
          hoverOffset: 0,
        },
      ],
    };
    return { available, mergePrevAndCurrentList, currentData, previousData };
  };

  useEffect(() => {
    /* If both the current sales mode list and the previous sales mode list are empty, 
       this is used to enter dummy data for the chart. */
    if (_.isEmpty(salesModeNodeList) && _.isEmpty(previousSalesModeNodeList)) {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialModeColorList,
          dummySalesModeNodeList,
          dummyPreviousSalesModeNodeList,
        );

      setCurrentModeData(currentData);
      setPreviousModeData(previousData);
      setModeList(mergePrevAndCurrentList);
      seIsShowDummyMode(true);
    } else {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialModeColorList,
          salesModeNodeList,
          previousSalesModeNodeList,
        );
      /* If either the current sales mode list or the previous sales mode list is empty, 
         this is used to enter dummy data for the empty list. */
      if (
        _.isEmpty(salesModeNodeList) ||
        _.isEmpty(previousSalesModeNodeList)
      ) {
        // Inserting dummy data when only the current sales mode list is empty.
        if (_.isEmpty(salesModeNodeList)) {
          const currentData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setCurrentModeData(currentData);
        } else {
          // Inserting dummy data when only the current sales mode list is not empty.
          setCurrentModeData(currentData);
        }

        // Inserting dummy data when only the previous sales mode list is empty.
        if (_.isEmpty(previousSalesModeNodeList)) {
          const previousData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setPreviousModeData(previousData);
        } else {
          // Inserting dummy data when only the previous sales mode list is not empty.
          setPreviousModeData(previousData);
        }
        seIsShowDummyMode(false);
        setModeList(mergePrevAndCurrentList);
      } else {
        /* If both the current sales mode list and the previous sales mode list are not empty, 
           this is used to API data */
        setCurrentModeData(currentData);
        setPreviousModeData(previousData);
        setModeList(mergePrevAndCurrentList);
        seIsShowDummyMode(false);
      }
    }
  }, [salesModeNodeList, previousSalesModeNodeList]);

  useEffect(() => {
    /* If both the current sales platform list and the previous sales platform list are empty, 
       this is used to enter dummy data for the chart. */
    if (
      _.isEmpty(salesPlatformsNodeList) &&
      _.isEmpty(previousPlatformNodeList)
    ) {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialPlatformsColorList,
          dummySalesPlatformNodeList,
          dummyPreviousSalesPlatformNodeList,
        );
      setCurrentPlatformData(currentData);
      setPreviousPlatformData(previousData);
      setPlatformList(mergePrevAndCurrentList);
      setAvailablePlatform(true);
    } else {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialPlatformsColorList,
          salesPlatformsNodeList,
          previousPlatformNodeList,
        );

      /* If either the current sales mode list or the previous sales mode list is empty, 
         this is used to enter dummy data for the empty list. */
      if (
        _.isEmpty(salesPlatformsNodeList) ||
        _.isEmpty(previousPlatformNodeList)
      ) {
        // Inserting dummy data when only the current sales platform list is empty.
        if (_.isEmpty(salesPlatformsNodeList)) {
          const currentData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setCurrentPlatformData(currentData);
        } else {
          // Inserting dummy data when only the current sales platform list is not empty.
          setCurrentPlatformData(currentData);
        }

        // Inserting dummy data when only the previous sales platform list is empty.
        if (_.isEmpty(previousPlatformNodeList)) {
          const previousData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setPreviousPlatformData(previousData);
        } else {
          // Inserting dummy data when only the previous sales platform list is not empty.
          setPreviousPlatformData(previousData);
        }
        setAvailablePlatform(false);
        setPlatformList(mergePrevAndCurrentList);
      } else {
        /* If both the current sales platform list and the previous sales platform list are not empty, 
           this is used to API data */
        setCurrentPlatformData(currentData);
        setPreviousPlatformData(previousData);
        setPlatformList(mergePrevAndCurrentList);
        setAvailablePlatform(false);
      }
    }
  }, [salesPlatformsNodeList, previousPlatformNodeList]);

  useEffect(() => {
    let cloneSalesChannelsNodeList = _.cloneDeep(salesChannelsNodeList);
    let clonePreviousSalChannelsNodeList = _.cloneDeep(
      previousSalChannelsNodeList,
    );

    /* If there are both eat-presto and eatpresto channels in the current sales channel list, 
       then merge the two channels to create a single channel. */
    if (
      Object.keys(salesChannelsNodeList).includes("eat-presto") &&
      Object.keys(salesChannelsNodeList).includes("eatpresto")
    ) {
      cloneSalesChannelsNodeList.eatpresto.order_count =
        cloneSalesChannelsNodeList.eatpresto.order_count +
        salesChannelsNodeList["eat-presto"].order_count;
      cloneSalesChannelsNodeList.eatpresto.discount_amount =
        cloneSalesChannelsNodeList.eatpresto.discount_amount +
        salesChannelsNodeList["eat-presto"].discount_amount;
      cloneSalesChannelsNodeList.eatpresto.total_amount =
        cloneSalesChannelsNodeList.eatpresto.total_amount +
        salesChannelsNodeList["eat-presto"].total_amount;
    }

    /* If there are both eat-presto and eatpresto channels in the previous sales channel list, 
       then merge the two channels to create a single channel. */
    if (
      Object.keys(previousSalChannelsNodeList).includes("eat-presto") &&
      Object.keys(previousSalChannelsNodeList).includes("eatpresto")
    ) {
      clonePreviousSalChannelsNodeList.eatpresto.order_count =
        clonePreviousSalChannelsNodeList.eatpresto.order_count +
        previousSalChannelsNodeList["eat-presto"].order_count;
      clonePreviousSalChannelsNodeList.eatpresto.discount_amount =
        clonePreviousSalChannelsNodeList.eatpresto.discount_amount +
        previousSalChannelsNodeList["eat-presto"].discount_amount;
      clonePreviousSalChannelsNodeList.eatpresto.total_amount =
        clonePreviousSalChannelsNodeList.eatpresto.total_amount +
        previousSalChannelsNodeList["eat-presto"].total_amount;
    }

    /* If the channel in the current sales channel list is eat-presto, change it to eatpresto. */
    if (
      Object.keys(salesChannelsNodeList).includes("eat-presto") &&
      !Object.keys(salesChannelsNodeList).includes("eatpresto")
    ) {
      cloneSalesChannelsNodeList["eatpresto"] =
        salesChannelsNodeList["eat-presto"];
      delete cloneSalesChannelsNodeList["eat-presto"];
    }

    /* If the channel in the previous sales channel list is eat-presto, change it to eatpresto. */
    if (
      Object.keys(previousSalChannelsNodeList).includes("eat-presto") &&
      !Object.keys(previousSalChannelsNodeList).includes("eatpresto")
    ) {
      clonePreviousSalChannelsNodeList["eatpresto"] =
        previousSalChannelsNodeList["eat-presto"];
      delete clonePreviousSalChannelsNodeList["eat-presto"];
    }

    /* If both the current sales channel list and the previous sales channel list are empty, 
       this is used to enter dummy data for the chart. */
    if (
      _.isEmpty(cloneSalesChannelsNodeList) &&
      _.isEmpty(clonePreviousSalChannelsNodeList)
    ) {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialChannelColorList,
          dummySalesChannelNodeList,
          dummyPreviousSalesChannelNodeList,
        );
      setCurrenChannelData(currentData);
      setPreviousChannelData(previousData);
      setChannelList(mergePrevAndCurrentList);
      setAvailableChannel(true);
    } else {
      const { mergePrevAndCurrentList, currentData, previousData } =
        handleUpdateModeData(
          initialChannelColorList,
          cloneSalesChannelsNodeList,
          clonePreviousSalChannelsNodeList,
        );

      /* If either the current sales mode list or the previous sales mode list is empty, 
         this is used to enter dummy data for the empty list. */
      if (
        _.isEmpty(cloneSalesChannelsNodeList) ||
        _.isEmpty(clonePreviousSalChannelsNodeList)
      ) {
        // Inserting dummy data when only the current sales channel list is empty.
        if (_.isEmpty(cloneSalesChannelsNodeList)) {
          const currentData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setCurrenChannelData(currentData);
        } else {
          // Inserting dummy data when only the current sales channel list is not empty.
          setCurrenChannelData(currentData);
        }

        // Inserting dummy data when only the previous sales channel list is empty.
        if (_.isEmpty(previousSalChannelsNodeList)) {
          const previousData = {
            labels: ["Sample Data"],
            datasets: [
              {
                data: [20],
                backgroundColor: "grey",
                hoverOffset: 1,
              },
            ],
          };
          setPreviousChannelData(previousData);
        } else {
          // Inserting dummy data when only the previous sales channel list is not empty.
          setPreviousChannelData(previousData);
        }
        setAvailableChannel(false);
        setChannelList(mergePrevAndCurrentList);
      } else {
        /* If both the current sales channel list and the previous sales channel list are not empty, 
           this is used to API data */
        setCurrenChannelData(currentData);
        setPreviousChannelData(previousData);
        setChannelList(mergePrevAndCurrentList);
        setAvailableChannel(false);
      }
    }
  }, [salesChannelsNodeList, previousSalChannelsNodeList]);

  /* Switch the tab to be displayed. */
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const theme: CustomTheme = useTheme();
  const classes = useStyles();

  return (
    <div style={{ marginTop: "24px" }}>
      <CardCommon backgroundColor={"entity_background"}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="discount-types-tabs"
          variant="fullWidth"
        >
          <Tab
            label="MODES"
            id="sale-dashboard-chart-type-tab-0"
            aria-controls="sale-dashboard-chart-type-tab-0"
            className={classes.tabStyle}
          />
          <Tab
            label="PLATFORMS"
            id="sale-dashboard-chart-type-tab-1"
            aria-controls="sale-dashboard-chart-type-tab-1"
            className={classes.tabStyle}
          />
          <Tab
            label="CHANNELS"
            id="sale-dashboard-chart-type-tab-0"
            aria-controls="sale-dashboard-chart-type-tab-0"
            className={classes.tabStyle}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          {isShowDummyMode ? (
            <Watermark
              lineHeight="1.2rem"
              opacity={0.2}
              rotate={-45}
              show
              text={"Sample Data"}
              textColor={theme.palette.custom.orange.contrastText}
              textSize={24}
              gutter={50}
              wrapperElement="div"
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={3}
                  className={classes.newChartStyle}
                >
                  <ModeChart
                    currentData={currentModeData}
                    previousData={previousModeData}
                  />
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                  <div className={classes.tableStyle}>
                    <ModeTable modeList={modeList} />
                  </div>
                </Grid>
              </Grid>
            </Watermark>
          ) : (
            <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                item
                xs={12}
                md={4}
                xl={3}
                className={classes.newChartStyle}
              >
                <ModeChart
                  currentData={currentModeData}
                  previousData={previousModeData}
                />
              </Grid>
              <Grid item xs={12} md={8} xl={9}>
                <div className={classes.tableStyle}>
                  <ModeTable modeList={modeList} />
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {availablePlatform ? (
            <Watermark
              lineHeight="1.2rem"
              opacity={0.2}
              rotate={-45}
              show
              text={"Sample Data"}
              textColor={theme.palette.custom.green.contrastText}
              textSize={24}
              gutter={50}
              wrapperElement="div"
              wrapperStyle={{ color: "red" }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={3}
                  className={classes.newChartStyle}
                >
                  <ModeChart
                    currentData={currentPlatformData}
                    previousData={previousPlatformData}
                  />
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                  <div className={classes.tableStyle}>
                    <ModeTable modeList={platformList} />
                  </div>
                </Grid>
              </Grid>
            </Watermark>
          ) : (
            <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                item
                xs={12}
                md={4}
                xl={3}
                className={classes.newChartStyle}
              >
                <ModeChart
                  currentData={currentPlatformData}
                  previousData={previousPlatformData}
                />
              </Grid>
              <Grid item xs={12} md={8} xl={9}>
                <div className={classes.tableStyle}>
                  <ModeTable modeList={platformList} />
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {availableChannel ? (
            <Watermark
              lineHeight="1.2rem"
              opacity={0.2}
              rotate={-45}
              show
              text={"Sample Data"}
              textColor={theme.palette.custom.green.contrastText}
              textSize={24}
              gutter={50}
              wrapperElement="div"
              wrapperStyle={{ color: "red" }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={3}
                  className={classes.newChartStyle}
                >
                  <ModeChart
                    currentData={currentChannelData}
                    previousData={previousChannelData}
                  />
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                  <div className={classes.tableStyle}>
                    <ModeTable modeList={channelList} />
                  </div>
                </Grid>
              </Grid>
            </Watermark>
          ) : (
            <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid
                item
                xs={12}
                md={4}
                xl={3}
                className={classes.newChartStyle}
              >
                <ModeChart
                  currentData={currentChannelData}
                  previousData={previousChannelData}
                />
              </Grid>
              <Grid item xs={12} md={8} xl={9}>
                <div className={classes.tableStyle}>
                  <ModeTable modeList={channelList} />
                </div>
              </Grid>
            </Grid>
          )}
        </TabPanel>
      </CardCommon>
    </div>
  );
};

export default WithLoading(Mode, "chart");
