import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CustomTheme } from "../../../../types/customTheme";
import "jspdf-autotable";
import CardCommon from "../../../../components/card/CardCommon";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import { handleDownloadPdf } from "./convert/convertDataToPdf";
import { handleDownloadCsv } from "./convert/convertDataToExcel";
import {
  getFilterListFromArrayObject,
  sortDates,
  sortNumbers,
  sortStrings,
} from "../../../../utils/commonArrayMap";
import moment from "moment";
import { useLocation, useHistory } from "react-router";
import { Icon } from "@iconify/react";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { convertDecimalPoint } from "../../../../utils/ReplaceIcon";
import {
  convertDateFormat,
  convertTimeFormat,
} from "../../../../utils/ConvertDateTimeFormat";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface VoidSummeryInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  filterDetails: any;
  locationSelectedList: any;
  handleOnClickText: any;
}

const timeShiftTypePayInOrOutStyle: any = {
  display: "flex",
  overflowY: "auto",
};

const shiftStyle: any = {
  display: "flex",
  overflowY: "auto",
  cursor: "pointer",
};

const remarkStyle: any = {
  maxHeight: "42px",
  overflowY: "auto",
  paddingRight: "4px",
  padding: "auto",
};

const dateStyle: any = {
  display: "flex",
  fontWeight: "bold",
  overflowY: "auto",
};

const useStyles = (
  nodesLength: number,
  nodesHeadersLength: number,
  bodyLength: number,
) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "16px",
        alignItems: "center",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "center",
        fontWeight: "bold",
        alignItems: "center",
        fontSize: "12px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "center",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      headerRowFinal: {
        fontSize: "12px",
        height: "40px",
        [`&:nth-of-type(${nodesLength})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
      },
      finalTotalRow: {
        fontSize: "12px",
        height: "40px",
        [`&:nth-of-type(${bodyLength + 1})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.green.light,
          },
        },
        [`&:nth-of-type(n+${bodyLength + 2}):nth-of-type(-n+${nodesLength})`]: {
          color: theme.palette.custom["blue"].contrastText,
          backgroundColor: theme.palette.custom.blue.main,
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: theme.palette.custom.blue.light,
          },
        },
      },
      totalRow: {
        fontSize: "12px",
        fontWeight: "bold",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(n)": {
          color: theme.palette.custom.blue.contrastText,
          backgroundColor: theme.palette.custom.blue.main,
          "&:hover": {
            backgroundColor: theme.palette.custom.blue.light,
          },
        },
      },
      headerRow: {
        fontSize: "12px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "center",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
      baseCellRow: {
        placeItems: "center",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "22%",
          width: "22%",
        },
        [theme.breakpoints.up("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
        },
        [theme.breakpoints.up("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "22%",
            width: "22%",
          },
        },

        [theme.breakpoints.up("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: "32%",
            width: "32%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "40%",
            width: "40%",
          },
        },

        [theme.breakpoints.up("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: "20%",
            width: "20%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "28%",
            width: "28%",
          },
        },

        [theme.breakpoints.up("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+${nodesHeadersLength})`]: {
            minWidth: "12%",
            width: "12%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "28%",
            width: "28%",
          },
        },
      },
      payInChipStyle: {
        width: "88px",
        backgroundColor: theme.palette.custom.green.main,
        color: theme.palette.custom.red.contrastText,
      },
      payOutChipStyle: {
        backgroundColor: theme.palette.custom.red.main,
        color: theme.palette.custom.red.contrastText,
      },
    }),
  );

/* Creation of 'Pay in/out' table, and conversion of Pay in/out table to PDF and Excel. */
const PayInOutInfoNode: React.FunctionComponent<VoidSummeryInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  filterDetails,
  locationSelectedList,
  handleOnClickText,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [nodesLength, setNodeLength] = useState(0);
  const [bodyLength, setBodyLength] = useState(0);
  const [nodesHeadersLength, setNodesHeadersLength] = useState(7);
  const [locationsLength, setLocationsLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const history = useHistory();
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const maxWidthCustom = useMediaQuery("(max-width: 1600px)");

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  /* Obtaining the ID at the location where Pay in/out has been done. */
  const handleLocationId = (payInOrOutList: any) => {
    // Here, when there are multiple location IDs, only one location ID will be retrieved.
    const vatList = payInOrOutList.reduce(
      (previousValue: any, currentValue: any) => {
        if (
          !previousValue.some(
            (locationId: any) => locationId === currentValue.locationId,
          )
        ) {
          previousValue.push(currentValue.locationId);
        }
        return previousValue;
      },
      [],
    );
    return vatList;
  };

  /* Creating a chip according to payIn and payOut. */
  const handlePayInOutChip = (payInOrOut: any) => {
    const label = payInOrOut.replace("_", " ");
    if (payInOrOut === "pay_in") {
      return (
        <Chip
          label={label}
          style={{
            width: "88px",
            backgroundColor: theme.palette.custom.green.main,
            color: theme.palette.custom.red.contrastText,
          }}
          className={classes.payInChipStyle}
        />
      );
    } else {
      return (
        <Chip
          label={label}
          style={{
            width: "88px",
            backgroundColor: theme.palette.custom.red.main,
            color: theme.palette.custom.red.contrastText,
          }}
          className={classes.payOutChipStyle}
        />
      );
    }
  };

  const handleAmount = (nodeDate: any) => {
    const { amount, payInOrOut } = nodeDate;
    /* If the In/Out column is a pay in, the value is converted to two decimal points. */
    if (payInOrOut === "pay_in") {
      return convertDecimalPoint(amount);
    } else {
      /* If the In/Out column is a payout, the minus sign for the Amount column is applied to the beginning of the Amount.
         In addition, the value is converted to two decimal points. */
      return `-${convertDecimalPoint(amount)}`;
    }
  };

  /* Creating the data for the Pay in/out table and creating the data for pdf and excel files.
     Using a API request */
  useEffect(() => {
    if (!_.isEmpty(nodeData)) {
      let clonePayInOutNodeList = _.cloneDeep(nodeData);
      let footer: any = [];
      let sortPayInOrOutList: any = [];

      /* Sort pay in/out list using location id */
      sortPayInOrOutList = clonePayInOutNodeList.sort(
        (prev: any, current: any) =>
          prev.locationId.localeCompare(current.locationId),
      );

      const locationIdList = handleLocationId(sortPayInOrOutList);
      setLocationsLength(locationIdList.length);

      let payInOutTableData: any = [];

      /* Enter values ​​for the Date, Time, Shift, Type, Amount, PayInOrOut, and Remarks columns in the Pay in/out table. */
      sortPayInOrOutList.map((data: any) => {
        payInOutTableData.push({
          Date: convertDateFormat(data.date),
          Time: convertTimeFormat(data.date),
          Shift: `${data.deviceId} - ${data.extId}*${data.shiftId}`,
          Type: data.type,
          Amount: handleAmount(data),
          PayInOrOut: handlePayInOutChip(data.payInOrOut),
          Remarks: data.remarks,
        });
      });

      if (!_.isEmpty(payInOutTableData)) {
        let grandTotal = 0;
        let locationPayInOutLength = 0;

        locationIdList.map((id: any, index: any) => {
          let total = 0;

          /* The amount of 'pay in/out and the sum of 'pay in/out' for a location is calculated. */
          sortPayInOrOutList.map((data: any, index: number) => {
            const { locationId, amount, payInOrOut } = data;
            if (id === locationId) {
              // If the value of payInOrOut is pay_in, then add a value.
              if (payInOrOut === "pay_in") {
                total = total + amount;
              } else {
                // If the value of payInOrOut is pay_out, then subtracts a value.
                total = total - amount;
              }
              // Calculate the total row of pay in/out data in one location.
              locationPayInOutLength = locationPayInOutLength + 1;
            }
          });

          // Calculate all locations total
          grandTotal = grandTotal + total;

          // Get the location name using locationId
          const locationDetails = getFilterListFromArrayObject(
            locationSelectorList,
            id,
          );

          // Calculation rows of 'pay in/out' with footer in each location.
          const locationLength = locationPayInOutLength + index;

          /* Entering the total of each location obtained above in the table. */
          payInOutTableData.splice(locationLength, 0, {
            Date: locationDetails[0].label,
            Time: "",
            Shift: "",
            Type: "",
            Amount: parseFloat(total.toString()).toFixed(2),
            PayInOrOut: "",
            Remarks: "",
          });
        });

        // Removing the 'Total' row at the end.
        const footerDetails = payInOutTableData.pop();

        // Inserting the removed 'Total' row into the 'footer'.
        footer.push(footerDetails);

        // Adding the total of all the locations obtained above to the 'footer'.
        footer.push({
          Date: "Grand Total",
          Time: "",
          Shift: "",
          Type: "",
          Amount: parseFloat(grandTotal.toString()).toFixed(2),
          PayInOrOut: "",
          Remarks: "",
        });
      }

      // Set pdf headers
      setPdfHeaders([
        { title: "Date", field: "Date" },
        { title: "Time", field: "Time" },
        { title: "Shift", field: "Shift" },
        { title: "Type", field: "Type" },
        { title: "Amount", field: "Amount" },
        { title: "IN/OUT", field: "PayInOrOut" },
        { title: "Remarks", field: "Remarks" },
      ]);

      // Set excel headers
      setExcelHeaders([
        { header: "Date", key: "Date", width: 52, height: 68 },
        { header: "Time", key: "Time", width: 32, height: 68 },
        { header: "Shift", key: "Shift", width: 32, height: 68 },
        { header: "Type", key: "Type", width: 32, height: 68 },
        { header: "Amount", key: "Amount", width: 32, height: 68 },
        { header: "IN/OUT", key: "PayInOrOut", width: 32, height: 68 },
        { header: "Remarks", key: "Remarks", width: 32, height: 68 },
      ]);

      // Set table headers
      setHeadersList([
        "Date",
        "Time",
        "Shift",
        "Type",
        "Amount",
        "IN/OUT",
        "Remarks",
      ]);

      setNodeLength(payInOutTableData.length + 2);
      setNodes({ nodes: payInOutTableData });
      setFooter(footer);

      let pdfExcelNodeList: any = [];

      /* Entering values ​​for Date, Time, Shift, Type, Amount, PayInOrOut, and Remarks columns in the PDF/ Excel.​​ */
      payInOutTableData.map((data: any) => {
        pdfExcelNodeList.push({
          Date: data.Date,
          Time: data.Time,
          Shift: data.Shift.split("*")[0],
          Type: data.Type,
          Amount: data.Amount,
          PayInOrOut: data.PayInOrOut
            ? data.PayInOrOut.props.label === "pay in"
              ? "Pay In"
              : "Pay Out"
            : "",
          Remarks: data.Remarks,
        });
      });

      // Merge the body and footer of PDF/ Excel.
      const merged = [...pdfExcelNodeList, ...footer];

      // set the pdf excel data in the state
      setNodesPdfExcel(merged);
    }
  }, [nodeData]);

  // Create a pay In Out sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Date: (array) => sortDates(array, "Date"),
        Time: (array) => sortStrings(array, "Time"),
        Shift: (array) => sortStrings(array, "Shift"),
        Type: (array) => sortStrings(array, "Type"),
        Amount: (array) => sortNumbers(array, "Amount"),
        "IN/OUT": (array) => sortStrings(array, "PayInOrOut"),
        Remarks: (array) => sortStrings(array, "Remarks"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Change tha column Style */
  const handleStyle = (item: any, data: any) => {
    if (data === "Remarks") {
      return remarkStyle;
    } else if (
      item.Time &&
      item.Shift &&
      item.Type &&
      item.PayInOrOut &&
      item.Remarks
    ) {
      if (data === "Shift") {
        return shiftStyle;
      } else {
        return timeShiftTypePayInOrOutStyle;
      }
    } else {
      return dateStyle;
    }
  };
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const classes: any = useStyles(nodesLength, nodesHeadersLength, bodyLength)();
  const theme: CustomTheme = useTheme();

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => {
                    return locationsLength > 1 ? (
                      <HeaderCell
                        pinLeft={index === 0 ? true : false}
                        className={classes.baseCellRow}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                          className={
                            index === 0
                              ? classes.startTextStyle
                              : classes.textStyleHeader
                          }
                        >
                          {data}
                        </Typography>
                      </HeaderCell>
                    ) : (
                      <HeaderCellSort
                        sortKey={data}
                        pinLeft={index === 0 ? true : false}
                        className={classes.baseCellRow}
                      >
                        <Typography
                          variant="caption"
                          className={
                            index === 0
                              ? classes.startTextStyle
                              : classes.textStyleHeader
                          }
                          style={{
                            color: theme.palette.custom.orange.contrastText,
                          }}
                        >
                          {data}
                        </Typography>
                      </HeaderCellSort>
                    );
                  })}
                </HeaderRow>
              </Header>
              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={
                        item.Time &&
                        item.Shift &&
                        item.Type &&
                        item.PayInOrOut &&
                        item.Remarks
                          ? classes.headerRow
                          : classes.totalRow
                      }
                    >
                      {Object.keys(item).map((data: any, index: any) => {
                        return (
                          <>
                            <Cell
                              pinLeft={index === 0 ? true : false}
                              className={classes.baseCellRow}
                              style={
                                index === 0
                                  ? {
                                      height: "52px",
                                      textAlign: "left",
                                      cursor: "context-menu",
                                    }
                                  : {
                                      display: "flex",
                                      height: "52px",
                                      cursor: "context-menu",
                                    }
                              }
                            >
                              <Typography
                                variant="caption"
                                onClick={() => {
                                  data === "Shift" &&
                                    item["Shift"] &&
                                    history.push(
                                      `shift/${item["Shift"].split("*")[1]}`,
                                    );
                                }}
                                className={
                                  index === 0
                                    ? classes.startTextStyle
                                    : classes.textStyle
                                }
                                style={handleStyle(item, data)}
                              >
                                {data === "Shift"
                                  ? item[data].split("*")[0]
                                  : item[data]}
                              </Typography>
                            </Cell>
                          </>
                        );
                      })}
                    </Row>
                    {bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any, footerIndex: any) => (
                        <>
                          <Row
                            className={
                              footerIndex + 1 === footer.length
                                ? classes.headerRowFinal
                                : classes.finalTotalRow
                            }
                            style={{
                              height: "52px",
                              paddingTop: "8px",
                            }}
                            key={item.Date}
                            item={item}
                          >
                            {Object.keys(item).map((data: any, index: any) => {
                              return (
                                <>
                                  <Cell
                                    pinLeft={index === 0 ? true : false}
                                    className={classes.baseCellRow}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      textAlign: "left",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      className={
                                        index === 0
                                          ? classes.startTextStyle
                                          : classes.textStyle
                                      }
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      {data1[data]}
                                    </Typography>
                                  </Cell>
                                </>
                              );
                            })}
                          </Row>
                        </>
                      ))}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default withAuthority(PayInOutInfoNode, Authorities.SALE_READ);
