import {
  makeStyles,
  createStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { DateRangePicker } from "react-date-range";
import { CustomTheme } from "../../../types/customTheme";
import {
  checkDateRangeFromTwoDates,
  handleStaticRanges,
} from "../../../utils/checkDateRangeFromTwoDates";

export interface SortingMethodProps {
  handleChangeDateRange: any;
  dateSelectionRange: any;
  horizontalMaxWidth: any;
  setOpen: any;
}

const useStyles = makeStyles<any>((theme: CustomTheme) =>
  createStyles({
    rdrCalendarWrapper: {
      "& .rdrCalendarWrapper": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        // border: `1px solid ${theme.palette.background.entity_border}`,
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrDateRangePickerWrapper": {
        backgroundColor: "red",
      },
      "& .rdrStartEdge": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrInRange": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrEndEdge": {
        backgroundColor: theme.palette.background.default,
      },
      "& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span":
        {
          color: theme.palette.text.primary,
          fontWeight: "bold",
        },
      "& .rdrWeekDay": {
        color: theme.palette.text.primary,
      },
      "& .rdrDateDisplayItem": {
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .rdrMonthName": {
        color: theme.palette.text.primary,
      },
      "& .rdrDateDisplayWrapper": {
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .rdrMonthAndYearPickers select": {
        color: theme.palette.text.primary,
      },
      "& .rdrDayPassive .rdrDayNumber span": {
        color: theme.palette.text.hint,
      },
      "& .rdrDayNumber span": {
        color: theme.palette.text.primary,
      },
      "& .rdrDateDisplayItem input": {
        color: theme.palette.text.primary,
      },
      "& .rdrDefinedRangesWrapper": {
        backgroundColor: theme.palette.background.entity_highlight_background,
        borderRight: `1px solid ${theme.palette.grey[700]}`,
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
      },
      "& .rdrStaticRange": {
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
        backgroundColor: theme.palette.background.entity_highlight_background,
        color: theme.palette.text.primary,
        borderTopLeftRadius: "10px",
      },
      "& .rdrStaticRangeLabel": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .rdrStaticRange .rdrStaticRangeLabel": {
        "&:hover": {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.success,
        },
      },
      "& .rdrDateDisplay": {
        backgroundColor: theme.palette.background.entity_highlight_background,
      },
      "& .rdrNextPrevButton": {
        backgroundColor: theme.palette.grey,
      },
      "& .rdrDayToday .rdrDayNumber span": {
        "&:after": {
          color: theme.palette.success,
        },
      },
    },
  }),
);

/* Desktop date picker */
const DatePicker: React.FunctionComponent<SortingMethodProps> = ({
  handleChangeDateRange,
  dateSelectionRange,
  horizontalMaxWidth,
  setOpen,
}) => {
  const renderStaticRangeLabel = () => (
    <Typography>This is a custom label content: </Typography>
  );

  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <DateRangePicker
        className={classes.rdrCalendarWrapper}
        onChange={(ranges: any) => {
          handleChangeDateRange(ranges);
          if (ranges.selection.startDate !== ranges.selection.endDate) {
            /* Returns true if the selected date range is today, yesterday, this week, 
               last week, this month, last month, this year, or last year, otherwise returns false. */
            const result = checkDateRangeFromTwoDates(ranges);
            /* Close the modal if the selected date range is today, yesterday, this week, 
               last week, this month, last month, this year, or last year. */
            if (result.specificTimeSpan === true) {
              setOpen(false);
            }
          }
        }}
        renderStaticRangeLabel={renderStaticRangeLabel}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateSelectionRange}
        preventSnapRefocus={false}
        retainEndDateOnFirstSelection={false}
        direction={!horizontalMaxWidth ? "horizontal" : "vertical"}
        editableDateInputs={false}
        inputRanges={[]}
        staticRanges={handleStaticRanges()}
        rangeColors={[theme.palette.primary.main]}
      />
    </>
  );
};

export default DatePicker;
