import _ from "lodash";
import { getCookie, setCookie } from "../../utils/cookies";
import { axiosClient } from "../client";

const AUTH_ENDPOINT = `${process.env.REACT_APP_API_PRESTO_EXPRESS_ACCOUNTS}/account/add`;
const API_FIREBASE_AUTH_REFRESH_TOKEN = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_AUTH_API_KEY}`;

export const authenticateTokenWithServer = (data) => {
  return axiosClient({
    url: AUTH_ENDPOINT,
    method: "POST",
    data,
  });
};

export const refreshAccessToken = async (refreshToken) => {
  try {
    const {
      data: { id_token, refresh_token },
    } = await axiosClient({
      url: API_FIREBASE_AUTH_REFRESH_TOKEN,
      method: "POST",
      data: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      },
    });
    const firebaseUser = getCookie("firebaseUser");
    await verifyAuthTokenWithServer(id_token, JSON.parse(firebaseUser), refresh_token);
    return { id_token, refresh_token };
  } catch (error) {
    return { id_token: "", refresh_token: "" };
  }
};

export const verifyAuthTokenWithServer = async (
  idToken,
  firebaseUser,
  refreshToken,
) => {
  const { uid, extId, displayName, photoURL, email, emailVerified } = firebaseUser;
  let data = {}
  if(!_.isEmpty(uid)) {
     data = {
      extId: uid,
      name: displayName,
      email: email,
      isActive: true,
      isEmailVerified: emailVerified,
      issuer: "firebase",
      profilePic: photoURL,
      isAuthenticated: true,
    };
  }
  if(!_.isEmpty(extId)) {
     data = {
      extId: extId,
      name: displayName,
      email: email,
      isActive: true,
      isEmailVerified: emailVerified,
      issuer: "firebase",
      profilePic: photoURL,
      isAuthenticated: true,
    };
  }
  
  if (!data.extId) return;
  await authenticateTokenWithServer(data);
  setCookie("firebaseUser", data, {
    domain: process.env.REACT_APP_ALLOWED_DOMAIN,
  });
  setCookie("idToken", idToken, {
    domain: process.env.REACT_APP_ALLOWED_DOMAIN,
  });
  setCookie("refreshToken", refreshToken, {
    domain: process.env.REACT_APP_ALLOWED_DOMAIN,
  });
};
