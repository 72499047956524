import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import ApplyToConditionSelectionModal from "./ApplyToConditionSelectionModel";
import {
  DISCOUNT_TYPE_ALL,
  DISCOUNT_TYPE_CATEGORY,
} from "../../../utils/consts";
import CardCommon from "../../card/CardCommon";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "self-start",
    flex: 1,
  },
}));

export interface AvailabilitySelectionProps {
  disabled?: boolean;
  nodeData: any;
  categoryList: any;
  handleSubmitApplyTo: any;
  setApplyToConditionErrorMessage: any;
}

/* This component shows the applyTo selection modal. */
const ApplyToSelection: React.FunctionComponent<AvailabilitySelectionProps> = ({
  disabled,
  nodeData,
  categoryList,
  handleSubmitApplyTo,
  setApplyToConditionErrorMessage,
}) => {
  const [open, setOpen] = React.useState(false);
  const [applicableList, setApplicableList] = React.useState<any>([]);
  const [applicableInitialList, setApplicableInitialList] = React.useState<any>(
    [],
  );
  const [applyToCondition, setApplyToCondition] = React.useState("");
  const [applyToConditionInitial, setApplyToConditionInitial] =
    React.useState("");
  const [availableApplyToList, setAvailableApplyToList] = React.useState([]);
  const [error, setError] = React.useState("");
  const classes = useStyles();

  const getNodeData = React.useCallback((nodeValues) => {
    const { applyToCondition, applyTo } = nodeValues;
    setApplicableList(applyTo);
    setApplicableInitialList(applyTo);
    setApplyToCondition(applyToCondition);
    setApplyToConditionInitial(applyToCondition);
  }, []);

  React.useEffect(() => {
    getNodeData(nodeData);
  }, [nodeData]);

  /* handleSubmitApplyTo function call to after click apply button.
  applyToCondition When "all" the applicableList is empty. */
  const handleSubmitData = () => {
    // If 'applyToCondition' is 'all', an empty list is sent to the backend.
    if (applyToCondition === DISCOUNT_TYPE_ALL) {
      handleSubmitApplyTo([], applyToCondition);
      setApplicableList([]);
      setOpen(false);
    } else if (!_.isEmpty(applicableList)) {
      // If any category is selected, that selected category is sent to the backend.
      setOpen(false);
      handleSubmitApplyTo(applicableList, applyToCondition);
    } else {
      /* When the applyToCondition is not selected 'all' and no category is selected, 
         when trying to add an applyTo, an error message is displayed. */
      setError("Please select categories");
    }
  };

  // Set selected catMenu id to applicableList.
  const handleApplyLToList = (e: any) => {
    const { value, checked } = e.target;
    // If the catId is selected, add that catId to a list.
    if (checked) { 
      const selectedCatIdList: any = new Set([...applicableList, value]);
      setApplicableList([...selectedCatIdList]);
    } else {
      // If the catId is not selected, remove that catId from the list.
      const selectedCatIdList: any = applicableList.filter(
        (catId: String) => value !== catId,
      );
      setApplicableList(selectedCatIdList);
    }
  };

  /* Close applyTo condition modal after click cancel button and reset data */
  const handleClose = () => {
    setApplicableList(applicableInitialList);
    setApplyToCondition(applyToConditionInitial);
    setOpen(false);
  };

  // Change available apply list and apply condition
  const handleApplyToCondition = (discountType: string) => {
    setError("");
    setApplyToCondition(discountType);
    if (discountType === DISCOUNT_TYPE_ALL || _.isEmpty(discountType)) {
      setAvailableApplyToList([]);
    } else if (discountType === DISCOUNT_TYPE_CATEGORY) {
      setAvailableApplyToList(categoryList);
    } else {
      setAvailableApplyToList([]);
      setError("Discount type not support.");
    }
  };

  return (
    <CardCommon
      backgroundColor={"entity_highlight_background"}
      style={{ margin: "8px 0" }}
    >
      <div className={classes.root}>
        <div className={classes.content}>
          <div>
            {applyToCondition ? (
              <Typography style={{ textTransform: "capitalize" }}>
                {applyToCondition}
              </Typography>
            ) : (
              <Typography>Add apply to condition</Typography>
            )}
          </div>
        </div>
        <ApplyToConditionSelectionModal
          open={open}
          setOpen={setOpen}
          disabled={disabled}
          availableApplyToList={availableApplyToList}
          handleApplyToCondition={handleApplyToCondition}
          handleApplyLToList={handleApplyLToList}
          applicableList={applicableList}
          handleSubmitData={handleSubmitData}
          handleCloseModal={handleClose}
          applyToCondition={applyToCondition}
          error={error}
          setApplyToConditionErrorMessage={setApplyToConditionErrorMessage}
          nodeData={nodeData}
        />
      </div>
    </CardCommon>
  );
};

export default ApplyToSelection;
