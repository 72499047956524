import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import _ from "lodash";

import DialogCommonDefault from "../../../../components/dialogs/DialogCommonDefault";
import { buttonColors } from "../../../../utils/enum";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import IncrementDecrementTextboxNormal from "../../../../components/common/IncrementDecrementTextbox/IncrementDecrementTextboxNoraml";

export interface paymentReportProps {
  isOpenStockLevelModal: any;
  setIsOpenStockLevelModal: any;
  handleChangeStockLevel: any;
  stockLevel: any;
  handleSaveStockLevel: any;
  stockLevelInitial: any;
  handleDecrement: any;
  handleIncrement: any;
  isLoadingToast: any;
  stockLevelName: any;
  displaySuffix: any;
}

/**
 * This component represents a modal dialog for adjusting and saving stock levels.
 * Users can increment or decrement the stock level and save their changes.
 */
const StockLevelModal: React.FunctionComponent<paymentReportProps> = ({
  isOpenStockLevelModal,
  setIsOpenStockLevelModal,
  handleChangeStockLevel,
  stockLevel,
  handleSaveStockLevel,
  stockLevelInitial,
  handleDecrement,
  handleIncrement,
  isLoadingToast,
  stockLevelName,
  displaySuffix,
}) => {
  return (
    <DialogCommonDefault
      open={isOpenStockLevelModal}
      setOpen={setIsOpenStockLevelModal}
      isNeedFixedHeight={false}
      backgroundColor="entity_background"
      maxWidth="xs"
    >
      <DialogTitle>{`Stock Level ${stockLevelName}`}</DialogTitle>
      <DialogContent>
        <IncrementDecrementTextboxNormal
          handleValue={handleChangeStockLevel}
          value={stockLevel}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          isDisable={false}
          displaySuffix={displaySuffix}
        />
        {/* <div style={{ display: "flex" }}>
          <IconButton onClick={handleDecrement}>
            <IndeterminateCheckBoxOutlinedIcon
              style={{
                fontSize: "20px",
              }}
            />
          </IconButton>
          <TextfieldCommon
            id="stockLevel"
            name="stockLevel"
            type="number"
            label="Stock Level"
            disabled={false}
            value={stockLevel}
            onChange={handleChangeStockLevel}
            style={{ margin: "0px 4px" }}
          />
          <IconButton onClick={handleIncrement}>
            <AddBoxOutlinedIcon
              style={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </div> */}
      </DialogContent>
      <DialogActions style={{ marginBottom: "12px" }}>
        <ButtonCommon
          onClick={() => {
            setIsOpenStockLevelModal(false);
          }}
          disabled={isLoadingToast}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "12px",
          }}
          color={buttonColors.CANCEL_BUTTON_COLOR}
        >
          Back
        </ButtonCommon>

        <ButtonCommon
          disabled={
            stockLevelInitial.toString() === stockLevel.toString() ||
            isLoadingToast
          }
          onClick={handleSaveStockLevel}
          variant="contained"
          style={{
            fontSize: 11,
            width: "120px",
            marginRight: "14px",
          }}
          color={buttonColors.CREATE_BUTTON_COLOR}
        >
          Done
        </ButtonCommon>
      </DialogActions>
    </DialogCommonDefault>
  );
};

export default StockLevelModal;
