import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router";
import { Grid } from "@material-ui/core";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import moment from "moment";
import CardCommon from "../../../../components/card/CardCommon";
import ChartType from "./ChartType";
import { zeroToTwentyThreeList } from "../../../../utils/consts/list";
import { enumerateDaysBetweenDates } from "../../../../utils/enumerateDaysBetweenDates";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
);

export interface DashboardInfoNodeProps {
  currentDashboardNodeList: any;
  previousDashboardNodeList: any;
  paymentNodeList: any;
  previousPaymentNodeList: any;
  previousStartDate: any;
  previousEndDate: any;
  currentStartDate: any;
  currentEndDate: any;
  ordersNodeList: any;
  previousOrdersNodeList: any;
  averageNodeList: any;
  previousAverageNodeList: any;
  platformNodeList: any;
  previousPlatformNodeList: any;
  modeNodeList: any;
  previousModeNodeList: any;
  channelNodeList: any;
  previousChannelNodeList: any;
  isEmpty?: any;
  currentSalesTotal: any;
  previousSalesTotal: any;
}

/* This component handles all the data processing required for the all chart. */
const ChartReportInfoNode: React.FunctionComponent<DashboardInfoNodeProps> = ({
  currentDashboardNodeList,
  previousDashboardNodeList,
  paymentNodeList,
  previousPaymentNodeList,
  previousStartDate,
  previousEndDate,
  currentStartDate,
  currentEndDate,
  ordersNodeList,
  previousOrdersNodeList,
  averageNodeList,
  previousAverageNodeList,
  platformNodeList,
  previousPlatformNodeList,
  modeNodeList,
  previousModeNodeList,
  channelNodeList,
  previousChannelNodeList,
  isEmpty,
  currentSalesTotal,
  previousSalesTotal,
}) => {
  const [shortPreviousDateRange, setShortPreviousDateRange] = useState<any>([]);
  const [shortCurrentDateRange, setShortCurrentDateRange] = useState<any>([]);

  /* This equates the number of days in the previous date range to the 
     number of days in the current date range. Then the date ranges are sorted. */
  useEffect(() => {
    /* If the current start date and the current end date are unequal, 
    the date range to be displayed on the chart's x-axis is created using the start date and the end date. */
    if (currentStartDate !== currentEndDate) {
      const currentDateRange = enumerateDaysBetweenDates(
        currentStartDate,
        currentEndDate,
      );
      const previousDateRange = enumerateDaysBetweenDates(
        previousStartDate,
        previousEndDate,
      );
      /* This works if the data in the current date range is equal to this month. */
      if (
        (currentDateRange[0] ===
          moment(new Date()).startOf("month").format("YYYY-MM-DD") &&
          currentDateRange[currentDateRange.length - 1] ===
            moment(new Date()).endOf("month").format("YYYY-MM-DD")) ||
        (currentDateRange[0] ===
          moment(new Date(currentDateRange[0]))
            .startOf("month")
            .format("YYYY-MM-DD") &&
          currentDateRange[currentDateRange.length - 1] ===
            moment(new Date(currentDateRange[0]))
              .endOf("month")
              .format("YYYY-MM-DD"))
      ) {
        /* This works when the number of days in the 
            previous month is less than the number of days in this month.
            ex:- Number of days in October = 31
                 Number of days in September  = 30
         */
        if (previousDateRange.length < currentDateRange.length) {
          /* New days are added to the previous date range as the number of previous days 
             should be equal to the current number of days. */
          const date = moment(previousEndDate).add(1, "d").format("YYYY-MM-DD");
          previousDateRange.push(date);
          if (previousDateRange.length < currentDateRange.length) {
            const date = moment(previousEndDate)
              .add(2, "d")
              .format("YYYY-MM-DD");
            previousDateRange.push(date);
          }
          if (previousDateRange.length < currentDateRange.length) {
            const date = moment(previousEndDate)
              .add(3, "d")
              .format("YYYY-MM-DD");
            previousDateRange.push(date);
          }
        } else if (previousDateRange.length > currentDateRange.length) {
          /* This works when the number of days in the 
            previous month is greater than the number of days in this month.
            ex:- Number of days in November = 30
                 Number of days in October  = 31 
            The last day of the previous date range is removed as the number of days 
            in the previous month must be equal to the number of days in the current month. */
          previousDateRange.pop();
          if (previousDateRange.length > currentDateRange.length) {
            previousDateRange.pop();
          }
          if (previousDateRange.length > currentDateRange.length) {
            previousDateRange.pop();
          }
          if (previousDateRange.length > currentDateRange.length) {
            previousDateRange.pop();
          }
        }
      } else if (currentDateRange.length > previousDateRange.length) {
        /* If this month is not selected, this will work if the number of days in 
         the previous date range is less than the number of days in this current date range. */

        // Get the difference in days between the previous end date and the previous start date.
        const previousDateRangeDateDifference =
          moment(previousEndDate).diff(moment(previousStartDate), "days") + 1;

        let len = 0;

        currentDateRange.map((data: any, index: any) => {
          /* Ex:- currentDateRange.length = 15
                  previousDateRangeDateDifference = 5
                  In this case, only 10 (currentDateRange.length - previousDateRangeDateDifference) 
                  days should be entered for the previous date range. */
          if (
            index <
            currentDateRange.length - previousDateRangeDateDifference
          ) {
            const date = moment(previousEndDate)
              .add(index + 1, "d")
              .format("YYYY-MM-DD");
            const subDate = moment(previousStartDate)
              .subtract(len + 1, "d")
              .format("YYYY-MM-DD");

            /* A date is added to the previous date range only if the date to be 
               added to the previous date range is a day before the start date of the current date range. */
            if (date < currentStartDate) {
              previousDateRange.push(date);
            } else {
              /* Otherwise, a day before the start date of the previous date range is added to the previous date range. */
              previousDateRange.push(subDate);
              len = len + 1;
            }
          }
        });
      } else if (currentDateRange.length < previousDateRange.length) {
        /* If this month is not selected, this will work if the number of days in 
         the previous date range is greater  than the number of days in this current date range. */

        // Get the difference in days between the current end date and the current start date.
        const currentDateRangeDateDifference =
          moment(currentEndDate).diff(moment(currentStartDate), "days") + 1;

        let len = 0;

        previousDateRange.map((data: any, index: any) => {
          /* Ex:- previousDateRange.length = 15
                  currentDateRangeDateDifference = 5
                  In this case, only 10 (previousDateRange.length - currentDateRangeDateDifference) 
                  days should be entered for the current date range. */
          if (
            index <
            previousDateRange.length - currentDateRangeDateDifference
          ) {
            const date = moment(currentEndDate)
              .add(len + 1, "d")
              .format("YYYY-MM-DD");
            const subDate = moment(currentStartDate)
              .subtract(index + 1, "d")
              .format("YYYY-MM-DD");

            /* A value will be added to the current date range only if the date to be added 
                 to the current date range is a day after the last date of the previous date range.
                 That add date is a date before the start date of the current date range. */
            if (subDate > previousEndDate) {
              currentDateRange.push(subDate);
            } else {
              /* Otherwise, days after the last day of the current date range are added. */
              currentDateRange.push(date);
              len = len + 1;
            }
          }
        });
      }

      /* Sort the previous and current date range */
      const shortPreviousDateRange = previousDateRange.sort();
      const shortCurrentDateRange = currentDateRange.sort();
      setShortPreviousDateRange(shortPreviousDateRange);
      setShortCurrentDateRange(shortCurrentDateRange);
    } else {
      /* If only one date is selected, the hours contained in the date will be entered as a list. (0-23) */
      setShortPreviousDateRange(zeroToTwentyThreeList);
      setShortCurrentDateRange(zeroToTwentyThreeList);
    }
  }, [currentDashboardNodeList, previousDashboardNodeList]);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "14px" }}>
        <Grid item xs={12}>
          <CardCommon backgroundColor={"entity_background"}>
            <ChartType
              previousStartDate={previousStartDate}
              previousEndDate={previousEndDate}
              currentStartDate={currentStartDate}
              currentEndDate={currentEndDate}
              shortPreviousDateRange={shortPreviousDateRange}
              shortCurrentDateRange={shortCurrentDateRange}
              previousDashboardNodeList={previousDashboardNodeList}
              currentDashboardNodeList={currentDashboardNodeList}
              paymentNodeList={paymentNodeList}
              previousPaymentNodeList={previousPaymentNodeList}
              ordersNodeList={ordersNodeList}
              previousOrdersNodeList={previousOrdersNodeList}
              averageNodeList={averageNodeList}
              previousAverageNodeList={previousAverageNodeList}
              platformNodeList={platformNodeList}
              previousPlatformNodeList={previousPlatformNodeList}
              modeNodeList={modeNodeList}
              previousModeNodeList={previousModeNodeList}
              channelNodeList={channelNodeList}
              previousChannelNodeList={previousChannelNodeList}
              isEmpty={isEmpty}
              currentSalesTotal={currentSalesTotal}
              previousSalesTotal={previousSalesTotal}
            />
          </CardCommon>
        </Grid>
      </Grid>
    </>
  );
};

export default ChartReportInfoNode;
