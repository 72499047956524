import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import _ from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import MenuBody from "./MenuBody";

export interface MenuInfoPageProps {
  handleRemoveOrderListSorting: any;
  getMenuInfoList: any;
  menuNodeList: any;
  disableButton: any;
}

/* This component displays the menu details. Additionally, sorting the menu details using the header. */
const MenuListPage: React.SFC<MenuInfoPageProps> = ({
  handleRemoveOrderListSorting,
  getMenuInfoList,
  menuNodeList,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const match: any = useRouteMatch();

   // Sorting the menu information using a header name
  const handleChangeOrderListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    setDisableButton(true);
    getMenuInfoList(headerName, sortingMethod);
  };

   // Remove sorting method
  const handleRemoveOrderListSortingApi = () => {
    setDisableButton(false);
    handleRemoveOrderListSorting();
  };

  const history = useHistory();

  /* Redirect a menu info page after clicking the edit button. */
  const handleGetMenuInfo = (id: any) => {
    history.push(
      `/${match.params.locationId}/presto-menus/${id}/presto-menus-info`,
    );
  };

  return (
    <>
      {!_.isEmpty(menuNodeList) && (
        <MenuHeader
          handleChangeOrderListSorting={handleChangeOrderListSorting}
          handleRemoveOrderListSortingApi={handleRemoveOrderListSortingApi}
          disableButton={disableButton}
        />
      )}
      <Grid container>
        {!_.isEmpty(menuNodeList) &&
          menuNodeList.map((nodeData: any) => (
            <MenuBody
              nodeData={nodeData}
              handleGetMenuInfo={handleGetMenuInfo}
            />
          ))}
      </Grid>
    </>
  );
};

export default withAuthority(MenuListPage, Authorities.MENU_READ);
