import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import DashboardCard from "./DashboardCard";

export interface CreateNewNodeProps {
  dashboardNodeList: any;
  handleUpdateDashboardInfo: any;
}

/* Update dashboard information and users can view all dashboard information. */
const DashboardDetails: React.SFC<CreateNewNodeProps> = ({
  dashboardNodeList,
  handleUpdateDashboardInfo,
}) => {
  const [isOrderingActive, setIsOrderingActive] = useState(false);
  const [isPreOrderActive, setIsPreOrderActive] = useState(false);
  const [isCarrierBagChargeActive, setIsCarrierBagChargeActive] =
    useState(false);
  const [isOrderingStatusActive, setIsOrderingStatusActive] = useState(false);
  const [isPreOrderingStatusActive, setIsPreOrderingStatusActive] =
    useState(false);
  const [
    isCarrierBagChargingStatusActive,
    setIsCarrierBagChargingStatusActive,
  ] = useState(false);
  const [nodeList, setNodeList] = useState<any>();
  const [error, setError] = useState("");

  useEffect(() => {
    const { isOrderingActive, isPreOrderActive, carrierBagCharge } =
      dashboardNodeList;
    setIsOrderingActive(isOrderingActive);
    setIsOrderingStatusActive(isOrderingActive);
    setIsPreOrderActive(isPreOrderActive);
    setIsPreOrderingStatusActive(isPreOrderActive);

    // CarrierBagCharge value If 0.00, changes the status of 'true'.
    if (carrierBagCharge !== "0.00") {
      setIsCarrierBagChargeActive(true);
      setIsCarrierBagChargingStatusActive(true);
    } else {
      // Otherwise, changes the status of 'false'.
      setIsCarrierBagChargeActive(false);
      setIsCarrierBagChargingStatusActive(false);
    }
    setNodeList(dashboardNodeList);
  }, [dashboardNodeList]);

  // Change Ordering Switch status.
  const handleOrderingSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsOrderingActive(checked);
    nodeList.isOrderingActive = checked;
    handleUpdateDashboardInfo(nodeList);
  };

  // Change Pre Ordering Switch status.
  const handlePreOrderSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsPreOrderActive(checked);
    nodeList.isPreOrderActive = checked;
    handleUpdateDashboardInfo(nodeList);
  };

  // Change Carrier Bag Charge Switch status.
  const handleCarrierBagChargeActivate = (e: any) => {
    const { checked } = e.target;
    setIsCarrierBagChargeActive(checked);
    // CarrierBagCharge If 'true', changes the value of carrierBagCharge to 0.10'.
    if (checked) {
      nodeList.carrierBagCharge = "0.10";
    } else {
      // CarrierBagCharge If 'false', changes the value of carrierBagCharge to '0.00'.
      nodeList.carrierBagCharge = "0.00";
    }

    handleUpdateDashboardInfo(nodeList);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard
            backgroundColor={isOrderingStatusActive}
            title={"Online Ordering"}
            subTitle={
              isOrderingStatusActive
                ? "You are accepting orders."
                : "You are not accepting orders."
            }
            handleOrderingSwitchActivate={handleOrderingSwitchActivate}
            isActive={isOrderingActive}
            activeButtonLabel="Online Ordering"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard
            backgroundColor={isPreOrderingStatusActive}
            title="Scheduled Ordering"
            subTitle={
              isPreOrderingStatusActive
                ? "Customers can pre-order for a later date and time."
                : "Customers can only order for now."
            }
            handleOrderingSwitchActivate={handlePreOrderSwitchActivate}
            isActive={isPreOrderActive}
            activeButtonLabel="Scheduled Ordering"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard
            backgroundColor={isCarrierBagChargingStatusActive}
            title="Carrier Bag Charge"
            subTitle={
              isCarrierBagChargingStatusActive
                ? "You are charging 10p per order for carrier bags"
                : "You are not charging for carrier bags"
            }
            handleOrderingSwitchActivate={handleCarrierBagChargeActivate}
            isActive={isCarrierBagChargeActive}
            activeButtonLabel="Carrier Bag Charge"
          />
        </Grid>
      </Grid>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(DashboardDetails, Authorities.DASHBOARD_READ);
