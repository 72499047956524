import { Hidden, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import RoundBoxWithHeader from "../../../../components/common/RoundBoxWithHeader";
import OpeningTime from "../../../../components/opening-times/OpeningTime";
import {
  getOpeningTimes,
  updateSelectedOpeningTimeSchedule,
} from "../../../../services/locationApp/openingTimesService";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface OpeningTimesProps {}

/* Get all opening time information using API call at initial load. */
const OpeningTimes: React.FunctionComponent<OpeningTimesProps> = () => {
  const [selectedOpeningSchedule, setSelectedOpeningSchedule] = useState({});
  const [openingTimeList, setOpeningTimeList] = useState([]);
  const [error, setError] = useState("");

  const match: any = useRouteMatch();

  /* Get all opening time information using API call and response data set to openingTimeList state. */
  const fetchOpeningTimes = useCallback(async () => {
    try {
      const res = await getOpeningTimes(match.params.locationId);
      setOpeningTimeList(res.data.data);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, [match.params.locationId]);

  /* Send an API call to update opening time information after click apply button. */
  const updateSelectedSchedule = useCallback(async () => {
    try {
      await updateSelectedOpeningTimeSchedule(
        match.params.locationId,
        selectedOpeningSchedule,
      );
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, [match.params.locationId, selectedOpeningSchedule]);

  useEffect(() => {
    if (!_.isEmpty(selectedOpeningSchedule)) updateSelectedSchedule();
  }, [selectedOpeningSchedule, updateSelectedSchedule]);

  useEffect(() => {
    document.title = "Locations - Opening Times";
    fetchOpeningTimes();
  }, [fetchOpeningTimes, match.params.locationId]);

  /* Entering the current open time slot into a state. */
  const handleSelectOpeningSchedule = (option: any) => {
    setSelectedOpeningSchedule(option);
  };


  return (
    <div>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <div style={{ marginTop: "18px" }}>
        <RoundBoxWithHeader header={""}>
          {!_.isEmpty(openingTimeList) &&
            openingTimeList.map((option: any) => (
              <>
                <Typography variant={"h4"} align={"left"} gutterBottom>
                  {option.title}
                </Typography>
                <OpeningTime
                  option={option}
                  fetchOpeningTimes={fetchOpeningTimes}
                  locationId={match.params.locationId}
                  handleSelectOpeningSchedule={handleSelectOpeningSchedule}
                />
              </>
            ))}
        </RoundBoxWithHeader>
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity={"error"}
        />
      </div>
    </div>
  );
};

export default withAuthority(OpeningTimes, Authorities.OPENING_TIME_READ);
