import React, { useState } from "react";
import {
  Box,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import _ from "lodash";
import ShiftInfoNode from "./ShiftInfoNode";
import Generate from "./Generate";
import WithLoading from "../../../../utils/WithLoading";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import CardCommon from "../../../../components/card/CardCommon";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface ShiftHeaderProps {
  nodeData: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  userList: any;
  handleGetShiftReportInfo: any;
  shiftReportNode: any;
  filterDetails: any;
  isShiftSummaryReportLoading: any;
  locationSelectedList: any;
  handleOnClickText: any;
  numberOfLocations: any;
  deviceIdObj: any;
  deviceIdErr: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
  pageSize: any;
  idList: any;
  setIdList: any;
  selectedList: any;
  setSelectedList: any;
  isSelectAllShift: any;
  handleChangeCheckBox: any;
  setIsSelectAllShift: any
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      marginTop: "1px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "16px",
      },
    },
    headerContainerStyle: {
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStyle: {
      display: "flex",
      justifyContent: "center",
      placeItems: "center",
      fontSize: "10px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "end",
      placeItems: "center",
      paddingRight: "8px",
    },
  }),
);

/* Shift Table header page. */
const ShiftHeader: React.FunctionComponent<ShiftHeaderProps> = ({
  nodeData,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  isLoading,
  locationSelectorList,
  userList,
  handleGetShiftReportInfo,
  shiftReportNode,
  filterDetails,
  isShiftSummaryReportLoading,
  locationSelectedList,
  handleOnClickText,
  numberOfLocations,
  deviceIdObj,
  deviceIdErr,
  isSort,
  headerName,
  sortingMethod,
  pageSize,
  idList,
  setIdList,
  selectedList,
  setSelectedList,
  isSelectAllShift,
  handleChangeCheckBox,
  setIsSelectAllShift
}) => {
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [nodes, setNodes] = useState<any>([]);

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            <div
              style={
                maxWidthLocationChips
                  ? {
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "8px",
                    }
                  : { marginLeft: "16px" }
              }
            >
              <ButtonCommon
                variant="contained"
                color="blue"
                style={
                  maxWidthLocationChips ? { width: "100%" } : { width: "140px" }
                }
                onClick={() => {
                  handleGetShiftReportInfo({
                    shiftId: idList,
                  });
                  setIsOpenModel(true);
                }}
                disabled={_.isEmpty(idList)}
              >
                Generate
              </ButtonCommon>
            </div>
          </div>
        </Grid>
      </Grid>
      {!maxWidth && (
        <div className={classes.headerContainerStyle}>
          <CardCommon backgroundColor={"table_header_background"}>
            <Box
              display="grid"
              gridTemplateColumns="repeat(18, 1fr)"
              style={{ minHeight: "36px", alignItems: "center" }}
            >
              {numberOfLocations > 1 && (
                <Box
                  gridColumn={
                    numberOfLocations > 1 && !deviceIdErr ? "span 3" : "span 4"
                  }
                >
                  <Grid item className={classes.headerFirstItemStyle}>
                  <Checkbox
                      checked={isSelectAllShift}
                      onChange={handleChangeCheckBox}
                      style={{padding: "0px 0px 0px 9px"}}
                    />
                    <SortingMethod
                      handleChangeOrderListSorting={
                        handleChangeOrderListSorting
                      }
                      handleRemoveOrderListSorting={
                        handleRemoveOrderListSorting
                      }
                      headerName={"Location"}
                      headerId={"locationId"}
                      disableButton={disableButton || _.isEmpty(nodeData)}
                      color={"orange"}
                      isSort={headerName === "userId" ? isSort : false}
                      sortingMethod={
                        headerName === "userId" ? sortingMethod : ""
                      }
                    />
                  </Grid>
                </Box>
              )}
              <Box gridColumn="span 2">
                <Grid
                  item
                  className={
                    numberOfLocations > 1
                      ? classes.headerItemStyle
                      : classes.headerFirstItemStyle
                  }
                >
                  {!(numberOfLocations > 1) && (
                    <Checkbox
                      checked={isSelectAllShift}
                      onChange={handleChangeCheckBox}
                      style={{padding: "0px 0px 0px 9px"}}
                    />
                  )}

                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Terminal"}
                    headerId={"deviceId"}
                    disableButton={disableButton || _.isEmpty(nodeData)}
                    color={"orange"}
                    isSort={headerName === "deviceId" ? isSort : false}
                    sortingMethod={
                      headerName === "deviceId" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
              {!deviceIdErr && (
                <Box gridColumn="span 2">
                  <Grid item className={classes.headerItemStyle}>
                    <SortingMethod
                      handleChangeOrderListSorting={
                        handleChangeOrderListSorting
                      }
                      handleRemoveOrderListSorting={
                        handleRemoveOrderListSorting
                      }
                      headerName={"TID"}
                      headerId={"TID"}
                      disableButton={true}
                      color={"orange"}
                      isSort={headerName === "TID" ? isSort : false}
                      sortingMethod={headerName === "TID" ? sortingMethod : ""}
                    />
                  </Grid>
                </Box>
              )}
              <Box gridColumn={numberOfLocations > 1 ? "span 2" : "span 3"}>
                <Grid item className={classes.headerItemStyle}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Start Date"}
                    headerId={"startDate"}
                    disableButton={disableButton || _.isEmpty(nodeData)}
                    color={"orange"}
                    isSort={headerName === "startDate" ? isSort : false}
                    sortingMethod={
                      headerName === "startDate" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
              <Box gridColumn="span 2">
                <Grid item className={classes.headerItemStyle}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Start Time"}
                    headerId={"startTime"}
                    disableButton={true}
                    color={"orange"}
                    isSort={headerName === "startTime" ? isSort : false}
                    sortingMethod={
                      headerName === "startTime" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
              <Box gridColumn={numberOfLocations > 1 ? "span 2" : "span 3"}>
                <Grid item className={classes.headerItemStyle}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"End Date"}
                    headerId={"endDate"}
                    disableButton={disableButton || _.isEmpty(nodeData)}
                    color={"orange"}
                    isSort={headerName === "endDate" ? isSort : false}
                    sortingMethod={
                      headerName === "endDate" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
              <Box gridColumn="span 2">
                <Grid item className={classes.headerItemStyle}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"End Time"}
                    headerId={"endTime"}
                    disableButton={true}
                    color={"orange"}
                    isSort={headerName === "endTime" ? isSort : false}
                    sortingMethod={
                      headerName === "endTime" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
              <Box
                gridColumn={
                  numberOfLocations > 1
                    ? !deviceIdErr
                      ? "span 3"
                      : "span 4"
                    : !deviceIdErr
                    ? "span 4"
                    : "span 6"
                }
              >
                <Grid item className={classes.headerItemStyleLastColumn}>
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Duration"}
                    headerId={"durationSort"}
                    disableButton={true}
                    color={"orange"}
                    isSort={headerName === "durationSort" ? isSort : false}
                    sortingMethod={
                      headerName === "durationSort" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Box>
            </Box>
          </CardCommon>
        </div>
      )}
      <Grid container className={classes.root}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <ShiftInfoNode
                nodeData={nodeData}
                locationSelectorList={locationSelectorList}
                setIdList={setIdList}
                idList={idList}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                numberOfLocations={numberOfLocations}
                deviceIdObj={deviceIdObj}
                deviceIdErr={deviceIdErr}
                setIsSelectAllShift={setIsSelectAllShift}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No shift available.
            </Typography>
          </div>
        )}
      </Grid>
      <Generate
        open={isOpenModel}
        handleCloseModal={() => setIsOpenModel(false)}
        shiftReportNode={shiftReportNode}
        filterDetails={filterDetails}
        isShiftSummaryReportLoading={isShiftSummaryReportLoading}
        selectedList={selectedList}
        userList={userList}
        setNodes={setNodes}
        nodes={nodes}
        deviceIdErr={deviceIdErr}
      />
    </>
  );
};

export default WithLoading(ShiftHeader);
