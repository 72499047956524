import React, { useEffect, useState } from "react";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { useRouteMatch } from "react-router";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import { Box, useTheme } from "@material-ui/core";
import _ from "lodash";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import { getCookie } from "../../../../utils/cookies";
import {
  paymentFilterObject,
  stockMomentFilterObject,
} from "../../../../utils/consts/list";
import PageHeader from "../../../../components/common/PageHeader/PageHeader";
import { CustomTheme } from "../../../../types/customTheme";
import SaleReport from "../../../../components/common/SaleReport";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import moment from "moment";
import DailySaleReportInfoNode from "./DailySaleReportInfoNode";
import { fetchAllDailySaleReportInfo } from "../../../../services/salesApp/dailySaleReportService";
import {
  convertDateFormat,
  convertDateToMoment,
  getEndDateDateMonthYear,
  getStartDateDateMonthYear,
} from "../../../../utils/ConvertDateTimeFormat";
import InventoryDetailsDefaultFilter from "../../../../components/common/InventoryDetails/InventoryDetailsDefaultFilter";
import { fetchAllStockMomentsReportInfo } from "../../../../services/inventory/stockMoments";
import { fetchStockItemInfoGetAll } from "../../../../services/inventory/stockItems";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

export interface paymentReportProps {}

type StockMovements = {
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

type DailySaleObject = {
  date: string;
  orders: number;
  subTotal: number;
  discount: number;
  total: number;
  cash: number;
  card: number;
  otherPayment: number;
  average: number;
  min: number;
  max: number;
};

/* Get the daily sale details using API call and get the location information */
const StockMovements: React.FunctionComponent<paymentReportProps> = () => {
  const [dailySaleReportNodeList, setDailySaleReportNodeList] = useState<any>(
    [],
  );
  const [previousDailySaleReportNodeList, setPreviousDailySaleReportNodeList] =
    useState<any>([]);
  const [error, setError] = useState("");
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const [basedOnShift, setBasedOnShift] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [
    stockItemGroupDepartmentNodeInSelector,
    setStockItemGroupDepartmentNodeInSelector,
  ] = useState<any>([]);
  const [
    stockItemGroupDepartmentNodeInSelectorInitial,
    setStockItemGroupDepartmentNodeInSelectorInitial,
  ] = useState<any>([]);
  const [searchName, setSearchName] = useState("");

  const match: any = useRouteMatch();
  const idToken = getCookie("idToken");

  /** This function retrieves the daily sales report data and
   * updates the state of the component with the updated report
   * The function accepts two parameters: filter and
   * basedOnShift which are used as query parameters when fetching the data
   */
  const getStockMomentsReportInfo = async (
    filter: any,
    basedOnShift: boolean,
  ) => {
    try {
      // This function calls the fetchAllStockMomentsReportInfo API to retrieve the data
      const res = await fetchAllStockMomentsReportInfo(
        idToken,
        match.params.locationId,
        filter,
        basedOnShift,
      );
      // If the response data is not empty, the function calls the handleDailyReportNotIncludeDate function to process the data
      if (!_.isEmpty(res.data)) {
        // The updated daily sale data is set to the dailySaleReportNodeList state
        setDailySaleReportNodeList(res.data);
      } else {
        // If the response data is empty, the dailySaleReportNodeList state is set to an empty array
        setDailySaleReportNodeList([]);
      }

      // The setIsLoading state is set to false to stop the loading spinner
      setIsLoading(false);
    } catch (err) {
      // If an error occurs, the setIsLoading state is set to false and the setError state is set to an error message
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Transforms the response data into a structured stock order item format.
   * @param {Array} response - The response data containing stock order items. format (item->department->group)
   * @returns {Array} - Transformed stock order item structure. format (group->department->item)
   */
  const handleStockOrderItemStructure = (response: any) => {
    // Initialize an empty array to store the transformed structure
    const transformedStructure = response.reduce(
      (accumulator: any, item: any) => {
        if (item?.stockDepartmentId?.stockGroupId?.id) {
          const stockGroupId = item.stockDepartmentId.stockGroupId.id;
          const existingGroup = accumulator?.find(
            (group: any) => group.id === stockGroupId,
          );

          if (existingGroup) {
            const existingDept = existingGroup.stockDepartmentId.find(
              (dept: any) => dept.id === item.stockDepartmentId.id,
            );

            if (existingDept) {
              existingDept.item.push({
                id: item.id,
                name: item.name,
                barcode: item.barcode,
                active: item.active,
                created: item.created,
                criticalThreshold: item.criticalThreshold,
                displaySuffix: item.displaySuffix,
                inputMultiplier: item.inputMultiplier,
                inputSuffix: item.inputSuffix,
                primaryLocationId: item.primaryLocationId,
                sku: item.sku,
                updated: item.updated,
                version: item.version,
                warningThreshold: item.warningThreshold,
                stockDepartmentId: item.stockDepartmentId.id,
              });
            } else {
              existingGroup.stockDepartmentId.push({
                id: item.stockDepartmentId.id,
                name: item.stockDepartmentId.name,
                locationId: item.stockDepartmentId.locationId,
                created: item.stockDepartmentId.created,
                updated: item.stockDepartmentId.updated,
                version: item.stockDepartmentId.version,
                item: [
                  {
                    id: item.id,
                    name: item.name,
                    barcode: item.barcode,
                    active: item.active,
                    created: item.created,
                    criticalThreshold: item.criticalThreshold,
                    displaySuffix: item.displaySuffix,
                    inputMultiplier: item.inputMultiplier,
                    inputSuffix: item.inputSuffix,
                    primaryLocationId: item.primaryLocationId,
                    sku: item.sku,
                    updated: item.updated,
                    version: item.version,
                    warningThreshold: item.warningThreshold,
                    stockDepartmentId: item.stockDepartmentId.id,
                  },
                ],
              });
            }
          } else {
            accumulator.push({
              id: item.stockDepartmentId.stockGroupId.id,
              name: item.stockDepartmentId.stockGroupId.name,
              version: item.stockDepartmentId.stockGroupId.version,
              updated: item.stockDepartmentId.stockGroupId.updated,
              created: item.stockDepartmentId.stockGroupId.created,
              locationId: item.stockDepartmentId.stockGroupId.locationId,
              stockDepartmentId: [
                {
                  id: item.stockDepartmentId.id,
                  name: item.stockDepartmentId.name,
                  locationId: item.stockDepartmentId.locationId,
                  created: item.stockDepartmentId.created,
                  updated: item.stockDepartmentId.updated,
                  version: item.stockDepartmentId.version,
                  item: [
                    {
                      id: item.id,
                      name: item.name,
                      barcode: item.barcode,
                      active: item.active,
                      created: item.created,
                      criticalThreshold: item.criticalThreshold,
                      displaySuffix: item.displaySuffix,
                      inputMultiplier: item.inputMultiplier,
                      inputSuffix: item.inputSuffix,
                      primaryLocationId: item.primaryLocationId,
                      sku: item.sku,
                      updated: item.updated,
                      version: item.version,
                      warningThreshold: item.warningThreshold,
                      stockDepartmentId: item.stockDepartmentId.id,
                    },
                  ],
                },
              ],
            });
          }
        }

        return accumulator;
      },
      [],
    );

    // Return the transformed structure
    return transformedStructure;
  };

  /**
   * Handles the search functionality for filtering stock item groups, departments, and items.
   *
   * @param {any} response - The search query entered by the user.
   */
  const handleChangeSearchUsingResponse = (response: any) => {
    // Filter and update the data based on the search response
    const filteredOutput = response
      .map((group: any) => ({
        ...group,
        stockDepartmentId: group.stockDepartmentId
          .map((dept: any) => ({
            ...dept,
            // Filter items within each department by name
            item: dept.item.filter((item: any) =>
              item.name.includes(searchName),
            ),
          }))
          // Remove departments with no matching items
          .filter((dept: any) => dept.item.length > 0),
      }))
      // Remove groups with no matching departments
      .filter((group: any) => group.stockDepartmentId.length > 0);

    // Update the state with the filtered data
    setStockItemGroupDepartmentNodeInSelector(filteredOutput);
  };

  /**
   * Fetch All Stock Departments for a Group (Without Loading Data)
   *
   * This function is responsible for fetching all stock departments for a specific group without loading data into
   * various states. It performs API requests to obtain the necessary information.
   *
   * @param {any} groupId - The identifier of the group for which departments are fetched.
   */
  const getAllStockDepartmentForGroupNotLoad = async () => {
    try {
      // Attempt to fetch stock items products information using the 'fetchAllStockItemsProductInfo' API
      const res = await fetchStockItemInfoGetAll(match.params.locationId);

      // Check if the API response contains data
      if (res.data.data) {
        // Transform the stock item data into a structured format
        const transformedStructure = handleStockOrderItemStructure(
          res.data.data,
        );

        // Set the transformed data into relevant states
        setStockItemGroupDepartmentNodeInSelector(transformedStructure);
        setStockItemGroupDepartmentNodeInSelectorInitial(transformedStructure);

        if (!_.isEmpty(searchName)) {
          handleChangeSearchUsingResponse(res.data.data);
        }
      }
    } catch (err) {
      // Set loading states to false in case of an error
      setIsLoading(false);

      // If an error occurs during the API call, set the 'error' state with an unexpected error message
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /** A function that fetches all filter locations based on searchName and updates the state
   * of locationList, locationData, locationSelectorList and isGetLocationInfo based on the response
   * */
  const getAllFilterLocation = async (searchName: any) => {
    fetchAllFilterLocations(searchName)
      .then((res) => {
        getAllStockDepartmentForGroupNotLoad();

        let locationList: any = [];
        /* Setting up the list of locations as needed to select locations. */
        if (!_.isEmpty(res.data.data)) {
          res.data.data.map((location: any) => {
            locationList.push({
              id: location.id,
              label: location.businessDisplayName,
            });
          });
          const locationName = getFilterListFromArrayObject(
            locationList,
            match.params.locationId,
          );
        }
        setLocationSelectorList(locationList);
        setIsGetLocationInfo(true);
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        getAllFilterLocation("");
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Inventory - Stock Movements Report";
    handleGetMasterLocations();
  }, []);

  // Get filter data
  const handleFilterData = (filterData: any, basedOnShift: boolean) => {
    setIsLoading(true);
    getStockMomentsReportInfo(filterData, basedOnShift);

    setFilterData(filterData);
    setBasedOnShift(basedOnShift);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  /**
   * Handles the search functionality for filtering stock item groups, departments, and items.
   *
   * @param {string} value - The search query entered by the user.
   */
  const handleChangeSearch = (value: string) => {
    // Create a deep clone of the initial data to work with
    const cloneStockItemGroupDepartmentNodeInSelector = _.cloneDeep(
      stockItemGroupDepartmentNodeInSelectorInitial,
    );

    // Update the search input value in the state
    setSearchName(value);

    // Check if the search value is not empty
    if (!_.isEmpty(value)) {
      // Filter and update the data based on the search value
      const filteredOutput = cloneStockItemGroupDepartmentNodeInSelector
        .map((group: any) => ({
          ...group,
          stockDepartmentId: group.stockDepartmentId
            .map((dept: any) => ({
              ...dept,
              // Filter items within each department by name
              item: dept.item.filter((item: any) =>
                item.name
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase()),
              ),
            }))
            // Remove departments with no matching items
            .filter((dept: any) => dept.item.length > 0),
        }))
        // Remove groups with no matching departments
        .filter((group: any) => group.stockDepartmentId.length > 0);

      // Update the state with the filtered data
      setStockItemGroupDepartmentNodeInSelector(filteredOutput);
    } else {
      // If the search value is empty, reset the data to the initial state
      setStockItemGroupDepartmentNodeInSelector(
        stockItemGroupDepartmentNodeInSelectorInitial,
      );
    }
  };

  const theme: CustomTheme = useTheme();
  return (
    <>
      <Box>
        <InventoryDetailsDefaultFilter
          handleFilterData={handleFilterData}
          locationSelectorList={locationSelectorList}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={stockMomentFilterObject}
          isOpenSkeletonLoading={isLoading}
          nodeList={dailySaleReportNodeList}
          topic="Stock Items"
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          filterDetails={filterDetails}
          isNeedButton={false}
          isActiveLocationId={isActiveLocationId}
        >
          <DailySaleReportInfoNode
            dailySaleReportNodeList={dailySaleReportNodeList}
            filterDetails={filterDetails}
            locationSelectedList={locationSelectedList}
            handleOnClickText={handleOnClickText}
            stockItemGroupDepartmentNodeInSelector={
              stockItemGroupDepartmentNodeInSelector
            }
            searchName={searchName}
            handleChangeSearch={handleChangeSearch}
          />
        </InventoryDetailsDefaultFilter>

        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockMovements, Authorities.INVENTORY_MOMENT_READ);
