import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";
import SwitchCommon from "../../../../components/switches/SwitchCommon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeTextStyle: {
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  }),
);

export interface CreateNewNodeProps {
  backgroundColor: any;
  handleOrderingSwitchActivate: any;
  isActive: any;
  title: any;
  subTitle: any;
  activeButtonLabel: any;
}

/* Show the dashboard card and change the card background color, and change the card title. */
const DashboardCard: React.SFC<CreateNewNodeProps> = ({
  backgroundColor,
  handleOrderingSwitchActivate,
  isActive,
  title,
  subTitle,
  activeButtonLabel,
}) => {
  /* Change the card background color */
  const handleBackgroundColor = () => {
    // If the value of backgroundColor is 'true', the card change to green.
    if (backgroundColor) {
      return "green";
    } else {
      // If the value of backgroundColor is 'false', the card change to red.
      return "red";
    }
  };

  /* Change the card title. */
  const handleTitle = () => {
    // If the value of isActive is 'true', 'Enabled' is inserted at the end of the header.
    if (isActive) {
      return `${title} Enabled`;
    } else {
      // If the value of isActive is 'false', 'Disabled' is inserted at the end of the header.
      return `${title} Disabled`;
    }
  };

  const classes = useStyles();

  return (
    <>
      <CardCommon color={handleBackgroundColor()}>
        <Typography
          variant="h6"
          align="left"
          style={{ margin: "16px 16px 0px 16px", color: "white" }}
        >
          {handleTitle()}
        </Typography>
        <Typography
          variant="body1"
          align="left"
          style={{ margin: "0px 16px 0px 16px", color: "white" }}
        >
          {subTitle}
        </Typography>
        <div style={{ margin: "16px", marginTop: "40px" }}>
          <CardCommon backgroundColor={"entity_highlight_background"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
                alignItems: "center",
              }}
            >
              <Typography className={classes.activeTextStyle}>
                {activeButtonLabel}
              </Typography>
              <FormControlLabel
                control={<SwitchCommon />}
                checked={isActive}
                onChange={handleOrderingSwitchActivate}
                label=""
                labelPlacement="start"
              />
            </div>
          </CardCommon>
        </div>
      </CardCommon>
    </>
  );
};

export default withAuthority(DashboardCard, Authorities.DASHBOARD_READ);
