import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Theme } from "../../../../types/customTheme";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import moment from "moment";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";
import StockItemDetailsType from "./StockItemDetailsType";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AddIcon from "@material-ui/icons/Add";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      width: "10px",
      height: "10px,",
      backgroundColor: theme.palette.custom["blue"].main,
      "&:hover": {
        backgroundColor: theme.palette.custom["blue"].dark,
      },
      color: "white",
    },
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      minHeight: "36px",
      textTransform: "none",
      paddingLeft: "14px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "4px",
      marginBottom: "4px",
      alignItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      paddingRight: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldLastColumnDisplayView: {
      display: "flex",
      alignItems: "center",
      minHeight: "36px",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginBottom: "16px",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      textTransform: "none",
      minHeight: "36px",
      paddingLeft: "14px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
        paddingLeft: "0px",
      },
    },
    detailsDropDownIcon: {
      color: theme.palette.custom.orange.contrastText,
    },
    detailsDropDownIconSelected: {
      color: theme.palette.custom.green.contrastText,
    },
    editIcon: { fontSize: "24px", color: "black" },
    editIconStyle: {
      paddingRight: "12px",
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0px",
        marginBottom: "12px",
      },
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
  handleOpenEdtSupplierToTheStockItems: any;
  departmentName: any;
  registerElementWithTitle: any;
  groupName: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const StockItemInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
  handleOpenEdtSupplierToTheStockItems,
  departmentName,
  registerElementWithTitle,
  groupName,
}) => {
  const [updateTime, setUpdateTime] = useState("");
  const [inputMultiplier, setInputMultiplier] = useState("");
  const [barcode, setBarcode] = useState("");
  const [name, setName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [sku, setSku] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [openOrderDetailsCard, setOpenOrderDetailsCard] = useState(false);
  const [stockId, setStockId] = useState("");

  /* Update states after getting the order information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      id,
      created,
      inputMultiplier,
      barcode,
      updated,
      primaryLocationId,
      name,
      sku,
    } = nodeValues;
    setStockId(id);
    setSku(sku);
    setName(name);
    setBarcode(barcode);
    setInputMultiplier(inputMultiplier);
    setCreatedTime(moment(created).format("MMM D, YYYY HH:mm"));
    setUpdateTime(moment(updated).format("MMM D, YYYY HH:mm"));
    // Get the location name using locationId
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      primaryLocationId,
    );
    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
  }, []);

  const handleChangeRowColor = () => {
    if (openOrderDetailsCard) {
      return "green";
    }
  };

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const maxWidth = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();

  return (
    <>
      <CardCommon
        color={handleChangeRowColor()}
        backgroundColor={"entity_background"}
      >
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px", zIndex: 0 }}
          onClick={() => handleOpenEditStockItemsModal(nodeData)}
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridFieldFirstColumn}
            >
              {groupName}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {departmentName.name}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {name}
            </Grid>
            {maxWidth ? (
              sku && (
                <Grid
                  style={{ textAlign: "left" }}
                  item
                  xs={12}
                  md={2}
                  className={classes.gridField}
                >
                  {sku}
                </Grid>
              )
            ) : (
              <Grid
                style={{ textAlign: "left" }}
                item
                xs={12}
                md={2}
                className={classes.gridField}
              >
                {sku}
              </Grid>
            )}
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              md={2}
              className={classes.gridField}
            >
              {barcode}
            </Grid>

            <Grid item xs={12} md={2} className={classes.gridFieldLastColumn}>
              <div className={classes.gridFieldLastColumnDisplayView}>
                <div className={classes.editIconStyle}>
                  <Tooltip title="Update Stock Item">
                    <div>
                      <ButtonCommon
                        onClick={() => handleOpenEditStockItemsModal(nodeData)}
                        color="yellow"
                      >
                        <Icon className={classes.editIcon} icon="mdi:cog" />
                      </ButtonCommon>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Add Map Stock">
                    <div>
                      <Button
                        onClick={(event: any) =>
                          handleOpenEditMapProductsItemsModal(nodeData, event)
                        }
                        className={classes.root}
                        style={{ padding: "6px", zIndex: 4 }}
                        // color="blue"
                      >
                        <ListIcon
                          style={{ fontSize: "28px", color: "white" }}
                        />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {openOrderDetailsCard && (
        <StockItemDetailsType
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenEditStockItemsModal={handleOpenEditStockItemsModal}
          handleOpenEditMapProductsItemsModal={
            handleOpenEditMapProductsItemsModal
          }
        />
      )}
    </>
  );
};

export default withAuthority(StockItemInfoNode, Authorities.INVENTORY_ADMIN_READ);
