import axios from "axios";
import _ from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import { getCookie } from "../../utils/cookies";
import { refreshAccessToken } from "../accountApp/authService";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const options = {
  baseUrl: baseUrl,
};

export const axiosClient = axios.create(options);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        try {
          const refreshToken = getCookie("refreshToken");
          const { id_token } = await refreshAccessToken(refreshToken);
          if (!_.isEmpty(id_token)) {
            let updatedConfig = {
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${id_token}`,
              },
            };
            return axios(updatedConfig);
          } else {
            return (window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`);
          }
        } catch (error) {
          return (
            <Redirect
              to={`${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`}
            />
          );
        }
      }
    } else {
      // redirect to login
    }
    return Promise.reject(error);
  },
);

axiosClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (config.url.includes("securetoken.googleapis.com/v1/token")) {
      return config;
    }
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getCookie("idToken")}`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
