import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Box } from "@material-ui/core";
import _ from "lodash";

import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import withAuthority from "../../../../components/Auth/withAuthority";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { fetchAllFilterLocations } from "../../../../services/locationApp/locationFilterService";
import Pagination from "../../../../components/common/Pagination";
import { fetchAllStockOrdersInfo } from "../../../../services/inventory/stockOrder";
import StockOrdersHeader from "./StockOrdersHeader";
import AddStockOrderModal from "./AddStockOrderModal";
import AddStockOrderItemModal from "./AddStockOrderItemModal";
import { fetchAllSupplierInfo } from "../../../../services/inventory/supplier";
import { stockOrderFilterObject } from "../../../../utils/consts/list";
import { getLocationBasicInfo } from "../../../../services/locationApp/storeService";
import EditDeliveryStockOrderItem from "./EditDeliveryStockOrderItem";
import Toast from "../../../../components/alerts/Toast";
import InventoryDetailsDefaultFilter from "../../../../components/common/InventoryDetails/InventoryDetailsDefaultFilter";
import { fetchStockLevelInfoGetAll } from "../../../../services/inventory/stockLevel";
import { fetchAllMasterLocations } from "../../../../services/inventory/masterLocations";
import { getIsAuthorized } from "../../../../utils/authorities";

interface Location {
  id: string;
  businessDisplayName: string;
}

/**
 * This component manages the display of stock order information and related UI components.
 * It fetches data from APIs, handles user interactions, and renders various UI elements.
 * The component includes features such as sorting, pagination, and filtering of stock orders.
 * Additionally, it provides the ability to create and edit stock order items through modal dialogs.
 * Access control is enforced using the 'withAuthority' higher-order component.
 */
const StockOrders: React.FunctionComponent = () => {
  const [stockOrdersNodeList, setStockOrdersNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [headerName, setHeaderName] = useState("created");
  const [sortingMethod, setSortingMethod] = useState("desc");
  const [disableButton, setDisableButton] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationSelectorList, setLocationSelectorList] = useState([]);
  const [isSort, setIsSort] = useState(false);
  const [isOpenSkeletonLoading, setIsOpenSkeletonLoading] = useState(true);
  const [isOpenCreateStockOrderModal, setIsOpenCreateStockOrderModal] =
    useState(false);
  const [supplierId, setSupplierId] = useState("");
  const [success, setSuccess] = useState("");
  const [orderId, setOrderId] = useState("");
  const [isOpenCreateStockOrderItemModal, setIsOpenCreateStockOrderItemModal] =
    useState(false);
  const [isOpenEditDeliveryDetailsModal, setIsOpenEditDeliveryDetailsModal] =
    useState(false);
  const [supplierNode, setSupplierNode] = useState<any>([]);
  const [isGetLocationInfo, setIsGetLocationInfo] = useState(false);
  const [filterDetails, setFilterDetails] = useState("");
  const [locationSelectedList, setLocationSelectedList] = useState([]);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const [selectedStockOrderDetails, setSelectedStockOrderDetails] =
    useState<any>([]);
  const [locationData, setLocationData] = useState<any>([]);
  const [isOpenDeliveryDetailsModal, setIsOpenDeliveryDetailsModal] =
    useState(false);
  const [deliveryDetailsNode, setDeliveryDetailsNode] = useState<any>([]);
  const [isDeliver, setIsDelivery] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [isLoadingToast, setIsLoadingToast] = useState(false);
  const [stockLevelNode, setStockLevelNode] = useState<any>({});
  const [isActiveLocationId, setIsActiveLocationId] = useState(true);

  const match: any = useRouteMatch();

  /**
   * Function to fetch stock level information for a specific location and set it in the component's state.
   */
  const getAllStockLevel = async () => {
    try {
      // Fetch stock level information for the specified location
      const res = await fetchStockLevelInfoGetAll(match.params.locationId);

      const output: any = {};

      if (res.data.data) {
        res.data.data.forEach((item: any) => {
          const { id, stockLevel, stockItemId } = item;
          const stockItemIdKey = stockItemId.id;

          output[stockItemIdKey] = {
            stockLevel,
            criticalThreshold: stockItemId.criticalThreshold,
            warningThreshold: stockItemId.warningThreshold,
          };
        });
        // Set the fetched stock level data in the component's state
        setStockLevelNode(output);
      } else {
        setStockLevelNode({});
      }
    } catch (err) {
      // Handle errors if they occur during the API call
      setIsOpenSkeletonLoading(false);
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Function to fetch stock orders information using API call and update component states.
   * @param {number} pageSize - Number of items to display per page.
   * @param {number} currentPage - Current page number.
   * @param {string} sortingMethod - Sorting method for the items.
   * @param {string} headerName - Name of the header to sort by.
   */
  const getStockOrderInfo = async (
    pageSize: number,
    currentPage: number,
    sortingMethod: string,
    headerName: string,
  ) => {
    // Set the pageSize and currentPage states for pagination
    setPageSize(pageSize);
    setCurrentPage(currentPage);

    try {
      // Attempt to fetch stock orders information using the 'fetchAllStockOrdersInfo' API
      const res = await fetchAllStockOrdersInfo(
        match.params.locationId,
        pageSize,
        currentPage,
        sortingMethod,
        headerName,
      );
      // Update component states with the received data from the API response
      // Set loading state to false
      setIsLoading(false);
      // Set stock orders data
      setStockOrdersNodeList(res.data.data);
      // Set total number of pages
      setTotalPages(res.data.totalPages);
      // Disable skeleton loading
      setIsOpenSkeletonLoading(false);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to fetch all supplier information for the specified location.
   * It makes an asynchronous API call to fetch supplier data and updates the component state.
   */
  const getAllSupplierInfo = async () => {
    try {
      // Attempt to fetch supplier information using the 'fetchAllSupplierInfo' API
      const res = await fetchAllSupplierInfo(match.params.locationId);

      // Update the 'supplierNode' state with the received supplier data from the API response
      setSupplierNode(res.data.data);

      // After successfully fetching supplier data, trigger the function to fetch location information
      // with an empty searchName (getAllFilterLocation function).
      getAllFilterLocation("");
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state to display an error message,
      // and disable loading and skeleton loading states.
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  const handleGetMasterLocations = async () => {
    try {
      const res = await fetchAllMasterLocations(match.params.locationId);
      if (
        (res.data && res.data.data && res.data.data.active) ||
        getIsAuthorized(Authorities.STOCK_CONFIGURATION_READ)
      ) {
        // Fetch location information with an empty searchName
        getAllSupplierInfo();

        fetchBasicInfo();

        getAllStockLevel();
        setIsActiveLocationId(true);
      } else {
        setIsActiveLocationId(false);
        setIsLoading(false);
        setIsOpenSkeletonLoading(false);
      }
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state and disable loading
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Effect to set the document title and fetch location information on component mount.
   */
  useEffect(() => {
    // Set the document title to "Eat Presto - Stock Order"
    document.title = "Inventory - Stock Orders";

    handleGetMasterLocations();
  }, []);

  /**
   * Function to fetch location information and initialize stock orders loading.
   * @param {string} searchName - Name to search for locations.
   */
  const getAllFilterLocation = async (searchName: string) => {
    try {
      // Attempt to fetch location information using the 'fetchAllFilterLocations' API
      const res = await fetchAllFilterLocations(searchName);

      // Initialize an array to hold location data
      let locationList: any = [];

      // Check if location data is not empty in the response
      if (!_.isEmpty(res.data.data)) {
        // Iterate through each location in the response data
        res.data.data.forEach((locationData: Location) => {
          // Push an object with correct property name and label properties to the locationList array
          locationList.push({
            id: locationData.id, // Use the correct property name 'locationId'
            label: locationData.businessDisplayName,
          });
        });
      }
      // Update the 'locationSelectorList' state with the fetched location list
      setLocationSelectorList(locationList);

      // Trigger the function to fetch stock orders information with appropriate parameters
      getStockOrderInfo(pageSize, 1, "desc", "created");

      setIsGetLocationInfo(true);
    } catch (error) {
      // If an error occurs during the API call, set the 'error' state
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
      setIsOpenSkeletonLoading(false);
    }
  };

  /**
   * Function to handle pagination change using selector.
   * @param {any} pageSize - Number of items to display per page.
   */
  const handleChangePaginationUsingSelector = (pageSize: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);
    // If headerName is provided, fetch stock orders information with sorting and filtering
    getStockOrderInfo(pageSize, 1, sortingMethod, headerName);
  };

  /**
   * Function to handle pagination change using page number.
   * @param {any} currentPage - The selected page number.
   */
  const handleChangePaginationUsingPageNumber = (currentPage: any) => {
    // Set the flag to indicate that skeleton loading is in progress
    setIsOpenSkeletonLoading(true);

    // Fetch stock orders information with the created pagination parameters
    getStockOrderInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to handle sorting of stock orders list.
   * @param {any} headerName - The selected header name for sorting.
   * @param {any} sortingMethod - The selected sorting method.
   */
  const handleChangeStockGroupsListSorting = (
    headerName: any,
    sortingMethod: any,
  ) => {
    // Set the 'disableButton' state to true to disable the sorting button temporarily
    setDisableButton(true);

    setIsSort(true);

    // Set the 'headerName' state with the selected header name
    setHeaderName(headerName);

    // Set the 'sortingMethod' state with the selected sorting method
    setSortingMethod(sortingMethod);

    // Fetch stock orders information with the created sorting parameters
    getStockOrderInfo(pageSize, currentPage, sortingMethod, headerName);
  };

  /**
   * Function to remove sorting from the stock orders list.
   */
  const handleRemoveStockGroupsListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock orders information without sorting
    getStockOrderInfo(pageSize, currentPage, "desc", "created");
  };

  /**
   * Function to remove sorting from the stock orders list.
   */
  const handleGetStockOrdersListSorting = () => {
    // Set the 'disableButton' state to false to enable the sorting button
    setDisableButton(false);

    setIsSort(false);

    // Clear the 'headerName' state to remove sorting by header
    setHeaderName("");

    // Clear the 'sortingMethod' state to remove sorting method
    setSortingMethod("");

    // Fetch stock orders information without sorting
    getStockOrderInfo(pageSize, currentPage, "desc", "created");
  };

  /**
   * Function to handle opening the create stock orders modal.
   */
  const handleOpenCreateStockOrderModal = () => {
    // Open the 'isOpenCreateStockOrderModal'
    setIsOpenCreateStockOrderModal(true);
  };

  /**
   * Function to handle opening the "Add Stock Order Item" modal.
   * @param {string} orderId - The ID of the stock order.
   * @param {string} supplierId - The ID of the supplier.
   * @param {any} nodeData - Additional data related to the stock order.
   * @param {React.MouseEvent<HTMLLIElement>} event - The click event that triggered the function.
   */
  const handleOpenAddStockOrderItemModal = (
    orderId: string,
    supplierId: string,
    nodeData: any,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event was provided and stop its propagation to prevent further actions
    if (event) {
      event.stopPropagation();
    }

    // Set the selected stock order details using the provided 'nodeData'
    setSelectedStockOrderDetails(nodeData);

    // Set the 'orderId' state with the provided 'orderId'
    setOrderId(orderId);

    // Set the 'supplierId' state with the provided 'supplierId'
    setSupplierId(supplierId);

    // Open the "Add Stock Order Item" modal by setting 'isOpenCreateStockOrderItemModal' to true
    setIsOpenCreateStockOrderItemModal(true);
  };

  /**
   * Function to fetch basic location information.
   */
  const fetchBasicInfo = async () => {
    try {
      // Attempt to fetch location basic information using the 'getLocationBasicInfo' API
      const res = await getLocationBasicInfo(match.params.locationId);

      // Update the 'locationData' state with the fetched data from the API response
      setLocationData(res.data.data);
    } catch (error) {}
  };

  const handleFilterData = () => {};

  /*Get location list API call after typing. */
  const handleLocationSelectorTypingList = (searchName: any) => {};

  /**
   * Function to handle opening the stock delivery item modal.
   * @param {string} orderId - The ID of the stock order.
   * @param {string} supplierId - The ID of the supplier.
   * @param {any} nodeData - The data associated with the selected item.
   * @param {React.MouseEvent<HTMLLIElement>} event - The click event triggering the function.
   */
  const handleOpenStockDeliveryItemModal = (
    orderId: string,
    supplierId: string,
    nodeData: any,
    event: React.MouseEvent<HTMLLIElement>,
  ) => {
    // Check if an event object exists and prevent its propagation (if available)
    if (event) {
      event.stopPropagation();
    }

    // Set selected stock order details based on the provided data
    setSelectedStockOrderDetails(nodeData);

    // Set the order ID and supplier ID for the modal
    setOrderId(orderId);
    setSupplierId(supplierId);

    // Open the stock delivery item modal
    setIsOpenEditDeliveryDetailsModal(true);
  };

  /**
   * Function to handle opening the delivery details modal.
   * @param {any} item - The delivery item details.
   * @param {any} isDelivery - Indicates whether it's a delivery (true) or not (false).
   */
  const handleOpenDeliveryDetailsModal = (item: any, isDelivery: any) => {
    // Set the delivery details node with the provided item data
    setDeliveryDetailsNode(item);

    // Set the 'isDelivery' state to indicate whether it's a delivery
    setIsDelivery(isDelivery);

    // Open the delivery details modal
    setIsOpenDeliveryDetailsModal(true);
  };

  const handleOnClickText = () => {
    setOpenFilterCard(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Toast message={toastMessage} type={toastType} loading={isLoadingToast} />
      {/* Main container */}
      <Box>
        {/* InventoryDetails component */}
        <InventoryDetailsDefaultFilter
          locationSelectorList={locationSelectorList}
          isOpenSkeletonLoading={isOpenSkeletonLoading}
          nodeList={stockOrdersNodeList}
          topic="Stock Orders"
          handleOpenCreateModal={handleOpenCreateStockOrderModal}
          buttonName="Create a new order"
          handleFilterData={handleFilterData}
          handleLocationSelectorTypingList={handleLocationSelectorTypingList}
          isGetLocationInfo={isGetLocationInfo}
          setFilterDetails={setFilterDetails}
          availableFilter={stockOrderFilterObject}
          locationSelectedList={locationSelectedList}
          setLocationSelectedList={setLocationSelectedList}
          openFilterCard={openFilterCard}
          setOpenFilterCard={setOpenFilterCard}
          handleOnClickText={handleOnClickText}
          filterDetails={filterDetails}
          isNeedButton={true}
          isActiveLocationId={isActiveLocationId}
        >
          {/* StockOrders table component */}
          <StockOrdersHeader
            handleChangeOrderListSorting={handleChangeStockGroupsListSorting}
            handleRemoveOrderListSorting={handleRemoveStockGroupsListSorting}
            nodeData={stockOrdersNodeList}
            disableButton={disableButton}
            isLoading={isLoading}
            handleOpenAddStockOrderItemModal={handleOpenAddStockOrderItemModal}
            isSort={isSort}
            headerName={headerName}
            sortingMethod={sortingMethod}
            supplierNode={supplierNode}
            openFilterCard={openFilterCard}
            handleOpenStockDeliveryItemModal={handleOpenStockDeliveryItemModal}
          />

          {isOpenCreateStockOrderItemModal && (
            <AddStockOrderItemModal
              isOpenCreateStockOrderModal={isOpenCreateStockOrderItemModal}
              setIsOpenCreateStockOrderModal={
                setIsOpenCreateStockOrderItemModal
              }
              setSuccess={setSuccess}
              setError={setError}
              handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
              supplierId={supplierId}
              orderId={orderId}
              selectedStockOrderDetails={selectedStockOrderDetails}
              locationData={locationData}
              setIsLoadingToast={setIsLoadingToast}
              setToastType={setToastType}
              setToastMessage={setToastMessage}
              isLoadingToast={isLoadingToast}
              stockLevelNode={stockLevelNode}
            />
          )}

          {isOpenEditDeliveryDetailsModal && (
            <EditDeliveryStockOrderItem
              isOpenEditDeliveryDetailsModal={isOpenEditDeliveryDetailsModal}
              setIsOpenEditDeliveryDetailsModal={
                setIsOpenEditDeliveryDetailsModal
              }
              setSuccess={setSuccess}
              setError={setError}
              handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
              supplierId={supplierId}
              orderId={orderId}
              selectedStockOrderDetails={selectedStockOrderDetails}
              handleOpenDeliveryDetailsModal={handleOpenDeliveryDetailsModal}
              isOpenDeliveryDetailsModal={isOpenDeliveryDetailsModal}
              setIsOpenDeliveryDetailsModal={setIsOpenDeliveryDetailsModal}
              deliveryDetailsNode={deliveryDetailsNode}
              isDeliver={isDeliver}
              isLoadingToast={isLoadingToast}
              setIsLoadingToast={setIsLoadingToast}
              setToastType={setToastType}
              setToastMessage={setToastMessage}
            />
          )}

          {/* Render Pagination if stockOrdersNodeList is not empty */}
          {!_.isEmpty(stockOrdersNodeList) && (
            <Pagination
              handleChangePaginationUsingSelector={
                handleChangePaginationUsingSelector
              }
              handleChangePaginationUsingPageNumber={
                handleChangePaginationUsingPageNumber
              }
              totalPages={totalPages}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
        </InventoryDetailsDefaultFilter>
        {isOpenCreateStockOrderModal && (
          <AddStockOrderModal
            isOpenCreateStockOrderModal={isOpenCreateStockOrderModal}
            setIsOpenCreateStockOrderModal={setIsOpenCreateStockOrderModal}
            handleOpenAddStockOrderItemModal={handleOpenAddStockOrderItemModal}
            setError={setError}
            handleGetStockOrdersListSorting={handleGetStockOrdersListSorting}
            isLoadingToast={isLoadingToast}
            setIsLoadingToast={setIsLoadingToast}
            setToastType={setToastType}
            setToastMessage={setToastMessage}
          />
        )}
        {/* DefaultAlert components for success and error messages */}
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity="error"
        />
      </Box>
    </>
  );
};

export default withAuthority(StockOrders, Authorities.INVENTORY_READ);
