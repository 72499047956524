import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { CustomTheme } from "../../types/customTheme";
import { makeStyles, useTheme } from "@material-ui/core";

export interface DialogCommonDefaultProps {
  open: boolean;
  setOpen: any;
  children: any;
  isNeedFixedHeight: any;
  backgroundColor?: any;
  maxWidth?: any;
}

/* Common modal UI.. */
const DialogCommonDefault: React.FunctionComponent<
  DialogCommonDefaultProps
> = ({
  open,
  setOpen,
  children,
  isNeedFixedHeight,
  backgroundColor,
  maxWidth,
}) => {
  const theme: CustomTheme = useTheme();
  
  return (
    <Dialog
      PaperProps={{
        style: isNeedFixedHeight
          ? {
              borderRadius: 10,
              border: `1px solid ${theme.palette.background.entity_border}`,
              backgroundColor: backgroundColor
                ? backgroundColor
                : theme.palette.background.backgroundColor,
              width: "100%",
              height: "100%",
            }
          : {
              borderRadius: 10,
              border: `1px solid ${theme.palette.background.entity_border}`,
              backgroundColor: backgroundColor
                ? backgroundColor
                : theme.palette.background.backgroundColor,
              width: "100%",
            },
      }}
      style={{ width: "100%" }}
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick
    >
      {children}
    </Dialog>
  );
};

export default DialogCommonDefault;
