import {
  Box,
  createStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import AppHeaderBar from "../../components/AppHeaderBar";
import SideBar from "../../components/SideBar";
import { useLocation, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import MobileLayout from "../MobileLayout";
import _ from "lodash";
import { getLocalStore, setLocalStore } from "../../utils/store/localStore";
import AlertDialog from "../../components/alerts/AlertDialog";
import { getCookie } from "../../utils/cookies";
import { fetchAllLocations } from "../../services/locationApp/locationService";
import { getFilterListFromArrayObject } from "../../utils/commonArrayMap";
import { handleChangeRestaurant } from "../../utils";

const drawerWidth = 248;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    contentSection: {
      zIndex: theme.zIndex.drawer + 1,
      marginLeft: 58,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentSectionShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentBox: {
      marginTop: 48,
    },
  }),
);

interface PrimaryLayoutProps {
  children: any;
  sidebarRoutes: Array<any>;
  handleChangeSidebar?: any;
  seIsOpenDesktopSideBar?: any;
  isNedSidebarOpen?: any;
}

/* This component show the app bar. */
const PrimaryLayout: React.FunctionComponent<PrimaryLayoutProps> = ({
  children,
  sidebarRoutes,
  handleChangeSidebar,
  seIsOpenDesktopSideBar,
  isNedSidebarOpen,
}) => {
  const [openMobileSideBar, setOpenMobileSideBar] = useState(false);
  const [openDesktopSideBar, setOpenDesktopSideBar] = useState(true);

  const classes = useStyles();
  const theme = useTheme();
  const search = useLocation().search;
  const appName = new URLSearchParams(search).get("app");
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const match: any = useRouteMatch();

  // Define a function to get the local storage value as a boolean
  const getLocalStorageBoolean = (key: any, defaultValue: any) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue;
    }
    return storedValue === "true";
  };

  useEffect(() => {
    // Get the stored value of "isOpenSlideBar" from local storage
    const isOpenSlideBar = getLocalStorageBoolean("isOpenSlideBar", true);

    // Set the state based on the stored value
    setOpenDesktopSideBar(isOpenSlideBar);
    if (isNedSidebarOpen) {
      seIsOpenDesktopSideBar(isOpenSlideBar);
    }
  }, []);

  // useEffect(() => {
  //   if (getLocalStore("isOpenSlideBar")) {
  //     if (
  //       getLocalStore("isOpenSlideBar") === true ||
  //       getLocalStore("isOpenSlideBar") === "true"
  //     ) {
  //       setOpenDesktopSideBar(true);
  //       if (isNedSidebarOpen) {
  //         seIsOpenDesktopSideBar(true);
  //       }
  //     } else {
  //       setOpenDesktopSideBar(false);
  //       if (isNedSidebarOpen) {
  //         seIsOpenDesktopSideBar(false);
  //       }
  //     }
  //   } else {
  //     setLocalStore("isOpenSlideBar", true);
  //     setOpenDesktopSideBar(true);
  //     if (isNedSidebarOpen) {
  //       seIsOpenDesktopSideBar(true);
  //     }
  //   }
  // }, []);

  /* Determining the appbar to open according to the screen size. */
  const handleOpenAppBar = (e: any) => {
    // If the screen size is smaller than the tablet size, the mobile view is displayed.
    if (matches) {
      setOpenMobileSideBar(e);
    } else {
      // If the screen size is greater than the tablet size, the desktop view is displayed.
      setOpenDesktopSideBar(e);
      if (isNedSidebarOpen) {
        seIsOpenDesktopSideBar(e);
      }
      setLocalStore("isOpenSlideBar", Boolean(e));
    }
  };

  return (
    <div>
      <AppHeaderBar
        open={matches ? openMobileSideBar : openDesktopSideBar}
        setOpen={(e: any) => handleOpenAppBar(e)}
        handleChangeSidebar={handleChangeSidebar}
      />

      {!window.location.origin.includes(
        process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "",
      ) ||
      window.location.origin.includes(
        process.env.REACT_APP_LOCAL_HOST || "",
      ) ? (
        matches ? (
          <MobileLayout
            open={openMobileSideBar}
            setOpen={(e: any) => handleOpenAppBar(e)}
            sidebarRoutes={sidebarRoutes}
          >
            <Box
              className={classes.contentBox}
              p={isNedSidebarOpen && _.isEmpty(appName) ? 0 : 1}
            >
              {children}
            </Box>
          </MobileLayout>
        ) : (
          <div
            style={{ zIndex: 0 }}
            className={clsx(
              classes.root,
              _.isEmpty(appName)
                ? openDesktopSideBar
                  ? classes.contentSectionShift
                  : classes.contentSection
                : null,
            )}
          >
            {_.isEmpty(appName) && !!sidebarRoutes.length && (
              <SideBar
                navRoutes={sidebarRoutes}
                open={openDesktopSideBar}
                drawerWidth={drawerWidth}
                setOpen={setOpenDesktopSideBar}
              />
            )}
            <Box p={isNedSidebarOpen && _.isEmpty(appName) ? 0 : 2} pl={4}>
              {children}
            </Box>
          </div>
        )
      ) : (
        <Box>{children}</Box>
      )}
    </div>
  );
};

// 1. common buttons, check box, radio etc accross
// 2. loading inside button
// 3. color code per each action -save / dark mode /light mode
// 4. delivery info / business info cards - two columns or 3 column

export default PrimaryLayout;
