import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import { useLocation } from "react-router";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  createStyles,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Icon } from "@iconify/react";
import {
  HeaderCellSort,
  useSort,
} from "@table-library/react-table-library/sort";
import { CustomTheme } from "../../../../types/customTheme";
import "jspdf-autotable";
import CardCommon from "../../../../components/card/CardCommon";
import MenuButtonCommon from "../../../../components/buttons/MenuButtonCommon";
import { sortDates, sortNumbers } from "../../../../utils/commonArrayMap";
import { handleDownloadPdf } from "./convertPdfExcel/convertDataToPdf";
import { handleDownloadCsv } from "./convertPdfExcel/convertDataToExcel";
import moment from "moment";
import { convertDateFormat } from "../../../../utils/ConvertDateTimeFormat";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface VoidSummeryInfoNodeProps {
  dailySaleReportNodeList: any;
  filterDetails: any;
  min: any;
  max: any;
  previousDailySaleReportNodeList: any;
  diffDate: number;
  locationSelectedList: any;
  handleOnClickText: any;
}

const useStyles = (bodyLength: number) =>
  makeStyles((theme: CustomTheme) =>
    createStyles({
      startTextStyle: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        paddingLeft: "12px",
        fontSize: "12px",
      },
      tooltipBackgroundColor: {
        color: "white",
      },
      startTextStyleHeader: {
        fontWeight: "bold",
        display: "flex",
        justifyContent: "start",
        fontSize: "12px",
      },
      textStyleHeader: {
        display: "flex",
        justifyContent: "start",
        fontWeight: "bold",
        fontSize: "12px",
      },
      textStyle: {
        display: "flex",
        justifyContent: "start",
        fontSize: "12px",
      },
      textStyleEnd: {
        display: "flex",
        justifyContent: "end",
        fontSize: "12px",
      },
      headerStyle: {
        "&:last-of-type": {
          backgroundColor: theme.palette.background.table_header_background,
          color: theme.palette.custom.orange.contrastText,
        },
      },
      finalTotalRow: {
        cursor: "pointer",
        height: "40px",
        [`&:nth-of-type(${bodyLength})`]: {
          color: theme.palette.custom["green"].contrastText,
          backgroundColor: theme.palette.custom.green.main,
          fontWeight: "bold",
        },
      },
      headerRow: {
        fontSize: "13px",
        cursor: "pointer",
        "&.css-16gtl2w": {
          display: "flex",
          justifyContent: "start",
        },
        "&:nth-of-type(odd)": {
          backgroundColor: theme.palette.background.paper,
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.background.entity_background,
        },
      },
      baseCellRow: {
        placeItems: "start",
        "& .css-16gtl2w": {
          fontWeight: "bold",
        },
        height: "40px",
        "&:nth-of-type(1)": {
          left: "0px",
          minWidth: "10%",
          width: "10%",
        },
        [theme.breakpoints.down("xl")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "9%",
            width: "9%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "9%",
            width: "9%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
            },
          },
        },
        [theme.breakpoints.down("lg")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "9%",
            width: "9%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "9%",
            width: "9%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "10%",
            width: "10%",
          },
        },

        [theme.breakpoints.down("md")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "13%",
            width: "13%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "16%",
            width: "16%",
          },
        },
        [theme.breakpoints.down("sm")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "16%",
            width: "16%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "20%",
            width: "20%",
          },
        },
        [theme.breakpoints.down("xs")]: {
          [`&:nth-of-type(n+2):nth-of-type(-n+10)`]: {
            minWidth: "24%",
            width: "24%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "start",
              fontWeight: "bold",
            },
          },
          [`&:nth-of-type(n+11)`]: {
            minWidth: "24%",
            width: "24%",
            "& .css-16gtl2w": {
              display: "flex",
              justifyContent: "end",
              fontWeight: "bold",
              paddingRight: "8px",
            },
          },
          "&:nth-of-type(1)": {
            left: "0px",
            minWidth: "26%",
            width: "26%",
          },
        },
      },
    }),
  );

/* Creation of 'daily sale' table, and conversion of 'daily sale' table to PDF and Excel. */
const DailySaleReportInfoNode: React.FunctionComponent<
  VoidSummeryInfoNodeProps
  // eslint-disable-next-line react/function-component-definition
> = ({
  dailySaleReportNodeList,
  filterDetails,
  min,
  max,
  previousDailySaleReportNodeList,
  diffDate,
  locationSelectedList,
  handleOnClickText,
}) => {
  const [pdfHeaders, setPdfHeaders] = useState<any>([]);
  const [headersList, setHeadersList] = useState<any>([]);
  const [excelHeaders, setExcelHeaders] = useState<any>([]);
  const [nodes, setNodes] = useState<any>({ nodes: [] });
  const [nodesPdfExcel, setNodesPdfExcel] = useState<any>([]);
  const [footer, setFooter] = useState<any>([]);
  const [bodyLength, setBodyLength] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const maxWidth = useMediaQuery("(max-width: 1300px)");
  const maxWidthCustom = useMediaQuery("(max-width: 2000px)");
  const open = Boolean(anchorEl);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const theme: CustomTheme = useTheme();

  useEffect(() => {
    setNodes({ nodes: [] });
  }, [params.get("startDate")]);

  const tooltipTitle = (sale: any, previousSale: any, key: string) => (
    <div className={classes.tooltipBackgroundColor}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="caption"
          className={classes.tooltipBackgroundColor}
        >
          {convertDateFormat(moment(sale["date"]).format("YYYY-MM-DD"))}
        </Typography>
        <Typography
          variant="caption"
          style={{ fontWeight: "bold", paddingLeft: "12px" }}
          className={classes.tooltipBackgroundColor}
        >
          {sale[key]}
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="caption"
          className={classes.tooltipBackgroundColor}
        >
          {convertDateFormat(
            moment(sale.date).subtract(diffDate, "days").format("YYYY-MM-DD"),
          )}
        </Typography>
        <Typography
          variant="caption"
          style={{ fontWeight: "bold", paddingLeft: "12px" }}
          className={classes.tooltipBackgroundColor}
        >
          {key !== "orders" ? previousSale[key].toFixed(2) : previousSale[key]}
        </Typography>
      </div>
    </div>
  );

  /**
   * This function is used to render sale data with an up or
   * down arrow based on the comparison between the current sale and the previous sale for a given index.
   * @param sale - object containing sale data
   * @param key - the key for the current sale data being processed
   * @param prevSale - object containing the previous sale data
   * @param index - the index of the current sale data being processed
   * @returns - a JSX element containing the sale data, or the sale data with an up or down arrow icon
   */
  const handleSaleUpDownIcon = (sale: any, key: string) => {
    // Create variables for the up and down arrow icons
    const arrowUpIcon = (
      <ArrowDropUpIcon
        style={{ color: theme.palette.custom.green.light, fontSize: "32px" }}
      />
    );
    const arrowDownIcon = (
      <ArrowDropDownIcon
        style={{ color: theme.palette.custom.red.light, fontSize: "32px" }}
      />
    );
    // Check if the key is "date", if there is no previous sale data or if the index is 0

    // If there is a previous sale data, compare it with the current sale data
    const currentSale = parseFloat(sale[key]);
    const previousSale =
      previousDailySaleReportNodeList[
        moment(sale.date).subtract(diffDate, "days").format("YYYY-MM-DD")
      ];

    if (previousSale) {
      if (currentSale > parseFloat(previousSale[key])) {
        // If the current sale data is greater than the previous sale data, render the current sale data with an up arrow
        return (
          <Tooltip title={tooltipTitle(sale, previousSale, key)}>
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "12px",
                fontSize: "13px",
              }}
              align="left"
            >
              {arrowUpIcon}
              {key !== "orders" ? currentSale.toFixed(2) : currentSale}
            </Typography>
          </Tooltip>
        );
      }
      if (currentSale < parseFloat(previousSale[key])) {
        // If the current sale data is less than the previous sale data, render the current sale data with a down arrow
        return (
          <Tooltip
            style={{
              margin: "8px",
              borderRadius: "10px",
              border: `1px solid ${theme.palette.background.entity_border}`,
              backgroundColor: "red",
            }}
            title={tooltipTitle(sale, previousSale, key)}
          >
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "12px",
                fontSize: "12px",
              }}
              align="left"
            >
              {arrowDownIcon}
              {key !== "orders" ? currentSale.toFixed(2) : currentSale}
            </Typography>
          </Tooltip>
        );
      } else {
        // If the current sale data is equal to the previous sale data, simply render the sale data
        // eslint-disable-next-line no-lonely-if
        if (key !== "orders" && key !== "max") {
          return (
            <div style={{ paddingLeft: "32px" }}>{currentSale.toFixed(2)}</div>
          );
        } else if (key !== "orders" && key === "max") {
          return (
            <div style={{ paddingRight: "12px" }}>{currentSale.toFixed(2)}</div>
          );
        } else {
          return <div style={{ paddingLeft: "32px" }}>{sale[key]}</div>;
        }
      }
    } else {
      // If the current sale data is equal to the previous sale data, simply render the sale data
      if (key !== "orders" && key !== "max") {
        return (
          <div style={{ paddingLeft: "32px" }}>{currentSale.toFixed(2)}</div>
        );
      }
      if (key !== "orders" && key === "max") {
        return (
          <div style={{ paddingRight: "12px" }}>{currentSale.toFixed(2)}</div>
        );
      }
      return <div style={{ paddingLeft: "32px" }}>{sale[key]}</div>;
    }
  };

  /**
   * Determines whether to display an up or down arrow icon, or the sale data
   * @param sale - object containing sale data
   * @param key - the key for the current sale data being processed
   * @param prevSale - object containing the previous sale data
   * @param index - the index of the current sale data being processed
   * @returns - a JSX element containing the sale data, or the sale data with an up or down arrow icon
   */
  const handleUpDown = (sale: any, key: string) => {
    if (key !== "date") {
      return handleSaleUpDownIcon(sale, key);
    }
    return (
      <Typography style={{ fontSize: "13px" }} align="left">
        {sale[key]}
      </Typography>
    );
  };

  /* Creating the data for the Payment report table and creating the data for pdf and excel files.
       Using a API request */
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let cloneDailySaleReportNodeList: any = _.cloneDeep(
      dailySaleReportNodeList,
    );

    // Format the data by converting numeric values to two decimal points, except for the "date" and "orders" fields.
    const convertedData = cloneDailySaleReportNodeList.map((obj: any) => {
      const newObj: any = {};
      Object.keys(obj).forEach((key) => {
        if (key !== "date" && key !== "orders") {
          newObj[key] = parseFloat(obj[key]).toFixed(2);
        } else {
          newObj[key] = obj[key];
        }
      });
      return newObj;
    });

    // Calculate the footer values by reducing the cloneDailySaleReportNodeList.
    const footer = cloneDailySaleReportNodeList.reduce(
      (acc: any, item: any) => {
        acc.average += Number(item.average);
        acc.card += Number(item.card);
        acc.cash += Number(item.cash);
        acc.discount += Number(item.discount);
        acc.orders += Number(item.orders);
        acc.otherPayment += Number(item.otherPayment);
        acc.total += Number(item.total);
        acc.subTotal += Number(item.subTotal);
        return acc;
      },
      {
        average: 0.0,
        card: 0.0,
        cash: 0.0,
        discount: 0.0,
        orders: 0,
        otherPayment: 0.0,
        total: 0.0,
        subTotal: 0.0,
      },
    );

    // Format the footer values for display.
    const formattedFooter = Object.entries(footer).reduce(
      (acc: any, [key, value]: any) => {
        if (key !== "orders") {
          acc[key] = (
            <div style={{ paddingLeft: "32px" }}>
              {parseFloat(value.toString()).toFixed(2)}
            </div>
          );
        } else {
          acc[key] = <div style={{ paddingLeft: "32px" }}>{value}</div>;
        }
        return acc;
      },
      {},
    );

    // Format the footer values for PDF generation.
    const formattedFooterPdf = Object.entries(footer).reduce(
      (acc: any, [key, value]: any) => {
        if (key !== "orders") {
          acc[key] = parseFloat(value.toString()).toFixed(2);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    // Set the footer for display.
    formattedFooter.date = "Grand total";
    formattedFooter.average = (
      <div style={{ paddingLeft: "32px" }}>
        {(
          formattedFooter.total.props.children /
          formattedFooter.orders.props.children
        ).toFixed(2)}
      </div>
    );
    formattedFooter.min = (
      <div style={{ paddingLeft: "32px" }}>{min.toFixed(2)}</div>
    );
    formattedFooter.max = (
      <div style={{ paddingRight: "12px" }}>{max.toFixed(2)}</div>
    );
    formattedFooterPdf.date = "Grand total";
    formattedFooterPdf.min = min.toFixed(2);
    formattedFooterPdf.max = max.toFixed(2);
    formattedFooterPdf.average = (
      formattedFooter.total.props.children /
      formattedFooter.orders.props.children
    ).toFixed(2);

    setFooter([formattedFooter]);

    // Set the body length.
    setBodyLength(cloneDailySaleReportNodeList.length + 1);

    // Set pdf headers
    setPdfHeaders([
      { title: "Date", field: "date" },
      { title: "Orders", field: "orders" },
      { title: "SubTotal", field: "subTotal" },
      { title: "Discount", field: "discount" },
      { title: "Total", field: "total" },
      { title: "Cash Pay", field: "cash" },
      { title: "Card Pay", field: "card" },
      { title: "Other Pay", field: "otherPayment" },
      { title: "Average", field: "average" },
      { title: "Min", field: "min" },
      { title: "Max", field: "max" },
    ]);

    // Set excel headers
    setExcelHeaders([
      { header: "Date", key: "date", width: 52, height: 68 },
      { header: "Orders", key: "orders", width: 32, height: 68 },
      { header: "SubTotal", key: "subTotal", width: 32, height: 68 },
      { header: "Discount", key: "discount", width: 32, height: 68 },
      { header: "Total", key: "total", width: 32, height: 68 },
      { header: "Cash Pay", key: "cash", width: 32, height: 68 },
      { header: "Card Pay", key: "card", width: 32, height: 68 },
      {
        header: "Other Pay",
        key: "otherPayment",
        width: 32,
        height: 68,
      },
      { header: "Average", key: "average", width: 32, height: 68 },
      { header: "Min", key: "min", width: 32, height: 68 },
      { header: "Max", key: "max", width: 32, height: 68 },
    ]);

    // Set table headers
    setHeadersList([
      "Date",
      "Orders",
      "SubTotal",
      "Discount",
      "Total",
      "Cash Pay",
      "Card Pay",
      "Other Pay",
      "Average",
      "Min",
      "Max",
    ]);

    // Merge the body and footer of PDF/ Excel.
    const mergedPdf = [...convertedData, formattedFooterPdf];

    // set the pdf excel data in the state
    setNodesPdfExcel(mergedPdf);

    setNodes({ nodes: convertedData });
  }, [dailySaleReportNodeList]);

  // Create a daily sale table sorting function
  const sort = useSort(
    nodes,
    {},
    {
      sortFns: {
        Date: (array) => sortDates(array, "date"),
        Orders: (array) => sortNumbers(array, "orders"),
        SubTotal: (array) => sortNumbers(array, "subTotal"),
        Discount: (array) => sortNumbers(array, "discount"),
        Total: (array) => sortNumbers(array, "total"),
        "Cash Pay": (array) => sortNumbers(array, "cash"),
        "Card Pay": (array) => sortNumbers(array, "card"),
        "Other Pay": (array) => sortNumbers(array, "otherPayment"),
        Average: (array) => sortNumbers(array, "average"),
        Min: (array) => sortNumbers(array, "min"),
        Max: (array) => sortNumbers(array, "max"),
      },
      sortIcon: {
        margin: "0px",
        iconDefault: (
          <Icon
            icon="fluent:chevron-up-down-16-filled"
            width="44"
            height="44"
          />
        ),
        iconUp: <ArrowDropDownIcon />,
        iconDown: <ArrowDropUpIcon />,
      },
    },
  );

  /* Open PDF / Excel selection menu */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close PDF / Excel selection menu */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const classes: any = useStyles(bodyLength)();

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "20px",
          marginTop: "8px",
        }}
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? { display: "block" }
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
            {!_.isEmpty(nodes.nodes) && (
              <div
                style={
                  maxWidthLocationChips
                    ? {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "8px",
                      }
                    : { marginLeft: "16px" }
                }
              >
                <MenuButtonCommon
                  id="basic-button"
                  variant="contained"
                  style={{
                    fontSize: 11,
                    height: "38px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                  color="blue"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <GetAppIcon />
                  <Typography
                    variant="body1"
                    style={{ paddingLeft: "16px", color: "white" }}
                  >
                    Export
                  </Typography>
                </MenuButtonCommon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    elevation: 0,
                    style: {
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.background.entity_border}`,
                    },
                  }}
                  style={{ top: "56px" }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleDownloadPdf(
                        "p",
                        pdfHeaders,
                        nodesPdfExcel,
                        filterDetails,
                      );
                      handleClose();
                    }}
                  >
                    Export as PDF
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDownloadCsv(
                        excelHeaders,
                        nodesPdfExcel,
                        filterDetails,
                        bodyLength,
                      );
                      handleClose();
                    }}
                  >
                    Export as CSV
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </Grid>
      </Grid>

      <CardCommon>
        <Table
          data={nodes}
          sort={sort}
          layout={{ custom: maxWidthCustom, horizontalScroll: maxWidth }}
        >
          {(tableList) => (
            <>
              <Header style={{ zIndex: 0 }}>
                <HeaderRow className={classes.headerStyle}>
                  {headersList.map((data: any, index: any) => (
                    <HeaderCellSort
                      sortKey={data}
                      pinLeft={index === 0}
                      className={classes.baseCellRow}
                      style={{ fontWeight: "bold" }}
                    >
                      <Typography
                        variant="caption"
                        style={
                          index === 11
                            ? {
                                paddingLeft: "0px",
                                color: theme.palette.custom.orange.contrastText,
                              }
                            : index === 0
                            ? {
                                paddingLeft: "12px",
                                color: theme.palette.custom.orange.contrastText,
                              }
                            : {
                                paddingLeft: "32px",
                                color: theme.palette.custom.orange.contrastText,
                              }
                        }
                        className={
                          index === 0
                            ? classes.startTextStyle
                            : classes.textStyleHeader
                        }
                      >
                        {data}
                      </Typography>
                    </HeaderCellSort>
                  ))}
                </HeaderRow>
              </Header>
              <Body className={classes.bodyStyle}>
                {tableList.map((item, bodyIndex, prevItem: any) => (
                  <>
                    <Row
                      key={item.gross20}
                      item={item}
                      className={classes.headerRow}
                    >
                      {Object.keys(item).map((data: any, index: any) => (
                        <Cell
                          pinLeft={index === 0}
                          className={classes.baseCellRow}
                        >
                          <div
                            className={
                              index === 0
                                ? classes.startTextStyle
                                : index === 10
                                ? classes.textStyleEnd
                                : classes.textStyle
                            }
                          >
                            {handleUpDown(item, data)}
                          </div>
                        </Cell>
                      ))}
                    </Row>
                    {bodyIndex + 1 === tableList.length &&
                      footer.map((data1: any) => (
                        <Row
                          className={classes.finalTotalRow}
                          key={item.gross20}
                          item={item}
                        >
                          {Object.keys(item).map((data: any, index: any) => (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                              <Cell
                                pinLeft={index === 0}
                                className={classes.baseCellRow}
                              >
                                <Typography
                                  variant="caption"
                                  style={{ fontWeight: "bold", color: "white" }}
                                  className={
                                    index === 0
                                      ? classes.startTextStyle
                                      : index === 10
                                      ? classes.textStyleEnd
                                      : classes.textStyle
                                  }
                                >
                                  {data1[data]}
                                </Typography>
                              </Cell>
                            </>
                          ))}
                        </Row>
                      ))}
                  </>
                ))}
              </Body>
            </>
          )}
        </Table>
      </CardCommon>
    </>
  );
};

export default DailySaleReportInfoNode;
