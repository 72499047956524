import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import AddressInfo from "./AddressInfo";
import BusinessInfo from "./BusinessInfo";
import ContactInfo from "./ContactInfo";
import RoundBoxWithHeader from "../../../../components/common/RoundBoxWithHeader";
import {
  getLocationBasicInfo,
  updateLocationBasicInfo,
} from "../../../../services/locationApp/storeService";
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import GoogleMap from "../../../../components/common/googleMap.tsx";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface BasicInfoProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addressInfoStyle: {
      paddingRight: "12px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
      },
    },
    googleMapStyle: {
      paddingLeft: "12px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0px",
        marginTop: "8px",
      },
    },
  }),
);

/* Get all basic information and location information using API call at initial load. */
const BasicInfo: React.FunctionComponent<BasicInfoProps> = () => {
  const [locationData, setLocationData] = useState({
    // business info
    businessDisplayName: "",
    businessRegName: "",
    businessRegNo: "",
    businessTradingName: "",
    locationIdentifier: "",
    description: "",

    // contact info
    contactNo: "",
    email: "",
    web: "",
    orderUrl: "",

    // location
    lat: 0.0,
    lon: 0.0,
    addressFormatted: "",
    buildingNoOrName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    country: "",
    postcode: "",

    // terms section
    terms: "",

    // other static for API
    created: "",
    version: 0,
    profileUrl: "",
    coverUrl: "",
    id: "",
  });

  /* Use a react form hooks */
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const match: any = useRouteMatch();

  const onError = (errors: any, e: any) => console.error(errors, e);

  /* Send an API call to update location basic information after click save changes button. */
  const onSubmit = async (data: any) => {
    if (!isUpdated) return;
    try {
      const res = await updateLocationBasicInfo(locationData.id, locationData);
      setLocationData(res.data.data);
      setSuccess(SUCCESSFULLY_UPDATED);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
    setIsUpdated(false);
  };

  /* Update locationData state when text field changes occur. */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUpdated(true);
    const { name, value } = event.target;
    setLocationData({ ...locationData, [name]: value });
  };

  /* Get all basic information using API call after change locationId. */
  useEffect(() => {
    document.title = "Locations - Basic Info";
    const fetchBasicInfo = async () => {
      try {
        const res = await getLocationBasicInfo(match.params.locationId);
        setLocationData(res.data.data);
      } catch (error) {
        setError(
          "Error fetching location basic info. Please check your internet connection.",
        );
      }
    };

    fetchBasicInfo();
  }, [match.params.locationId]);

  /* Change the locationsData state after change location. */
  const handleChangeLocationAddress = (address: any) => {
    setIsUpdated(true);
    setLocationData((prevState) => ({
      ...prevState,
      lat: address.lat,
      lon: address.lon,
      addressFormatted: address.addressFormatted,
      buildingNoOrName: address.buildingNoOrName,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      county: address.county,
      country: address.country,
      postcode: address.postcode,
    }));
  };

  const classes = useStyles();

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{ marginTop: "18px" }}
      >
        <DefaultAlert
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          severity={"error"}
        />
        <DefaultAlert
          open={!!success}
          handleClose={() => setSuccess("")}
          message={success}
          severity={"success"}
        />
        <RoundBoxWithHeader header={"Business Entity"}>
          <BusinessInfo
            locationData={locationData}
            handleChange={handleChange}
            register={register}
            errors={errors}
          />
        </RoundBoxWithHeader>

        <div style={{ marginTop: "24px" }}>
          <RoundBoxWithHeader header={"Public Contact Details"}>
            <ContactInfo
              locationData={locationData}
              handleChange={handleChange}
              register={register}
              errors={errors}
            />
          </RoundBoxWithHeader>
        </div>
        <div style={{ marginTop: "24px" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={7}
              className={classes.addressInfoStyle}
            >
              <RoundBoxWithHeader header={"Address Info"}>
                <AddressInfo
                  locationData={locationData}
                  register={register}
                  errors={errors}
                />
              </RoundBoxWithHeader>
            </Grid>
            <Grid item xs={12} sm={6} md={5} className={classes.googleMapStyle}>
              <GoogleMap
                address={locationData}
                handleChangeLocationAddress={handleChangeLocationAddress}
                isValidated={true}
                isNeedTextBox={false}
                height={"400"}
              />
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

export default withAuthority(BasicInfo, Authorities.BASIC_INFO_READ);
