import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { NODE_STATUS_CREATE } from "../../../../utils/consts";
import WithLoading from "../../../../utils/WithLoading";
import CreateNewNode from "./CreateNewNode";
import TaskInfoNode from "./TaskInfoNode";

export interface TaskTypesProps {
  nodes: any;
  getTaskInfo: any;
  isLoading: boolean;
}

const TaskMap: React.FunctionComponent<TaskTypesProps> = ({
  nodes,
  getTaskInfo,
  isLoading,
}) => {
  const [taskNodes, setTaskNodes] = useState<any>();

  useEffect(() => {
    setTaskNodes(nodes);
  }, [nodes]);

  const createNode = (nodeData: any) => {
    setTaskNodes([...taskNodes, { ...nodeData }]);
  };

  /* Used to create a new task. */
  const handleCreateNode = () => {
    const nodeData = {
      status: "create",
      isActive: false,
      scope: "",
      taskTrigger: "",
      type: "",
      subType: "",
      resource: "",
      resourceTitle: "",
      resourceSubtitle: "",
      resourceBody: "",
    };
    createNode(nodeData);
  };

  /* Used to remove a task. */
  const handleRemoveNode = () => {
    setTaskNodes(
      taskNodes.filter((node: any) => node.status !== NODE_STATUS_CREATE),
    );
  };
  return (
    <div>
      <Typography
        variant="h4"
        component="div"
        align="left"
        style={{ margin: 4 }}
      />
      <Grid
        container
        spacing={2}
        style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
      >
        {!_.isEmpty(taskNodes) &&
          taskNodes.map((nodeData: any, index: any) => (
            <Grid item xs={12} key={nodeData.id} style={{ display: "flex" }}>
              <TaskInfoNode
                nodeData={nodeData}
                getTaskInfo={getTaskInfo}
                handleRemoveNode={handleRemoveNode}
              />
            </Grid>
          ))}
        <CreateNewNode handleCreateNode={handleCreateNode} />
      </Grid>
    </div>
  );
};

export default WithLoading(TaskMap);
