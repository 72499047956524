import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import CardCommon from "../../../../components/card/CardCommon";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    addNodeButton: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.entity_background,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.background.paper,
          theme.palette.action.hoverOpacity,
        ),
      },
    },
  }),
);

export interface CreateNewNodeProps {
  handleCreateNode: any;
}

/* New discount info creation UI. */
const CreateNewNode: React.SFC<CreateNewNodeProps> = ({
  handleCreateNode,
}) => {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={6} xs={12} lg={4}>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          variant="contained"
          className={classes.addNodeButton}
          onClick={() => handleCreateNode()}
        >
          <AddIcon fontSize="large" />
        </Button>
      </CardCommon>
    </Grid>
  );
};

export default withAuthority(CreateNewNode, Authorities.DISCOUNT_CREATE);
