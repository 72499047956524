import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import _ from "lodash";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    arrowColor: {
      color: theme.palette.custom["yellow"].main,
    },
    cardColorStage: {
      backgroundColor: theme.palette.background.imgBackground,
      height: "40px",
      zIndex: 0,
      cursor: "pointer",
    },
    dropDownButton: {
      zIndex: 50,
      width: "28px",
      height: "28px",
    },
  }),
);

export interface BannersProps {
  allStageIndex: any;
  data: any;
  count: any;
  levelOfStage: any;
  handleStyleHeader: any;
  handleStageTwoModifierEditPageOpen: any;
  data1: any;
  category: any;
  handleOddStages: any;
  headerStyleBody: any
  handleItemDropDownOpen: any
  handleItemDropDownClose: any
  cat: any
}

/* This component show the odd stages. */
const OddStages: React.FunctionComponent<BannersProps> = ({
  allStageIndex,
  data,
  count,
  levelOfStage,
  handleStyleHeader,
  handleStageTwoModifierEditPageOpen,
  data1,
  category,
  handleOddStages,
  headerStyleBody,
  handleItemDropDownOpen,
  handleItemDropDownClose,
  cat,
}) => {
  const classes = useStyles();
  return (
      allStageIndex.includes(data.itemId) && (
          <>
            {count === 1 && (
              <div
                style={
                  levelOfStage < 4
                    ? {
                        marginTop: "4px",
                        paddingLeft: "44px",
                      }
                    : {
                        marginTop: "4px",
                        paddingLeft: "98px",
                      }
                }
              >
                <CardCommon>
                  <Grid container className={handleStyleHeader(levelOfStage)}>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        placeItems: "center",
                        paddingLeft: "28px",
                      }}
                    >
                      Title
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        placeItems: "center",
                        paddingLeft: "24px",
                      }}
                    >
                      Surcharge
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                        placeItems: "center",
                        paddingLeft: "24px",
                      }}
                    >
                      Extra Cost
                    </Grid>
                  </Grid>
                </CardCommon>
              </div>
            )}
            <div
              style={
                levelOfStage < 4
                  ? {
                      marginTop: "4px",
                      paddingLeft: "44px",
                    }
                  : {
                      marginTop: "4px",
                      paddingLeft: "98px",
                    }
              }
            >
              <CardCommon>
                <Grid container className={classes.cardColorStage}>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      placeItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleStageTwoModifierEditPageOpen(data1, category.menuId)
                    }
                  >
                    <Box className={headerStyleBody(levelOfStage)}></Box>
                    <Typography>{data1.title}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      placeItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleStageTwoModifierEditPageOpen(data1, category.menuId)
                    }
                  >
                    <Typography
                      style={{
                        display: "flex",
                        paddingLeft: "24px",
                      }}
                    >
                      {data1.surcharge}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: "flex",
                      placeItems: "center",
                      justifyContent: "start",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleStageTwoModifierEditPageOpen(data1, category.menuId)
                    }
                  >
                    <Typography
                      style={{
                        display: "flex",
                        paddingLeft: "24px",
                      }}
                    >
                      {data1.extraCost}
                    </Typography>
                  </Grid>
                  {!_.isEmpty(data1.enableStageGroup) && (
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        placeItems: "center",
                        justifyContent: "end",
                        paddingRight: "16px",
                      }}
                    >
                      {!allStageIndex.includes(data1.itemId) ? (
                        <IconButton
                          style={{
                            zIndex: 50,
                          }}
                          className={classes.dropDownButton}
                          onClick={() => handleItemDropDownOpen(data1.itemId)}
                        >
                          <ArrowDropDownIcon className={classes.arrowColor} />
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{
                            zIndex: 50,
                          }}
                          className={classes.dropDownButton}
                          onClick={() => handleItemDropDownClose(data1.itemId)}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardCommon>
            </div>{" "}
            {!_.isEmpty(data1.enableStageGroup) &&
              handleOddStages(cat, data1, levelOfStage)}
          </>
        )
  );
};

export default withAuthority(OddStages, Authorities.MENU_READ);
