import { createStyles, makeStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import {
  getColorByStatus,
  getFontColorByStatus,
} from "../../../../utils/Color";
import OrderDetailsTab from "./OrderDetailsTab";
import { CustomTheme } from "../../../../types/customTheme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface styleProps {
  status: any;
}

const useStylesTabPanel = makeStyles((theme: any) =>
  createStyles({
    tabPanel: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

const useStyles = makeStyles<any, styleProps>((theme: CustomTheme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: ({ status }) => getColorByStatus(status),
      color: ({ status }) => getFontColorByStatus(status),
      marginTop: "5px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  }),
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStylesTabPanel();
  return (
    <div
      role="tabpanel"
      className={classes.tabPanel}
      hidden={value !== index}
      id={`order-type-tab-${index}`}
      aria-labelledby={`order-type-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ padding: "8px 0" }}>{children}</div>}
    </div>
  );
}

export interface DiscountDetailsTypesProps {
  nodeData: Array<any>;
  locationName: any;
  orderChannel: any;
  orderChannelImage: any;
  status: any;
  orderTime: any;
  createdTime: any;
  posTime: any;
  acceptedTime: any;
}

/* This page has 2 tab panels. They are order, and status. 
Information related to those panels is designed to be visible to the user using cards */
const OrderDetailsTabType: React.FunctionComponent<
  DiscountDetailsTypesProps
> = ({
  nodeData,
  locationName,
  orderChannel,
  orderChannelImage,
  status,
  orderTime,
  createdTime,
  posTime,
  acceptedTime,
}) => {
  const [value, setValue] = React.useState(0);

  /* Switch the tab to be displayed. */
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const classes = useStyles({ status });

  return (
    <div>
      <div className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="order-types-tabs"
          style={{ width: "100%" }}
        >
          <Tab
            label="Order"
            id="order-type-tab-1"
            aria-controls="order-type-tab-1"
          />
          {/* <Tab
            label="Status"
            id="status-type-tab-1"
            aria-controls="status-type-tab-1"
          /> */}
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div style={{ borderRadius: "10px" }}>
          <OrderDetailsTab
            nodeData={nodeData}
            locationName={locationName}
            orderChannel={orderChannel}
            orderChannelImage={orderChannelImage}
          />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <OrderStatusTab
          orderTime={orderTime}
          createdTime={createdTime}
          posTime={posTime}
          acceptedTime={acceptedTime}
        />
      </TabPanel> */}
    </div>
  );
};

export default OrderDetailsTabType;
