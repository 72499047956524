import queryString from 'query-string';
import { EATPRESTO_QUERY_PARAMS, INVENTORY_QUERY_PARAMS, LOCATION_QUERY_PARAMS, MENU_QUERY_PARAMS, SALE_QUERY_PARAMS, TASK_QUERY_PARAMS } from './consts';

/* This function use to create a query param */
export const handleCreateQueryParam = () => {
  // If the main url is eatpresto, the query param is "eatpresto".
  if (
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN || "",
    )
  ) {
    return EATPRESTO_QUERY_PARAMS;
  } else if (
    // If the main url is location, the query param is "location".
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_LOCATION_DOMAIN || "",
    )
  ) {
    return LOCATION_QUERY_PARAMS;
  } else if (
    // If the main url is menu, the query param is "menu".
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_MENU_DOMAIN || "",
    )
  ) {
    return MENU_QUERY_PARAMS;
  } else if (
    // If the main url is task, the query param is "task".
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_TASK_DOMAIN || "",
    )
  ) {
    return TASK_QUERY_PARAMS;
  } else if (
    // If the main url is sale, the query param is "sale".
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_SALES_DOMAIN || "",
    )
  ) {
    return SALE_QUERY_PARAMS;
  }  else if (
    // If the main url is inventory, the query param is "inventory".
    window.location.origin.includes(
      process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN || "",
    )
  ) {
    return INVENTORY_QUERY_PARAMS;
  } else {
    return "";
  }
};

/* Checking whether a query param exists or not. */
export const checkQueryParams = () => {
  const selectedApp = queryString.parse(window.location.search).app
  if (
    selectedApp === EATPRESTO_QUERY_PARAMS ||
    selectedApp === LOCATION_QUERY_PARAMS ||
    selectedApp === MENU_QUERY_PARAMS ||
    selectedApp === TASK_QUERY_PARAMS ||
    selectedApp === SALE_QUERY_PARAMS ||
    selectedApp === INVENTORY_QUERY_PARAMS
  ) {
    return true;
  } else {
    return false;
  }
};
