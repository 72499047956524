import React from "react";
import WithLoading from "../../../../utils/WithLoading";
import MenuInfoPage from "./MenuInfoPage";

export interface DiscountTypesProps {
  menuOptionNodeList: any;
  isLoading: Boolean;
  getMenuOptionInfo: any;
  getCategoryMenuInfo: any;
  menuNodeList: any;
}

/* Loading the page until data is retrieved from the API call. */
const Loading: React.FunctionComponent<DiscountTypesProps> = ({
  menuOptionNodeList,
  isLoading,
  getMenuOptionInfo,
  getCategoryMenuInfo,
  menuNodeList,
}) => {
  return (
    <MenuInfoPage
      getMenuOptionInfo={getMenuOptionInfo}
      menuNodeList={menuNodeList}
      getCategoryMenuInfo={getCategoryMenuInfo}
      menuOptionNodeList={menuOptionNodeList}
    />
  );
};

export default WithLoading(Loading);
