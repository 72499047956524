import React from "react";
import WithLoading from "../../../../utils/WithLoading";
import MenuListPage from "./MenuListPage";

export interface DiscountTypesProps {
  menuNodeList: any;
  getMenuInfoList: any;
  handleRemoveOrderListSorting: any;
  handleChangeOrderListSorting: any;
  isLoading: any;
}

/* Loading the page until data is retrieved from the API call. */
const Loading: React.FunctionComponent<DiscountTypesProps> = ({
  menuNodeList,
  getMenuInfoList,
  handleRemoveOrderListSorting,
  handleChangeOrderListSorting,
  isLoading,
}) => {
  return (
    <MenuListPage
      getMenuInfoList={getMenuInfoList}
      menuNodeList={menuNodeList}
      handleRemoveOrderListSorting={handleRemoveOrderListSorting}
      handleChangeOrderListSorting={handleChangeOrderListSorting}
    />
  );
};

export default WithLoading(Loading);
