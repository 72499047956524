import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import _ from "lodash";

import SkeleTonLoading from "../Loading/SkeleTonLoading";
import PageHeaderMobile from "../PageHeader/PageHeaderMoblie";
import ButtonCommon from "../../buttons/ButtonCommon";
import { buttonColors } from "../../../utils/enum";
import { CustomTheme } from "../../../types/customTheme";
import Filter from "../Filter";
import NoDataFound from "../NoDataFound";
import SaleTitle from "../SaleReport/SaleTitle/SaleTitle";
import SalePageTitleAndLocationDetails from "../SaleReport/SalePageTitleAndLocationDetails";
import moment from "moment";
import NoInventoryDataFound from "../NoDataFound/NoInventoryDataFound";
import AlertDialog from "../../alerts/AlertDialog";

export interface InventoryStockOrderProps {
  locationSelectorList: any;
  isOpenSkeletonLoading: any;
  nodeList: any;
  topic: any;
  children: any;
  handleOpenCreateModal?: any;
  buttonName?: any;
  handleFilterData: any;
  handleLocationSelectorTypingList: any;
  isGetLocationInfo: any;
  setFilterDetails: any;
  availableFilter: any;
  locationSelectedList: any;
  setLocationSelectedList: any;
  openFilterCard: any;
  setOpenFilterCard: any;
  handleOnClickText: any;
  filterDetails: any;
  isNeedButton: any;
  isActiveLocationId: any;
}

/**
 * InventoryDetailsDefaultFilter is a React component that displays inventory details
 * with filtering options and the ability to create items. It handles responsive design,
 * loading states, and provides a user-friendly interface with filters.
 *
 * @returns {JSX.Element} A React component that renders inventory details with filtering options.
 */
const InventoryDetailsDefaultFilter: React.FunctionComponent<
  InventoryStockOrderProps
> = ({
  locationSelectorList,
  isOpenSkeletonLoading,
  nodeList,
  topic,
  children,
  handleOpenCreateModal,
  buttonName,
  handleFilterData,
  handleLocationSelectorTypingList,
  isGetLocationInfo,
  setFilterDetails,
  availableFilter,
  locationSelectedList,
  setLocationSelectedList,
  openFilterCard,
  setOpenFilterCard,
  handleOnClickText,
  filterDetails,
  isNeedButton,
  isActiveLocationId
}) => {
  const [filterLocation, setFilterLocation] = useState("");
  const [filterDate, setFilterDate] = useState("");

  /* Change the filter location details and filter date using filter details list */
  useEffect(() => {
    const filterLocation = filterDetails.split("*")[0].split(",");
    if (filterLocation.length === 1) {
      setFilterLocation(filterLocation[0]);
    } else {
      setFilterLocation(filterLocation.length + " locations");
    }
    const startDateAndDate = filterDetails.split("*")[1];

    if (startDateAndDate) {
      if (startDateAndDate.split(" - ")[1]) {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const endDate = moment(startDateAndDate.split(" - ")[1]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${endDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      } else {
        const startDate = moment(startDateAndDate.split(" - ")[0]).format(
          "Do MMM YYYY",
        );
        const convertedStartDateAndEndDate = `${startDate} - ${startDate}`;
        setFilterDate(convertedStartDateAndEndDate);
      }
    }
  }, [filterDetails]);

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/apps`;
  };

  const theme: CustomTheme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Box>
        <div
          style={
            matchesLg
              ? {
                  padding: "8px 6px 8px 8px",
                }
              : {
                  padding: "2px 12px 2px 12px",
                  marginTop: "6px",
                }
          }
        >
          <Hidden lgUp>
            <PageHeaderMobile />
          </Hidden>
          {isNeedButton ? (
            <div
              style={{
                marginTop: "36px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Filter
                  getFilterData={handleFilterData}
                  locationSelectorList={locationSelectorList}
                  getLocationSelectorTypingList={
                    handleLocationSelectorTypingList
                  }
                  isGetLocationInfo={isGetLocationInfo}
                  setFilterDetails={setFilterDetails}
                  availableFilter={availableFilter}
                  locationSelectedList={locationSelectedList}
                  setLocationSelectedList={setLocationSelectedList}
                  openFilterCard={openFilterCard}
                  setOpenFilterCard={setOpenFilterCard}
                  isChart={false}
                />
              </div>
              <ButtonCommon
                style={{
                  marginRight: 4,
                  width: "180px",
                }}
                variant="contained"
                color={buttonColors.CREATE_BUTTON_COLOR}
                onClick={handleOpenCreateModal}
              >
                <Typography style={{ textTransform: "none", color: "white" }}>
                  {buttonName}
                </Typography>
              </ButtonCommon>
            </div>
          ) : (
            <div style={{ marginTop: "36px" }}>
              <Filter
                getFilterData={handleFilterData}
                locationSelectorList={locationSelectorList}
                getLocationSelectorTypingList={handleLocationSelectorTypingList}
                isGetLocationInfo={isGetLocationInfo}
                setFilterDetails={setFilterDetails}
                availableFilter={availableFilter}
                locationSelectedList={locationSelectedList}
                setLocationSelectedList={setLocationSelectedList}
                openFilterCard={openFilterCard}
                setOpenFilterCard={setOpenFilterCard}
                isChart={false}
              />
            </div>
          )}
        </div>

        {!isOpenSkeletonLoading && !_.isEmpty(nodeList) && (
          <div style={{ marginLeft: "8px" }}>
            <SalePageTitleAndLocationDetails
              topic={topic}
              filterDate={filterDate}
              handleOnClickText={handleOnClickText}
            />
          </div>
        )}

        {!isOpenSkeletonLoading ? (
          <>
            {!_.isEmpty(nodeList) ? (
              <div
                style={
                  matchesLg
                    ? { padding: "8px" }
                    : { padding: "8px 16px 8px 8px" }
                }
              >
                {children}
              </div>
            ) : (
              <NoInventoryDataFound title={topic} />
            )}
          </>
        ) : (
          <div
            style={
              matchesLg
                ? { marginTop: "0px", padding: "8px" }
                : { marginTop: "20px", padding: "8px 16px 8px 8px" }
            }
          >
            <SkeleTonLoading isReport={false} />
          </div>
        )}
      </Box>
      {!isActiveLocationId && (
        <AlertDialog
          open={!isActiveLocationId}
          title="Activation Required"
          desc="Stock & inventory management is disabled on your account. Please contact your Presto Express account manager to request access."
          severity="error"
          confirmLabel="Back to Apps"
          disableBackdropClick
          confirmAction={handleRedirect}
          color="secondary"
        />
      )}
    </>
  );
};

export default InventoryDetailsDefaultFilter;
