/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { Card, FormControlLabel, Grid, useTheme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import ButtonCommon from "../../../../components/buttons/ButtonCommon";
import ConfirmDeleteDialog from "../../components/dialogs/ConfirmDeleteDialog";
import ValidationMessage from "../../../../components/validation/ValidationMessage";
import { updateDiscountInfo } from "../../../../services/eatprestoApp/discountInfoService";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_DELETING_ERROR,
  ERROR_MESSAGE_TIME_ERROR,
  ERROR_MESSAGE_UPDATING_ERROR,
  ERROR_MESSAGE_VERSION_ERROR,
  NODE_STATUS_CREATE,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_DELETED,
  SUCCESSFULLY_UPDATED,
} from "../../../../utils/consts";
import { CustomTheme, Theme } from "../../../../types/customTheme";
import SwitchCommon from "../../../../components/switches/SwitchCommon";
import { HttpStatus, submitButtonName } from "../../../../utils/enum";
import ConfirmDeleteDialogDefault from "../../components/dialogs/ConfirmDeleteDialogDefault";
import TextfieldCommon from "../../../../components/textField/TextfieldCommon";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import {
  createClosePeriodInfo,
  deleteClosePeriodInfo,
  updateClosePeriodInfo,
} from "../../../../services/eatprestoApp/closePeriodService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
    },
    base: {
      padding: "12px 12px 0 12px",
      [theme.breakpoints.up("sm")]: {
        padding: "16px 16px 0 16px",
      },
      borderRadius: 4,
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      borderRadius: "8px 8px 0 0",
      border: `1px solid ${theme.palette.background.entity_border}`,
      padding: 8,
    },
    actionsWrapper: {
      padding: "16px 16px",
      display: "flex",
      justifyContent: "center",
    },
    datePickerStyle: {
      [`& fieldset`]: {
        borderRadius: "10px",
        border: `1px solid ${theme.palette.background.entity_border}`,
        cursor: "pointer",
      },
      "& .MuiPickersClockPointer-pointer": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      "& .MuiPickersClockNumber-clockNumberSelected": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.background.entity_border}`,
      },
    },
    activeButtonStyle: {
      placeItems: "center",
      display: "flex",
      justifyContent: "end",
      paddingLeft: "8px",
    },
  }),
);

interface FormData {
  title: string;
}
export interface ClosePeriodInfoNodeProps {
  nodeData: any;
  type: string;
  getClosePeriodInfo: any;
  isAuthorized: boolean;
  handleRemoveNode: any;
}

/* Create, delete, and update discount information and users can view all discount information. */
const ClosePeriodInfoNode: React.FunctionComponent<
  ClosePeriodInfoNodeProps
> = ({
  nodeData,
  getClosePeriodInfo,
  type,
  handleRemoveNode,
  isAuthorized,
}) => {
  const [error, setError] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nodeID, setNodeID] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState("");
  const [success, setSuccess] = useState("");
  const [isOpenDiscountResetModal, setIsOpenDiscountResetModal] =
    useState(false);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  );
  const [originalNodeData, setOriginalNodeData] = useState({
    id: "",
    locationId: "",
    title: "",
    fromDate: "",
    toDate: "",
    isActive: true,
    version: "",
  });

  /* Use a react form hooks */
  const { register, handleSubmit, errors, reset, formState } =
    useForm<FormData>({
      defaultValues: {
        title: "",
      },
    });

  // Check the form edit or not using react form hook.
  const isFormEdited = formState.isDirty;

  const match: any = useRouteMatch();

  /* Get updated data after changes. */
  const getNodeDataByState = (data: any) => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.isActive = isActive;
    updatedData.title = data.title;
    if (fromDate.slice(-4) === ".000") {
      updatedData.fromDate = fromDate;
    } else {
      updatedData.fromDate = fromDate + ".000";
    }
    if (toDate.slice(-4) === ".000") {
      updatedData.toDate = toDate;
    } else {
      updatedData.toDate = toDate + ".000";
    }
    return updatedData;
  };

  const getNodeDataByStates = () => {
    const updatedData = _.cloneDeep(originalNodeData);
    updatedData.isActive = isActive;
    if (fromDate.slice(-4) === ".000") {
      updatedData.fromDate = fromDate;
    } else {
      updatedData.fromDate = fromDate + ".000";
    }
    if (toDate.slice(-4) === ".000") {
      updatedData.toDate = toDate;
    } else {
      updatedData.toDate = toDate + ".000";
    }
    return updatedData;
  };

  /* Update states after creating delete or update banner information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const { id, isActive, fromDate, title, toDate } = nodeValues;
    setNodeID(id);
    setTitle(title);
    setIsActive(isActive);
    setFromDate(fromDate);
    setToDate(toDate);

    // Changing the value of the text field according to the response.
    reset({
      title,
    });
  }, []);

  useEffect(() => {
    setOriginalNodeData(nodeData);
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const updatedData = getNodeDataByStates();
  // Checking if a change has occurred in the form.
  const isSame = !isFormEdited && _.isEqual(originalNodeData, updatedData);

  /* Send an API call to update discount information after click save changes button. */
  const handleUpdateClosePeriodInfo = async (data: any) => {
    setError("");
    const updatedData = getNodeDataByState(data);
    await updateClosePeriodInfo(match.params.locationId, nodeID, updatedData)
      .then((res) => {
        setOriginalNodeData(res.data.data);
        setNodeDataToState(res.data.data);
        setSuccess(SUCCESSFULLY_UPDATED);
      })
      .catch((err: any) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getClosePeriodInfo();
        } else {
          setError(ERROR_MESSAGE_UPDATING_ERROR);
        }
      });
  };

  /* Send an API call to crete discount information after click create button. */
  const handleCreateDiscountOption = async (data: any) => {
    setError("");
    const createData = getNodeDataByState(data);
    await createClosePeriodInfo(match.params.locationId, createData)
      .then((res) => {
        setOriginalNodeData(res.data.data);
        setNodeDataToState(res.data.data);
        setIsCreated(true);
        setSuccess(SUCCESSFULLY_CREATED);
      })
      .catch((err) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getClosePeriodInfo();
        } else {
          setError(ERROR_MESSAGE_CREATING_ERROR);
        }
      });
  };

  /* Send an API call to delete discount information after click delete button. */
  const handleDeleteDiscountOption = async () => {
    setError("");
    await deleteClosePeriodInfo(match.params.locationId, nodeID)
      .then(() => {
        getClosePeriodInfo();
        setOpenDeleteConfirm(false);
        setSuccess(SUCCESSFULLY_DELETED);
      })
      .catch((err) => {
        setOpenDeleteConfirm(false);
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getClosePeriodInfo();
        } else {
          setError(ERROR_MESSAGE_DELETING_ERROR);
        }
      });
  };

  /* Reset states after clicking the cancel button. */
  const resetToOriginalData = () => {
    setNodeDataToState(originalNodeData);
  };

  /* Remove discount information using status. 
  If the status is "create", it will be removed not using an API call
  and if the status is not "create", it will be removed using an API call.  */
  const deleteFunc = () => {
    if (isCreated) {
      handleDeleteDiscountOption();
    } else if (nodeData.status === NODE_STATUS_CREATE) {
      handleRemoveNode(type, newId);
    } else {
      setOpenDeleteConfirm(true);
    }
  };

  /* Check for a create or update and 
  call the handleCreateDiscountOption function if it is a create and 
  handleUpdateClosePeriodInfo function if it is a update. */
  const handleSubmitData = handleSubmit((data) => {
    setError("");
    // When no option is selected in the delivery type option, the state is set to 'true' to display the error message.
    if (nodeData.status === NODE_STATUS_CREATE && !isCreated) {
      // Call the handleCreateDiscountOption function if it is a create
      if (moment(fromDate) < moment(toDate)) {
        return handleCreateDiscountOption(data);
      } else {
        setError(ERROR_MESSAGE_TIME_ERROR);
      }
    } else {
      // Call handleUpdateClosePeriodInfo function if it is a update.
      if (moment(fromDate) < moment(toDate)) {
        return handleUpdateClosePeriodInfo(data);
      } else {
        setError(ERROR_MESSAGE_TIME_ERROR);
      }
    }
  });

  /* Active switch status change using switch */
  const handleSwitchActivate = (e: any) => {
    const { checked } = e.target;
    setIsActive(checked);
  };

  /* Send an API call to after click reset icon. */
  const handleResetDiscount = async () => {
    setIsOpenDiscountResetModal(false);
    const updatedData = _.cloneDeep(originalNodeData);

    // After clicking the reset icon, usageCount value changes to 0 and usageDiscountValue value changes to 0.00.
    await updateDiscountInfo(match.params.locationId, nodeID, updatedData)
      .then((res) => {
        setOriginalNodeData(res.data.data);
        setNodeDataToState(res.data.data);
        getClosePeriodInfo();
      })
      .catch((err) => {
        if (err.response.status === HttpStatus.CONFLICT_409) {
          setError(ERROR_MESSAGE_VERSION_ERROR);
          getClosePeriodInfo();
        } else {
          setError(ERROR_MESSAGE_UPDATING_ERROR);
        }
      });
  };

  const handleChangeFromDate = (date: any) => {
    setFromDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleChangeFromTime = (time: any) => {
    setFromDate(moment(time).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleChangeToDate = (date: any) => {
    setToDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleChangeToTime = (time: any) => {
    setToDate(moment(time).format("YYYY-MM-DD HH:mm:ss"));
  };

  const { newId } = nodeData;
  const theme: CustomTheme = useTheme();

  const classes = useStyles();

  return (
    <Grid style={{ width: "100%" }}>
      <ConfirmDeleteDialogDefault
        open={isOpenDiscountResetModal}
        setOpen={setIsOpenDiscountResetModal}
        confirmAction={handleResetDiscount}
      />
      <ConfirmDeleteDialog
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        confirmAction={handleDeleteDiscountOption}
      />
      <DefaultAlert
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        severity={"success"}
      />
      <Card
        style={{
          backgroundColor: theme.palette.background.entity_background,
          borderRadius: "10px",
          border: `1px solid ${theme.palette.background.entity_border}`,
          boxShadow: "none",
        }}
      >
        {error && (
          <Typography component="div" className={classes.error} variant="body1">
            {error}
          </Typography>
        )}
        <form className={classes.base}>
          <Grid
            container
            spacing={2}
            style={{ marginLeft: "0px", marginBottom: "8px" }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" align="left">
                {title && title}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginLeft: "8px",
                marginRight: "3px",
              }}
            >
              <div style={{ paddingRight: "12px", width: "100%" }}>
                <TextfieldCommon
                  id="title"
                  name="title"
                  type="text"
                  label="Title"
                  disabled={!isAuthorized}
                  style={{ width: "100%" }}
                  inputRef={register({
                    required: "Title is required",
                    minLength: {
                      value: 2,
                      message: "Minimum Character is 2",
                    },
                    maxLength: {
                      value: 30,
                      message: "Maximum Character is 30",
                    },
                  })}
                />
                {errors.title ? (
                  <ValidationMessage message={errors.title.message} />
                ) : (
                  ""
                )}
              </div>
              <div className={classes.activeButtonStyle}>
                <FormControlLabel
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  control={<SwitchCommon />}
                  checked={isActive}
                  onChange={handleSwitchActivate}
                  label="Active"
                  labelPlacement="start"
                  disabled={!isAuthorized}
                />
              </div>
            </div>

            <Grid item xs={6}>
              <KeyboardDatePicker
                autoOk
                label="From Date"
                value={fromDate}
                onChange={handleChangeFromDate}
                inputVariant={"outlined"}
                fullWidth
                margin="normal"
                className={classes.datePickerStyle}
                format="dd/MM/yyyy"
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardTimePicker
                margin="normal"
                ampm={false}
                id="time-picker"
                label="From time"
                value={fromDate}
                onChange={handleChangeFromTime}
                inputVariant={"outlined"}
                className={classes.datePickerStyle}
                DialogProps={{ className: classes.datePickerStyle }}
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                autoOk
                label="To Date"
                value={toDate}
                disablePast
                minDate={moment(fromDate)}
                onChange={handleChangeToDate}
                inputVariant={"outlined"}
                fullWidth
                margin="normal"
                className={classes.datePickerStyle}
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardTimePicker
                margin="normal"
                ampm={false}
                id="time-picker"
                label="To time"
                value={toDate}
                onChange={handleChangeToTime}
                inputVariant={"outlined"}
                className={classes.datePickerStyle}
                DialogProps={{ className: classes.datePickerStyle }}
                okLabel={<Typography color="secondary">Ok</Typography>}
                cancelLabel={<Typography color="secondary">Cancel</Typography>}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 10,
                    height: 56,
                    backgroundColor:
                      theme.palette.background.entity_highlight_background,
                  },
                }}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </Grid>
        </form>

        {isAuthorized && (
          <div className={classes.actionsWrapper}>
            <ButtonCommon
              disabled={isSame}
              style={{
                flex: 1,
                fontSize: 11,
                marginRight: 4,
              }}
              variant="contained"
              color={
                nodeData.status === NODE_STATUS_CREATE && !isCreated
                  ? "green"
                  : "yellow"
              }
              onClick={handleSubmitData}
            >
              {nodeData.status === NODE_STATUS_CREATE
                ? submitButtonName.CREATE_SUBMIT_BUTTON
                : submitButtonName.UPDATE_SUBMIT_BUTTON}
            </ButtonCommon>
            <ButtonCommon
              disabled={isSame}
              variant="contained"
              style={{ fontSize: 11, flex: 1 }}
              color="orange"
              startIcon={<RotateLeftIcon />}
              onClick={resetToOriginalData}
            >
              Cancel
            </ButtonCommon>
            {isAuthorized && (
              <ButtonCommon
                variant="contained"
                style={{
                  fontSize: 11,
                  flex: 1,
                  marginLeft: 4,
                }}
                color="red"
                startIcon={<DeleteIcon />}
                onClick={deleteFunc}
              >
                Delete
              </ButtonCommon>
            )}
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default withAuthority(ClosePeriodInfoNode, Authorities.DISCOUNT_READ);
