const Authorities: any = {
  // PRESTO LOCATION APP -----------------------
  // basic info
  BASIC_INFO_READ: "BASIC_INFO:READ",
  BASIC_INFO_UPDATE: "BASIC_INFO:UPDATE",

  // opening times
  OPENING_TIME_READ: "OPENING_TIME:READ",
  OPENING_TIME_CREATE: "OPENING_TIME:CREATE",
  OPENING_TIME_UPDATE: "OPENING_TIME:UPDATE",
  OPENING_TIME_DELETE: "OPENING_TIME:DELETE",

  USERS_PERMISSION_READ: "USERS_PERMISSION:READ",
  USERS_PERMISSION_CREATE: "USERS_PERMISSION:CREATE",
  USERS_PERMISSION_UPDATE: "USERS_PERMISSION:UPDATE",
  USERS_PERMISSION_DELETE: "USERS_PERMISSION:DELETE",

  // location
  LOCATION_DASHBOARD_READ: "LOCATION_DASHBOARD:READ",
  LOCATION_DASHBOARD_CREATE: "LOCATION_DASHBOARD:CREATE",
  LOCATION_DASHBOARD_UPDATE: "LOCATION_DASHBOARD:UPDATE",
  LOCATION_DASHBOARD_DELETE: "LOCATION_DASHBOARD:DELETE",

  PAYMENT_CONFIGURATION_READ: "PAYMENT_CONFIGURATION:READ",
  PAYMENT_CONFIGURATION_CREATE: "PAYMENT_CONFIGURATION:CREATE",
  PAYMENT_CONFIGURATION_UPDATE: "PAYMENT_CONFIGURATION:UPDATE",
  PAYMENT_CONFIGURATION_DELETE: "PAYMENT_CONFIGURATION:DELETE",

  // PRESTO EATPRESTO APP ----------------------
  // delivery info
  DELIVERY_INFO_READ: "DELIVERY_INFO:READ",
  DELIVERY_INFO_CREATE: "DELIVERY_INFO:CREATE",
  DELIVERY_INFO_UPDATE: "DELIVERY_INFO:UPDATE",
  DELIVERY_INFO_DELETE: "DELIVERY_INFO:DELETE",

  // discounts
  DISCOUNT_READ: "DISCOUNT:READ",
  DISCOUNT_CREATE: "DISCOUNT:CREATE",
  DISCOUNT_UPDATE: "DISCOUNT:UPDATE",
  DISCOUNT_DELETE: "DISCOUNT:DELETE",

  // orders
  ORDERS_READ: "ORDERS:READ",
  ORDERS_CREATE: "ORDERS:CREATE",
  ORDERS_UPDATE: "ORDERS:UPDATE",
  ORDERS_DELETE: "ORDERS:DELETE",

  // Dashboard
  DASHBOARD_READ: "DASHBOARD:READ",
  DASHBOARD_CREATE: "DASHBOARD:CREATE",
  DASHBOARD_UPDATE: "DASHBOARD:UPDATE",
  DASHBOARD_DELETE: "DASHBOARD:DELETE",

  // Banners
  BANNERS_READ: "BANNERS:READ",
  BANNERS_CREATE: "BANNERS:CREATE",
  BANNERS_UPDATE: "BANNERS:UPDATE",
  BANNERS_DELETE: "BANNERS:DELETE",

  // PRESTO MENU APP ----------------------
  // Dashboard
  MENU_DASHBOARD_READ: "MENU_DASHBOARD:READ",
  MENU_DASHBOARD_CREATE: "MENU_DASHBOARD:CREATE",
  MENU_DASHBOARD_UPDATE: "MENU_DASHBOARD:UPDATE",
  MENU_DASHBOARD_DELETE: "MENU_DASHBOARD:DELETE",

  // Menus
  MENU_READ: "MENU:READ",
  MENU_CREATE: "MENU:CREATE",
  MENU_UPDATE: "MENU:UPDATE",
  MENU_DELETE: "MENU:DELETE",

  // PRESTO SALES APP ----------------------
  // void transaction info
  SALES_VOID_TRANSACTION_INFO_READ: "SALES_VOID_TRANSACTION_INFO:READ",
  SALES_VOID_TRANSACTION_INFO_CREATE: "SALES_VOID_TRANSACTION_INFO:CREATE",
  SALES_VOID_TRANSACTION_INFO_UPDATE: "SALES_VOID_TRANSACTION_INFO:UPDATE",
  SALES_VOID_TRANSACTION_INFO_DELETE: "SALES_VOID_TRANSACTION_INFO:DELETE",

  // sale payment type info
  SALE_PAYMENT_TYPE_UPDATE_READ: "SALE_PAYMENT_TYPE_UPDATE_READ:READ",
  SALE_PAYMENT_TYPE_UPDATE_CREATE: "SALE_PAYMENT_TYPE_UPDATE:CREATE",
  SALE_PAYMENT_TYPE_UPDATE_UPDATE: "SALE_PAYMENT_TYPE_UPDATE:UPDATE",
  SALE_PAYMENT_TYPE_UPDATE_DELETE: "SALE_PAYMENT_TYPE_UPDATE:DELETE",

  SALE_READ: "SALE:READ",
  SALE_CREATE: "SALE:CREATE",
  SALE_UPDATE: "SALE:UPDATE",
  SALE_DELETE: "SALE:DELETE",

  // PRESTO TASK APP ----------------------
  // task
  TASKS_READ: "TASKS:READ",
  TASKS_CREATE: "TASKS:CREATE",
  TASKS_UPDATE: "TASKS:UPDATE",
  TASKS_DELETE: "TASKS:DELETE",

  // PRESTO INVENTORY APP ----------------------
  // Inventory
  INVENTORY_READ: "INVENTORY:READ",
  INVENTORY_CREATE: "INVENTORY:CREATE",
  INVENTORY_UPDATE: "INVENTORY:UPDATE",
  INVENTORY_DELETE: "INVENTORY:DELETE",

  INVENTORY_ADMIN_READ: "INVENTORY_ADMIN:READ",
  INVENTORY_ADMIN_CREATE: "INVENTORY_ADMIN:CREATE",
  INVENTORY_ADMIN_UPDATE: "INVENTORY_ADMIN:UPDATE",
  INVENTORY_ADMIN_DELETE: "INVENTORY_ADMIN:DELETE",

  INVENTORY_MOMENT_READ: "INVENTORY_MOMENT:READ",
  INVENTORY_MOMENT_CREATE: "INVENTORY_MOMENT:CREATE",
  INVENTORY_MOMENT_UPDATE: "INVENTORY_MOMENT:UPDATE",
  INVENTORY_MOMENT_DELETE: "INVENTORY_MOMENT:DELETE",

  STOCK_CONFIGURATION_READ: "STOCK_CONFIGURATION:READ",
  STOCK_CONFIGURATION_CREATE: "STOCK_CONFIGURATION:CREATE",
  STOCK_CONFIGURATION_UPDATE: "STOCK_CONFIGURATION:UPDATE",
  STOCK_CONFIGURATION_DELETE: "STOCK_CONFIGURATION:DELETE",
};
export default Authorities;

export const authoritiesMapping: any = {
  // PRESTO LOCATION APP ----------------------
  // basic info
  BASIC_INFO_READ: ["BASIC_INFO_READ"],
  BASIC_INFO_UPDATE: ["BASIC_INFO_UPDATE"],

  // opening times
  OPENING_TIME_READ: [
    "OPENING_TIME",
    "OPENING_TIME_GET_ALL",
    "OPENING_TIME_GET",
    "OPENING_TIME_READ",
  ],
  OPENING_TIME_CREATE: ["OPENING_TIME_ADD", "OPENING_TIME_CREATE"],
  OPENING_TIME_UPDATE: ["OPENING_TIME_UPDATE"],
  OPENING_TIME_DELETE: ["OPENING_TIME_DELETE"],

  USERS_PERMISSION_READ: [
    "USERS_PERMISSION",
    "USERS_PERMISSION_GET_ALL",
    "USERS_PERMISSION_GET",
    "USERS_PERMISSION_READ",
  ],
  USERS_PERMISSION_CREATE: ["USERS_PERMISSION_ADD", "USERS_PERMISSION_CREATE"],
  USERS_PERMISSION_UPDATE: ["USERS_PERMISSION_UPDATE"],
  USERS_PERMISSION_DELETE: ["USERS_PERMISSION_DELETE"],

  // location
  LOCATION_DASHBOARD_READ: [
    "LOCATION_DASHBOARD",
    "LOCATION_DASHBOARD_GET_ALL",
    "LOCATION_DASHBOARD_GET",
    "LOCATION_DASHBOARDE_READ",
  ],
  LOCATION_DASHBOARD_CREATE: [
    "LOCATION_DASHBOARD_ADD",
    "LOCATION_DASHBOARD_CREATE",
  ],
  LOCATION_DASHBOARD_UPDATE: ["LOCATION_DASHBOARD_UPDATE"],
  LOCATION_DASHBOARD_DELETE: ["LOCATION_DASHBOARD_DELETE"],

  PAYMENT_CONFIGURATION_READ: [
    "PAYMENT_CONFIGURATION_GET_ALL",
    "PAYMENT_CONFIGURATION_GET",
    "PAYMENT_CONFIGURATION_READ",
  ],
  PAYMENT_CONFIGURATION_CREATE: [
    "PAYMENT_CONFIGURATION_CREATE",
    "PAYMENT_CONFIGURATION_ADD",
  ],
  PAYMENT_CONFIGURATION_UPDATE: [
    "PAYMENT_CONFIGURATION_UPDATE",
  ],
  PAYMENT_CONFIGURATION_DELETE: [
    "PAYMENT_CONFIGURATION_DELETE",
  ],

  // PRESTO EATPRESTO APP -----------------------
  // delivery info
  DELIVERY_INFO_READ: [
    "DELIVERY_INFO_READ",
    "DELIVERY_INFO_GET",
    "DELIVERY_INFO_GET_ALL",
  ],
  DELIVERY_INFO_CREATE: ["DELIVERY_INFO_CREATE", "DELIVERY_INFO_ADD"],
  DELIVERY_INFO_UPDATE: ["DELIVERY_INFO_UPDATE"],
  DELIVERY_INFO_DELETE: ["DELIVERY_INFO_DELETE"],

  // discounts
  DISCOUNT_READ: ["DISCOUNT_GET_ALL", "DISCOUNT_GET", "DISCOUNT_READ"],
  DISCOUNT_CREATE: ["DISCOUNT_CREATE", "DISCOUNT_ADD"],
  DISCOUNT_UPDATE: ["DISCOUNT_UPDATE"],
  DISCOUNT_DELETE: ["DISCOUNT_DELETE"],

  // orders
  ORDERS_READ: ["ORDERS_GET_ALL", "ORDERS_GET", "ORDERS_READ"],
  ORDERS_CREATE: ["ORDERS_CREATE", "ORDERS_ADD"],
  ORDERS_UPDATE: ["ORDERS_UPDATE"],
  ORDERS_DELETE: ["ORDERS_DELETE"],

  // Dashboard
  DASHBOARD_READ: [
    "DASHBOARD_TIME",
    "DASHBOARD_GET_ALL",
    "DASHBOARD_GET",
    "DASHBOARD_READ",
  ],
  DASHBOARD_CREATE: ["DASHBOARD_CREATE", "DASHBOARD_ADD"],
  DASHBOARD_UPDATE: ["DASHBOARD_UPDATE"],
  DASHBOARD_DELETE: ["DASHBOARD_DELETE"],

  // PRESTO MENU APP -----------------------
  // Menus
  MENU_READ: ["MENU_TIME", "MENU_GET_ALL", "MENU_GET", "MENU_READ"],
  MENU_CREATE: ["MENU_CREATE", "MENU_ADD"],
  MENU_UPDATE: ["MENU_UPDATE"],
  MENU_DELETE: ["MENU_DELETE"],

  // Menu Dashboard
  MENU_DASHBOARD_READ: [
    "MENU_DASHBOARD_TIME",
    "MENU_DASHBOARD_GET_ALL",
    "MENU_DASHBOARD_GET",
    "MENU_DASHBOARD_READ",
  ],
  MENU_DASHBOARD_CREATE: ["MENU_DASHBOARD_CREATE", "MENU_DASHBOARD_ADD"],
  MENU_DASHBOARD_UPDATE: ["MENU_DASHBOARD_UPDATE"],
  MENU_DASHBOARD_DELETE: ["MENU_DASHBOARD_DELETE"],

  // banners
  BANNERS_READ: [
    "BANNERS_TIME",
    "BANNERS_GET_ALL",
    "BANNERS_GET",
    "BANNERS_READ",
  ],
  BANNERS_CREATE: ["BANNERS_CREATE", "BANNERS_ADD"],
  BANNERS_UPDATE: ["BANNERS_UPDATE"],
  BANNERS_DELETE: ["BANNERS_DELETE"],

  // PRESTO SALES APP -----------------------
  // Void transaction info
  SALES_VOID_TRANSACTION_INFO_READ: [
    "SALES_VOID_TRANSACTION_INFO_READ",
    "SALES_VOID_TRANSACTION_INFO_GET",
    "SALES_VOID_TRANSACTION_INFO_GET_ALL",
  ],
  SALES_VOID_TRANSACTION_INFO_CREATE: [
    "SALES_VOID_TRANSACTION_INFO_CREATE",
    "SALES_VOID_TRANSACTION_INFO_ADD",
  ],
  SALES_VOID_TRANSACTION_INFO_UPDATE: ["SALES_VOID_TRANSACTION_INFO_UPDATE"],
  SALES_VOID_TRANSACTION_INFO_DELETE: ["SALES_VOID_TRANSACTION_INFO_DELETE"],

  SALE_PAYMENT_TYPE_UPDATE_READ: [
    "SALE_PAYMENT_TYPE_UPDATE_READ",
    "SALE_PAYMENT_TYPE_UPDATE_GET",
    "SALE_PAYMENT_TYPE_UPDATE_GET_ALL",
  ],
  SALE_PAYMENT_TYPE_UPDATE_CREATE: [
    "SALE_PAYMENT_TYPE_UPDATE_CREATE",
    "SALE_PAYMENT_TYPE_UPDATE_ADD",
  ],
  SALE_PAYMENT_TYPE_UPDATE_UPDATE: ["SALE_PAYMENT_TYPE_UPDATE_UPDATE"],
  SALE_PAYMENT_TYPE_UPDATE_DELETE: ["SALE_PAYMENT_TYPE_UPDATE_DELETE"],

  SALE_READ: [
    "SALE_READ",
    "SALE_GET",
    "SALE_GET_ALL",
  ],
  SALE_CREATE: [
    "SALE_CREATE",
    "SALE_ADD",
  ],
  SALE_UPDATE: ["SALE_UPDATE"],
  SALE_DELETE: ["SALE_DELETE"],

  // Tasks
  TASKS_READ: ["TASKS_TIME", "TASKS_GET_ALL", "TASKS_GET", "TASKS_READ"],
  TASKS_CREATE: ["TASKS_CREATE", "TASKS_ADD"],
  TASKS_UPDATE: ["TASKS_UPDATE"],
  TASKS_DELETE: ["TASKS_DELETE"],

  // Inventory
  INVENTORY_READ: ["INVENTORY_GET_ALL", "INVENTORY_GET", "INVENTORY_READ"],
  INVENTORY_CREATE: ["INVENTORY_CREATE", "INVENTORY_ADD"],
  INVENTORY_UPDATE: ["INVENTORY_UPDATE"],
  INVENTORY_DELETE: ["INVENTORY_DELETE"],

  INVENTORY_ADMIN_READ: ["INVENTORY_ADMIN_GET_ALL", "INVENTORY_ADMIN_GET", "INVENTORY_ADMIN_READ"],
  INVENTOR_ADMIN_CREATE: ["INVENTORY_ADMIN_CREATE", "INVENTORY_ADMIN_ADD"],
  INVENTORY_ADMIN_UPDATE: ["INVENTORY_ADMIN_UPDATE"],
  INVENTORY_ADMIN_DELETE: ["INVENTORY_ADMIN_DELETE"],

  INVENTORY_MOMENT_READ: ["INVENTORY_MOMENT_GET_ALL", "INVENTORY_MOMENT_GET", "INVENTORY_MOMENT_READ"],
  INVENTORY_MOMENT_CREATE: ["INVENTORY_MOMENT_CREATE", "INVENTORY_MOMENT_ADD"],
  INVENTORY_MOMENT_UPDATE: ["INVENTORY_MOMENT_UPDATE"],
  INVENTORY_MOMENT_DELETE: ["INVENTORY_MOMENT_DELETE"],

  // Inventory
  STOCK_CONFIGURATION_READ: [
    "STOCK_CONFIGURATION_GET_ALL",
    "STOCK_CONFIGURATION_GET",
    "STOCK_CONFIGURATION_READ",
  ],
  STOCK_CONFIGURATION_CREATE: [
    "STOCK_CONFIGURATION_CREATE",
    "STOCK_CONFIGURATION_ADD",
  ],
  STOCK_CONFIGURATION_UPDATE: [
    "STOCK_CONFIGURATION_UPDATE",
  ],
  STOCK_CONFIGURATION_DELETE: [
    "STOCK_CONFIGURATION_DELETE",
  ],
};

// priority levels
// create: all permissions
// delete: read, update, delete
// update: read, update
// read: read only

export type Authority =
  // basic info
  | "BASIC_INFO:READ"
  | "BASIC_INFO:UPDATE"

  // opening times
  | "OPENING_TIME:READ"
  | "OPENING_TIME:CREATE"
  | "OPENING_TIME:UPDATE"
  | "OPENING_TIME:DELETE"

  | "USERS_PERMISSION:READ"
  | "USERS_PERMISSION:CREATE"
  | "USERS_PERMISSION:UPDATE"
  | "USERS_PERMISSION:DELETE"

  // location
  | "LOCATION_DASHBOARD:READ"
  | "LOCATION_DASHBOARD:CREATE"
  | "LOCATION_DASHBOARD:UPDATE"
  | "LOCATION_DASHBOARD:DELETE"

  | "PAYMENT_CONFIGURATION:READ"
  | "PAYMENT_CONFIGURATION:CREATE"
  | "PAYMENT_CONFIGURATION:UPDATE"
  | "PAYMENT_CONFIGURATION:DELETE"

  // delivery info
  | "DELIVERY_INFO:READ"
  | "DELIVERY_INFO:CREATE"
  | "DELIVERY_INFO:UPDATE"
  | "DELIVERY_INFO:DELETE"

  // delivery info
  | "DISCOUNT:READ"
  | "DISCOUNT:CREATE"
  | "DISCOUNT:UPDATE"
  | "DISCOUNT:DELETE"

  // order info
  | "ORDERS:READ"
  | "ORDERS:CREATE"
  | "ORDERS:UPDATE"
  | "ORDERS:DELETE"

  // dashboard info
  | "DASHBOARD:READ"
  | "DASHBOARD:CREATE"
  | "DASHBOARD:UPDATE"
  | "DASHBOARD:DELETE"

  // Banners info
  | "BANNERS:READ"
  | "BANNERS:CREATE"
  | "BANNERS:UPDATE"
  | "BANNERS:DELETE"

  // Menu info
  | "MENU:READ"
  | "MENU:CREATE"
  | "MENU:UPDATE"
  | "MENU:DELETE"

  // Menu Dashboard info
  | "MENU_DASHBOARD:READ"
  | "MENU_DASHBOARD:CREATE"
  | "MENU_DASHBOARD:UPDATE"
  | "MENU_DASHBOARD:DELETE"

  // Sales void transaction info
  | "SALES_VOID_TRANSACTION_INFO:READ"
  | "SALES_VOID_TRANSACTION_INFO:CREATE"
  | "SALES_VOID_TRANSACTION_INFO:UPDATE"
  | "SALES_VOID_TRANSACTION_INFO:DELETE"

  // Sales sale update payment type info
  | "SALE_PAYMENT_TYPE_UPDATE:READ"
  | "SALE_PAYMENT_TYPE_UPDATE:CREATE"
  | "SALE_PAYMENT_TYPE_UPDATE:UPDATE"
  | "SALE_PAYMENT_TYPE_UPDATE:DELETE"

  | "SALE:READ"
  | "SALE:CREATE"
  | "SALE:UPDATE"
  | "SALE:DELETE"

  // Sales sale update payment type info
  | "INVENTORY:READ"
  | "INVENTORY:CREATE"
  | "INVENTORY:UPDATE"
  | "INVENTORY:DELETE"

  | "INVENTORY_ADMIN:READ"
  | "INVENTORY_ADMIN:CREATE"
  | "INVENTORY_ADMIN:UPDATE"
  | "INVENTORY_ADMIN:DELETE"

  | "INVENTORY_MOMENT:READ"
  | "INVENTORY_MOMENT:CREATE"
  | "INVENTORY_MOMENT:UPDATE"
  | "INVENTORY_MOMENT:DELETE"

  // Sales sale update payment type info
  | "STOCK_CONFIGURATION:READ"
  | "STOCK_CONFIGURATION:CREATE"
  | "STOCK_CONFIGURATION:UPDATE"
  | "STOCK_CONFIGURATION:DELETE";
