import { Button, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import DefaultStatus from "../../../../components/status/DefaultStatus";
import Status from "../../../../components/status/Status";
import { Theme } from "../../../../types/customTheme";
import OrdersInfoChannel from "./OrdersInfoChannel";
import deliverect from "../../../../assets/images/deliverect.png";
import deliveroo from "../../../../assets/images/deliveroo.png";
import eatpresto from "../../../../assets/images/eatpresto.png";
import justeat from "../../../../assets/images/justeat.png";
import other from "../../../../assets/images/other.png";
import ubereats from "../../../../assets/images/ubereats.png";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OrderDetailsType from "./OrderDetailsType";
import moment from "moment";
import CardCommon from "../../../../components/card/CardCommon";
import { getFilterListFromArrayObject } from "../../../../utils/commonArrayMap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    orderChannelStyle: {
      paddingLeft: "20px",
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "10px",
      },
    },
    orderTimeStyle: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("md")]: {
        paddingLeft: "28px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    deliveryTypeStyle: {
      textTransform: "capitalize",
    },
    gridField: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "11px",
      placeItems: "center",
      fontSize: "13px",
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        paddingLeft: "0px",
      },
    },
    gridFieldLocationName: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
      },
    },
    gridFieldDisplayRef: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      marginLeft: "28px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        marginLeft: "0px",
      },
    },
    gridFieldDisplayRefOneNumOfLocation: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        justifyContent: "center",
        marginTop: "6px",
        marginLeft: "0px",
      },
    },
    gridFieldLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9px",
      marginTop: "2px",
      marginBottom: "8px",
      placeItems: "center",
      fontSize: "13px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "0px",
        fontSize: "14px",
        justifyContent: "center",
      },
    },
    gridFieldFirstColumn: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      fontSize: "13px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        marginTop: "12px",
        justifyContent: "center",
      },
    },
    detailsDropDownIcon: {
      color: theme.palette.custom.orange.contrastText,
      [theme.breakpoints.down("md")]: {
        visibility: "hidden",
      },
    },
    nameStyle: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
    },
    nameAlign: {
      textTransform: "lowercase",
      display: "flex",
      justifyContent: "start",
    },
  }),
);

export interface DiscountInfoNodeProps {
  nodeData: any;
  locationSelectorList: any;
  numberOfLocations: any;
}

/* This component creates the data for the table using the data received from the API call.  */
const OrdersInfoNode: React.FunctionComponent<DiscountInfoNodeProps> = ({
  nodeData,
  locationSelectorList,
  numberOfLocations,
}) => {
  const [orderTime, setOrderTime] = useState("");
  const [dueTime, setDueTime] = useState("");
  const [locationName, setLocationName] = useState("");
  const [displayRef, setDisplayRef] = useState("");
  const [orderChannelImage, setOrderChannelImage] = useState("");
  const [orderChannel, setOrderChannel] = useState("");
  const [openOrderDetailsCard, setOpenOrderDetailsCard] = useState(false);
  const [status, setStatus] = useState("");
  const [createdTime, setCreatedTime] = useState("");
  const [posTime, setPosTime] = useState("");
  const [acceptedTime, setAcceptedTime] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    name: "",
    email: "",
  });

  /* Update states after getting the order information. */
  const setNodeDataToState = useCallback((nodeValues) => {
    const {
      orderTime,
      dueTime,
      locationId,
      displayRef,
      orderChannel,
      status,
      created,
      posTime,
      acceptedTime,
      deliveryType,
      customer,
    } = nodeValues;
    setDeliveryType(deliveryType);
    setOrderTime(moment(orderTime).format("MMM D, YYYY HH:mm"));
    setDueTime(moment(dueTime).format("MMM D, YYYY HH:mm"));

    // Get the location name using locationId
    const locationDetails = getFilterListFromArrayObject(
      locationSelectorList,
      locationId,
    );

    // Only if there is a detail of the location, A location name entered into a state.
    if (!_.isEmpty(locationDetails)) {
      setLocationName(locationDetails[0].label);
    } else {
      // If there is no description of the location, the state will be entered as 'Unknown location'.
      setLocationName("Unknown location");
    }
    setOrderChannel(orderChannel);
    setDisplayRef(displayRef);

    // If the order channel is 'eatpresto', the image 'eatpresto' will be inserted into the state.
    if (orderChannel === "eatpresto") {
      setOrderChannelImage(eatpresto);
    } else if (orderChannel === "deliverect") {
      // If the order channel is 'deliverect', the image 'deliverect' will be inserted into the state.
      setOrderChannelImage(deliverect);
    } else if (orderChannel === "justeat") {
      // If the order channel is 'justeat', the image 'justeat' will be inserted into the state.
      setOrderChannelImage(justeat);
    } else if (orderChannel === "ubereats") {
      // If the order channel is 'ubereats', the image 'ubereats' will be inserted into the state.
      setOrderChannelImage(ubereats);
    } else if (orderChannel === "deliveroo") {
      // If the order channel is 'deliveroo', the image 'deliveroo' will be inserted into the state.
      setOrderChannelImage(deliveroo);
    } else if (orderChannel === "other") {
      // If the order channel is 'other', the image 'other' will be inserted into the state.
      setOrderChannelImage(other);
    }

    setStatus(status);
    setCreatedTime(moment(created).format("MMM D, YYYY HH:mm"));
    setPosTime(moment(posTime).format("MMM D, YYYY HH:mm"));
    setAcceptedTime(moment(acceptedTime).format("MMM D, YYYY HH:mm"));
    setCustomer(customer);
  }, []);

  useEffect(() => {
    setNodeDataToState(nodeData);
  }, [nodeData, setNodeDataToState]);

  const classes = useStyles();

  return (
    <>
      <CardCommon backgroundColor={"entity_background"}>
        <Button
          style={{ width: "100%", margin: "0px", padding: "0px" }}
          onClick={() => setOpenOrderDetailsCard(!openOrderDetailsCard)}
        >
          <Grid container>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              lg={1}
              className={classes.gridFieldFirstColumn}
            >
              <DefaultStatus status={status} />
              <div className={classes.orderChannelStyle}>
                <OrdersInfoChannel orderChannelImage={orderChannelImage} />
              </div>
            </Grid>
            <Grid item xs={12} lg={2} className={classes.orderTimeStyle}>
              {orderTime}
            </Grid>
            <Grid
              style={{ textAlign: "left" }}
              item
              xs={12}
              lg={2}
              className={classes.gridField}
            >
              {dueTime}
            </Grid>
            <Grid
              style={{ textAlign: "left", textTransform: "capitalize" }}
              item
              xs={12}
              lg={numberOfLocations > 1 ? 3 : 2}
              className={classes.gridField}
            >
              <Grid container>
                {numberOfLocations > 1 && (
                  <Grid
                    item
                    xs={12}
                    lg
                    className={classes.gridFieldLocationName}
                  >
                    {locationName}
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  lg
                  className={
                    numberOfLocations > 1
                      ? classes.gridFieldDisplayRef
                      : classes.gridFieldDisplayRefOneNumOfLocation
                  }
                >
                  {displayRef}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={1} className={classes.gridField}>
              <div className={classes.deliveryTypeStyle}>{deliveryType}</div>
            </Grid>
            <Grid
              item
              xs={12}
              lg={numberOfLocations > 1 ? 1 : 2}
              className={classes.gridField}
            >
              <div className={classes.nameStyle}>
                <div
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    justifyContent: "start",
                    marginRight: "4px",
                  }}
                >
                  {customer.firstName}
                </div>
                <div className={classes.nameAlign}>{customer.lastName}</div>
              </div>
            </Grid>

            <Grid item xs={12} lg={2} className={classes.gridFieldLastColumn}>
              <Status label={status} status={status} />
              {openOrderDetailsCard ? (
                <ArrowDropUpIcon className={classes.detailsDropDownIcon} />
              ) : (
                <ArrowDropDownIcon className={classes.detailsDropDownIcon} />
              )}
            </Grid>
          </Grid>
        </Button>
      </CardCommon>
      {openOrderDetailsCard && (
        <OrderDetailsType
          nodeData={nodeData}
          locationName={locationName}
          orderChannel={orderChannel}
          orderChannelImage={orderChannelImage}
          status={status}
          orderTime={orderTime}
          createdTime={createdTime}
          posTime={posTime}
          acceptedTime={acceptedTime}
        />
      )}
    </>
  );
};

export default withAuthority(OrdersInfoNode, Authorities.ORDERS_READ);
