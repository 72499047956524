import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import DeliveryInfo from "../pages/DeliveryInfoPage";
import Discounts from "../pages/DiscountsPage";
import ThemeCustomizationPage from "../pages/ThemeCustomizationPage";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BookmarkBorder from "@material-ui/icons/BookmarkBorder";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import { Icon } from "@iconify/react";
import Orders from "../pages/OrdersPage";
import BannersPage from "../pages/BannersPage";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dashboard from "../pages/Dashboard";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import ClosePeriodPage from "../pages/ClosePeriodPage";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "DASHBOARD",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
    {
      id: "ORDERS",
      title: "Orders",
      path: "/orders",
      icon: <BookmarkBorder />,
      isAuthorized: getIsAuthorized(Authorities.ORDERS_READ),
    },
    {
      id: "DISCOUNTS",
      title: "Discounts",
      path: "/discounts",
      icon: <Icon icon="tabler:discount-2" height="26" width="26" />,
      isAuthorized: getIsAuthorized(Authorities.DISCOUNT_READ),
    },
    {
      id: "DELIVERY_INFO",
      title: "Delivery Info",
      path: "/delivery-info",
      icon: <LocalShippingIcon />,
      isAuthorized: getIsAuthorized(Authorities.DELIVERY_INFO_READ),
    },
    {
      id: "THEME_CUSTOMIZATION",
      title: "Theme",
      path: "/theme",
      icon: <ColorLensIcon />,
      isAuthorized: getIsAuthorized(Authorities.DISCOUNT_READ),
    },
    {
      id: "BANNERS",
      title: "Banners",
      path: "/banners",
      icon: <ArtTrackIcon />,
      isAuthorized: getIsAuthorized(Authorities.BANNERS_READ),
    },
    {
      id: "CLOSURE DATES",
      title: "Closure Dates",
      path: "/closureDates",
      icon: <QueryBuilderIcon />,
      isAuthorized: getIsAuthorized(Authorities.DASHBOARD_READ),
    },
  ];
  return sidebarRoute;
};
export interface EatprestoAppRoutesProps {}

const EatprestoAppRoutes: React.FunctionComponent<
  EatprestoAppRoutesProps
> = () => {
  return (
    <Switch>
      <Route
        path="/:locationId/eatpresto/delivery-info"
        component={DeliveryInfo}
        exact
      />
      <Route
        path="/:locationId/eatpresto/discounts"
        component={Discounts}
        exact
      />
      <Route path="/:locationId/eatpresto/orders" component={Orders} exact />
      <Route
        path="/:locationId/eatpresto/theme"
        component={ThemeCustomizationPage}
        exact
      />
      <Route
        path="/:locationId/eatpresto/banners"
        component={BannersPage}
        exact
      />
      <Route
        path="/:locationId/eatpresto/closureDates"
        component={ClosePeriodPage}
        exact
      />
      <Route path="/:locationId/eatpresto" component={Dashboard} exact />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default EatprestoAppRoutes;
