import Dialog from "@material-ui/core/Dialog";
import * as React from "react";
import { CustomTheme } from "../../types/customTheme";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import DialogCommonDefault from "../dialogs/DialogCommonDefault";
import TextfieldDefault from "../textField/TextfieldDefault";
import ButtonCommon from "../buttons/ButtonCommon";
import { buttonColors } from "../../utils/enum";

export interface ConditionalModalProps {
  isOpenNewModal: boolean;
  setIsOpenNewModal: any;
  isLoadingToast: any;
  handleReset: any;
  handleOpenEditMapProductsItemsModal: any;
  stockItemId: any;
  displaySuffix: any;
  setIsOpenMapProducts: any;
  setIsOpenCreateStockItemsModal: any;
  handleResetCreatedData: any;
  name: any;
}

/* Common modal UI.. */
const ConditionalModal: React.FunctionComponent<ConditionalModalProps> = ({
  isOpenNewModal,
  setIsOpenNewModal,
  isLoadingToast,
  handleReset,
  handleOpenEditMapProductsItemsModal,
  stockItemId,
  displaySuffix,
  setIsOpenMapProducts,
  setIsOpenCreateStockItemsModal,
  handleResetCreatedData,
  name,
}) => {
  const theme: CustomTheme = useTheme();

  return (
    <DialogCommonDefault
      open={isOpenNewModal}
      setOpen={setIsOpenNewModal}
      isNeedFixedHeight={false}
    >
      <DialogTitle>
        <Typography variant="h6">{name}</Typography>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <ButtonCommon
            onClick={() => {
              setIsOpenNewModal(false);
              handleReset();
            }}
            disabled={isLoadingToast}
            variant="contained"
            style={{
              fontSize: 11,
              width: "120px",
              marginRight: "12px",
            }}
            color={buttonColors.CANCEL_BUTTON_COLOR}
          >
            Cancel
          </ButtonCommon>

          <ButtonCommon
            disabled={isLoadingToast}
            onClick={() => {
              setIsOpenNewModal(false);
              handleOpenEditMapProductsItemsModal({
                id: stockItemId,
                displaySuffix: displaySuffix,
              });
              setIsOpenMapProducts(true);
            }}
            variant="contained"
            style={{
              fontSize: 11,
              width: "280px",
              marginRight: "16px",
            }}
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            {`Map products to ${name}`}
          </ButtonCommon>

          <ButtonCommon
            disabled={isLoadingToast}
            onClick={() => {
              setIsOpenNewModal(false);
              setIsOpenCreateStockItemsModal(true);
              handleResetCreatedData();
            }}
            variant="contained"
            style={{
              fontSize: 11,
              width: "280px",
            }}
            color={buttonColors.CREATE_BUTTON_COLOR}
          >
            Add another stock items
          </ButtonCommon>
        </div>
      </DialogContent>
    </DialogCommonDefault>
  );
};

export default ConditionalModal;
