import { Box, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import Authorities from "../../../../auth/authorities";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import withAuthority from "../../../../components/Auth/withAuthority";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import ThemeService from "../../services/ThemeService";
import BannersMap from "./BannersMap";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMoblie";

export interface BannersProps {}

/* Get all theme information and location information using API call at initial load. */
const Banners: React.FunctionComponent<BannersProps> = () => {
  const [themeNodeList, setBThemeNodeList] = useState<any>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [locationData, setLocationData] = useState({ businessDisplayName: "" });

  const match: any = useRouteMatch();

  /* Get all theme information using API call and response data set to themeNodeList state. */
  const getThemeInfo = async () => {
    try {
      const res = await ThemeService.getThemeConfig(match.params.locationId);
      setIsLoading(false);
      setBThemeNodeList(res.data.data);
      setIsLoading(false);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /* Get location information */
  // const fetchBasicInfo = async () => {
  //   try {
  //     const res = await getLocationBasicInfo(match.params.locationId);
  //     setLocationData(res.data.data);
  //   } catch (error) {
  //     setError(
  //       "Error fetching location basic info. Please check your internet connection.",
  //     );
  //   }
  // };

  useEffect(() => {
    document.title = "Eat Presto - Banner";
    setIsLoading(true);
    getThemeInfo();
    // fetchBasicInfo();
  }, []);

  return (
    <div>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <BannersMap
          nodes={themeNodeList}
          isLoading={isLoading}
          getThemeInfo={getThemeInfo}
        />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </div>
  );
};

export default withAuthority(Banners, Authorities.BANNERS_READ);
