import React from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import OrdersInfoNode from "./OrdersInfoNode";
import _ from "lodash";
import SortingMethod from "../../../../components/common/SortingMethod/SortingMethod";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";
import LocationDetailsCard from "../../../../components/common/LocationDetailsCard";

export interface OrdersHeaderProps {
  nodeData: any;
  handleChangeOrderListSorting: any;
  handleRemoveOrderListSorting: any;
  disableButton: any;
  isLoading: any;
  locationSelectorList: any;
  handleOnClickText: any;
  locationSelectedList: any;
  numberOfLocations: any;
  isSort: any;
  headerName: any;
  sortingMethod: any;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    headerContainerStyle: {
      marginTop: "16px",
      [theme.breakpoints.down("md")]: {
        visibility: "hidden",
      },
    },
    headerItemStyle: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerItemStyleCustomerName: {
      display: "flex",
      placeItems: "center",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
        paddingLeft: "0px",
      },
    },
    headerItemStyleDeliveryType: {
      display: "flex",
      placeItems: "center",
      justifyContent: "start",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
    headerFirstItemStyle: {
      display: "flex",
      justifyContent: "flex-start",
      placeItems: "center",
      paddingLeft: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "5px",
      },
    },
    headerItemStatusStyle: {
      [theme.breakpoints.down("xs")]: {
        marginLeft: "15px",
      },
    },
    headerItemStyleLastColumn: {
      display: "flex",
      justifyContent: "flex-end",
      placeItems: "center",
      paddingRight: "9px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "5px",
      },
    },
  }),
);

/* This is order Table header page. In addition, Until the required data for the order 
   table is received from the API call, this will show the loading of the skeleton. */
const OrdersHeader: React.FunctionComponent<OrdersHeaderProps> = ({
  nodeData,
  handleChangeOrderListSorting,
  handleRemoveOrderListSorting,
  disableButton,
  isLoading,
  locationSelectorList,
  handleOnClickText,
  locationSelectedList,
  numberOfLocations,
  isSort,
  headerName,
  sortingMethod,
}) => {
  const maxWidthLocationChips = useMediaQuery("(max-width: 600px)");
  const maxWidth = useMediaQuery("(max-width: 960px)");

  const classes = useStyles();
  return (
    <>
      <Grid
        container
        style={
          maxWidth
            ? {
                marginTop: "8px",
              }
            : {
                marginTop: "8px",
              }
        }
      >
        <Grid item xs={12}>
          <div
            style={
              maxWidthLocationChips
                ? {
                    display: "block",
                  }
                : {
                    display: "flex",
                  }
            }
          >
            {" "}
            <LocationDetailsCard
              locationSelectedList={locationSelectedList}
              handleOnClickText={handleOnClickText}
            />
          </div>
        </Grid>
      </Grid>
      <div className={classes.headerContainerStyle}>
        <CardCommon backgroundColor={"table_header_background"}>
          <Grid container style={{ minHeight: "36px" }}>
            <Grid item xs={1} className={classes.headerFirstItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Channel"}
                headerId={"orderChannel"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "orderChannel" ? isSort : false}
                sortingMethod={
                  headerName === "orderChannel" ? sortingMethod : ""
                }
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Order Time"}
                headerId={"orderTime"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "orderTime" ? isSort : false}
                sortingMethod={headerName === "orderTime" ? sortingMethod : ""}
              />
            </Grid>
            <Grid item xs={2} className={classes.headerItemStyle}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Due Time"}
                headerId={"dueTime"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "dueTime" ? isSort : false}
                sortingMethod={headerName === "dueTime" ? sortingMethod : ""}
              />
            </Grid>
            <Grid
              item
              xs={numberOfLocations > 1 ? 3 : 2}
              className={classes.headerItemStyle}
            >
              <Grid container>
                {numberOfLocations > 1 && (
                  <Grid item md>
                    <SortingMethod
                      handleChangeOrderListSorting={
                        handleChangeOrderListSorting
                      }
                      handleRemoveOrderListSorting={
                        handleRemoveOrderListSorting
                      }
                      headerName={"Location"}
                      headerId={"locationId"}
                      disableButton={disableButton}
                      color={"orange"}
                      isSort={headerName === "locationId" ? isSort : false}
                      sortingMethod={
                        headerName === "locationId" ? sortingMethod : ""
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  md
                  style={
                    numberOfLocations > 1
                      ? { marginLeft: "16px" }
                      : { marginLeft: "0px" }
                  }
                >
                  <SortingMethod
                    handleChangeOrderListSorting={handleChangeOrderListSorting}
                    handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                    headerName={"Order Id"}
                    headerId={"displayRef"}
                    disableButton={disableButton}
                    color={"orange"}
                    isSort={headerName === "displayRef" ? isSort : false}
                    sortingMethod={
                      headerName === "displayRef" ? sortingMethod : ""
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className={classes.headerItemStyleDeliveryType}>
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Delivery Type"}
                headerId={"deliveryType"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "deliveryType" ? isSort : false}
                sortingMethod={
                  headerName === "deliveryType" ? sortingMethod : ""
                }
              />
            </Grid>
            <Grid
              item
              xs={numberOfLocations > 1 ? 1 : 2}
              lg={numberOfLocations > 1 ? 2 : 3}
              style={{ display: "flex", justifyContent: "start" }}
              className={classes.headerItemStyleCustomerName}
            >
              <SortingMethod
                handleChangeOrderListSorting={handleChangeOrderListSorting}
                handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                headerName={"Customer Name"}
                headerId={"customer"}
                disableButton={disableButton}
                color={"orange"}
                isSort={headerName === "customer" ? isSort : false}
                sortingMethod={headerName === "customer" ? sortingMethod : ""}
              />
            </Grid>
            <Grid
              item
              xs={2}
              lg={1}
              className={classes.headerItemStyleLastColumn}
            >
              <div className={classes.headerItemStatusStyle}>
                <SortingMethod
                  handleChangeOrderListSorting={handleChangeOrderListSorting}
                  handleRemoveOrderListSorting={handleRemoveOrderListSorting}
                  headerName={"Status"}
                  headerId={"status"}
                  disableButton={disableButton}
                  color={"orange"}
                  isSort={headerName === "status" ? isSort : false}
                  sortingMethod={headerName === "status" ? sortingMethod : ""}
                />
              </div>
            </Grid>
          </Grid>
        </CardCommon>
      </div>
      <Grid container style={{ marginTop: "1px" }}>
        {!_.isEmpty(nodeData) ? (
          nodeData.map((nodeData: any) => (
            <Grid
              item
              xs={12}
              key={nodeData.id}
              style={{ placeItems: "center", marginTop: "4px" }}
            >
              <OrdersInfoNode
                nodeData={nodeData}
                locationSelectorList={locationSelectorList}
                numberOfLocations={numberOfLocations}
              />
            </Grid>
          ))
        ) : (
          <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>
            <Typography variant="h4" align="center">
              No orders available.
            </Typography>
          </div>
        )}
      </Grid>
    </>
  );
};

export default WithLoading(OrdersHeader);
