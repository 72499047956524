import { Grid } from "@material-ui/core";
import React from "react";
import StockItemDetailsNode from "./StockItemDetailsNode";

export interface DiscountDetailsTypesProps {
  nodeData: Array<any>;
  locationName: any;
  updateTime: any;
  createdTime: any;
  handleOpenEditStockItemsModal: any;
  handleOpenEditMapProductsItemsModal: any;
}

/* This page has 2 tab panels. They are order, and status. 
Information related to those panels is designed to be visible to the user using cards */
const StockItemDetailsType: React.FunctionComponent<
  DiscountDetailsTypesProps
> = ({
  nodeData,
  locationName,
  updateTime,
  createdTime,
  handleOpenEditStockItemsModal,
  handleOpenEditMapProductsItemsModal,
}) => {
  return (
    <Grid container style={{ marginTop: "8px" }}>
      <Grid item xs={12}>
        <StockItemDetailsNode
          nodeData={nodeData}
          locationName={locationName}
          updateTime={updateTime}
          createdTime={createdTime}
          handleOpenEditStockItemsModal={handleOpenEditStockItemsModal}
          handleOpenEditMapProductsItemsModal={
            handleOpenEditMapProductsItemsModal
          }
        />
      </Grid>
    </Grid>
  );
};

export default StockItemDetailsType;
