import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Authorities from "../../../../../auth/authorities";
import withAuthority from "../../../../../components/Auth/withAuthority";
import CardCommon from "../../../../../components/card/CardCommon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import _ from "lodash";
import OddStages from "./OddStages";
import EvenStages from "./EvenStages";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    arrowColor: {
      color: theme.palette.custom["yellow"].main,
    },
    cardColor: {
      backgroundColor: theme.palette.background.imgBackground,
      height: "48px",
      zIndex: 0,
      cursor: "pointer",
      marginTop: "12px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorYellowHeader: {
      backgroundColor: theme.palette.custom["yellow"].main,
      color: theme.palette.custom["yellow"].contrastText,
      height: "28px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorBlueHeader: {
      backgroundColor: theme.palette.custom["blue"].main,
      color: theme.palette.custom["blue"].contrastText,
      height: "28px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorGreenHeader: {
      backgroundColor: theme.palette.custom["green"].main,
      color: theme.palette.custom["green"].contrastText,
      height: "28px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorOrangeHeader: {
      backgroundColor: theme.palette.custom["orange"].main,
      color: theme.palette.custom["orange"].contrastText,
      height: "28px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorRedHeader: {
      backgroundColor: theme.palette.custom["red"].main,
      color: theme.palette.custom["red"].contrastText,
      height: "28px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    cardColorStage: {
      backgroundColor: theme.palette.background.imgBackground,
      height: "40px",
      zIndex: 0,
      cursor: "pointer",
    },
    gridButtonStyle: {
      paddingLeft: "8px",
      paddingRight: "28px",
      zIndex: 0,
    },
    dropDownButton: {
      zIndex: 50,
      width: "28px",
      height: "28px",
    },
    stageYellowColorBoxStyle: {
      backgroundColor: theme.palette.custom["yellow"].main,
      width: "8px",
      marginRight: "16px",
      height: "38px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    stageBlueColorBoxStyle: {
      backgroundColor: theme.palette.custom["blue"].main,
      width: "8px",
      marginRight: "16px",
      height: "38px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    stageRedColorBoxStyle: {
      backgroundColor: theme.palette.custom["red"].main,
      width: "8px",
      marginRight: "16px",
      height: "38px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    stageGreenColorBoxStyle: {
      backgroundColor: theme.palette.custom["green"].main,
      width: "8px",
      marginRight: "16px",
      height: "38px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
    stageOrangeColorBoxStyle: {
      backgroundColor: theme.palette.custom["orange"].main,
      width: "8px",
      marginRight: "16px",
      height: "38px",
      borderRadius: "10px",
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  }),
);

export interface BannersProps {
  handleStageOneModifierEditPageOpen: any;
  handleItemDropDownOpen: any;
  handleItemDropDownClose: any;
  allStageIndex: any;
  category: any;
  handleStageTwoModifierEditPageOpen: any;
}

/* This component shows the 'byo' details of menu information. */
const ByoModal: React.FunctionComponent<BannersProps> = ({
  handleStageOneModifierEditPageOpen,
  handleItemDropDownOpen,
  handleItemDropDownClose,
  allStageIndex,
  category,
  handleStageTwoModifierEditPageOpen,
}) => {
  let levelOfStage = 0;

  /* Change the subProducts header color. */
  const handleStyleHeader = (levelOfStage: any) => {
    let levelOfStageDividedFive = parseInt((levelOfStage / 5).toString());
    if (levelOfStageDividedFive > 0) {
      levelOfStageDividedFive = levelOfStageDividedFive * 5;
    }

    switch (levelOfStage - levelOfStageDividedFive) {
      case 4:
        return classes.cardColorOrangeHeader;
        break;
      case 3:
        return classes.cardColorGreenHeader;
        break;
      case 2:
        return classes.cardColorRedHeader;
        break;
      case 1:
        return classes.cardColorBlueHeader;
        break;
      case 0:
        return classes.cardColorYellowHeader;
        break;
      default:
        break;
    }
  };

  /* Change the subProducts body color. */
  const headerStyleBody = (levelOfStage: any) => {
    let levelOfStageDividedFive = parseInt((levelOfStage / 5).toString());
    if (levelOfStageDividedFive > 0) {
      levelOfStageDividedFive = levelOfStageDividedFive * 5;
    }

    switch (levelOfStage - levelOfStageDividedFive) {
      case 4:
        return classes.stageOrangeColorBoxStyle;
        break;
      case 3:
        return classes.stageGreenColorBoxStyle;
        break;
      case 2:
        return classes.stageRedColorBoxStyle;
        break;
      case 1:
        return classes.stageBlueColorBoxStyle;
        break;
      case 0:
        return classes.stageYellowColorBoxStyle;
        break;
      default:
        break;
    }
  };

  /* Stage 3, 5, 7, ... table  */
  const handleEvenStages = (data: any, cat: any, levelOfStage: any) => {
    let count = 0;
    levelOfStage = levelOfStage + 1;
    const levelOfStageDividedFive = data.subProducts.map((data1: any) => {
      count = count + 1;

      return (
        <OddStages
          allStageIndex={allStageIndex}
          data={data}
          count={count}
          levelOfStage={levelOfStage}
          handleStyleHeader={handleStyleHeader}
          handleStageTwoModifierEditPageOpen={
            handleStageTwoModifierEditPageOpen
          }
          data1={data1}
          category={category}
          handleOddStages={handleOddStages}
          headerStyleBody={headerStyleBody}
          handleItemDropDownOpen={handleItemDropDownOpen}
          handleItemDropDownClose={handleItemDropDownClose}
          cat={cat}
        />
      );
    });
    return levelOfStageDividedFive;
  };

  /* Stage 2, 4, 5, ... table  */
  const handleOddStages = (cat: any, item: any, levelOfStage: any) => {
    let count = 0;
    levelOfStage = levelOfStage + 1;
    const levelOfStageDividedFive = cat.subProducts.map((data: any) => {
      if (!_.isEmpty(data.stageGroup)) {
        count = count + 1;
        return (
          <EvenStages
            allStageIndex={allStageIndex}
            data={data}
            count={count}
            levelOfStage={levelOfStage}
            handleStyleHeader={handleStyleHeader}
            handleStageOneModifierEditPageOpen={
              handleStageOneModifierEditPageOpen
            }
            handleEvenStages={handleEvenStages}
            category={category}
            item={item}
            headerStyleBody={headerStyleBody}
            handleItemDropDownOpen={handleItemDropDownOpen}
            handleItemDropDownClose={handleItemDropDownClose}
            cat={cat}
          />
        );
      }
    });
    return levelOfStageDividedFive;
  };

  const classes = useStyles();
  return (
    <CardCommon>
      <div style={{ marginBottom: "20px" }}>
        <Grid
          container
          style={{
            marginLeft: "8px",
            marginTop: "16px",
          }}
        >
          {!_.isEmpty(category.data) &&
            category.data.map((cat: any) => {
              return (
                !_.isEmpty(cat.subProducts) &&
                cat.subProducts.map((stage1: any, index: any) => {
                  return (
                    <Grid item xs={12} className={classes.gridButtonStyle}>
                      {/* Only when StageGroup is empty, stage1 details are displayed. */}
                      {stage1.stageGroup === "" ? (
                        <>
                          {/* Show the stage1 using cards */}
                          <CardCommon>
                            <Grid container className={classes.cardColor}>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  placeItems: "center",
                                  paddingLeft: "16px",
                                  justifyContent: "start",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleStageOneModifierEditPageOpen(stage1)
                                }
                              >
                                <Typography>{stage1.title}</Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{
                                  display: "flex",
                                  placeItems: "center",
                                  justifyContent: "start",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleStageOneModifierEditPageOpen(stage1)
                                }
                              >
                                <Typography
                                  style={{
                                    display: "flex",
                                    paddingLeft: "24px",
                                  }}
                                >
                                  Min : {stage1.mealDealMin}
                                </Typography>
                                <Typography
                                  style={{
                                    display: "flex",
                                    paddingLeft: "24px",
                                  }}
                                >
                                  Max : {stage1.mealDealMax}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: "flex",
                                  placeItems: "center",
                                  justifyContent: "start",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleStageOneModifierEditPageOpen(stage1)
                                }
                              >
                                <Typography style={{ paddingLeft: "24px" }}>
                                  {`${stage1.subProducts.length} items`}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  display: "flex",
                                  placeItems: "center",
                                  justifyContent: "end",
                                  paddingRight: "16px",
                                }}
                              >
                                {!allStageIndex.includes(index) ? (
                                  <IconButton
                                    size="small"
                                    className={classes.dropDownButton}
                                    onClick={() =>
                                      handleItemDropDownOpen(index)
                                    }
                                  >
                                    <ArrowDropDownIcon
                                      className={classes.arrowColor}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    className={classes.dropDownButton}
                                    onClick={() =>
                                      handleItemDropDownClose(index)
                                    }
                                  >
                                    <ArrowDropUpIcon />
                                  </IconButton>
                                )}
                              </Grid>
                            </Grid>
                          </CardCommon>
                        </>
                      ) : null}

                      {/* allStageIndex contains all selected menu IDs.*/}
                      {!_.isEmpty(stage1.subProducts) &&
                        allStageIndex.includes(index) &&
                        stage1.subProducts.map((item: any) => (
                          <>
                            {/* Show the stage2 using cards. */}
                            <div
                              style={{
                                marginTop: "4px",
                                paddingLeft: "16px",
                              }}
                            >
                              {" "}
                              <CardCommon>
                                <Grid
                                  container
                                  className={classes.cardColorStage}
                                >
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      display: "flex",
                                      placeItems: "center",
                                    }}
                                    onClick={() =>
                                      handleStageTwoModifierEditPageOpen(
                                        item,
                                        category.menuId,
                                      )
                                    }
                                  >
                                    <Box
                                      className={
                                        classes.stageYellowColorBoxStyle
                                      }
                                    ></Box>
                                    {item.title}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      display: "flex",
                                      placeItems: "center",
                                    }}
                                    onClick={() =>
                                      handleStageTwoModifierEditPageOpen(
                                        item,
                                        category.menuId,
                                      )
                                    }
                                  >
                                    {item.surcharge}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    style={{
                                      display: "flex",
                                      placeItems: "center",
                                    }}
                                    onClick={() =>
                                      handleStageTwoModifierEditPageOpen(
                                        item,
                                        category.menuId,
                                      )
                                    }
                                  >
                                    {item.extraCost}
                                  </Grid>
                                  {!_.isEmpty(item.enableStageGroup) && (
                                    <Grid
                                      item
                                      xs={1}
                                      style={{
                                        display: "flex",
                                        placeItems: "center",
                                        justifyContent: "end",
                                        paddingRight: "16px",
                                      }}
                                    >
                                      {!allStageIndex.includes(item.itemId) ? (
                                        <IconButton
                                          style={{ zIndex: 50 }}
                                          className={classes.dropDownButton}
                                          onClick={() =>
                                            handleItemDropDownOpen(item.itemId)
                                          }
                                        >
                                          <ArrowDropDownIcon
                                            className={classes.arrowColor}
                                          />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          style={{ zIndex: 50 }}
                                          className={classes.dropDownButton}
                                          onClick={() =>
                                            handleItemDropDownClose(item.itemId)
                                          }
                                        >
                                          <ArrowDropUpIcon />
                                        </IconButton>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              </CardCommon>
                              {!_.isEmpty(item.enableStageGroup) &&
                                handleOddStages(cat, item, levelOfStage)}
                            </div>
                          </>
                        ))}
                    </Grid>
                  );
                })
              );
            })}
        </Grid>
      </div>
    </CardCommon>
  );
};

export default withAuthority(ByoModal, Authorities.MENU_READ);
