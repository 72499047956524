import * as React from "react";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import ColorPickerPopup from "../../components/popovers/ColorPickerPopup";
import ColorPickerDialog from "../../components/dialogs/ColorPickerDialog";
import CardCommon from "../../../../components/card/CardCommon";
import { CustomTheme } from "../../../../types/customTheme";

interface Props {
  colorCode: string;
}

const useStyles = makeStyles<any, Props>((theme: CustomTheme) =>
  createStyles({
    root: {
      display: "flex",
      borderRadius: 4,
    },
    colorCircle: {
      borderRadius: "50%",
      width: 40,
      height: 40,
      backgroundColor: ({ colorCode }) => colorCode,
      border: `1px solid ${theme.palette.background.entity_border}`,
    },
  }),
);

export interface ColorSelectionProps {
  title: string;
  colorCode: string;
  setColor: any;
  setInitialData: any;
}

/* Select a color using color picker. */
const ColorSelection: React.FunctionComponent<ColorSelectionProps> = ({
  title,
  colorCode,
  setColor,
  setInitialData,
}) => {
  const [openPicker, setOpenPicker] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const theme = useTheme();
  const open = Boolean(anchorEl);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  /* Open the color picker UI */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /* Close the color picker UI */
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({ colorCode });

  return (
    <>
      {matches ? (
        <ColorPickerDialog
          open={openPicker}
          setOpen={setOpenPicker}
          color={colorCode}
          setColor={setColor}
          setInitialData={setInitialData}
        />
      ) : (
        <ColorPickerPopup
          open={open}
          color={colorCode}
          setColor={setColor}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      )}

      <Grid container>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CardCommon backgroundColor={"entity_background"}>
              <IconButton
                className={classes.root}
                onClick={matches ? () => setOpenPicker(true) : handleClick}
              >
                <div>
                  <div className={classes.colorCircle}></div>
                </div>
              </IconButton>
            </CardCommon>
          </div>
          <Typography align="center" variant="subtitle1">
            {title}
          </Typography>
          <Typography align="center" variant={"subtitle2"}>
            {colorCode}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ColorSelection;
